import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

/*
    Author: Dilraj 2018
    --> Returns error if not no checkbox was checked.
    --> This validator is currently in financial-details.component.html
    --> To use this validator use ngModelGroup in a div outside of all of the checkboxes to capture the group
        and use ngModel = whatever.isChecked inside the <input type=checkbox> field
*/
@Directive({
    selector: '[checkboxValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: CheckboxValidator,
            multi: true
        }
    ]
})
export class CheckboxValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = this.validateRequiredCheckboxInGroup();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    validateRequiredCheckboxInGroup(): ValidatorFn {
        return (group) => { // take the group we declare in the template as a parameter
          if (group) {
            for (const checkboxCtrl in group['controls']) { // check every nested checkbox ctrl
              if (group['controls'][checkboxCtrl].value) { // if any value is true, then return no errors as at least one item is checked
                  return null;
              }
            }
          }
        return { checkboxRequired: true };
        };
      }
}
