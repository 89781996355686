import { forEach, isNil, remove, cloneDeep, find } from 'lodash';

// @created:
// @updated: Mayur Kode

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, OnChanges, Renderer2 } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormService } from '../../shared/service/form.service';
import { FormControl, NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX, LANGUAGE_ENGLISH, specialBypassEmail, State, USA_LABEL } from './../../config/app-config.constants';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Utils } from '../../shared/utils/utils.class';
import { COUNTRY_CODE } from '../../config/app-config.constants';
import { Title } from '@angular/platform-browser';
import { ElementRef } from '@angular/core';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { Subscriber, Observable, Subscription } from 'rxjs';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PrefillService } from '../../shared/service/prefill.service';
import * as moment from 'moment';
const dateFormat = 'YYYY-MM-DD';

@Component({
    selector: 'app-personal-information',
    templateUrl: './personal-information.component.html'
})
export class PersonalInformationComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    private isEnglish: boolean;
    public isFormSubmit: boolean;
    public personalInformation: PersonalInformation;
    public prefixes;
    private allCountriesExceptCanada = [];
    public taxIdentificationNumberMissingReason;
    public isCurrentLangFrench;
    public alternateCitizenship: boolean;
    public alternateCitizenshipSelected: boolean;
    public lang: string;
    public isReferenceCallFailed: Boolean = false;
    public isCash: Boolean = false;
    public isTfsa: Boolean = false;
    public isRRSP: Boolean = false;
    public isSRRSP: Boolean = false;
    public isMargin: Boolean = false;
    public isRRIF: Boolean = false;
    public isCRAtaxresidencyEnabled: boolean = false;
    public isCitizenOfAnyOtherCountry: Boolean = null;
    public sortedCountriesWithUSA: SelectedCountry[] = [];
    public APP_ROUTES = APP_ROUTES;

    public accountSetup;
    public showFinishLater = true;

    public appNumber: any;

    public _isCanadian: State; // Are you a Canadian question, set State as yes/no
    public _usCitizen: string; // Are you a usCitizen question, set State as yes/no

    private _maxCountries: number;
    private _maxTaxResCountries: number;


    public disableContinue: boolean;
    public isCanadaTaxResidency: State;
    public isUsTaxResidency: string;
    public isHideRemainingQuestions: any = false;
    private canada = {
        code: '100000',
        label: 'Canada' // same in French or English translation
    };
    private usa = {
        code: '100001',
    };

    public criticalError = false; // hide and show errors on page
    public systemCriticalError: boolean;
    public saveOperation = true;

    public url: any;
    public disableOnPrefillSin: boolean;
    public disableOnPrefillDOB: boolean;
    public disableOnPrefillName: boolean;

    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    public deletedCountry: any;
    public isSubProductAD: Boolean = null;
    public isBranchAssisted: Boolean = null;
    public isCdbOlbUser: boolean = false;
    public prefillDOB = {
        softWarningMsg: false,
        dateOfBirth: ''
    };
    public prefillSIN = {
        softWarningMsg: false,
        sinNumber: ''
    };

    public usTaxResidentValue: any;

    private onExitApplicationPerformSaveSubscriber;
    public isAMLFunctionalityEnabled: boolean = false
    public isCaaTaxResidencyCertificateEnabled: boolean = false;
    public isEnforceFinishLaterEnabled: Boolean = false;
    public otherCitizenShip: any;
    public ilJourneyUser: any;
    public isSDOwner: boolean = false;

    @ViewChild('personalDetailsForm', { static: true }) personalDetailsForm: NgForm;

    // For accessibility purposes
    @ViewChild('myPrefix', { static: true }) myPrefixCtrl: FormControl;
    @ViewChild('firstnameCtrl', { static: true }) firstnameCtrl: FormControl;
    @ViewChild('initialCtrl', { static: true }) initialCtrl: FormControl;
    @ViewChild('lastnameCtrl', { static: true }) lastnameCtrl: FormControl;
    @ViewChild('preferredFirstNameCtrl', { static: true }) preferredFirstNameCtrl: FormControl;
    @ViewChild('preferredMiddleNameCtrl', { static: true }) preferredMiddleNameCtrl: FormControl;
    @ViewChild('preferredLastNameCtrl', { static: true }) preferredLastNameCtrl: FormControl;
    @ViewChild('dateOfBirthCtrl', { static: true }) dateOfBirthCtrl: FormControl;
    @ViewChild('appSinNumberCtrl', { static: true }) appSinNumberCtrl: FormControl;
    @ViewChild('emailAddressCtrl', { static: true }) emailAddressCtrl: FormControl;
    @ViewChild('canadianCitizenshipCtrl', { static: true }) canadianCitizenshipCtrl: FormControl;
    @ViewChild('otherCitizenshipOrTaxResidencyCtrl', { static: true }) otherCitizenshipOrTaxResidencyCtrl: FormControl;
    @ViewChild('otherCitizenshipCtrl', { static: true }) otherCitizenshipCtrl: FormControl;

    @ViewChild('selectCitizenshipCountryCtrl', { static: true }) selectCitizenshipCountryCtrl: FormControl;
    @ViewChild('citizenOfThisCountryCtrl', { static: true }) citizenOfThisCountryCtrl: FormControl;
    @ViewChild('selectCitizenshipTaxCountryCtrl', { static: true }) SelectCitizenshipTaxCountryCtrl: FormControl;
    @ViewChild('socialSecurityNumberCtrl', { static: true }) socialSecurityNumberCtrl: FormControl;
    @ViewChild('taxIdentificationNumCtrl', { static: true }) taxIdentificationNumCtrl: FormControl;
    @ViewChild('noTaxIdentificationNumCtrl', { static: true }) noTaxIdentificationNumCtrl: FormControl;
    @ViewChild('selectNoTaxReasonCtrl', { static: true }) selectNoTaxReasonCtrl: FormControl;
    @ViewChild('otherNoTaxReasonCtrl', { static: true }) otherNoTaxReasonCtrl: FormControl;
    @ViewChild('residentForThisCountryCtrl', { static: true }) residentForThisCountryCtrl: FormControl;
    @ViewChild('canadianTaxResidentCtrl', { static: true }) canadianTaxResidentCtrl: FormControl;
    @ViewChild('usTaxResidentCtrl', { static: true }) usTaxResidentCtrl: FormControl;
    @ViewChild('residentOrCitizenUSCitizenCntrl', { static: true }) residentOrCitizenUSCitizenCntrl: FormControl;
    @ViewChild('residentOrCitizenUSResidentCtrl', { static: true }) residentOrCitizenUSResidentCtrl: FormControl;
    @ViewChild('appSinNumberUSCtrl', { static: true }) appSinNumberUSCtrl: FormControl;


    constructor(public applicationService: ApplicationService,
        public titleService: Title,
        public formService: FormService,
        private winRef: WindowRefService,
        private route: ActivatedRoute,
        private analytics: AnalyticsService,
        private configService: ConfigResolverService<any>,
        private http: HttpClient,
        private renderer: Renderer2,
        private prefillService: PrefillService) {

        this.isEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.formService.setActiveComponent('personalInformation', this);

        this.url = '/' + APP_ROUTES.personal_information;
        console.log(this.formService.getPayload());
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'personal') {
                    this.onFinishLater();
                }
            });

        this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
            if (value) {
                this.onFinishLater(true);
            }
        })
    }

    ngOnInit() {
        this.isAMLFunctionalityEnabled = this.applicationService.getAMLFunctionalityFlag();
        this.isCaaTaxResidencyCertificateEnabled = this.applicationService.getCaaTaxResidencyCertificateFlag();
        this.isEnforceFinishLaterEnabled = this.applicationService.getEnforceFinishLaterFeatureFlag();
        this.isCRAtaxresidencyEnabled = this.applicationService.getCRAtaxresidencyEnabledFeatureFlag();

        this.disableContinue = ((this.isCash || this.isMargin)) ? true : false;

        this.isCdbOlbUser = Utils.getIsOLBUser();
        this.ilJourneyUser = Utils.getIsJourneyUser();
        this.isSDOwner = Utils.getIsSDOwner();
        this.isBranchAssisted = Utils.getIsBranchAssisted();


        if ((this.isCdbOlbUser || this.ilJourneyUser  || this.isBranchAssisted) && Utils.getPrefillOlbPersonalInfo() && !this.formService.forms.personalInformation.data) {
            // PERSONAL
            this.prefillService.personalInfoPrefill();
            if (!this.formService.forms.contactInformation.data) {
                this.prefillService.contactInfoPrefill();
            }
            if (!this.formService.forms.employmentInformation.data) {
                this.prefillService.getEmployemntInfo();
            }
            Utils.setPrefillOlbPersonalInfo(false);
        }

        // For branch-assisted
        if (!this.isBranchAssisted) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        this.isSubProductAD = Utils.isSubProductAD();
        this.titleService.setTitle(Utils.getPageTitlePersonalInformation());

        this.route.queryParams.subscribe(params => {
            if (params.lang) {
                this.lang = params.lang;
            } else {
                this.lang = 'en';
            }
        });

        this.isCash = Utils.isCash();
        this.isTfsa = Utils.isTFSA();
        this.isRRSP = Utils.isRRSP();
        this.isSRRSP = Utils.isSpousalRRSP();
        this.isMargin = Utils.isMargin();
        this.isRRIF = Utils.isRIF();

        // 3 is the max for the cash, and 2 is the maximum for the TFSA accounts, see the function maxCountries() for more details
        this._maxCountries = (this.isCash || this.isTfsa || this.isMargin) ? 3 : 2;
        this._maxTaxResCountries = (this.isCash || this.isTfsa || this.isMargin) ? 3 : 2;
        /*if 'isCaaTaxResidencyCertificateEnabled' flag is enabled  and
        account type is Cash or Margin then max countries will be 2
        becouse Canada and USA are Yes No Quetions in new feature */
        if (this.isCaaTaxResidencyCertificateEnabled && (this.isCash || this.isMargin)) {
            this._maxCountries = 2

        }

        this.applicationService.fetchReferences(this.route);

        this.alternateCitizenship = false;
        this.alternateCitizenshipSelected = false;
        if (!isNil(this.applicationService.appReferences)) {
            if (this.applicationService.appReferences.isReferenceCallFailed) {
                this.isReferenceCallFailed = true;
            } else {

                this.taxIdentificationNumberMissingReason =
                    this.applicationService.appReferences.references.taxIdentificationNumberMissingReason;

                SelectedCountry.prototype.taxIdentificationNumberMissingReason = this.taxIdentificationNumberMissingReason;
                SelectedCountry.prototype.isEnglish = this.isEnglish;

                this.prefixes = this.applicationService.appReferences.references.titles
                    .filter((title) => title.expired === undefined || title.expired === false);

                if (this.applicationService.appReferences.references.countries) {
                    this.allCountriesExceptCanada = this.applicationService.appReferences.references.countries
                        .filter(x => x.code !== this.canada.code);  // filter Canada out because now it's a yes / no question
                    // also filter out usa now , because usa is a separate question.
                    // filter USA out because now it's a yes / no question
                    if (this.isCaaTaxResidencyCertificateEnabled && (this.isCash || this.isMargin)) {
                        this.allCountriesExceptCanada = this.allCountriesExceptCanada.filter(x => x.code !== this.usa.code);
                    }
                    // sort all countries labels except the zeroth item because that's the USA and it must remain first.
                    this.allCountriesExceptCanada = [this.allCountriesExceptCanada[0]]
                        .concat(this.allCountriesExceptCanada.slice(1).sort(function (a, b) {
                            return Intl.Collator().compare(a['label'], (b['label']));
                        }));
                }
            }
        }

        if (this.formService.forms.personalInformation.data != null) {
            // already visited form and now visiting back again
            this.personalInformation = this.formService.getFormData('personalInformation');

            //Set Data for Canada Tax Residency when visiting back again on click of back button
            this.setCanadianTaxResidency();

            //Set data for US Tax Residency
            if (this.isCaaTaxResidencyCertificateEnabled && (this.isCash || this.isMargin)) {
                if (!this.isCRAtaxresidencyEnabled || (this.isCRAtaxresidencyEnabled && this.personalInformation.otherCitizenshipOrTaxResidency)) {
                    this.setUsTaxResidency();
                }

                if (this.isCRAtaxresidencyEnabled) {
                    let prefillCitizenShip: any = this.setCitizenhipsForPreFill() || [];


                    this.personalInformation.selectedCitizenCountries = this.personalInformation.selectedCitizenCountries.concat(prefillCitizenShip);
                    this.personalInformation.selectedCitizenCountries = this.getuniqueValues(this.personalInformation.selectedCitizenCountries)

                    const containsNonUSAorNonCAD = (this.personalInformation.selectedCitizenCountries || []).filter(country => country.code && country.code != COUNTRY_CODE.USA && country.code != COUNTRY_CODE.CANADA).length > 0;

                    this.otherCitizenShip = containsNonUSAorNonCAD ? 'Yes' : 'No';
                    this.upDateCitizenShipCountry();


                    if (this.personalInformation.usTaxResidentCheckBox) {
                        this.usTaxResidentValue = 'Yes';
                        if (this.isCash || this.isTfsa || this.isMargin) {
                            this._maxTaxResCountries = 2;
                        }
                    } else {
                        this.usTaxResidentValue = 'No';
                        if (this.isCash || this.isTfsa || this.isMargin) {
                            this._maxTaxResCountries = 3;
                        }

                    }

                    if (this.personalInformation.usResidentCheckBox != null) {
                        this._usCitizen = Utils.yesOrNoRadioMapper(this.personalInformation.usResidentCheckBox);
                    } else {
                        this._usCitizen = State.no;
                    }

                    this.setUsCitizenshipField();
                }
            } else {
                if ((this.personalInformation.selectedCountries || []).length > 0) {
                    this.personalInformation.selectedCountries.forEach(country => {
                        country.countries = this.allCountriesExceptCanada;
                    });
                }
            }
            this.personalInformation.AML_ENABLED = undefined;
        } else {
            this.personalInformation = new PersonalInformation();  // coming to form for the first time
        }

        if (this.lang === 'en') {
            this.personalInformation.currentLanguage = '100';
        } else if (this.lang === 'fr') {
            this.personalInformation.currentLanguage = '100001';
        }

        if (this.isCdbOlbUser && (!this.personalInformation.firstname || !this.personalInformation.lastname)) {
            this.systemCriticalError = true;
        }

        // if there is a citizenship code set _isCanadian state to no by default unless the code is Canada
        /**To fix MWB-10737 */
        if (this.personalInformation.citizenship && this.personalInformation.citizenship.length > 0
            || this.personalInformation.alternateCitizenshipLabelTFSACash || this.personalInformation.alternateCitizenshipLabelRRSP) {
            this._isCanadian = State.no;
            // set Canadian Citizenship
            if (this.personalInformation.citizenship === this.canada.code) {
                this._isCanadian = State.yes;
            }
        }
        if (this.personalInformation.citizenship === undefined) {
            this._isCanadian = State.no
        }


        // if this is the first time to the page, add an empty object to the selectedCountries
        if ((this.personalInformation.selectedCountries && this.personalInformation.selectedCountries.length === 0) || (this.personalInformation.selectedCitizenCountries && this.personalInformation.selectedCitizenCountries.length === 0)) {
            this.addAnotherCountry();
        }

        this.formService.forms.personalInformation.isVisited = true;
        this.formService.setActiveForm(this.personalDetailsForm);
        this.isCitizenofAnyCountry(); // On load npeeds to be checked

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data === 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

        if (Utils.getIsBranchAssisted()) {
            if (this.personalInformation.sinNumber) {
                this.disableOnPrefillSin = true;
            } if (this.personalInformation.dateOfBirth) {
                this.disableOnPrefillDOB = true;
            }
            this.disableOnPrefillName = true;

            this.updateFilteredCountriesList(); // update country list

        }

        setTimeout(() => {
            this.setGTM();
        }, 200);

        if(this.ilJourneyUser && !this.formService.forms.personalInformation.isVisited){
            this.personalInformation.dateOfBirth = '';
            this.personalInformation.sinNumber = '';
        }

        if (this.isCdbOlbUser || this.ilJourneyUser) {
            this.getPrefillDOBandSINInfo();
        }

        let vlauedateOfBirth = this.personalInformation.dateOfBirth;
        if (vlauedateOfBirth) {
            let ValiddateOfBirth = moment(vlauedateOfBirth, dateFormat, true).isValid();
            if (!ValiddateOfBirth) {
                this.personalInformation.dateOfBirth = '';
            }
        }
    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data === 'clicked on ' + this.url) {

                if (this.personalDetailsForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }
    getPrefillDOBandSINInfo() {
        const prefillOlbDOBandSIN = Utils.getPrefillOlbDOBandSIN();
        if (prefillOlbDOBandSIN) {
            this.prefillDOB = {
                softWarningMsg: true,
                dateOfBirth: prefillOlbDOBandSIN.dateOfBirth
            };
            this.prefillSIN = {
                softWarningMsg: true,
                sinNumber: prefillOlbDOBandSIN.socialInsuranceNumber
            };
        } else if (this.prefillService.primaryApplicant) {
            this.prefillDOB = {
                softWarningMsg: true,
                dateOfBirth: this.prefillService.primaryApplicant.personal.identity.dateOfBirth
            };
            this.prefillSIN = {
                softWarningMsg: true,
                sinNumber: this.prefillService.primaryApplicant.personal.identity.socialInsuranceNumber
            };
        }

    }

    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }

    ngAfterViewInit() {

        // this.analytics.setState('step 4');
        this.analytics.setPageState(this.url);
        // this.analytics.setPPVID(ANALYTICS.personal_information);
        // this.analytics.setEvent(ANALYTICS.BMOVPageView);
        this.analytics.triggerView('PersonalInformation');
        setTimeout(() => {
            this.checkAgeOfMajority();

            if (this.personalDetailsForm.controls['dateOfBirth']) {
                this.personalDetailsForm.controls['dateOfBirth'].valueChanges.subscribe(value => {
                    this.checkAgeOfMajority();
                });
            }

        }, 100);

        this.checkFormValidityOnSignOut();
    }

    // To check if applicant has age of majority for province
    checkAgeOfMajority() {
        // sometimes during refresh, data doesnt exist, so using &&
        const provice = this.formService.forms.contactInformation.data &&
            this.formService.forms.contactInformation.data.province;

        const dobCtrl = this.personalDetailsForm.controls['dateOfBirth'];

        if (dobCtrl) {
            // If NOT of majority then set error flag.
            if (!this.formService.isAgeOfMajorityForProvince(this.personalInformation.dateOfBirth, provice)) {
                dobCtrl.setErrors({ notAgeOfMajority: true });
            } else if (dobCtrl.errors && dobCtrl.errors['notAgeOfMajority']) {
                dobCtrl.setErrors(null);
            }
        }
    }
    updAnalytics(reasonSelected: any) {
        let reasonSelectedDesc;
        reasonSelectedDesc = this.taxIdentificationNumberMissingReason.filter(x => x.code == reasonSelected);
        this.analytics.triggerView('SpouseEmploymentStatus' + reasonSelectedDesc[0].label);
    }
    private resetCitizenshipFields() {
        if (this.isNotCanadian) {
            if (!this.isCaaTaxResidencyCertificateEnabled || (this.isCaaTaxResidencyCertificateEnabled && !this.isCash && !this.isMargin)) {
                this.personalInformation.otherCitizenshipOrTaxResidency = '';
            }
        }
        if (this.isCanadian && this.personalInformation.otherCitizenshipOrTaxResidency === State.no) {
            this.personalInformation.alternateCitizenshipLabelTFSACash = [];
            this.personalInformation.selectedCountries = [];
        }
        // Reset Citizenship, not necessary but ensures that incorrect data is never set for US/non-US countries
        if (this.personalInformation && this.personalInformation.selectedCountries) {
            // this.personalInformation.otherCitizenshipOrTaxResidency = 'Yes';
            for (let i = 0; i < this.personalInformation.selectedCountries.length; i++) {
                if (this.personalInformation.selectedCountries[i].code === this.usa.code) { // IF US rest others fields
                    this.personalInformation.selectedCountries[i].isTaxDisabled = false;
                    this.personalInformation.selectedCountries[i].tin = '';
                    this.personalInformation.selectedCountries[i].reasonSelected = '';
                    this.personalInformation.selectedCountries[i].otherReason = '';
                } else {
                    this.personalInformation.selectedCountries[i].ssn = '';
                }

            }
        }
    }

    public setCitizenhipsForPreFill() {
        let tempArray: any = [];

        (this.personalInformation.selectedCountries || []).forEach(country => {
            let duplicateItem: any = this.personalInformation.selectedCitizenCountries.filter(citzenCountry => {
                return country.code == citzenCountry.code
            })

            if (country.isCitizen && (duplicateItem || []).length == 0) {
                tempArray.push(cloneDeep(country));
            }

        });

        return tempArray;
    }

    getuniqueValues(obj: any) {
        const uniqueArray = (obj || []).filter((value, index) => {
            const _value = (value.code);
            return index === obj.findIndex(obj => {
                return obj.code == _value;
            });
        });
        return uniqueArray;
    }

    private setCitizenship() {

        if (this.isRRSP || this.isSRRSP || this.isCash || this.isTfsa || this.isRRIF) {
            // begin by resetting alternativeCitizenship
            this.personalInformation.alternateCitizenship = '';
            this.personalInformation.alternateCitizenshipLabelRRSP = '';

            if (this._isCanadian === State.no && this.personalInformation.selectedCountries.length > 0) {

                if (this.isCaaTaxResidencyCertificateEnabled && (this.isCash || this.isMargin)) {
                    let isCitizenShipSelected: any = false;
                    (this.personalInformation.selectedCountries || []).forEach((country, index) => {
                        if (country.isCitizen && !isCitizenShipSelected) {
                            this.personalInformation.citizenship = country.code;
                            this.personalInformation.citizenshipLabel = country.countryLabel;
                            isCitizenShipSelected = true;
                        }
                    });
                } else if(!this.isTfsa) {
                    this.personalInformation.citizenship = this.personalInformation.selectedCountries[0].code;
                    this.personalInformation.citizenshipLabel = this.personalInformation.selectedCountries[0].countryLabel;
                }

                // if RRSP has two countries
                if (this.personalInformation?.selectedCountries?.length >= 2 && !this.isCash && !this.isMargin && !this.isTfsa && (this.isRRSP || this.isRRIF || this.isSRRSP)) {
                    this.personalInformation.alternateCitizenship = this.personalInformation.selectedCountries[1].code;
                    this.personalInformation.alternateCitizenshipLabelRRSP = this.personalInformation.selectedCountries[1].countryLabel;
                }
                this.setUsaCitizenship(); // To set USA as citizenship in review page , when no citizenship is chosen other than USA
                return;
            }
            // should only be one other country at most in RRSP
            if (this._isCanadian === State.yes && this.personalInformation.otherCitizenshipOrTaxResidency === State.yes && !this.isCash && !this.isMargin && !this.isTfsa && (this.isRRSP || this.isRRIF || this.isSRRSP)) {
                this.personalInformation.alternateCitizenship = this.personalInformation.selectedCountries[0].code;
                this.personalInformation.alternateCitizenshipLabelRRSP = this.personalInformation.selectedCountries[0].countryLabel;
                return;
            }
        }

        if (this.isCash || this.isTfsa || this.isMargin) {
            if (this._isCanadian === State.no) {
                // reset Canadian citizenship labels
                this.personalInformation.citizenship = ' '; // send empty if not Canadian
                this.personalInformation.citizenshipLabel = '';
                this.setUsaCitizenship()
            }

            if (this._isCanadian === State.no && this.personalInformation.otherCitizenshipOrTaxResidency !== State.no) {
                this.personalInformation.alternateCitizenshipLabelTFSACash = this.personalInformation.selectedCountries
                    .map(x => x.countryCitizenshipTaxResidencyString);
            }
        }
    }

    public upDateCitizenShipCountry() {
        //let tempArray: any = []

        (this.personalInformation.selectedCitizenCountries || []).forEach((countryObj: any) => {

            const filterCitizeCountryList: any = this.allCountriesExceptCanada.filter(x => x.code !== COUNTRY_CODE.USA);
            countryObj.countries = filterCitizeCountryList;
        });
    }
    hideAccountSetup() {
        this.accountSetup = false;
    }
    setUsaCitizenship() {
        if ((this.isCash || this.isMargin) && this.isCaaTaxResidencyCertificateEnabled) {
            if (this._isCanadian === State.no && this.personalInformation.usResidentCheckBox && this.personalInformation.selectedCountries.length > 0) {
                this.personalInformation.citizenship = COUNTRY_CODE.USA
                this.personalInformation.citizenshipLabel = USA_LABEL;
            }
        }
    }
    checkForAMLEnabled() {
        if ((this.isCdbOlbUser || this.ilJourneyUser) && this.dateOfBirthCtrl.valid && this.appSinNumberCtrl.valid
            && ((this.prefillDOB.dateOfBirth && this.prefillDOB.dateOfBirth !== this.personalInformation.dateOfBirth)
                || (this.prefillSIN.sinNumber && this.prefillSIN.sinNumber !== this.personalInformation.sinNumber))
        ) {
            this.personalInformation.AML_ENABLED = false;

        }
    }

    async onFinishLater(isExitModal = false) {
        this.isFormSubmit = true;

        if (!this.personalDetailsForm.valid) {
            if (isExitModal) {
                this.applicationService.setExitApplicationModal(true);
            } else {
                this.setFocus();
            }
        }

        this.resetComponentObjectAttr();

        try {
            await this.briteVerifyEmail();
        }
        catch (error) {
            console.log(error);
        }
        if (this.personalDetailsForm.valid) {
            this.setCitizenship();
            if (this.isCRAtaxresidencyEnabled) {
                this.mapUsTaxResident();
            }
            this.resetCitizenshipFields();
            this.sortCourtries();
            this.formService.forms.personalInformation.isCompleted = true;
            this.formService.forms.personalInformation.isVisited = true;

            this.checkForAMLEnabled();

            this.formService.setFormData('personalInformation', this.personalInformation);

            // save current page if form is valid
            this.formService.getPayload().setPersonalInformation(this.personalInformation);

            this.applicationService.save(this.formService.getPayload())
                .then((res: any) => {

                    if (res.status === SUCCESS_RESPONSE) {
                        const saveResponse = res.body;
                        this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true });
                    } else {
                        this.criticalError = true;
                    }
                })
                .catch(err => {
                    // console.log('onFinishLater err: ', err);
                    this.criticalError = true;
                });
        }

    }

    async saveOnSignOut() {
        try {
            await this.briteVerifyEmail();
        }
        catch (error) {
            console.log(error);
        }
        this.resetComponentObjectAttr();
        this.setCitizenship();
        if (this.isCRAtaxresidencyEnabled) {
            this.mapUsTaxResident();
        }
        this.resetCitizenshipFields();
        this.sortCourtries();
        this.formService.forms.personalInformation.isCompleted = true;
        this.formService.forms.personalInformation.isVisited = true;

        this.checkForAMLEnabled();

        this.formService.setFormData('personalInformation', this.personalInformation);

        // save current page if form is valid
        if (this.personalDetailsForm.valid) {
            this.formService.getPayload().setPersonalInformation(this.personalInformation);
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true;
            });


    }

    briteVerifyEmail() {
        const emailAddressCtrl = this.personalDetailsForm.controls['emailAddress'];
        const config = this.configService.getConfig();
        let everestApiCall = config.briteVerify;
        if(!this.personalInformation.emailAddress) {
            return emailAddressCtrl.setErrors({ 'errors': true });
            }
      if (this.personalInformation.emailAddress.toLowerCase() === specialBypassEmail && !this.isSubProductAD) {
            return emailAddressCtrl.setErrors(null);
        }
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "ApiKey: "+Utils.everestApiKey);

        let raw = JSON.stringify({
        "email": this.personalInformation.emailAddress
        });
      let  requestOptions = {};
        requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        let responseData;
       return responseData = fetch(everestApiCall, requestOptions)
        .then(response => response.text())
        .then((data : any ) => {
           let result = JSON.parse(data).email;
        if ((result.error_code === undefined && result.disposable == false && result.role_address == false) || result.status == "Unknown") {
            emailAddressCtrl.setErrors(null);
        }
        else {
            emailAddressCtrl.setErrors({ 'errors': true });
        }

        })
        .catch(error => { 
            emailAddressCtrl.setErrors(null);
        });



        // let response;
        // const emailAddressCtrl = this.personalDetailsForm.controls['emailAddress'];
        // const config = this.configService.getConfig();
        // let everestApiCall = config.briteVerify;
        // const updatedUrl = everestApiCall.replace("email&", this.personalInformation.emailAddress + '&').replace("briteVerifyAPIKey", Utils.everestApiKey);
        // if (this.personalInformation.emailAddress.toLowerCase() === specialBypassEmail && !this.isSubProductAD) {
        //     return emailAddressCtrl.setErrors(null);
        // }
        // return response = $.ajax({
        //     url: updatedUrl, dataType: 'jsonp', success: function (response) {
        //         if ((response.error_code === undefined && response.disposable == false && response.role_address == false) || response.status == "Unknown") {
        //             emailAddressCtrl.setErrors(null);
        //         }
        //         else {
        //             emailAddressCtrl.setErrors({ 'errors': true });
        //         }
        //     },
        //     error: function (error) {
        //         emailAddressCtrl.setErrors(null);
        //     }
        // });
    }
    onPreferredNameToggle() {
        this.personalInformation.likeToUsePreferredName = !this.personalInformation.likeToUsePreferredName;
    }

    async onSubmit() {

        // For accessibility purposes, set focus on the invalid element.
        if (!this.personalDetailsForm.valid) {
            this.setFocus();
        }
        if (((this.isCash || this.isMargin) && this.isCaaTaxResidencyCertificateEnabled) && this.checkCitizenship()) {
            this.personalDetailsForm.controls['canadianCitizenship'].setErrors({ isCitizenError: true })
        }


        this.resetComponentObjectAttr();
        // this.isCitizenofAnyCountry();

        this.isFormSubmit = true;
        try {
            await this.briteVerifyEmail();
        }
        catch (error) {
            console.log(error);
        }
        if (this.personalDetailsForm.valid && ((this.isCash || this.isMargin) && this.isCaaTaxResidencyCertificateEnabled ? !this.isHideRemainingQuestions : true)) { //
            this.setCitizenship();
            if (this.isCRAtaxresidencyEnabled) {
                this.mapUsTaxResident();
            }
            if (this.isCRAtaxresidencyEnabled) {
                if ((this.personalInformation.selectedCountries || []).length > this.maxTaxResidencyCountries && (this.usTaxResidentValue == State.yes || this.usTaxResidentValue == State.yesFrench)) {
                    this.personalInformation.selectedCountries.splice((this.personalInformation.selectedCountries || []).length - 1, 1);

                }
                if ((this.personalInformation.selectedCitizenCountries || []).length > this.maxCountries && this.selectedStateYes(this._usCitizen)) {
                    this.personalInformation.selectedCitizenCountries.splice((this.personalInformation.selectedCitizenCountries || []).length - 1, 1);
                }
            }

            this.resetCitizenshipFields();
            this.sortCourtries();
            this.formService.forms.personalInformation.isCompleted = true;
            this.formService.forms.personalInformation.isVisited = true;
            this.checkForAMLEnabled();
            this.formService.setFormData('personalInformation', this.personalInformation);
            this.formService.getPayload().setPersonalInformation(this.personalInformation);
            if (Utils.getIsBranchAssisted() && Utils.getIsBranchReferral()) {
                this.formService.getPayload().setBranchBMORelationship(true); // If Branch Assisted  set BMO Relationship to true
            }
            if (!this.isEnforceFinishLaterEnabled || Utils.getIsBranchAssisted() || Utils.getIsOLBUser() || this.ilJourneyUser) {
                this.applicationService.autoSave(this.personalDetailsForm, APP_ROUTES.contact_information);
            }
            if (Utils.getIsInSession() === false) {
                if (this.isEnforceFinishLaterEnabled && !Utils.getIsBranchAssisted() && !Utils.getIsOLBUser() && !this.ilJourneyUser) {
                    this.applicationService.setPartialSave('personal');
                } else {
                    this.applicationService.saveAndcontinue(this.personalDetailsForm, APP_ROUTES.contact_information);
                }

            } else if (Utils.getIsInSession() === true) {
                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        if (res.status === SUCCESS_RESPONSE) {
                            this.applicationService.saveAndcontinue(this.personalDetailsForm, APP_ROUTES.contact_information);
                        } else {
                            this.criticalError = true;
                        }
                        this.applicationService.saveAndcontinue(this.personalDetailsForm, APP_ROUTES.contact_information);
                    })
                    .catch(err => {
                        this.criticalError = true;
                        // Remove this line
                        // adding this line so that testers can continue testing even if issues on backend
                        // this.applicationService.saveAndcontinue(null, APP_ROUTES.terms_conditions);
                    });
            }
        }
    }


    sortCourtries() {  // Method to sort USA on top of  the array to save in RIS
        this.sortedCountriesWithUSA = [];
        this.personalInformation.selectedCountries.forEach((element, index) => {
            if (element.code === COUNTRY_CODE.USA) {
                this.sortedCountriesWithUSA.push(element); // Pushing USA to a new array
                this.personalInformation.selectedCountries.splice(index, 1); // Removing  USA from  Old array
            }
        });
        // concating US and nonUS arrays
        this.personalInformation.selectedCountries = this.sortedCountriesWithUSA.concat(this.personalInformation.selectedCountries);

    }



    selectPrefix(prefix) {
        this.personalInformation.prefixLabel = prefix.label;
    }

    resetComponentObjectAttr() {
        if (this.personalInformation.likeToUsePreferredName === false) {
            // if user unchecks preferred names checkbox, it will remove values from respective field
            this.personalInformation.preferredFirstName = null;
            this.personalInformation.preferredLastName = null;
            this.personalInformation.preferredMiddleName = null;
        }
    }
    ngOnDestroy() {

        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.setCitizenship(); // if user clicks on navigation window reset citizenship and fields
        if (this.isCRAtaxresidencyEnabled) {
            this.mapUsTaxResident();
        }
        this.resetCitizenshipFields();
        this.checkForAMLEnabled();
        this.formService.setFormData('personalInformation', this.personalInformation);
        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();

    }

    // returns maximum countries each account type can have
    get maxCountries() {
        return (this._isCanadian === State.yes && (this.isRRSP || this.isSRRSP || this.isRRIF) && (!this.isCRAtaxresidencyEnabled && !(this.isCash || this.isMargin))) ? this._maxCountries - 1 : this._maxCountries;
    }
    get maxTaxResidencyCountries() {
        return (this.usTaxResidentValue === State.yes && (this.isRRSP || this.isSRRSP || this.isRRIF) && !(this.isCash || this.isMargin)) ? this._maxTaxResCountries - 1 : this._maxTaxResCountries;
    }

    public showAddCountryButton(countrySelection?) {
        let showAddButton: any = false;
        if (!this.isCRAtaxresidencyEnabled) {
            showAddButton = (((this.personalInformation?.otherCitizenshipOrTaxResidency == 'Yes' || this.isNotCanadian) && this.personalInformation.selectedCountries?.length)) < this.maxCountries
        } else {
            if (!countrySelection) {

                if (this.isCash || this.isMargin) {
                    showAddButton = this.personalInformation.otherCitizenshipOrTaxResidency == 'Yes' && (this.personalInformation.selectedCountries || []).length < this.maxTaxResidencyCountries && (this.isValueNull(this.personalInformation.isCanadaTaxResidency) || this.personalInformation.isCanadaTaxResidency == 'Yes')

                } else {

                    showAddButton = (this.personalInformation.selectedCountries || []).length < this.maxCountries && (this.isValueNull(this.personalInformation.isCanadaTaxResidency) || this.personalInformation.isCanadaTaxResidency == 'Yes')
                }

            } else {
                showAddButton = (this.personalInformation.selectedCitizenCountries || []).length < this.maxCountries
            }

        }
        return showAddButton;
    }
    private addAnotherCountry(isCountrySelection?, uniqueID?) {
        if ((this.isCRAtaxresidencyEnabled && (this.otherCitizenShip == "Yes" || this.isValueNull(this.otherCitizenShip)) && (this.isValueNull(isCountrySelection) || isCountrySelection))) {

            if (!this.isValueNull(isCountrySelection) || (this.personalInformation.selectedCitizenCountries || []).length == 0) {
                this.personalInformation.selectedCitizenCountries.push(
                    new SelectedCitizenCountryList(this.personalInformation.citizenCountryIndex++)
                )
            }


            this.updateFilteredCountriesList();

            setTimeout(() => {
                let id = 'citizenCountry' + (this.personalInformation.citizenCountryIndex - 1) + "_" + uniqueID;
                var docEle = document.getElementById(id);
                if (docEle)
                    docEle.focus();
            }, 200);

        } //else {

        if ((!isCountrySelection || !this.isCRAtaxresidencyEnabled)) {
            if ((this.personalInformation.selectedCountries || []).length == 0 || !this.isValueNull(isCountrySelection))
                this.personalInformation.selectedCountries.push(
                    new SelectedCountry(this.personalInformation.countryIndex++)
                );
            this.updateFilteredCountriesList();
            setTimeout(() => {
                let id = 'country' + (this.personalInformation.countryIndex - 1);
                var docEle = document.getElementById(id);
                if (docEle)
                    docEle.focus();
            }, 200);
        }

        // }

    }


    citizenshipChange(index, option?) {
        this.analytics.triggerView('citizenOfThisCountry' + this.personalInformation.selectedCountries[index].isCitizen);
        this.taxCheckforUS(index);
        this.isCitizenofAnyCountry();
        if (this.isCitizenOfAnyOtherCountry && (this.isCash || this.isMargin) && this.isCaaTaxResidencyCertificateEnabled) {
            this.personalDetailsForm?.controls['canadianCitizenship']?.setErrors(null) // Remove error , if the user has citizenship
        }
    }

    // based upon what the "selectedCountries: SelectedCountry[]" list updates SelectedCountry.countries by removing
    // the country or countries the other objects contain
    private updateFilteredCountriesList(index?, isCountrySelection?, countryOpt?: any) {

        if (index != null) {
            this.clearCitizenShipFields(index, isCountrySelection);
            this.taxCheckforUS(index);
        }

        if (this.isCRAtaxresidencyEnabled) {
            if (isCountrySelection) {
                this.personalInformation.selectedCitizenCountries.push(countryOpt);
                this.personalInformation.selectedCitizenCountries = this.personalInformation.selectedCitizenCountries.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                })

            } else if (!isCountrySelection && !this.isValueNull(isCountrySelection) && (this.isCash || this.isMargin)) {
                countryOpt.isTaxResident = true;
            }
        }

        this.isCitizenofAnyCountry();
        for (const currentCountry of this.personalInformation.selectedCountries) {
            const otherCountries = this.personalInformation.selectedCountries.map(x => x.code).filter(y => y !== currentCountry.code);
            const filterCountryList = this.allCountriesExceptCanada.filter(x => otherCountries.indexOf(x.code) < 0);
            currentCountry.countries = filterCountryList;
            if (this.isCRAtaxresidencyEnabled && (this.isCash || this.isMargin)) {
                if (find(this.personalInformation.selectedCitizenCountries, { code: currentCountry.code })) {
                    currentCountry.isCitizen = true;
                } else {
                    currentCountry.isCitizen = false;
                }
            }
        }


        for (const currentCitizenCountry of this.personalInformation.selectedCitizenCountries) {
            const otherCountries = this.personalInformation.selectedCitizenCountries.map(x => x.code).filter(y => y !== currentCitizenCountry.code);
            const filterCitizeCountryList: any = this.allCountriesExceptCanada.filter(x => otherCountries.indexOf(x.code) < 0);
            currentCitizenCountry.countries = filterCitizeCountryList;

        }


    }



    removeSelectedCountry(index?, isCountrySelection?) {
        if (this.isCRAtaxresidencyEnabled && isCountrySelection) {
            this.personalInformation.selectedCitizenCountries.splice(index, 1)

        } else if (!this.isCRAtaxresidencyEnabled || (this.isCRAtaxresidencyEnabled && !isCountrySelection)) {
            if (this.personalInformation.selectedCountries[index].countryLabel) {
                this.deletedCountry = this.personalInformation.selectedCountries[index].countryLabel;
                this.addAccessTextToDom('#deleteCountryButton');
            } else {
                this.addAccessTextToDom('#emptyCountryDeleteButton');
            }
            this.personalInformation.selectedCountries.splice(index, 1);
            this.updateFilteredCountriesList();
        }

    }

    addAccessTextToDom(id: any) {
        if (!id) {
            return;
        }
        const ele = this.renderer.selectRootElement(id, true);
        if (ele) {
            this.renderer.setStyle(ele, 'display', 'block');
            setTimeout(() => {
                this.renderer.setStyle(ele, 'display', 'none');
            }, 5000);
        }
    }

    get isCanadian() {
        return (this._isCanadian === State.yes);
    }
    get isNotCanadian() {
        return (this._isCanadian === State.no);
    }

    // used inconjunction with error messages in the check box fields for citizenship
    // check is citizen of any country else set error if true
    isCitizenofAnyCountry() {

        if (this._isCanadian === State.no) {
            for (const currentCountry of this.personalInformation.selectedCountries) {
                if (currentCountry.isCitizen === true) {
                    this.isCitizenOfAnyOtherCountry = true;
                    return true;
                } else {
                    this.isCitizenOfAnyOtherCountry = false;
                }
            }
        } else {
            this.isCitizenOfAnyOtherCountry = true;
        }
    }
    setOtherCitizenship() {
        if (this.selectedStateYes(this.otherCitizenShip)) {
            this.personalDetailsForm?.controls['canadianCitizenship']?.setErrors(null)
        }

        this.analytics.triggerView('IsOtherCitizen' + this.personalInformation.otherCitizenshipOrTaxResidency);
        if (this.isCash || this.isMargin && this.isCaaTaxResidencyCertificateEnabled && (this.personalInformation.otherCitizenshipOrTaxResidency === State.no || this.otherCitizenShip == "No")) {
            this.removeCitizenshipFromCountries() //removeCitizenship from all countries , when user clicks on No ,we are persisting it even if we select no , leading to unnecessary validation errors in other scenarios
        }
    }
    removeCitizenshipFromCountries() {
        if (!this.isCRAtaxresidencyEnabled || this.personalInformation?.otherCitizenshipOrTaxResidency === State.no) {
            (this.personalInformation.selectedCountries || []).forEach((country, index) => {
                country.isCitizen = false
                country.code = '',
                    country.isTaxResident = false
                country.noTaxIdentificationNum = false;
                country.tin = '',
                    country.otherReason = ''
                country.isTaxDisabled = false
                country.reasonSelected = ''
            });
        }

        if (this.isCRAtaxresidencyEnabled && this.otherCitizenShip == "No") {
            (this.personalInformation.selectedCitizenCountries || []).forEach((country, index) => {
                country.code = null
            });
        }
    }
    public isValueNull(value) {
        if (value === '' || value === undefined || value === null) {
            return true;
        }
        return false;
    }
    setCanadianTaxResidency() {
        this.analytics.triggerView('IsCanadaTaxResidency' + this.personalInformation.isCanadaTaxResidency);
        if (this.isCaaTaxResidencyCertificateEnabled && (this.isCash || this.isMargin)) {
            if (!this.isValueNull(this.personalInformation.isCanadaTaxResidency) && this.personalInformation.isCanadaTaxResidency === State.yes || this.isValueNull(this.personalInformation.isCanadaTaxResidency)) {
                // this.disableContinue = false; fix as per MWB-15608
                // if(!this.isCRAtaxresidencyEnabled) {
                this.isHideRemainingQuestions = false;
                //  }

            } else {
                // this.disableContinue = true; fix as per MWB-15608
                //    if(!this.isCRAtaxresidencyEnabled) {
                this.isHideRemainingQuestions = true;
                // }
            }
        }
    }

    setUsTaxResidencyMaxCountry() {
        this._maxTaxResCountries = 2;
        if (this.isCRAtaxresidencyEnabled) {
            if (this.selectedStateYes(this.usTaxResidentValue)) {
                this._maxTaxResCountries = 2;
            } else {
                this._maxTaxResCountries = 3;
            }
        }
    }

    setUsCitizenshipField(userSelect = false) {
        this._maxCountries = 2;
        this.personalInformation.usResidentCheckBox = this.selectedStateYes(this._usCitizen);
        if (this.selectedStateYes(this._usCitizen)) {
            this.personalDetailsForm?.controls['canadianCitizenship']?.setErrors(null)
            this.usTaxResidentValue = 'Yes';
            this._maxTaxResCountries = 2;
        } else if (this.selectedStateNo(this._usCitizen)) {
            if (userSelect) {
                this.usTaxResidentValue = null;
            }
            this._maxCountries = 3;
            if (!this.isCRAtaxresidencyEnabled) {
                this.personalInformation.usSINNumber = '';
            }

        }
    }

    selectedStateYes(data) {
        return [State.yes, State.yesFrench].indexOf(data) > -1
    }

    selectedStateNo(data) {
        return [State.no, State.noFrench].indexOf(data) > -1
    }

    mapUsTaxResident() {
        if (this.usTaxResidentValue == State.yes) {
            this.personalInformation.usTaxResidentCheckBox = true;
        } else {
            this.personalInformation.usTaxResidentCheckBox = false;
        }
    }


    setUsTaxResidency() {
        if ((this.personalInformation.usCitizenship == State.no || this.personalInformation.usCitizenship == State.noFrench) && !this.isCRAtaxresidencyEnabled) {
            this.personalInformation.usSINNumber = '',
                this.personalInformation.usResidentCheckBox = false;
            this.personalInformation.usTaxResidentCheckBox = false;
        }

        this._maxCountries = this.personalInformation.usCitizenship == State.no || this.personalInformation.usCitizenship == State.noFrench ? 3 : 2;

        if (this.personalInformation.usResidentCheckBox) {
            this.personalDetailsForm?.controls['canadianCitizenship']?.setErrors(null)
            this.personalInformation.usTaxResidentCheckBox = true;
        }
    }


    setCanadianCitizenship() {

        this.analytics.triggerView('IsCanadianCitizen' + this._isCanadian);
        if (this._isCanadian === State.yes) {
            this.personalInformation.citizenship = this.canada.code;
            this.personalInformation.citizenshipLabel = this.canada.label;
            this.isCitizenOfAnyOtherCountry = true;
            //using [name] or [attr.name] in nested component confirmation-yes-no.component.html doesnt set correct name eventhough
            //DOM shows correct name. This is a bug in Angular version(issue number is 29922) and it seems no solution yet
            //Below one is a work around to make radio buttons as a group
            const ele = this.renderer.selectRootElement("#canadianCitizenship1", true);
            if (ele) {
                ele.setAttribute('name', 'canadianCitizenship1');
            }
            const ele1 = this.renderer.selectRootElement("#canadianCitizenship2", true);
            if (ele1) {
                ele1.setAttribute('name', 'canadianCitizenship1');
            }

            return;
        } else {
            // This was added in regards to defect MWB-10910.
            if (this.personalInformation.selectedCountries.length > 0) {
                this.isCitizenofAnyCountry();
            } else {
                this.isCitizenOfAnyOtherCountry = false; // SO REQUIRED
            }
        }
        this.personalInformation.citizenship = ' '; // send empty if not Canadian
    }

    taxCheckforUS(index) {
        if (!this.personalInformation.selectedCountries[index]) {
            return;
        }
        if ((this.personalInformation.selectedCountries[index].code === this.usa.code) &&
            (this.personalInformation.selectedCountries[index].isCitizen === true)) { // IF country is US and The user is a Citizen

            this.personalInformation.selectedCountries[index].isTaxResident = true; // Set  Tax Resident checked
            this.personalInformation.selectedCountries[index].isTaxDisabled = true; // Set  Disabled Resident
        } else {
            this.personalInformation.selectedCountries[index].isTaxDisabled = false;
        }
    }
    checkCitizenship() {
        return (this.isCaaTaxResidencyCertificateEnabled && (this.isCash || this.isMargin)) && (!this.isCanadian && !this.personalInformation.usResidentCheckBox && !this.hasCitizenship());
    }
    hasCitizenship(): boolean {
        if (this.isCRAtaxresidencyEnabled) {
            return !!(this.personalInformation.selectedCitizenCountries || []).filter(item => item.code).length;
        } else {
            let hasCitizenship = false;
            (this.personalInformation.selectedCountries || []).forEach((country, index) => {
                if (country.isCitizen) {
                    hasCitizenship = true;
                }
            });
            return hasCitizenship;
        }
    }
    noTaxIdentificationChange(index) {
        if (!this.personalInformation.selectedCountries[index]) {
            return;
        }
        this.analytics.triggerView('NoTaxIdentificationNum' + this.personalInformation.selectedCountries[index].noTaxIdentificationNum);
        if (this.personalInformation.selectedCountries[index].noTaxIdentificationNum) {
            this.personalInformation.selectedCountries[index].tin = ''; // Clear TIN
        } else {
            //Clear reasonSelected and other reason if user Unchecks the 'I do not have a Tax Identification Number' checkbox -  Fix for MWB-15790
            this.personalInformation.selectedCountries[index].reasonSelected = ''
            this.personalInformation.selectedCountries[index].otherReason = ''
        }

    }

    clearCitizenShipFields(index?, isCountrySelection?) { // Clear All fields
        if (!this.personalInformation.selectedCountries[index]) {
            return;
        }
        if (!this.isCRAtaxresidencyEnabled || (this.isCRAtaxresidencyEnabled && isCountrySelection && !this.isValueNull(index))) {
            this.personalInformation.selectedCountries[index].isCitizen = false;
        }
        if (!this.isCRAtaxresidencyEnabled || (this.isCRAtaxresidencyEnabled && !isCountrySelection && !this.isValueNull(index))) {
            this.personalInformation.selectedCountries[index].isTaxResident = false;
        }

    }
    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.personalDetailsForm);
        }, 250);
    }



    setGTM() {

        const gtmDataLayerForAccountDetails: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitlePersonalInformation(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.PERSONAL_INFO,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.personal_information,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountDetails);

    }
    showCitizenshipCountries() {
        return !(this.isCaaTaxResidencyCertificateEnabled && (this.isCash || this.isMargin)) ? (this.personalInformation?.otherCitizenshipOrTaxResidency == ('Yes' || 'No') || this.isNotCanadian) : (this.personalInformation?.otherCitizenshipOrTaxResidency == ('Yes' || 'No') && !this.isHideRemainingQuestions)

    }

}


// mark class export so that we can refer to this model Class in Payload
export class PersonalInformation {
    prefix: any = '';
    firstname: any = '';
    initial: any = '';
    lastname: any = '';
    likeToUsePreferredName: any = false;
    preferredFirstName: any = '';
    preferredMiddleName: any = '';
    preferredLastName: any = '';
    sinNumber: any = '';
    emailAddress = '';
    dateOfBirth: any = '';
    currentLanguage: string = '';
    prefixLabel: string = '';
    citizenship: any = ''; //  send empty or ' ' if not Canadian
    citizenshipLabel: string = '';
    usCitizenship: string = '';
    usResidentCheckBox: boolean = null;
    usSINNumber: '' = null;
    usTaxResidentCheckBox: boolean = false;
    alternateCitizenship: any = ''; // *** this probably has to change to a list, used on review page
    otherCitizenshipOrTaxResidency: string = ''; // Do you have any other citizenship - yes or no
    alternateCitizenshipLabelRRSP: string = '';
    alternateCitizenshipLabelTFSACash: string[];
    selectedCountries: SelectedCountry[] = [];
    countryIndex = 0;
    citizenCountryIndex = 0;
    AML_ENABLED: boolean = undefined;
    isCanadaTaxResidency: string = '';
    selectedCitizenCountries: SelectedCitizenCountryList[] = [];
}

export class SelectedCitizenCountryList {
    id: string;
    code: string;
    countries: [];
    constructor(id, countries?) {
        this.id = 'citizenCountry' + id;
        this.countries = countries;
    }
}
export class SelectedCountry {
    private usa = {
        code: '100001',
    };

    id: string; // format country + #
    code: string; // country code
    countries = []; // set from RDM, updated by personalInformation updateFilteredCountriesList() as appropriate
    ssn: string = '';
    tin: string = '';
    isCitizen: Boolean = false;
    isTaxResident: Boolean = false;

    // when the user selects the checkbox "I do not have a Tax Identification Number" and a dropdown appears
    noTaxIdentificationNum: Boolean = false;

    reasonSelected: string = '';
    otherReason: string = '';
    isTaxDisabled: Boolean = false;
    isTinDisabled: Boolean = false;
    taxIdentificationNumberMissingReason; // set by prototype from PersonalInformation RDM values in taxIdentificationNumberMissingReason
    isEnglish: Boolean; // set by prototype from PersonalInformation .isEnglish()

    constructor(id, countries?, forCitizen = false) {
        this.id = (forCitizen ? 'citizenCountry' : 'country') + id;
        this.countries = countries;
    }

    // returns country label e.g. USA
    get countryLabel() {
        if (this.code) {
            const filteredCountry = this.countries.filter(x => x.code === this.code);
            return (filteredCountry.length > 0) ? filteredCountry[0].label : '';
        }
        // return this.code = (this.code) ? this.countries.filter(x => x.code === this.code).reduce(x => x).label : '';
    }

    /* returns a string with the following format: country / citizenship / taxResidencyString
    Valid Scenarios: USA / Yes / 123456789, USA / No / 123456789
        Afghanistan / Yes / No, Afghanistan / Yes / 123456789
        Afghanistan / No / 123456789, Afghanistan / Yes / None - Reason 1, Afghanistan / No / None - Reason 2
        Afghanistan / Yes / None - Reason 3 : Other Reason String, Afghanistan / No / None - Reason 3 : Other Reason String
    */
    get countryCitizenshipTaxResidencyString() {
        this.isEnglish = (Utils.getLanguage() && Utils.getLanguage() === LANGUAGE_ENGLISH) ? true : false;

        if (this.code === this.usa.code) {
            if (this.isEnglish) {
                return [this.countryLabel, (this.isCitizen) ? State.yes : State.no, this.ssn].join(' / ');
            }
            return [this.countryLabel, (this.isCitizen) ? State.yesFrench : State.noFrench, this.ssn].join(' / ');
        }

        let isTaxResidentString: string = State.no; // set to no by Default
        if (this.isTaxResident) {
            if (this.noTaxIdentificationNum) {
                isTaxResidentString = (this.isEnglish) ? 'None - ' : 'Aucun - '; // set to one of the reasons selected

                isTaxResidentString += this.taxIdentificationNumberMissingReason.filter(x => x.code === this.reasonSelected)
                    .reduce(x => x).label;

                if (this.reasonSelected === '3' && this.otherReason.length > 0) { // '3' = Other Reason
                    isTaxResidentString += ' ' + this.otherReason;
                }
            }

            if (!this.noTaxIdentificationNum && this.tin.length > 0) {
                isTaxResidentString = this.tin; // set to the tin #
            }
        }

        if (this.isEnglish) {
            return [this.countryLabel, (this.isCitizen) ? State.yes : State.no, isTaxResidentString].join(' / ');

       }
        return [this.countryLabel, (this.isCitizen) ? State.yesFrench : State.noFrench, isTaxResidentString].join(' / ');
    }


}
