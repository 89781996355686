<!-- For testing puorposes - do not remove or change is visible attributes - end user should not see this message no matter what -->
<!-- --- -->
<span aria-hidden="true" class="display-none hidden-element">{{accountNumber}}</span>
<span aria-hidden="true" *ngFor="let account of accountDetails" class="display-none hidden-element">{{account.accountNumber}}</span>

<!-- <app-flood-light></app-flood-light> -->

<form *ngIf = "showDocUpload !== undefined && !navigateToNextSteps">
    <!-- <span>Decision type : {{decisionType}}</span> -->
    <div class="container-smallNoMenu">

        <!-- Title section -->
        <section id="nextStepsTitleSection">
            <!-- Page illustration section -->
            <section id="nextStepsSection">

                <!-- SHOW this for: Existing client & Pro Existing client  -->
                <div class="celebration-img" *ngIf="isApplicantExistingClient && !isSkipDocUpload">
                    <img aria-hidden="true" src="assets/img/artwork-celebratory3.svg"
                         attr.alt="{{ 'nextSteps.ariaLabels.imgCongratulations' | translate }}" />
                </div>

                <!-- SHOW this for: Non-Existing client & Pro Non-Existing client -->
                <div class="celebration-img" *ngIf="!isApplicantExistingClient && !isSkipDocUpload">
                    <img aria-hidden="true" src="assets/img/artwork-congratulation.svg"
                         attr.alt="{{ 'nextSteps.ariaLabels.imgCongratulations' | translate }}" />
                </div>

                <!-- SHOW this for: Non-Existing client & Pro Non-Existing client -->
                <div class="celebration-img" *ngIf="isSkipDocUpload">
                    <img aria-hidden="true" src="assets/img/artwork-questionnaire.svg"
                         attr.alt="{{ 'nextSteps.ariaLabels.imgDocuments' | translate }}" />
                </div>

            </section>


            <section id="reviewingApplicationSection">

                <div class="content-center">
                    <!--Display for users where no Doc Upload is required (user doesn’t have to submit documents)-->
                    <h1 *ngIf="(docUploadSuccess || !showDocUpload || !isSkipDocUpload) && !isProAccount" [innerHtml]="('nextSteps.titleSection' | translate).replace('[NAME]', firstName)  | safeHtml"></h1>

                    <h1 *ngIf="(isSkipDocUpload && (isMultiAccountFlow || isBranchSDMultiAccountFlow)) || isProAccount" [innerHtml]="('nextSteps.DocTitleSection2' | translate).replace('[NAME]', firstName)  | safeHtml"></h1>

                    <div *ngIf="(!showDocUpload && !showLogin) || (isMultiAccountFlow || isBranchSDMultiAccountFlow)" class="alert alert-info content-left" role="alert">
                        <p *ngIf="(!showDocUpload && !showLogin && (!isMultiAccountFlow && !isBranchSDMultiAccountFlow)) || ((STPAccounts||[]).length == 0)" class="font-bold" [innerHtml]="('nextSteps.titleSubsectionFailAMLMulti' | translate).replace('[APPLICATIONNUMBER]', applicationNumber)  | safeHtml"></p>
                        <p *ngIf="(!showDocUpload && !showLogin && (!isMultiAccountFlow && !isBranchSDMultiAccountFlow)) || ((STPAccounts||[]).length == 0)" translate="nextSteps.descriptionSubsectionFailAMLMulti3"></p>
                        <ng-container *ngIf="(STPAccounts||[]).length>0">
                            <p [innerHTML]="'nextSteps.descriptionSubsectionFailAMLMulti3MAO1T' | translate | safeHtml"></p>
                            <p [innerHTML]="'nextSteps.descriptionSubsectionFailAMLMulti3MAO1' | translate | safeHtml"></p>
                            <p translate="nextSteps.descriptionSubsectionFailAMLMulti3MAO2"></p>
                        </ng-container>
                    </div>

                    <!-- What's Next? - Manual Review & Documents Submitted -->

                    <div class="form-wrapper no-margin" *ngIf="((!showLogin && docUploadSuccess) || (!showDocUpload && !showLogin) || (!showLogin && !isSkipDocUpload)) && (!isMultiAccountFlow && !isBranchSDMultiAccountFlow)">
                        <div class="form-row flex-maxis-center">

                            <div class="flex-60 bmo-card-wrapper">
                                <div class="card-tile card-whats-next content-left" >

                                    <h2 class="card-header no-margin" translate="nextSteps.cardWhatsNextTitle"></h2>
                                    <p class="card-content" translate="nextSteps.descriptionSubsectionFailAMLMulti2"></p>
                                    <div class="card-footer">
                                        <!-- leave empty -->
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <!--New Nextsteps for Multiple account selection-->

                    <div class="form-wrapper no-margin" *ngIf="isMultiAccountFlow || isBranchSDMultiAccountFlow"
                    [ngClass]="isSTPandManualReviewAccounts||isSTPForAllAccountTypes ? 'justify-space-evenly flex-col' : ''">


                        <!--Manual Review or  Pro Letter Block -->
                        <div class="form-row flex-maxis-center" *ngIf="((allManualReview || isSTPandManualReviewAccounts || allManualReviewAndProLetter) && (ManualReviewAccounts||[]).length>0) || isSkipDocumentsandProLetterRequired || isDocSubmittedsAndProLetter || (isMixedSTPManualReviewandVoidCheque && isProAccount)">
                            <div class="flex-90 bmo-card-wrapper">
                                <div class="card-tile content-left" [ngClass]="{'card-search-document': allManualReviewAndProLetter || isDocSubmittedsAndProLetter || isSkipDocumentsandProLetterRequired, 'card-whats-next': !allManualReviewAndProLetter && !isDocSubmittedsAndProLetter && !showDocUpload && !isSkipDocumentsandProLetterRequired, null: !allManualReviewAndProLetter && !isDocSubmittedsAndProLetter && showDocUpload}">
                                    <h2 class="card-header no-margin icon-inline icon-rotation" *ngIf="(allManualReview || isSTPandManualReviewAccounts) && !isProAccount" translate="nextSteps.manualReviewAllAccountsTitle01"></h2>
                                    <h2 class="card-header no-margin icon-inline icon-warning-blue"  [ngClass]="true ? 'stretch' : 'stretch-img'" *ngIf="(allManualReviewAndProLetter || isDocSubmittedsAndProLetter || isSkipDocumentsandProLetterRequired || isMixedSTPManualReviewandVoidCheque)&& isProAccount" translate="nextSteps.actionRequiredProLetterTitle01"></h2>

                                    <div class="card-content" [ngClass]="(isSkipDocumentsandProLetterRequired || isDocSubmittedsAndProLetter) ? 'stretch' : 'stretch-img'">
                                        <div class="header-contextual-help" *ngIf="!isSkipDocumentsandProLetterRequired && !missigDocMessage && !isDocSubmittedsAndProLetter && !isMixedSTPManualReviewandVoidCheque && !allManualReview && !isSTPandManualReviewAccounts">
                                            <p class="font-bold display-inline">
                                                <span [innerHTML]="'nextSteps.labels.yourApplicationNumber' | translate"></span>
                                                <span >{{applicationNumber}}&nbsp;</span>
                                                <app-contextual-help class="button-wrapper va-middle"
                                                content="nextSteps.contextualHelp.manualReviewAllAccounts"
                                                 arialabel="nextSteps.ariaLabels.manualReviewAllAccounts">
                                                </app-contextual-help>
                                            </p>
                                        </div>
                                        <p *ngIf="(allManualReview || isSTPandManualReviewAccounts) && !isProAccount" translate="nextSteps.manualReviewAllAccountsDesc01"></p>
                                        <p *ngIf="(allManualReviewAndProLetter || isDocSubmittedsAndProLetter || isSkipDocumentsandProLetterRequired || isMixedSTPManualReviewandVoidCheque) && isProAccount && !isCIROEnabled" translate="nextSteps.actionRequiredProLetterDesc01"></p>
                                        <p *ngIf="(allManualReviewAndProLetter || isDocSubmittedsAndProLetter || isSkipDocumentsandProLetterRequired || isMixedSTPManualReviewandVoidCheque) && isProAccount && isCIROEnabled" translate="nextSteps.proNote"></p>

                                        <!-- Account Row #1 Display all accounts selected for PRO letter -->
                                        <div class="account-row" *ngIf="allManualReviewAndProLetter || isDocSubmittedsAndProLetter || isSkipDocumentsandProLetterRequired || (isMixedSTPManualReviewandVoidCheque && isProAccount)" [ngClass]="{'border-bottom': allManualReview || isSTPandManualReviewAccounts || isDocSubmittedsAndProLetter}">
                                            <div class="badge-container">
                                                    <span class="badge" *ngFor="let account of accountDetails" translate="{{getAccountTypeLabel(account?.accountType, account?.subTypes, account?.isSpousalFlag)}}"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer" *ngIf="allManualReview || isSTPandManualReviewAccounts">
                                        <div class="account-row justify-start">
                                            <div class="badge-container">
                                                <ng-container *ngFor="let account of ManualReviewAccounts">
                                                    <span class="badge" translate="{{getAccountTypeLabel(account?.accountType, account?.subTypes, account?.isSpousalFlag)}}"></span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <hr *ngIf="allManualReviewAndProLetter||isSkipDocumentsandProLetterRequired" class="add-margin t8px b8px"/>
                                    <div *ngIf="(allManualReviewAndProLetter || isDocSubmittedsAndProLetter||isSkipDocumentsandProLetterRequired || isMixedSTPManualReviewandVoidCheque)&& isProAccount" class="card-content stretch-img">
                                        <h3 class="font-bold" translate="nextSteps.infoBox01"></h3>
                                        <span [innerHtml]="'nextSteps.addressOptional' | translate | safeHtml"></span>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <!-- Docs not submitted or pro-manual review Block -->

                        <div class="form-row flex-maxis-center" *ngIf="isDocSubmittedsAndProLetter || isSkipDocumentsandProLetterRequired || missigDocMessage || (isMixedSTPManualReviewandVoidCheque && isRIF)">

                            <div class="flex-90 bmo-card-wrapper">
                                <div class="card-tile content-left" [ngClass]="{'card-whats-next': !isDocSubmittedsAndProLetter }">

                                    <h2 class="card-header no-margin icon-inline icon-rotation" *ngIf="isDocSubmittedsAndProLetter" translate="nextSteps.manualReviewAllAccountsTitle02"></h2>
                                    <h2 class="card-header no-margin icon-inline icon-warning-blue stretch" *ngIf="isSkipDocumentsandProLetterRequired || missigDocMessage || isMixedSTPManualReviewandVoidCheque" translate="nextSteps.actionRequiredSubmitDocumentsTitle01"></h2>

                                    <div class="card-content" [ngClass]="true ? 'stretch' : 'stretch-img'">
                                        <ng-container  *ngIf="isSkipDocumentsandProLetterRequired || missigDocMessage || isMixedSTPManualReviewandVoidCheque">
                                            <p *ngIf="!onlyVoidChequeRequired" translate="nextSteps.actionRequiredSubmitDocumentsNonCDB"></p>
                                            <!-- <p translate="nextSteps.actionRequiredSubmitDocumentsAML"></p> -->
                                            <p translate="nextSteps.actionRequiredSubmitDocumentsDesc01"></p>
                                        </ng-container>
                                        <p *ngIf="isDocSubmittedsAndProLetter" translate="nextSteps.manualReviewAllAccountsDesc01"></p>

                                        <!-- Account Row #1 -->
                                        <div class="account-row border-bottom" *ngIf="(isSkipDocumentsandProLetterRequired || missigDocMessage || isMixedSTPManualReviewandVoidCheque) && (isRIF && isRifPaymentNonBMOAccount())">
                                            <div class="badge-container">
                                                <span class="badge" translate="{{getAccountTypeLabel('100555')}}"></span>
                                            </div>
                                            <div class="account-info">
                                                <p class="no-margin font-bold" translate="nextSteps.actionRequiredSubmitDocumentsVoidCheque"></p>
                                            </div>
                                        </div>
                                        <div class="account-row" *ngIf="isDocSubmittedsAndProLetter">
                                            <div class="badge-container">
                                                <ng-container *ngFor="let account of accountDetails">
                                                    <span class="badge" translate="{{getAccountTypeLabel(account?.accountType, account?.subTypes, account?.isSpousalFlag)}}"></span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <!-- Account Row #2 -->
                                        <div class="account-row border-bottom" *ngIf="((isSkipDocumentsandProLetterRequired  || missigDocMessage || isMixedSTPManualReviewandVoidCheque) && !onlyVoidChequeRequired)">
                                            <div class="badge-container">
                                                <span *ngFor="let account of accountDetails" translate="{{getAccountTypeLabel(account?.accountType, account?.subTypes, account?.isSpousalFlag)}}" class="badge"></span>
                                            </div>
                                            <div class="account-info">
                                                <p class="no-margin font-bold" translate="nextSteps.actionRequiredSubmitDocumentsValidID"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer bmo-container-flex justify-space-between" *ngIf = "missigDocMessage || isSkipDocumentsandProLetterRequired || isMixedSTPManualReviewandVoidCheque">
                                        <div class="flex-60">
                                            <h3 class="font-bold" translate="nextSteps.cardUploadTitle3MAO"></h3>
                                            <p>
                                                <span translate="nextSteps.byMailBranchMAO"></span>
                                                <a href="javascript://" [routerLink]="['/'+APP_ROUTES.next_bybranch_pdf]"
                                                [queryParams]="{lang: lang}" target="_blank" translate="nextSteps.byMailBranchLink03"
                                                attr.aria-label="{{ 'nextSteps.ariaLabels.byMailBranchLink02' | translate }}" id="byBranchURL"
                                                data-ana="mywealth:submit:by branch"></a>
                                                <span [innerHTML]="'nextSteps.byMailBranch05' | translate | safeHtml"></span>
                                                <a href="javascript://" [routerLink]="['/'+APP_ROUTES.next_bymail_pdf]"
                                                [queryParams]="{lang: lang}" target="_blank" translate="nextSteps.byMailBranchLink01"
                                                attr.aria-label="{{ 'nextSteps.ariaLabels.byMailBranchLink01' | translate }}" id="byMailURL"
                                                data-ana="mywealth:submit:by mail"></a>
                                                <span [innerHTML]="'nextSteps.byMailBranch06' | translate | safeHtml"></span>
                                            </p>
                                        </div>
                                        <div class="">
                                            <button class="primary" translate="nextSteps.cardSubmitDocumentsButton"
                                            attr.aria-label="{{ 'nextSteps.ariaLabels.cardSubmitDocumentsButton' | translate }}"
                                            (click)="proceed()" data-ana="mywealth:doc upload:proceed"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!--Manual review block-->
                        <div class="form-row flex-maxis-center" *ngIf="(isMixedSTPManualReviewandVoidCheque && !isProAccount && (ManualReviewAccounts||[]).length>0) && !allManualReview && !isSTPandManualReviewAccounts">

                            <div class="flex-90 bmo-card-wrapper">
                                <div class="card-tile card-whats-next content-left">

                                    <h2 class="card-header no-margin icon-inline icon-rotation" translate="nextSteps.manualReviewAllAccountsTitle01"></h2>

                                    <div class="card-content">
                                        <p translate="nextSteps.manualReviewAllAccountsDesc01"></p>
                                    </div>

                                    <div class="card-footer">
                                        <div class="account-row justify-start">
                                            <div class="badge-container">
                                                <ng-container *ngFor="let account of ManualReviewAccounts">
                                                    <span class="badge" translate="{{getAccountTypeLabel(account?.accountType, account?.subTypes, account?.isSpousalFlag)}}"></span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                         <!-- STP Block-->

                         <div class="form-row flex-maxis-center" *ngIf="(isSTPForAllAccountTypes  || isSTPandManualReviewAccounts || isMixedSTPManualReviewandVoidCheque) && (STPAccounts||[]).length>0" [ngClass]="(isSTPForAllAccountTypes  || isSTPandManualReviewAccounts || isMixedSTPManualReviewandVoidCheque) && (STPAccounts||[]).length>0 ? 'order-3' : ''">

                            <div class="flex-90 bmo-card-wrapper">
                                <div class="card-tile content-left">

                                    <h2 class="card-header no-margin icon-inline icon-check-blue stretch" translate="nextSteps.stpAccountsAreReadyTitle01"></h2>

                                    <div class="card-content stretch">
                                        <p *ngIf="!isJourneys" translate="nextSteps.stpAccountsAreReadyDesc01"></p>
                                        <p *ngIf="isJourneys" translate="nextSteps.stpJourneyAccountsAreReadyDesc01"></p>
                                        <!-- Account Rows  -->

                                        <ng-container *ngFor="let account of STPAccounts">
                                            <div class="account-row border-bottom">
                                                <span class="badge" translate="{{getAccountTypeLabel(account?.accountType, account?.subTypes, account?.isSpousalFlag)}}"></span>
                                                <div class="account-info">
                                                    <p class="no-margin">
                                                        <span [innerHTML]="'nextSteps.labels.accountNumberMAO' | translate"></span>
                                                        <span class="font-bold">{{account.accountNumber}}</span>&nbsp;
                                                    </p>

                                                    <a  id="copyAccountNumber01" role="button" class="btn-icon" [ngClass]="{'tooltip-show': showTooltip}" (click)="copyAccountNumber(true)" (mouseout)="copyAccountNumber(false)" (focusout)="copyAccountNumber(false)"
                                                        href="javascript://" attr.tooltip="{{ 'linkButton.copied' | translate }}"
                                                        [copy-clipboard]="account.accountNumber"
                                                        [attr.aria-label]="('nextSteps.ariaLabels.copyAccountNumber' | translate).replace('[ACCOUNT_NAME]', 'TFSA')">
                                                        <span class="icon icon-large copy-blue" aria-hidden="true"></span>
                                                        <span class="btn-icon-text" translate="linkButton.copy"></span>
                                                    </a>
                                                    <span class="off-screen" *ngIf="showTooltip" role="alert" translate="nextSteps.ariaLabels.copiedAccountNumber"></span>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>

                                    <div class="card-footer content-center">
                                        <button class="primary" translate="button.logIn" (click)="onNavigate()" attr.aria-label="{{ 'nextSteps.ariaLabels.signIn' | translate }}"></button>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>

                    <!--This will show for Passed conditions ALM and others: No doc Upload -->
                    <!-- Login section with Account Number -->
                    <p translate="nextSteps.descriptionSubsectionPass" *ngIf="false"></p>
                    <button class="primary" type="submit" translate="button.login" (click)="onNavigate()"  *ngIf="false"></button>
                    <p class="add-margin t16px"  *ngIf="false">
                        <span [innerHtml]="'nextSteps.labels.accountNumber' | translate | safeHtml"></span>
                        <span class="font-medium">{{accountNumber}}</span>
                    </p>

                </div>
            </section>
                    <!--Section for document upload-->
                    <!-- Display for users where Doc Upload is required (user has to submit documents) -->
                    <section id="checkMyDocumentsSection" *ngIf="showDocUpload && (!isMultiAccountFlow && !isBranchSDMultiAccountFlow)">
                        <div class="content-center add-margin t32px 32px" >

                            <h1 *ngIf="!isProAccount" [innerHtml]="('nextSteps.DocTitleSection2' | translate).replace('[NAME]', firstName)  | safeHtml"></h1>

                            <div class="alert alert-info content-left" role="alert">
                                <p class="font-bold" [innerHtml]="('nextSteps.titleSubsectionFailAMLMulti' | translate).replace('[APPLICATIONNUMBER]', applicationNumber)  | safeHtml"></p>
                                <p translate="nextSteps.descriptionSubsectionFailAMLMulti3"></p>
                            </div>

                            <div class="form-wrapper no-margin" *ngIf="isSkipDocUpload">
                                <div class="form-row flex-maxis-center">

                                    <div class="flex-60 bmo-card-wrapper">
                                        <div class="card-tile card-upload content-left" >

                                            <h2 class="card-header no-margin" translate="nextSteps.cardUploadTitle2"></h2>
                                            <p class="card-content">
                                                <span [innerHTML]="'nextSteps.byMailBranch04' | translate | safeHtml"></span>
                                                <a class="pdf" href="javascript://" [routerLink]="['/'+APP_ROUTES.next_bybranch_pdf]"
                                                [queryParams]="{lang: lang}" target="_blank" translate="nextSteps.byMailBranchLink03"
                                                attr.aria-label="{{ 'nextSteps.ariaLabels.byMailBranchLink02' | translate }}" id="byBranchURL"
                                                data-ana="mywealth:submit:by branch"></a>
                                                <span [innerHTML]="'nextSteps.byMailBranch05' | translate | safeHtml"></span>
                                                <a class="pdf" href="javascript://" [routerLink]="['/'+APP_ROUTES.next_bymail_pdf]"
                                                [queryParams]="{lang: lang}" target="_blank" translate="nextSteps.byMailBranchLink01"
                                                attr.aria-label="{{ 'nextSteps.ariaLabels.byMailBranchLink01' | translate }}" id="byMailURL"
                                                data-ana="mywealth:submit:by mail"></a>
                                                <span [innerHTML]="'nextSteps.byMailBranch06' | translate | safeHtml"></span>
                                            </p>
                                            <ng-container *ngIf="isDataRationalization4Enabled"> <!-- MWB-15495 -->
                                            <p class="no-margin font-bold" translate="nextSteps.cardUploadTitle3"></p>
                                            <p class="card-content" translate="nextSteps.cardUploadDescription2"></p>
                                            <div class="card-footer">
                                                <button class="primary" translate="nextSteps.cardSubmitDocumentsButton"
                                                attr.aria-label="{{ 'nextSteps.ariaLabels.cardSubmitDocumentsButton' | translate }}"
                                                (click)="proceed()" data-ana="mywealth:doc upload:proceed"></button>
                                            </div>
                                        </ng-container>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="!isDataRationalization4Enabled" class="form-wrapper no-margin"> <!-- MWB-15495  -->
                                <div class="form-row flex-maxis-center">

                                    <div class="flex-60 bmo-card-wrapper">
                                        <div class="card-tile card-upload content-left" >

                                            <div class="card-header" role="heading" aria-level="2" translate="nextSteps.cardUploadTitle1"></div>
                                            <div class="card-content" translate="nextSteps.cardUploadDescription"></div>
                                            <div class="card-footer">
                                                <button class="primary"  translate="nextSteps.cardUploadLink" (click)="proceed()"
                                                data-ana="mywealth:doc upload:proceed"></button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- CSA/CFR CAA Form - documents not submitted -->
                            <div class="form-wrapper no-margin" *ngIf="isCsaCfrCAAFormEnabled && !isBranchAssisted">
                                <div class="form-row flex-maxis-center">

                                    <div class="flex-60 bmo-card-wrapper">
                                        <div class="card-tile card-upload content-left" >

                                            <h2 class="card-header no-margin" translate="nextSteps.cardCAAFormTitle"></h2>
                                            <p class="card-content" translate="nextSteps.cardCAAFormDescription"></p>
                                            <div class="card-footer">
                                                <button class="primary" (click)="printSaveCAADocument()" translate="nextSteps.cardCAAFormLink" attr.aria-label="{{ 'nextSteps.ariaLabels.cardCAAFormLink' | translate }}"></button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <p *ngIf="!isDataRationalization4Enabled">
                                <span [innerHTML]="'nextSteps.byMailBranch01' | translate | safeHtml"></span>
                                <a class="pdf" href="javascript://" [routerLink]="['/'+APP_ROUTES.next_bymail_pdf]"
                                [queryParams]="{lang: lang}" target="_blank" translate="nextSteps.byMailBranchLink01"
                                attr.aria-label="{{ 'nextSteps.ariaLabels.byMailBranchLink01' | translate }}" id="byMailURL"
                                data-ana="mywealth:submit:by mail"></a>
                                <span [innerHTML]="'nextSteps.byMailBranch02' | translate | safeHtml"></span>
                                <a class="pdf" href="javascript://" [routerLink]="['/'+APP_ROUTES.next_bybranch_pdf]"
                                [queryParams]="{lang: lang}" target="_blank" translate="nextSteps.byMailBranchLink02"
                                attr.aria-label="{{ 'nextSteps.ariaLabels.byMailBranchLink02' | translate }}" id="byBranchURL"
                                data-ana="mywealth:submit:by branch"></a>.
                                <span [innerHTML]="'nextSteps.byMailBranch03' | translate | safeHtml"></span>
                            </p>

                        </div>
                    </section>
                    <!-- CSA/CFR CAA Form - documents submitted -->
                    <div class="form-wrapper no-margin" *ngIf="isCsaCfrCAAFormEnabled && !showDocUpload && !isBranchAssisted">
                        <div class="form-row flex-maxis-center">

                            <div class="bmo-card-wrapper" [ngClass]="true ? 'flex-60' : 'flex-90'">
                                <div class="card-tile card-upload content-left">

                                    <h2 class="card-header no-margin" translate="nextSteps.cardCAAFormTitle"></h2>
                                    <p class="card-content" translate="nextSteps.cardCAAFormDescription" [ngClass]="true ? null : 'stretch-img'"></p>
                                    <div class="card-footer">
                                        <button class="primary" (click)="printSaveCAADocument()" translate="nextSteps.cardCAAFormLink" attr.aria-label="{{ 'nextSteps.ariaLabels.cardCAAFormLink' | translate }}"></button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
        </section>




        <section *ngIf="!isMultiAccountFlow && !isBranchSDMultiAccountFlow">

            <!-- SHOW if Authentication pass - Login -->
            <!-- --- -->
            <div class="content-center" *ngIf="showLogin">

                <p translate="nextSteps.descriptionSubsectionPass"></p>
                <button class="primary" type="submit" translate="button.login" (click)="onNavigate()"></button>

            </div>
            <!-- END -->
            <!--MWB-9887  show account number only if login button is shown on next steps screen-->

            <div *ngIf="showLogin">
                <hr />

                <!--Section for Account number-->
                <section>
                    <div class="content-center">
                        <span [innerHtml]="'nextSteps.labels.accountNumber' | translate | safeHtml"></span>
                        <span class="font-medium">{{accountNumber}}</span>
                    </div>
                </section>


            </div>

        </section>

        <!--Section for Alerts - if upload is successful or fails-->
        <!--HIDE Document Upload section above when either of the messages below display-->
        <section>

            <!-- Show this DIV if Documents have been uploaded and submitted successfully -->
            <!-- Removing message for MWB-14716  -->

             <!-- <div class="flex-elements flex-maxis-center" *ngIf="docUploadSuccess && !isDataRationalization4Enabled" role="alert" aria-live="polite">
                <div class="flex-60 content-left alert alert-check no-margin">

                    <p [innerHtml]="'nextSteps.cardUploadSuccessNote01' | translate | safeHtml"></p>
                    <p translate="nextSteps.cardUploadSuccessNote02"></p>

                </div>
            </div> -->
            <!-- END -->
            <!-- Show this DIV if Uploading the document has failed after third attempt -->
            <!-- --- -->
            <div class="flex-elements flex-maxis-center" *ngIf="docFailThirdAttempt">
                <div class="flex-60 content-left alert alert-yellow no-margin">

                    <p>
                        <span class="font-bold" translate="nextSteps.warnings.thirdAttemptNote01"></span>
                    </p>

                    <p>
                        <span translate="nextSteps.warnings.thirdAttemptNote02"></span>&nbsp;
                        <a class="external" href="javascript://" target="_blank"
                           translate="nextSteps.warnings.byMailBranchLink01"
                           attr.aria-label="{{ 'nextSteps.ariaLabels.byMailBranchLink01' | translate }}"></a>&nbsp;
                        <span translate="nextSteps.warnings.thirdAttemptNote03"></span>&nbsp;
                        <a class="external" href="javascript://" target="_blank"
                           translate="nextSteps.warnings.byMailBranchLink02"
                           attr.aria-label="{{ 'nextSteps.ariaLabels.byMailBranchLink02' | translate }}"></a>
                        <span translate="nextSteps.warnings.thirdAttemptNote04"></span>
                    </p>

                </div>
            </div>
            <!-- END -->

        </section>


        <!--Section for the Pro note/IIROC Msg  showProLetter-->
        <!--SHOW for both pro accounts (existing or non-existing) : HIDE for the other 2 non pro-->
        <section *ngIf="showProLetter &&  (!isMultiAccountFlow && !isBranchSDMultiAccountFlow)">

            <div class="content-left">

                <div class="alert alert-info">
                    <p>
                        <strong translate="nextSteps.proNoteTitle"></strong><br />
                        <span *ngIf="isCIROEnabled" translate="nextSteps.proNote"></span>
                        <span *ngIf="!isCIROEnabled" translate="nextSteps.proNote01"></span>
                    </p>
                </div>

            </div>

            <!-- PRO : SHOW if Authentication fail, multi account Pro letter req -->
            <!-- --- -->
            <div class="content-left">
                <div class="bmo-card-wrapper">
                    <div class="card-tile card-infobox">

                        <span translate="nextSteps.infoBox01"></span>

                        <hr class="small" />
                        <!--MWB-9887  show account number only if login button is shown on next steps screen-->
                        <!-- <div *ngIf="!isOptions && !isProAccount && amlFlag">
                            <span [innerHtml]="'nextSteps.labels.accountNumber' | translate | safeHtml"></span>
                            <span class="font-medium">{{accountNumber}}</span>
                        </div> -->
                        <div>
                            <span [innerHtml]="'nextSteps.labels.applicationNumber' | translate | safeHtml"></span>
                            <span class="font-medium">{{applicationNumber}}</span>
                        </div>
                        <hr class="small" />

                        <span [innerHtml]="'nextSteps.addressOptional' | translate | safeHtml"></span>

                    </div>
                </div>

            </div>
            <!-- END -->

        </section>

        <hr />

        <section *ngIf="!(isRRSP || isSRRSP || isTFSA || isRIF) && (!isMultiAccountFlow && !isBranchSDMultiAccountFlow) && false">

            <div *ngIf="!isQuebec && !isCash" class="content-left">

                <h2 translate="nextSteps.titleSubsectionOptional"></h2>

                <!-- HIDE this for TFSA and CASH -->
                <!--  Commented for  MWB- 11835  -->
                <!--  <p *ngIf="isRRSP || isSRRSP">

                    <span class="font-medium" translate="nextSteps.description1Optional"></span>
                    <app-contextual-help class="button-wrapper va-middle"
                                         content="nextSteps.contextualHelp.optionalBeneficiaryForm"
                                         arialabel="nextSteps.ariaLabels.optionalBeneficiaryFormLabel">
                    </app-contextual-help>

                    <br />

                    <span *ngIf="isSubProductAD  && beneficiaryForm === null">
                        <span [innerHtml]="'nextSteps.ad_description2TFSAOptional' | translate"></span>
                    </span>
                    <span *ngIf="!isSubProductAD && beneficiaryForm === null">
                        <span [innerHtml]="'nextSteps.description2TFSAOptional' | translate"></span>
                    </span>
                    <span *ngIf="beneficiaryForm !== null">
                        <a class="pdf"
                           href="javascript://"
                           attr.aria-label="{{ 'nextSteps.downloadAriaLabel' | translate }}"
                           (click)="downloadDocument(beneficiaryForm)"
                           translate="nextSteps.downloadLabel">
                        </a>
                        <span [innerHtml]="'nextSteps.instruction' | translate"></span>
                    </span>

                </p>  -->
                <!-- END -->
                <!-- HIDE this for all accounts, except TFSA -->
                <!--  Commented for  MWB- 11835  -->
                <!--  <p *ngIf="isTFSA">

                    <span class="font-medium" translate="nextSteps.description1TFSAOptional"></span>
                    <app-contextual-help class="button-wrapper va-middle"
                                         content="nextSteps.contextualHelp.optionalSuccessorBeneficiaryForm"
                                         arialabel="nextSteps.ariaLabels.optionalSuccessorBeneficiaryFormLabel">
                    </app-contextual-help>

                    <br />

                    <span *ngIf="isSubProductAD && beneficiaryForm === null">
                        <span [innerHtml]="'nextSteps.ad_description2TFSAOptional' | translate"></span>
                    </span>
                    <span *ngIf="!isSubProductAD  && beneficiaryForm === null">
                        <span [innerHtml]="'nextSteps.description2TFSAOptional' | translate"></span>
                    </span>
                    <span *ngIf="beneficiaryForm !== null">
                        <a class="pdf"
                           href="javascript://"
                           attr.aria-label="{{ 'nextSteps.downloadAriaLabel' | translate }}"
                           (click)="downloadDocument(beneficiaryForm)"
                           translate="nextSteps.downloadLabel">
                        </a>
                        <span [innerHtml]="'nextSteps.instruction' | translate"></span>
                    </span>


                </p> -->
                <!-- END -->
                <!-- HIDE this for CASH -->
                <!-- --- -->
                <p [innerHtml]="'nextSteps.addressOptional' | translate"></p>
                <!-- END -->

            </div>

        </section>

        <section id="successorBeneficiaryFormSection" *ngIf="((isRRSP || isSRRSP || isTFSA || isRIF) && !isQuebec)">


            <!-- Self-Serve flow ngIF condition required for MWB-13867 -->
            <div *ngIf="!BILAssisted" class="content-center add-margin b48px">
                <h2 translate="nextSteps.docstepsTitle"></h2>
                <p translate="nextSteps.docstepsDescription1"></p>
                <p translate="nextSteps.docstepsDescription2"></p>
            </div>

            <!-- Branch-Assisted flow ngIf condition required for MWB-13867 -->
            <div *ngIf="BILAssisted" class="content-center add-margin b48px">
                <ng-continer *ngIf="isBranchSDMultiAccountFlow; else singleBAAccount">
                    <h2 translate="nextSteps.ba_docstepsTitleMAO"></h2>
                    <p translate="nextSteps.ba_docstepsDescription1MAO"></p>
                </ng-continer>
                <ng-template #singleBAAccount>
                    <h2 translate="nextSteps.ba_docstepsTitle"></h2>
                    <p translate="nextSteps.ba_docstepsDescription1"></p>
                    <p translate="nextSteps.docstepsDescription2"></p>
                </ng-template>
            </div>

            <div class="steps-wrapper content-left">
                <h3 *ngIf="!isMultiAccountFlow && !isBranchSDMultiAccountFlow" [innerHtml]="'nextSteps.docstepsStep1' | translate | safeHtml"></h3>
                <div *ngIf="isMultiAccountFlow || isBranchSDMultiAccountFlow">
                    <ng-continer *ngIf="isMultiAccountFlow; else isBranchSDMultiAccount">
                        <h3 class="add-margin b16px" [innerHtml]="'nextSteps.docstepsStep1MAO' | translate | safeHtml"></h3>
                    </ng-continer>
                    <ng-template #isBranchSDMultiAccount>
                        <h3 class="add-margin b16px" [innerHtml]="'nextSteps.docstepsStep1BAMAO' | translate | safeHtml"></h3>
                    </ng-template>
                </div>


                <div *ngIf="!isMultiAccountFlow && !isBranchSDMultiAccountFlow" class="bmo-card-wrapper">
                    <div class="card-tile card-next-steps">
                        <div class="flex-2-col" *ngIf="true">
                            <div class="img-wrapper img-download"></div>
                            <div class="text-wrapper">
                                <span *ngIf="isSubProductAD  && beneficiaryForm === null">
                                    <span [innerHtml]="'nextSteps.ad_description2TFSAOptional' | translate | safeHtml"></span>
                                </span>
                                <span *ngIf="!isSubProductAD && beneficiaryForm === null && isTFSA">
                                    <span [innerHtml]="'nextSteps.description2TFSAOptional' | translate | safeHtml"></span>
                                </span>
                                <span *ngIf="!isSubProductAD && beneficiaryForm === null && !isTFSA">
                                    <span [innerHtml]="'nextSteps.description2Optional' | translate | safeHtml"></span>
                                </span>
                                <span *ngIf="beneficiaryForm !== null">

                                    <a  href="javascript://"
                                        attr.aria-label="{{ 'nextSteps.downloadAriaLabel' | translate }}"
                                        id="downloadURL"
                                        (click)="downloadDocument(beneficiaryForm)"
                                        translate="nextSteps.downloadLabel">
                                    </a>
                                    <span [innerHtml]="'nextSteps.docstepsDownload' | translate | safeHtml"></span>

                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isMultiAccountFlow || isBranchSDMultiAccountFlow" class="bmo-card-wrapper">
                    <div class="card-tile card-next-steps">
                        <div class="flex-2-col">
                            <div class="text-wrapper">
                                <span *ngIf="isTFSA" [innerHtml]="'nextSteps.beneficiarySuccessorTFSA' | translate | safeHtml"></span>
                                <span *ngIf="isRIF && !isQuebec"
                                    [innerHtml]="'nextSteps.beneficiarySuccessorRRIF' | translate | safeHtml"></span>
                                <span *ngIf="isRRSP" [innerHtml]="'nextSteps.beneficiarySuccessorRRSP' | translate | safeHtml"></span>
                                <span *ngIf="isSRRSP" [innerHtml]="'nextSteps.beneficiarySuccessorSRRSP' | translate | safeHtml"></span>
                            </div>
                            <div class="img-wrapper img-download"></div>
                        </div>
                    </div>
                </div>


                <h3 *ngIf="!isMultiAccountFlow && !isBranchSDMultiAccountFlow " [innerHtml]="'nextSteps.docstepsStep2' | translate | safeHtml"></h3>
                <div *ngIf="isMultiAccountFlow || isBranchSDMultiAccountFlow">
                    <ng-continer *ngIf="isBranchSDMultiAccountFlow; else isSDMultiAccountFlow">
                        <h3 class="add-margin b16px t32px" [innerHtml]="'nextSteps.docstepsStep2BAMAO' | translate | safeHtml"></h3>
                    </ng-continer>
                    <ng-template #isSDMultiAccountFlow>
                        <h3 class="add-margin b16px t32px" [innerHtml]="'nextSteps.docstepsStep2MAO' | translate | safeHtml"></h3>
                    </ng-template>
                </div>


                <div class="bmo-card-wrapper">
                    <div class="card-tile card-next-steps">

                        <div class="flex-2-col">
                            <div class="img-wrapper img-upload" [ngClass]="false ?  ' order-2' : ''"></div>
                            <div class="text-wrapper">
                                <!-- <p [innerHtml]="'nextSteps.docstepsUpload' | translate | safeHtml"></p> -->
                                <!-- Self-Serve flow ngIF condition required for MWB-13867 -->
                                <span *ngIf="!BILAssisted && !isMultiAccountFlow" class="description" translate="nextSteps.docstepsUpload">
                                    <a href="javascript://"
                                       translate="nextSteps.docstepsUpload1"
                                       id="signNewAccountURL"
                                       (click)="onNavigate()"
                                       role="button"></a>
                                       <span class="description" translate="nextSteps.docstepsUpload2"></span>
                                </span>
                                <span *ngIf="!BILAssisted && isMultiAccountFlow" [innerHtml]="'nextSteps.docstepsUploadMAO' | translate | safeHtml"></span>

                                <!-- Branch-Assisted flow ngIf condition required for MWB-13867 -->
                                <span *ngIf="BILAssisted && !isBranchSDMultiAccountFlow" class="description" translate="nextSteps.ba_docstepsUpload">
                                    <a href="javascript://"
                                       translate="nextSteps.docstepsUpload1"
                                       id="signNewAccountURL"
                                       (click)="onNavigate()"
                                       role="button"></a>
                                       <span class="description" translate="nextSteps.docstepsUpload2"></span>
                                </span>

                                <ng-container *ngIf="BILAssisted && isBranchSDMultiAccountFlow">
                                    <span class="description" [innerHTML]="'nextSteps.ba_docstepsUploadMAO' | translate | safeHtml"></span><br><br>
                                    <span class="description font-bold" translate="nextSteps.ba_docstepsUploadMAOSubline"></span><br>
                                    <span class="description" translate="nextSteps.ba_docstepsUploadMAODesc"></span>
                                </ng-container>
                            </div>
                        </div>

                        <div class="or-separator"></div>

                        <div class="flex-2-col">
                            <div class="img-wrapper img-mail" [ngClass]="false ?  ' order-2' : ''"></div>
                            <div class="text-wrapper">
                                <ng-continer *ngIf="BILAssisted && isBranchSDMultiAccountFlow; else othersAccountFlow">
                                    <p [innerHtml]="'nextSteps.docstepsMailBAMAO' | translate | safeHtml"></p>
                                </ng-continer>
                                <ng-template #othersAccountFlow>
                                    <p [innerHtml]="'nextSteps.docstepsMail' | translate | safeHtml"></p>
                                </ng-template>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>

    </div>

</form>
