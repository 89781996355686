
// @created: Mayur Kode
// @updated: Mayur Kode

import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    FormControl,
    ValidatorFn,
    Validator, AbstractControl
} from '@angular/forms';

import { Directive } from '@angular/core';

@Directive({
    selector: '[appPostalCodeValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PostalCodeValidator,
            multi: true
        }
    ]
})
export class PostalCodeValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = this.postalCodeValidator();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    postalCodeValidator(): ValidatorFn {
        const postalCodePattern = new RegExp (/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i);
        // const postalCodePattern: RegExp = new RegExp(/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][\s]
        // [0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i);
        return (control: AbstractControl) => {
            if (!this.isValueNull(control.value)) {

                if (postalCodePattern.test(control.value)) {
                    return null;
                    // if the pattern is correct, retrun null means no errors
                }
                return {
                    postalCode: {
                        invalidPostalCode: true
                    }
                };
            }

        };
    }
    public isValueNull(value) {
        if (value === '' || value === undefined || value === null) {
            return true;
        }
        return false;
    }
}
