/**
 * @purpose: Contact information
 * @ owner: Mayur Kode
 * @ last updated by: Hari Prasad kori (10 April, 2018)
 * @created on:
 *
 */
import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    FormControl,
    ValidatorFn,
    Validator,
    AbstractControl,
} from '@angular/forms';

import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { Utils } from '../../shared/utils/utils.class';
import { ADDRESS_REGEX } from '../../config/app-config.constants';

@Directive({
    selector: '[appPOBoxValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: POBoxValidator,
            multi: true
        }
    ]
})
export class POBoxValidator implements Validator {
    validator: ValidatorFn;

    @Input()
    public allowPobox: any;

    constructor(public el: ElementRef) {
        this.validator = this.POBoxValidator();
    }

    @HostListener('keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        const currentPosition = this.el.nativeElement.selectionStart;
        const temp = this.el.nativeElement.value.slice(0, currentPosition).concat(event.key)
            .concat(this.el.nativeElement.value.slice(currentPosition));
        if (temp !== temp.replace(/\s+/g, ' ')) {
            event.preventDefault();
        }
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    POBoxValidator(): ValidatorFn {
        const pattern = ADDRESS_REGEX.poBox;   // pattern same as PCD to avoid defect
        // const pattern = /^((?!P[\.\s]*O[\.\s]*Box|post office box|bin).)*$/i; // old pattern used
        /* const pattern = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office (box|bin)|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i; */
        return (control: AbstractControl) => {
            if (this.allowPobox === 'true') {
                return null;
            } else if (this.allowPobox === 'false') {
                if (pattern.test(control.value)) {
                    return null;
                } else {
                    return {
                        invalidPOBox: true
                    };
                }
            }
        };
    }
}
