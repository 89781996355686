import { filter } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    APP_ROUTES, ANALYTICS, AD, SD, LANGUAGES, ACCOUNT_CODES, ACCOUNT_NAME_EN, QUEBEC,
    PRODUCT, LANGUAGE_ENGLISH, LANGUAGE_FRENCH, productTypeAD, productTypeSD, BMO_INVESTORLINE_LINK, LANGUAGES_TYPE, DOCUMENT_TYPE, ACCOUNT_TYPE, SPOUSAL_RRSP_ACCOUNT
    , decisionType, DOC_UPLOAD_STATUS, RIF_PAYMENT_METHOD,
    TFSA_ACCOUNT, RRSP_ACCOUNT, RRIF_ACCOUNT, CASH, MARGIN
} from '../../config/app-config.constants';
import { FormService } from '../../shared/service/form.service';
import { ApplicationService } from '../../shared/service/application.service';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { LoaderService } from '../../shared/service/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../shared/utils/utils.class';
import { Title } from '@angular/platform-browser';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { MOCK_REFERENCE_DATA_11134_6, MOCK_REFERENCE_DATA_branchAssisted_22 } from './next-steps.mock';
import { DOC_SUBMITTED_MANUAL_REVIEW, MOCK_REFERENCE_AML_TRUE_CASE1, MOCK_REFERENCE_STP_MANUAL_CASE3, MOCK_STP_MANUAL_MIXED_CASE1, SKIPDOC_PROREQUIRED } from './next-steps-mao.mock'
import { DocumentTrackerService } from '../../shared/service/document-tracker.service';
import { AccountTypeService } from '../../shared/service/account-type.service';
import { SelectAccount } from '../select-account/select-account.component';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { CdbOlbService } from '../../shared/service/cdb-olb.service';



@Component({
    selector: 'app-next-steps',
    templateUrl: './next-steps.component.html'
})
export class NextStepsComponent implements OnInit, AfterViewInit {
    public APP_ROUTES = APP_ROUTES;
    public firstName: string;
    public accountNumber: string;
    public applicationNumber: string;
    public isCash = false;
    public isRRSP = false;
    public isSRRSP = false;
    public isTFSA = false;
    public isRIF = false;
    public isMargin = false;
    public docUpload: Boolean;
    public isExisting = false;
    public amlFlag = false;
    public isMultiAccount = null;
    public isApplicantExistingClient = false;
    public isProAccount = false;
    public isOptions = false;
    public isBranchReferral = false;
    public showDocUploadContainer = false;
    public docUploadSuccess = false;
    public docFailThirdAttempt = false;
    public lang: string = null;
    public isQuebec: boolean = null;
    public nextStepAgain: any = '';
    public isSubProductAD = false;
    public isIirocMember: boolean = null;
    public isShortSelling = false;
    public isDataRationalization4Enabled = false;
    public MWB10106: boolean = null;
    public MWB10104: boolean = null;
    public MWB10105: boolean = null;
    public MWB10070: boolean = null;
    public MWB10072: boolean = null;
    public MWB10076: boolean = null;
    public MWB10077: boolean = null;
    public MWB10359: boolean = null;
    public MWB10360: boolean = null;
    public MWB12119_MR: boolean = null;
    public MWB12119_Login: boolean = null;

    // sub tasks for doc upload
    public MWB10372: boolean = null;
    public MWB10373: boolean = null;

    // Sub-tasks for login button
    public MWB10374: boolean = null;
    public MWB10375: boolean = null;


    // sub-task for Manual Review
    public MWB10536_1 = false;
    public MWB10536_2 = false;
    public MWB10536_3 = false;
    public MWB10536_4 = false;
    public MWB10536_5 = false;
    public MWB10536_6 = false;
    public MWB10977_1 = false;
    public MWB10977_2 = false;
    public MWB10977_3 = false;

    public MWB11134_1 = false;
    public MWB11134_2 = false;
    public MWB11134_3 = false;
    public MWB11134_4 = false;
    public MWB11134_5 = false;
    public MWB11134_6 = false;

    public MWB11461_1 = false;
    public MWB11461_2 = false;

    public showDocUpload = undefined;
    public showManualReview = false;
    public showProLetter = false;
    public allManualReview = false; // All Manual Review
    public docMissingAccounts: any = [];
    public voidCheckOrBankStatementAccounts: any = [];
    public allManualReviewAndProLetter = false;
    public missigDocMessage: boolean = false;
    public isDocSubmittedsAndProLetter = false;
    public allSTP = true; // All Straight-Through Processing
    public mixedManualSTP = true; // Mixed Straight-Through Processing & Manual Review
    public pendingActionOrProcessing = false;
    public showIIROC = false;
    public showLogin = false;
    public CDBUser = false;
    public AMLFlagged = false;

    public bmoRelationship = false;
    public branchAssisted = {};
    applicationId: any;
    public document_package;
    public beneficiaryForm = null;
    public nextStepsObject;
    public url;
    public selectAccount: SelectAccount = new SelectAccount();
    public decisionType: any = null;
    public RIF_MANUAL_REVIEW_SD = false;
    public RIF_MANUAL_REVIEW = false;
    public RIF_PRO_LETTER = false;
    public RIF_PRO_LETTER_SD_1 = false;
    public RIF_PRO_LETTER_SD_2 = false;
    public RIF_PRO_LETTER_SD_3 = false;
    public RIF_PRO_LETTER_SD_4 = false;
    public RIF_DOC_UPLOAD_SD = false;
    public RIF_DOC_UPLOAD = false;
    public RIF_DOC_UPLOAD_SD_1 = false;
    public RIF_DOC_UPLOAD_SD_2 = false;
    public RIF_DOC_UPLOAD_1 = false;
    public RIF_LOGIN = false;
    public MARGIN_MANUAL_REVIEW_SD = false;
    public MARGIN_PRO_LETTER_SD = false;
    public MARGIN_DOC_UPLOAD_SD = false;
    public fraudFlag: boolean = false;
    public MARGIN_MANUAL_REVIEW: boolean = false;
    public MARGIN_MANUAL_REVIEW_1: boolean = false;
    public MARGIN_MANUAL_REVIEW_2: boolean = false;
    public MARGIN_PRO_LETTER: boolean = false;
    public MARGIN_PRO_LETTER_1: boolean = false;
    public MARGIN_PRO_LETTER_2: boolean = false;
    public MARGIN_DOC_UPLOAD: boolean = false;
    public MARGIN_DOC_UPLOAD_1: boolean = false;
    public MARGIN_DOC_UPLOAD_2: boolean = false;

    public rifPaymentMethod: any;
    public MARGIN_LOGIN: boolean = false;
    public BILAssisted = false;
    public isCsaCfrCAAFormEnabled: boolean = false;
    public content: any;
    public isBranchAssisted: boolean;
    public REGISTERED_LOGIN: boolean;
    public REGISTERED_MANUAL_REVIEW_SD: boolean;
    public isSTPOptionsEnabled: boolean;
    public isSkipDocUpload: boolean = undefined;
    public navigateToNextSteps: boolean = false;
    public application: any;
    public accountDetails: any = [];
    public STPAccounts: any = [];
    public ManualReviewAccounts: any = [];
    public IirocMemberOrganization: any;
    public isSTPForAllAccountTypes: boolean = false;
    public isSTPandManualReviewAccounts: boolean = false;
    public isMaoAppFlag: boolean = false;
    public isMultiAccountFlow: boolean = false;
    public isBranchSDMultiAccountFlow: boolean = false;
    isSkipDocumentsandProLetterRequired: boolean;
    onlyVoidChequeRequired: boolean = false;
    isMixedSTPManualReviewandVoidCheque: any;
    isMixedSTPManualReviewandMissingVoidCheque: boolean;
    public allManualFlag: boolean;
    public isNotMixedScenario: boolean;
    public showTooltip: boolean = false;
    public isJourneys: boolean = false;
    public isOlbCdbUser:boolean = false;
    public toolTipTimer: any;
    public isCIROEnabled: boolean = false;

    constructor(private formService: FormService,
        public applicationService: ApplicationService,
        public titleService: Title,
        private winRef: WindowRefService,
        private route: ActivatedRoute,
        private documentTrackerService: DocumentTrackerService,
        private accountTypeService: AccountTypeService,
        public analytics: AnalyticsService,
        @Inject(DOCUMENT) private document: Document,
        private translate: TranslateService,
        private loaderService: LoaderService,
        private configService: ConfigResolverService<any>,
        public cdbOlbService: CdbOlbService) {

        this.lang = Utils.getLanguage();

        if (this.lang !== undefined || this.lang !== null) {
            this.translate.use(this.lang);
        } else {
            this.translate.use(LANGUAGE_ENGLISH);  // fallback
        }
        this.url = '/' + APP_ROUTES.next_steps;

    }

    ngOnInit() {
        console.log(this.route.snapshot.data);
        this.isCsaCfrCAAFormEnabled = this.applicationService.getisCsaCfrCAAFormFeatureFlag();
        this.isDataRationalization4Enabled = this.applicationService.getDataRationalization4FeatureFlag();
        this.isBranchAssisted = Utils.getIsBranchAssisted();
        this.isSkipDocUpload = Utils.getSkipDocUpload();
        this.applicationService.setResumeLink(false);  // hide resume application link on Next-Step
        this.isSTPOptionsEnabled = this.applicationService.getSTPOptionsFeatureFlag();
        this.isMaoAppFlag = this.applicationService.getMultiAccountOpenFeatureFlag();
        this.isCIROEnabled = this.applicationService.getCIROEnabledFeatureFlag();

        // Hide Sign-out link
        this.applicationService.setSignOutLink(false);
        this.nextStepsObject = Utils.getNextStepsData();
        if (Utils.hasOwnProperty(this.nextStepsObject, 'decisionType')) {
            this.decisionType = this.nextStepsObject.decisionType;
        }
        if (this.route.snapshot.data) {

            // sample application retrieval response to test this page
            this.application = this.nextStepsObject;


            const appReferences = this.route.snapshot.data['reference'];
            const accountTypes = this.route.snapshot.data['accountTypes'];

            this.accountDetails = this.application.accounts;
            this.isJourneys = this.application.ilUser;
            this.isOlbCdbUser = this.application.olbuser;

            this.applicationId = this.application.applicationId;
            Utils.applicationID = this.applicationId;  // setting in Utils for use in pages ahead of Next Steps.
            // this.applicationService.appRetrieve = application;

            // Just append tilte and middle name to the first name.
            let applicantTitle = '';
            if (appReferences && accountTypes.length > 0) {
                // const labelName = this.lang === LANGUAGE_ENGLISH ? 'productNameEn' : 'productNameFr';
                // this.application.accountTypeTitle = accountTypes.find(
                //     x => x.type === this.application.accountType)[labelName];
                // applicantTitle = (this.application.title !== null) ? appReferences.references.titles.find(
                //     x => x.code === this.application.title).label : '';
            }

            this.application.title = applicantTitle;
            this.application.middleName = this.application && this.application.middleName ? this.application.middleName : '';

            // Set created app data to localstorage to retrieve in by-mail and by-branch page (new tab so no other way than local storage).
            // localStorage.setItem('application', JSON.stringify(application));

            this.translate.get('pageTitle.completion').subscribe((res) => {
                this.titleService.setTitle(res);
                Utils.setPageTitleCompletion(res);
            });

            if (this.application.preferredLanguage === LANGUAGES.ENGLISH) {
                this.lang = LANGUAGE_ENGLISH;
                this.translate.use(LANGUAGE_ENGLISH);
            } else if (this.application.preferredLanguage === LANGUAGES.FRENCH) {
                this.lang = LANGUAGE_FRENCH;
                this.translate.use(LANGUAGE_FRENCH);
            }
            this.document.documentElement.lang = this.lang;


            this.isQuebec = this.application.province === QUEBEC ? true : false;

            if (this.isCash) {
                Utils.accountName = PRODUCT.CASH.name;
                Utils.accountNumber = PRODUCT.CASH.number;
            } else if (this.isTFSA) {
                Utils.accountName = PRODUCT.TFSA.name;
                Utils.accountNumber = PRODUCT.TFSA.number;
            } else if (this.isRRSP) {
                Utils.accountName = PRODUCT.RRSP.name;
                Utils.accountNumber = PRODUCT.RRSP.number;
            } else if (this.isSRRSP) {
                Utils.accountName = PRODUCT.SRRSP.name;
                Utils.accountNumber = PRODUCT.SRRSP.number;
            } else if (this.isMargin) {
                Utils.accountName = PRODUCT.MARGIN.name;
                Utils.accountNumber = PRODUCT.MARGIN.number;
            }


            if (this.application) {
                this.firstName = this.application.firstName;
                this.applicationNumber = this.application.applicationNumber;
                this.isSubProductAD = this.application.applicationSubProduct === productTypeAD ? true : false;
                Utils.setSubProduct(this.application.applicationSubProduct);
                this.applicationService.setSubproduct$.next(this.application.applicationSubProduct);
                this.applicationService.applicationNumber$.next(this.application.applicationNumber);
                this.isIirocMember = this.application.iirocMember;
                if (Utils.getIsBranchReferral() === false) {
                    this.bmoRelationship = this.application.bmoRelationship['isApplicantExistingClient'];
                }

                this.BILAssisted = Utils.getIsBranchReferral();

                for (let i in this.application.accounts) {

                    if (!this.isMultiAccountFlow && !this.isBranchSDMultiAccountFlow) {
                        this.accountNumber = this.accountDetails[0].accountNumber;
                    }

                    if ((this.accountDetails[i].accountType === ACCOUNT_CODES.CASH && this.accountDetails[i].subTypes.includes('1'))) {
                        this.isCash = true;
                    }
                    else if (this.accountDetails[i].accountType === ACCOUNT_CODES.RRSP && this.accountDetails[i].isSpousalFlag == false) {
                        this.isRRSP = true;
                    }
                    else if (this.accountDetails[i].accountType === ACCOUNT_CODES.TFSA) {
                        this.isTFSA = true
                    }
                    else if (this.accountDetails[i].accountType === ACCOUNT_CODES.RRSP && this.accountDetails[i].isSpousalFlag == true) {
                        this.isSRRSP = true;
                    }
                    else if (this.accountDetails[i].accountType === ACCOUNT_CODES.CASH && this.accountDetails[i].subTypes.includes('2')) {
                        this.isMargin = true;
                    }
                    else if (this.accountDetails[i].accountType === ACCOUNT_CODES.RIF) {
                        this.isRIF = true;
                    }
                }

                if (this.isSubProductAD) {
                    this.analytics.setCustomerSource(AD);
                } else {
                    this.analytics.setCustomerSource(SD);
                }
                this.applicationService.setOLbJourneyStatus(this.application.ilUser, this.application.olbuser);
                if (this.application.ilUser || this.application.olbuser) {
                    // Utils.setIsOLBUser(true);
                    this.cdbOlbService.cdbOlbUserInfo(this.application.firstName);
                }
            }

            if (Utils.hasOwnProperty(this.application, 'amlFlag')) {
                this.amlFlag = this.application.amlFlag;
            } else {
                // console.log('next step -- ', 'amlFlag is missing so setting it to false');
                this.amlFlag = false; // if amlFlag is missing, making it false by default
            }
            if (Utils.hasOwnProperty(this.application, 'iirocOrganization')) {
                this.IirocMemberOrganization = this.application.iirocOrganization;
            }

            if (Utils.hasOwnProperty(this.application, 'isOptionFlag')) {
                this.isOptions = this.application.accounts.some(account => account.isOptionFlag === true);
            } else {
                // console.log('next step -- ', 'isOptions flag is missing so setting is to false');
                this.isOptions = false; // if isOptionsFlag is missing, making it false by default
            }

            if (Utils.hasOwnProperty(this.application, 'isBranchReferral')) {
                this.isBranchReferral = this.application.isBranchReferral;
            } else {
                //  console.log('next step -- ', 'isBranchReferral flag is missing so setting is to false');
                this.isBranchReferral = false; // if isBranchReferral is missing, making it false by default
            }


            // code 102 is for multi account, 101 for single account
            if (!this.amlFlag && Utils.hasOwnProperty(this.application, 'amlError')) {
                // this.isMultiAccount = this.application.amlError[0].errorCode === '102' ? true : false;
                if (this.application.amlError[0].errorCode === '102') {
                    this.isMultiAccount = true;
                } else {
                    this.isMultiAccount = false;
                }
            } else {
                this.isMultiAccount = false;
            }

            if (Utils.hasOwnProperty(this.application, 'rifPaymentMethod')) {
                this.rifPaymentMethod = this.application.rifPaymentMethod;
            } else {
                this.rifPaymentMethod = null;
            }
            if (Utils.hasOwnProperty(this.application, 'isShortSelling')) {
                this.isShortSelling = this.application.isShortSelling;
            } else {
                this.isShortSelling = false;
            }
            if (Utils.hasOwnProperty(this.application, 'fraudFlag')) {
                this.fraudFlag = this.application.fraudFlag === 'Y' ? true : false;
            } else {
                this.fraudFlag = false;
            }

            this.isApplicantExistingClient = this.application.bmoRelationship['isApplicantExistingClient'] ? this.application.bmoRelationship['isApplicantExistingClient'] : false;
            this.isProAccount = this.application.isProAccount;

            if (this.isDataRationalization4Enabled && this.application.docUploadStatus === DOC_UPLOAD_STATUS.COMPLETED) {
                Utils.setIsDocUploadSuccess(true);
            }
            // show when aml is false and user visits next-steps page for first time and hide on return from Doc upload.
            if (!this.amlFlag && !Utils.getIsDocUploadVisited()) {
                this.showDocUploadContainer = true;
            }


            this.docUploadSuccess = Utils.getIsDocUploadSuccess() || this.applicationService.getDocumentUploadStatus();


            // this.docFailThirdAttempt = Utils.getIsDocUploadVisited() && !Utils.getIsDocUploadSuccess();
            if (Utils.getIsDocUploadVisited() && !Utils.getIsDocUploadSuccess()) {
                this.docFailThirdAttempt = true;
            }

            sessionStorage.setItem('NextStep', 'true');

            if (this.docUploadSuccess || this.docFailThirdAttempt) {
                // if user comes back from document upload to next step screen,
                // we set this value in local storage... so if we refersh application now...
                // application will go to select account page
                sessionStorage.setItem('NextStep', 'true');
            }

            this.MWB10104 = (this.isOptions === true && this.bmoRelationship === true && this.amlFlag === false &&
                this.isProAccount === true && this.isIirocMember === true && this.isBranchReferral === false);

            this.MWB10106 = (this.isOptions === true && this.bmoRelationship === true && this.amlFlag === false &&
                this.isProAccount === false && this.isIirocMember === true && this.isBranchReferral === false);

            this.MWB10105 = (this.isOptions === true && this.bmoRelationship === true && this.amlFlag === false &&
                this.isProAccount === false && this.isIirocMember === false && this.isBranchReferral === false);

            this.MWB10070 = (this.isOptions === false && this.bmoRelationship === true && this.amlFlag === false &&
                this.isProAccount === false && this.isIirocMember === true && this.isBranchReferral === false);

            // show Doc upload w/ Pro letter req & IIROC Msg
            this.MWB10372 = (this.isOptions === false && this.bmoRelationship === false && this.amlFlag === false &&
                this.isProAccount === true && this.isIirocMember === true && this.isBranchReferral === false);

            // show doc upload
            this.MWB10373 = (this.isOptions === false && this.bmoRelationship === false && this.amlFlag === false &&
                this.isProAccount === false && this.isIirocMember === false && this.isBranchReferral === false);

            /* Sub-tasks */
            this.MWB10374 = (this.isOptions === false && this.bmoRelationship === true && this.amlFlag === true &&
                this.isProAccount === false && this.isIirocMember === false && this.isBranchReferral === false);

            this.MWB10375 = (this.isOptions === false && this.bmoRelationship === true && this.amlFlag === true &&
                this.isProAccount === false && this.isIirocMember === true && this.isBranchReferral === false);

            this.MWB10359 = (this.isOptions === true && this.bmoRelationship === false && this.isProAccount === true &&
                this.isIirocMember === true && this.amlFlag === false && this.isBranchReferral === false);

            this.MWB10360 = (this.isOptions === true && this.bmoRelationship === false && this.isProAccount === false &&
                this.isIirocMember === false && this.amlFlag === false && this.isBranchReferral === false);

            this.MWB10072 = (this.isOptions === false && this.bmoRelationship === true && this.isProAccount === false &&
                this.isIirocMember === false && this.amlFlag === false && this.isBranchReferral === false);

            this.MWB10076 = (this.isOptions === false && this.bmoRelationship === true && this.isProAccount === true &&
                this.isIirocMember === true && this.amlFlag === false && this.isBranchReferral === false);

            this.MWB10077 = (this.isOptions === false && this.bmoRelationship === true && this.isProAccount === true &&
                this.isIirocMember === true && this.amlFlag === true && this.isBranchReferral === false);

            this.MWB11461_1 = (this.isOptions === true && this.bmoRelationship === false && this.amlFlag === false &&
                this.isIirocMember === true && this.isBranchReferral === false);
            this.MWB11461_2 = (this.isOptions === false && this.bmoRelationship === false && this.amlFlag === false &&
                this.isIirocMember === true && this.isBranchReferral === false);

            this.MWB12119_MR = (this.isMargin && this.amlFlag === true && (this.decisionType == decisionType.ILACCEPT || this.decisionType == decisionType.ILREVIEW));

            this.MWB12119_Login = (this.isMargin && this.amlFlag === true && (this.decisionType == decisionType.ILDWNGRD || this.decisionType == decisionType.ILDECLNE));


            // this.MWB10536_1 = (this.bmoRelationship === true && this.isProAccount === false &&
            //     this.isIirocMember === true && this.amlFlag === true && this.isOptions === true);

            // this.MWB10536_2 = (this.bmoRelationship === true && this.isProAccount === false &&
            //     this.isIirocMember === false && this.amlFlag === true && this.isOptions === true);

            // this.MWB10536_3 = (this.bmoRelationship === false && this.isProAccount === true &&
            //     this.isIirocMember === true && this.amlFlag === false && this.isOptions === true);

            // this.MWB10536_4 = (this.bmoRelationship === true && this.isProAccount === true &&
            //     this.isIirocMember === true && this.amlFlag === true && this.isOptions === false);

            // this.MWB10536_5 = (this.bmoRelationship === false && this.isProAccount === false &&
            //     this.isIirocMember === false && this.amlFlag === false && this.isOptions === false);

            // this.MWB10536_6 = (this.bmoRelationship === true && this.isProAccount === true &&
            //     this.isIirocMember === true && this.amlFlag === true && this.isOptions === true);

            this.MWB10977_1 = (this.bmoRelationship === true && this.isProAccount === true &&
                this.isIirocMember === true && this.amlFlag === true && this.isOptions === true && this.isBranchReferral === false);

            this.MWB10977_2 = (this.bmoRelationship === true && this.isProAccount === false &&
                this.isIirocMember === true && this.amlFlag === true && this.isOptions === true && this.isBranchReferral === false);

            this.MWB10977_3 = (this.bmoRelationship === true && this.isProAccount === false &&
                this.isIirocMember === false && this.amlFlag === true && this.isOptions === true && this.isBranchReferral === false);


            // new conditions

            this.MWB11134_1 = (this.bmoRelationship === true && this.isProAccount === true &&
                this.isIirocMember === true && this.amlFlag === true && this.isOptions === true && this.isBranchReferral === false);

            this.MWB11134_2 = (this.bmoRelationship === true && this.isProAccount === false &&
                this.isIirocMember === true && this.amlFlag === true && this.isOptions === true && this.isBranchReferral === false);

            this.MWB11134_3 = (this.bmoRelationship === true && this.isProAccount === false &&
                this.isIirocMember === false && this.amlFlag === true && this.isOptions === true && this.isBranchReferral === false);

            this.MWB11134_4 = (this.bmoRelationship === false && this.isProAccount === true &&
                this.isIirocMember === true && this.amlFlag === false && this.isOptions === true && this.isBranchReferral === false);

            this.MWB11134_5 = (this.bmoRelationship === true && this.isProAccount === true &&
                this.isIirocMember === true && this.amlFlag === true && this.isOptions === false && this.isBranchReferral === false);

            this.MWB11134_6 = (this.bmoRelationship === false && this.isProAccount === false &&
                this.isIirocMember === false && this.amlFlag === false && this.isOptions === false && this.isBranchReferral === false);

            this.RIF_MANUAL_REVIEW_SD = (!this.isSubProductAD && this.isApplicantExistingClient && this.isOptions === true &&
                (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR));
            this.RIF_MANUAL_REVIEW = (this.isApplicantExistingClient && this.isOptions === false &&
                (this.amlFlag === false || (this.amlFlag === true && this.isProAccount === true)) &&
                (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR));
            this.RIF_PRO_LETTER_SD_1 = (!this.isSubProductAD && this.isApplicantExistingClient && this.isOptions === true &&
                this.isIirocMember === true && this.isProAccount === true &&
                (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR));
            this.RIF_PRO_LETTER = (this.isOptions === false &&
                (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR) &&
                this.isIirocMember === true && this.isProAccount === true);
            this.RIF_PRO_LETTER_SD_2 = (!this.isSubProductAD && !this.isApplicantExistingClient && this.isOptions === true &&
                this.isIirocMember === true && this.isProAccount === true &&
                (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR));
            this.RIF_PRO_LETTER_SD_3 = (!this.isSubProductAD && this.isOptions === true &&
                this.rifPaymentMethod === RIF_PAYMENT_METHOD.NBMOEFT && this.isIirocMember === true && this.isProAccount === true);
            this.RIF_PRO_LETTER_SD_4 = (this.isOptions === false &&
                this.rifPaymentMethod === RIF_PAYMENT_METHOD.NBMOEFT && this.isIirocMember === true && this.isProAccount === true);
            this.RIF_DOC_UPLOAD_SD = (!this.isBranchReferral && !this.isSubProductAD && !this.isApplicantExistingClient && this.isOptions === true &&
                (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR));
            this.RIF_DOC_UPLOAD = (!this.isBranchReferral && !this.isApplicantExistingClient && this.isOptions === false &&
                (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR));
            this.RIF_DOC_UPLOAD_SD_1 = (!this.isSubProductAD && this.isOptions === true &&
                this.rifPaymentMethod === RIF_PAYMENT_METHOD.NBMOEFT);
            this.RIF_DOC_UPLOAD_SD_2 = (!this.isSubProductAD && (this.isApplicantExistingClient || this.isBranchReferral) && this.isOptions === false &&
                this.rifPaymentMethod === RIF_PAYMENT_METHOD.NBMOEFT && this.isIirocMember === false && this.amlFlag === true);
            this.RIF_DOC_UPLOAD_1 = (this.isOptions === false && this.rifPaymentMethod === RIF_PAYMENT_METHOD.NBMOEFT);
            if (this.isSTPOptionsEnabled) {
                this.RIF_LOGIN = (this.isApplicantExistingClient && this.amlFlag === true && this.isProAccount === false &&
                    (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR)); //add comment for 15228 later
                this.REGISTERED_LOGIN = (!this.isSubProductAD && this.isOptions && this.isApplicantExistingClient && this.amlFlag === true && this.isProAccount === false);
                this.REGISTERED_MANUAL_REVIEW_SD = (!this.isSubProductAD && this.isOptions && !this.isApplicantExistingClient && (this.amlFlag === false || (this.amlFlag === true && this.isProAccount === true)));
            } else {
                this.RIF_LOGIN = (this.isApplicantExistingClient && this.isOptions === false && this.amlFlag === true && this.isProAccount === false &&
                    (this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR));
            }
            //MWB-12682
            this.MARGIN_MANUAL_REVIEW_SD = (!this.isSubProductAD && this.isMargin && this.isApplicantExistingClient && (this.isOptions === true ||
                this.isShortSelling === true) && (this.decisionType == decisionType.ILACCEPT || this.decisionType == decisionType.ILREVIEW));
            this.MARGIN_PRO_LETTER_SD = (!this.isSubProductAD && this.isMargin && (this.isOptions === true ||
                this.isShortSelling === true) && (this.decisionType == decisionType.ILACCEPT || this.decisionType == decisionType.ILREVIEW) && this.isIirocMember === true && this.isProAccount === true)
            this.MARGIN_DOC_UPLOAD_SD = (!this.isSubProductAD && this.isMargin && !this.isApplicantExistingClient && (this.isOptions === true ||
                this.isShortSelling === true) && (this.decisionType == decisionType.ILACCEPT || this.decisionType == decisionType.ILREVIEW))
            //MWB-12681
            if (this.isSTPOptionsEnabled) {
                this.MARGIN_LOGIN = (this.isMargin && this.isApplicantExistingClient && (!this.isShortSelling) && !this.fraudFlag && this.decisionType == decisionType.ILACCEPT &&
                    this.amlFlag && (!this.isIirocMember || (this.isIirocMember && !this.isProAccount)));
                this.MARGIN_MANUAL_REVIEW = (this.isMargin && this.isApplicantExistingClient && (!this.isShortSelling) && this.fraudFlag
                    && this.decisionType == decisionType.ILACCEPT && this.amlFlag && (!this.isIirocMember || (this.isIirocMember && !this.isProAccount)));

                this.MARGIN_MANUAL_REVIEW_1 = (this.isMargin && this.isApplicantExistingClient && (!this.isShortSelling) &&
                    this.decisionType == decisionType.ILACCEPT && !this.amlFlag);
                this.MARGIN_MANUAL_REVIEW_2 = (this.isMargin && this.isApplicantExistingClient && (!this.isShortSelling) &&
                    this.decisionType == decisionType.ILREVIEW);
            } else {
                this.MARGIN_LOGIN = (this.isMargin && this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling) && !this.fraudFlag && this.decisionType == decisionType.ILACCEPT &&
                    this.amlFlag && (!this.isIirocMember || (this.isIirocMember && !this.isProAccount)));
                this.MARGIN_MANUAL_REVIEW = (this.isMargin && this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling) && this.fraudFlag
                    && this.decisionType == decisionType.ILACCEPT && this.amlFlag && (!this.isIirocMember || (this.isIirocMember && !this.isProAccount)));

                this.MARGIN_MANUAL_REVIEW_1 = (this.isMargin && this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling) &&
                    this.decisionType == decisionType.ILACCEPT && !this.amlFlag);
                this.MARGIN_MANUAL_REVIEW_2 = (this.isMargin && this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling) &&
                    this.decisionType == decisionType.ILREVIEW);
            }
            this.MARGIN_PRO_LETTER = (this.isMargin && this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling)
                && this.decisionType == decisionType.ILACCEPT && this.isIirocMember && this.isProAccount);

            this.MARGIN_PRO_LETTER_1 = (this.isMargin && !this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling)
                && this.decisionType == decisionType.ILACCEPT && this.isIirocMember && this.isProAccount);
            this.MARGIN_PRO_LETTER_2 = (this.isMargin && (!this.isOptions && !this.isShortSelling)
                && this.decisionType == decisionType.ILREVIEW && this.isIirocMember && this.isProAccount);

            this.MARGIN_DOC_UPLOAD = (this.isMargin && !this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling) &&
                this.decisionType == decisionType.ILACCEPT);

            this.MARGIN_DOC_UPLOAD_1 = (this.isMargin && !this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling) && this.fraudFlag &&
                this.decisionType == decisionType.ILACCEPT && this.isIirocMember);
            this.MARGIN_DOC_UPLOAD_2 = (this.isMargin && !this.isApplicantExistingClient && (!this.isOptions && !this.isShortSelling) &&
                this.decisionType == decisionType.ILREVIEW);


            //branch assisted conditions as per excel check respective comments MWB-10447

            this.branchAssisted['case_1'] = this.isBranchReferral && this.isProAccount && this.isIirocMember && this.isOptions && this.amlFlag; // case 1
            this.branchAssisted['case_2'] = (this.isBranchReferral && this.isProAccount && this.isIirocMember && this.isOptions) && this.amlFlag == false; // case 2
            this.branchAssisted['case_3'] = (this.isBranchReferral && this.amlFlag && this.isIirocMember && this.isOptions) && this.isProAccount == false;  // case 3
            this.branchAssisted['case_4'] = (this.isBranchReferral && this.isIirocMember && this.isOptions) && this.isProAccount == false && this.amlFlag == false; // case 4
            this.branchAssisted['case_5'] = (this.isBranchReferral && this.isOptions) && this.isProAccount == false && this.amlFlag == false && this.isIirocMember == false; //case 5
            this.branchAssisted['case_6'] = (this.isBranchReferral && this.amlFlag && this.isOptions) && this.isProAccount == false && this.isIirocMember == false; // case 6
            this.branchAssisted['case_9'] = (this.isBranchReferral && this.isProAccount && this.isIirocMember && this.isOptions) && this.amlFlag == false; // case 9
            this.branchAssisted['case_12'] = (this.isBranchReferral && this.isProAccount && this.isIirocMember && this.amlFlag) && this.isOptions == false; // case 10
            this.branchAssisted['case_13'] = (this.isBranchReferral && this.isProAccount && this.isIirocMember) && this.amlFlag == false && this.isOptions == false; //case 11
            this.branchAssisted['case_16'] = (this.isBranchReferral && this.amlFlag) && this.isProAccount == false && this.isIirocMember == false && this.isOptions == false; // case 14
            this.branchAssisted['case_17'] = (this.isBranchReferral) && this.amlFlag == false && this.isProAccount == false && this.isIirocMember == false && this.isOptions == false; //case 15
            this.branchAssisted['case_18'] = (this.isBranchReferral && this.isIirocMember && this.amlFlag) && this.isProAccount == false && this.isOptions == false; //case 16
            this.branchAssisted['case_19'] = (this.isBranchReferral && this.isIirocMember) && this.isProAccount == false && this.isOptions == false && this.amlFlag == false; //case 17
            this.branchAssisted['case_20'] = (this.isBranchReferral && this.isIirocMember && this.isProAccount) && this.isOptions == false && this.amlFlag == false; //case 18
            this.branchAssisted['case_22'] = (this.isBranchReferral) && this.isIirocMember == false && this.isProAccount == false && this.isOptions == false && this.amlFlag == false; // case 19




            this.showManualReview = this.isManualReviewCondition();
            this.showDocUpload = this.isDocUploadCondition();


            if (this.isDataRationalization4Enabled) {
                if (this.showDocUpload == false || this.application.docUploadStatus === DOC_UPLOAD_STATUS.COMPLETED) {
                    this.loaderService.hide();
               } else if(this.showDocUpload && !this.isSkipDocUpload){
                    this.navigateToNextSteps = true;
                    this.loaderService.hide();
                    this.proceed();
                }
            }
            this.showProLetter = this.isProAccountCondition();
            this.showLogin = this.isLoginCondition();
            // console.log('showManualReview: ', this.showManualReview);
            // console.log('showDocUpload: ', this.showDocUpload);
            // console.log('showProLetter: ', this.showProLetter);
            // console.log('showLogin: ', this.showLogin);

            // console.log('this.MWB10977_2: ', this.MWB10977_2);
        }

        this.document_package = Utils.getDocumentsPackage();

        if (this.document_package && this.document_package.length > 0) {
            this.beneficiaryForm = this.document_package[0].documents[0];
        } else {
            this.beneficiaryForm = null;
        }

        this.isMultiAccountFlow = Utils.isMultiAccountFlow();
        this.isBranchSDMultiAccountFlow = Utils.isBranchSDMultiAccountFlow();

        if (this.isMultiAccountFlow || this.isBranchSDMultiAccountFlow) {
            this.isSTPForAllAccountTypes = this.isSTPForAllAccounts();
            this.isSTPandManualReviewAccounts = this.stpAndManualReviewAccounts();
            this.allManualReview = this.manualReviewAllAccounts();
            this.allManualReviewAndProLetter = this.manualReviewAllAccountsAndProLetter();
            this.isSkipDocumentsandProLetterRequired = this.skipDocumentsandProLetterRequired();
            this.missigDocMessage = this.submitMissingDocumentMessage();
            this.isDocSubmittedsAndProLetter = this.docSubmittedsAndProLetter();
            this.isMixedSTPManualReviewandVoidCheque = this.mixedSTPManualReviewandMissingVoidCheque();
            this.accountTypeService.accountType.next([]);
        }
        setTimeout(() => {
            this.setGTM();
        }, 400);

        if (this.isDataRationalization4Enabled && this.showDocUpload && !this.isSkipDocUpload) {
            this.navigateToNextSteps = true;
            this.loaderService.hide();
            this.proceed();
        }

    }

    downloadDocument(document) {
        this.documentTrackerService.downloadDocument(this.document_package[0].id, document.id).toPromise()
            .then(response => {
                this.documentTrackerService.openDownloadedDocument(response);
            })
            .catch(e => {
                console.log(e);
            });

    }
    ngAfterViewInit() {

        if (this.route.snapshot.data && this.nextStepsObject) {
            const application = this.nextStepsObject;

            const appReferences = this.route.snapshot.data['reference'];
            this.analytics.setPageState(this.url);
            this.analytics.triggerView('NextSteps');
            this.analytics.setApplicationID(application.applicationNumber);
            for (let i in this.accountDetails) {
                this.analytics.setProductNumber(this.accountDetails[i].accountNumber);
                this.analytics.setProductName(this.getProductName(application));
            }
            // this.analytics.setCustomerType(this.getCustomerType(application));
            (Utils.getIsBranchAssisted() === true)
                ? this.analytics.setCustomerType('next existing')
                : this.analytics.setCustomerType(this.getCustomerType(application));

            if (Utils.getIsBranchAssisted() === true) {
                this.analytics.setApplicationFlow(Utils.isBranchSDMultiAccountFlow() ? ANALYTICS.APPLICATION_FLOW.MULTIASSISTED : ANALYTICS.APPLICATION_FLOW.ASSISTED);
            } else if (Utils.getIsOLBUser()) {
                this.analytics.setApplicationFlow(Utils.isMultiAccountFlow() ? ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_CDB_MULTI : ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_CDB);
            } else {
                this.analytics.setApplicationFlow(Utils.isMultiAccountFlow() ? ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_MULTI : ANALYTICS.APPLICATION_FLOW.NON_ASSISTED);
            }

        }

        $('body').removeAttr('style');
        // this.analytics.setState('step 17');


    }

    isManualReviewCondition(): boolean {

        if (this.isRIF) {
            if (this.RIF_MANUAL_REVIEW_SD || this.RIF_MANUAL_REVIEW) {
                return true;
            } else {
                return false;
            }
        }
        if (this.isMargin) {
            if (this.MARGIN_MANUAL_REVIEW_SD || this.MARGIN_MANUAL_REVIEW || this.MARGIN_MANUAL_REVIEW_1 || this.MARGIN_MANUAL_REVIEW_2) {
                return true;
            } else {
                return false;
            }
        }
        if (this.MWB10106 || this.MWB10104 || this.MWB10105 || this.MWB10070 || this.MWB11461_1 ||
            this.MWB10072 || this.MWB10076 || this.MWB10977_1 || this.MWB10977_2 ||
            this.MWB10977_3 || this.MWB10977_1 || this.MWB11134_2 || this.MWB11134_3 ||
            this.MWB11134_4 || this.MWB11134_5 || this.MWB11134_6 || this.branchAssisted['case_1'] || this.branchAssisted['case_2'] || this.branchAssisted['case_3']
            || this.branchAssisted['case_4'] || this.branchAssisted['case_5'] || this.branchAssisted['case_6'] || this.branchAssisted['case_9'] || this.branchAssisted['case_12']
            || this.branchAssisted['case_13'] || this.branchAssisted['case_17'] || this.branchAssisted['case_19'] || this.branchAssisted['case_20'] || this.branchAssisted['case_22']
            // this.MWB12119_MR
        ) {
            if (this.MWB12119_Login) {
                return false;
            }
            return true;
        } else {
            return false;
        }

    }

    isDocUploadCondition(): boolean {

        if (this.docUploadSuccess === true) {
            return false;
        }

        if (this.isRIF) {
            if (this.RIF_DOC_UPLOAD_SD || this.RIF_DOC_UPLOAD_SD_1 || this.RIF_DOC_UPLOAD_SD_2 || this.RIF_DOC_UPLOAD_1 || this.RIF_DOC_UPLOAD) {
                return true;
            }
        }

        if (this.isMargin) {
            if (this.MARGIN_DOC_UPLOAD_SD || this.MARGIN_DOC_UPLOAD || this.MARGIN_DOC_UPLOAD_1 || this.MARGIN_DOC_UPLOAD_2) {
                return true;
            }
        }

        if (this.MWB10106 || this.MWB10104 || this.MWB10105 || this.MWB10070 ||
            this.MWB10072 || this.MWB10076 || this.branchAssisted['case_1'] || this.branchAssisted['case_2'] || this.branchAssisted['case_3']
            || this.branchAssisted['case_4'] || this.branchAssisted['case_5'] || this.branchAssisted['case_6'] || this.branchAssisted['case_9'] || this.branchAssisted['case_12']
            || this.branchAssisted['case_13'] || this.branchAssisted['case_17'] || this.branchAssisted['case_18'] ||
            this.branchAssisted['case_19'] || this.branchAssisted['case_20'] || this.branchAssisted['case_22']) {

            return false;

        } else if (this.MWB10359 || this.MWB10360 || this.MWB10373 || this.MWB10372 || this.MWB11134_4 || this.MWB11134_6 || this.MWB11461_1 ||
            this.MWB11461_2) {

            return true;

        }

        return false;
    }

    isProAccountCondition(): boolean {
        let proLetterCondition = false;
        if (this.isProAccount) {
            proLetterCondition = true;
            if (this.isRIF) {
                if (this.RIF_PRO_LETTER_SD_1 || this.RIF_PRO_LETTER_SD_2 || this.RIF_PRO_LETTER_SD_3 || this.RIF_PRO_LETTER_SD_4 ||
                    this.RIF_PRO_LETTER) {
                    proLetterCondition = true;
                } else {
                    proLetterCondition = false;
                }
            } else if (this.isMargin) {
                if (this.MARGIN_PRO_LETTER_SD || this.MARGIN_PRO_LETTER || this.MARGIN_PRO_LETTER_1 || this.MARGIN_PRO_LETTER_2) {
                    proLetterCondition = true;
                } else {
                    proLetterCondition = false;
                }

            } else if (this.MWB10977_2 || this.MWB11134_6 || this.branchAssisted['case_3'] || this.branchAssisted['case_4'] || this.branchAssisted['case_5'] ||
                this.branchAssisted['case_6'] || this.branchAssisted['case_16'] || this.branchAssisted['case_17'] || this.branchAssisted['case_18'] ||
                this.branchAssisted['case_19'] || this.branchAssisted['case_22']) {
                proLetterCondition = false;
                // console.log('proLetterCondition: MWB10977_2 ', proLetterCondition);
            }
        }
        return proLetterCondition;
    }

    isLoginCondition(): boolean {

        let loginCondition = false;

        if (this.isSTPOptionsEnabled) {
            if (this.isMargin) {
                if (this.isOptions && this.isShortSelling && (!this.amlFlag || !this.isApplicantExistingClient)) {
                    if ((this.decisionType == decisionType.ILACCEPT || this.decisionType == decisionType.ILREVIEW)) { //MWB-15230
                        return loginCondition = false;
                    }
                }
                if ((!this.MWB12119_MR && this.MWB12119_Login) || this.MARGIN_LOGIN) {
                    return loginCondition = true;
                }
                else if (this.MARGIN_MANUAL_REVIEW_1 || this.MARGIN_MANUAL_REVIEW_2) {
                    return loginCondition = false;
                }
            }
            else if (this.isRIF) {
                if (this.RIF_LOGIN) {
                    return loginCondition = true;
                }
            }
            else if (this.isOptions && (this.isSRRSP || this.isRRSP || this.isTFSA)) {  //Manual review and Login Conditions for Registered Accounts
                if (this.REGISTERED_LOGIN) {
                    return loginCondition = true;
                }
                else if (this.REGISTERED_MANUAL_REVIEW_SD) {
                    return loginCondition = false;
                }
            }
            else if ((!this.isOptions && !this.isProAccount && this.amlFlag) || this.MWB10374 || this.MWB10375 ||
                this.branchAssisted['case_16'] || this.branchAssisted['case_18']) {
                loginCondition = true;
            }
        } else {
            if (this.isMargin) {

                if ((!this.MWB12119_MR && this.MWB12119_Login) || this.MARGIN_LOGIN) {
                    return loginCondition = true;
                }

            } else if (this.isRIF) {

                if (this.RIF_LOGIN) {
                    return loginCondition = true;
                }
            } else if ((!this.isOptions && !this.isProAccount && this.amlFlag) || this.MWB10374 || this.MWB10375 ||
                this.branchAssisted['case_16'] || this.branchAssisted['case_18']) {
                loginCondition = true;
            }
        }


        // else if (this.MWB12119_MR){ //MWB-12681
        //     return loginCondition = false;
        // }

        return loginCondition;
    }

    getCustomerType(application) {

        if (application !== undefined && application !== null) {

            if (application.bmoRelationship !== undefined) {

                if (application.bmoRelationship.isApplicantExistingClient === true && application.isProAccount === false) {

                    return 'next existing';

                } else if (application.bmoRelationship.isApplicantExistingClient === false && application.isProAccount === false) {

                    return 'next non-existing';

                } else if (application.bmoRelationship.isApplicantExistingClient === true && application.isProAccount === true) {

                    return 'next pro existing';

                } else if (application.bmoRelationship.isApplicantExistingClient === false && application.isProAccount === true) {

                    return 'next pro non-existing';

                }

            }

        }

    }

    getProductName(application) {
        // send selected account Types to BMODATA data object for Adobe report
        if (application !== undefined && application !== null) {
            for (let i in this.accountDetails) {
                if (this.accountDetails[i].accountType === ACCOUNT_CODES.CASH) {
                    if (this.accountDetails[i].subTypes.includes('2')) {
                        return ACCOUNT_NAME_EN.MARGIN;
                    } else {
                        return ACCOUNT_NAME_EN.CASH;
                    }
                } else if (this.accountDetails[i].accountType === ACCOUNT_CODES.RRSP) {
                    return ACCOUNT_NAME_EN.RRSP;
                } else if (this.accountDetails[i].accountType === ACCOUNT_CODES.TFSA) {
                    return ACCOUNT_NAME_EN.TFSA;
                } else if (this.accountDetails[i].accountType === ACCOUNT_CODES.SRRSP) {
                    return ACCOUNT_NAME_EN.SRRSP;
                }
            }
        }

    }

    proceed() {
        this.applicationService.continue(APP_ROUTES.document_upload, this.lang);
    }

    onNavigate() {

        // if (this.lang === LANGUAGE_ENGLISH) {
        //     window.open(BMO_INVESTORLINE_LINK.EN, '_blank');
        // } else if (this.lang === LANGUAGE_FRENCH) {
        //     window.open(BMO_INVESTORLINE_LINK.FR, '_blank');
        // }

        if (this.configService.getConfig() !== null) {
            if (this.lang === LANGUAGE_ENGLISH) {
                window.open(this.configService.getConfig().ILCLIENTWEB_EN, '_blank');
            } else if (this.lang === LANGUAGE_FRENCH) {
                window.open(this.configService.getConfig().ILCLIENTWEB_FR, '_blank');
            }
        }

    }

    setGTM() {


        if (this.isSubProductAD) {
            Utils.product = productTypeAD;
        } else {
            Utils.product = productTypeSD;
        }

        const gtmDataLayerForNextStep: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleCompletion(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.NEXT_STEP,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.next_steps,
            'applicationId': this.applicationId,
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.product,
            'netWorth': ''
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForNextStep);

    }

    printSaveCAADocument() {
        let openWindow = window.open();
        this.applicationService.printSaveCAA()
            .then((res: any) => {
                this.documentTrackerService.openDownloadedCAADocument(res.body, openWindow);
            })
            .catch(err => {
                console.log('catch err: ', err);
            });
    }
    getAccountTypeLabel(accountCode, subTypes?, spousalFlag?) {
        if (accountCode == ACCOUNT_CODES.TFSA) {
            return TFSA_ACCOUNT.description;
        }
        else if (accountCode == ACCOUNT_CODES.RRSP && spousalFlag === false) {
            return RRSP_ACCOUNT.description;
        }
        else if (accountCode == ACCOUNT_CODES.RRSP && spousalFlag === true) {
            return SPOUSAL_RRSP_ACCOUNT.description;
        }
        else if (accountCode == ACCOUNT_CODES.RIF) {
            return RRIF_ACCOUNT.description;
        }
        else if (accountCode == ACCOUNT_CODES.CASH && subTypes.includes("1")) {
            return CASH.description;
        }
        else if (accountCode == ACCOUNT_CODES.CASH && subTypes.includes("2")) {
            return MARGIN.description;
        }
    }

    isOptionFlag(account) {
        return account.optionFlag || false;
    }

    isSTPForAllAccounts(): boolean {
        if (this.isApplicantExistingClient && this.amlFlag == true && !this.isMargin && this.accountDetails.length > 0
            && (!this.isRIF || this.isRifPaymentBmoAccount()) && !this.isProAccount) {
            this.STPAccounts = this.accountDetails;
            return true;
        }
        else if (this.isApplicantExistingClient && this.amlFlag == true && (!this.isMargin || this.decisionType == decisionType.ILACCEPT) && this.accountDetails.length > 0
            && (!this.isRIF || this.isRifPaymentBmoAccount()) && !this.isProAccount) {
            this.STPAccounts = this.accountDetails;
            return true;
        }
        return false;
    }

    stpAndManualReviewAccounts(): boolean {

        if (this.accountDetails.length > 0 && this.isApplicantExistingClient && (!this.isMargin || this.decisionType == decisionType.ILREVIEW) && (!this.isRIF || this.isRifPaymentBmoAccount()) && this.amlFlag
            && !this.isProAccount) {

            this.STPAccounts = this.accountDetails.filter(account => (account.subTypes != '2'));
            this.ManualReviewAccounts = this.accountDetails.filter(account => (account.accountType == ACCOUNT_CODES.CASH && account.subTypes == '2'));

            return true;

        }
        else if (this.accountDetails.length > 0 && this.isApplicantExistingClient && (!this.isMargin || (this.isShortSelling === true && this.decisionType == decisionType.ILACCEPT)) && this.amlFlag
            && (!this.isRIF || this.isRifPaymentBmoAccount()) && (!this.isProAccount)) {

            this.STPAccounts = this.accountDetails.filter(account => (account.subTypes != '2'));
            this.ManualReviewAccounts = this.accountDetails.filter(account => (account.accountType == ACCOUNT_CODES.CASH && account.subTypes == '2'));

            return true;
        }

        else if (this.accountDetails.length > 0 && this.isApplicantExistingClient && (!this.isMargin || this.decisionType == decisionType.ILREVIEW || this.isShortSelling) && this.amlFlag
            && (!this.isRIF || this.isRifPaymentNonBMOAccount()) && this.docUploadSuccess && (!this.isProAccount)) {

            this.STPAccounts = this.accountDetails.filter(account => ((account.subTypes != '2') && account.accountType !== ACCOUNT_CODES.RIF));
            this.ManualReviewAccounts = this.accountDetails.filter(account => ((account.accountType === ACCOUNT_CODES.CASH && account.subTypes == '2') || account.accountType === ACCOUNT_CODES.RIF));

            return true;
        }
        else if (this.accountDetails.length > 0 && this.isApplicantExistingClient && (!this.isMargin || this.decisionType == decisionType.ILACCEPT) && this.amlFlag == true
            && (!this.isRIF || this.isRifPaymentNonBMOAccount()) && this.docUploadSuccess && (!this.isProAccount)) {

            this.STPAccounts = this.accountDetails.filter(account => (account.accountType !== ACCOUNT_CODES.RIF));
            this.ManualReviewAccounts = this.accountDetails.filter(account => (account.accountType === ACCOUNT_CODES.RIF));

            return true;
        }
        return false;
    }


    isRifPaymentNonBMOAccount() {

        if (this.rifPaymentMethod == RIF_PAYMENT_METHOD.NBMOEFT) {
            return true;
        }
        return false;
    }

    isRifPaymentBmoAccount() {
        if (this.rifPaymentMethod == RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod == RIF_PAYMENT_METHOD.TFR) {
            return true;
        }
        return false;
    }
    isMarginDecisionTypeReviewOrAccept(): boolean {
        if (this.decisionType == decisionType.ILREVIEW || this.decisionType == decisionType.ILACCEPT) {
            return true;
        }
        return false;
    }
    manualReviewAllAccounts(): boolean {
        if (this.accountDetails.length > 0 && this.isApplicantExistingClient && !this.amlFlag &&
            (!this.isProAccount)) {
            if (!this.isMargin || this.decisionType == decisionType.ILREVIEW || this.decisionType == decisionType.ILACCEPT) {
                if (!this.isRIF || this.rifPaymentMethod === RIF_PAYMENT_METHOD.BMOEFT || this.rifPaymentMethod === RIF_PAYMENT_METHOD.TFR ||
                    (this.rifPaymentMethod === RIF_PAYMENT_METHOD.NBMOEFT && this.docUploadSuccess)) {
                    this.ManualReviewAccounts = this.accountDetails;
                    this.allManualFlag = true;
                    return true;
                }
            }
            let allManualThirdCondition: boolean;
            let allManualFourthCondition: boolean;

            if (this.isRIF) {
                allManualThirdCondition = this.decisionType == decisionType.ILREVIEW && this.docUploadSuccess && this.isRifPaymentNonBMOAccount();
                allManualFourthCondition = this.decisionType == decisionType.ILACCEPT && this.docUploadSuccess && this.isRifPaymentNonBMOAccount();
            } else {
                allManualThirdCondition = this.decisionType == decisionType.ILREVIEW && this.docUploadSuccess;
                allManualFourthCondition = this.decisionType == decisionType.ILACCEPT && this.docUploadSuccess;
            }

            if (allManualThirdCondition || allManualFourthCondition) {
                this.ManualReviewAccounts = this.accountDetails;
                this.allManualFlag = true;
                return true;
            }


        } else if (this.accountDetails.length > 0 && !this.isApplicantExistingClient && !this.amlFlag &&
            (!this.isProAccount)) {

            let docSubmittedFirstCondition: boolean;
            let docSubmittedSecondCondition: boolean;

            docSubmittedFirstCondition = (!this.isRIF || this.isRifPaymentBmoAccount()) && this.docUploadSuccess && (!this.isMargin || this.isMarginDecisionTypeReviewOrAccept()) && !this.isProAccount;
            docSubmittedSecondCondition = (!this.isRIF || this.isRifPaymentNonBMOAccount()) && this.docUploadSuccess && (!this.isMargin || this.isMarginDecisionTypeReviewOrAccept()) && !this.isProAccount;

            if (docSubmittedFirstCondition || docSubmittedSecondCondition) {
                this.ManualReviewAccounts = this.accountDetails;
                this.allManualFlag = true;
                return true;
            }
        }
        return false;
    }


    manualReviewAllAccountsAndProLetter(): boolean {
        if (this.accountDetails.length > 0 && this.isApplicantExistingClient && this.isProAccount &&
            (!this.isMargin || this.isMarginDecisionTypeReviewOrAccept()) &&
            (!this.isRIF || this.isRifPaymentBmoAccount() || (this.isRifPaymentNonBMOAccount() && this.docUploadSuccess))) {
            this.ManualReviewAccounts = this.accountDetails;
            this.allManualFlag = true;
            return true;
        }
        return false;
    }
    docSubmittedsAndProLetter(): boolean {
        if (this.accountDetails.length > 0 && !this.isApplicantExistingClient && this.isProAccount && this.docUploadSuccess &&
            (!this.isMargin || this.isMarginDecisionTypeReviewOrAccept())) {
            return true;
        }
        return false;
    }

    submitMissingDocumentMessage(): boolean {

        if (this.accountDetails.length > 0 && this.isSkipDocUpload && this.isApplicantExistingClient && this.showDocUpload && (!this.isMargin || this.isMarginDecisionTypeReviewOrAccept() || this.isShortSelling)
            && !this.isProAccount) {
            this.onlyVoidChequeRequired = true;
            return true;
        }
        else if (this.accountDetails.length > 0 && this.isSkipDocUpload && this.showDocUpload && !this.isApplicantExistingClient && (!this.isMargin || this.isMarginDecisionTypeReviewOrAccept() || this.isShortSelling)
            && !this.isProAccount) {
            return true;
        }
        return false;
    }

    skipDocumentsandProLetterRequired() {
        if (this.accountDetails.length > 0 && this.isSkipDocUpload && this.isApplicantExistingClient && this.showDocUpload && (!this.isMargin || this.isMarginDecisionTypeReviewOrAccept() || this.isShortSelling)
            && this.isProAccount) {
            this.onlyVoidChequeRequired = true;
            return true;
        }
        else if (this.accountDetails.length > 0 && this.isSkipDocUpload && this.showDocUpload && !this.isApplicantExistingClient && (!this.isMargin || this.isMarginDecisionTypeReviewOrAccept() || this.isShortSelling)
            && this.isProAccount) {
            return true;
        }
        return false;
    }

    mixedSTPManualReviewandMissingVoidCheque() {
        if (this.accountDetails.length > 0 && this.isApplicantExistingClient && (!this.isRIF || (this.isRifPaymentNonBMOAccount() && this.isSkipDocUpload)) && (!this.isMargin || (this.isShortSelling && this.isMarginDecisionTypeReviewOrAccept()) || this.decisionType == decisionType.ILREVIEW) && !this.isProAccount) {
            if (this.amlFlag) {
                this.STPAccounts = this.accountDetails.filter(account => ((account.subTypes != '2') && account.accountType !== ACCOUNT_CODES.RIF));
                this.ManualReviewAccounts = this.accountDetails.filter(account => (account.subTypes == '2'));
                if (this.isRifPaymentNonBMOAccount()) {
                    this.onlyVoidChequeRequired = true;
                }
                this.isNotMixedScenario = false;
                return true;
            }
            else {
                this.ManualReviewAccounts = this.accountDetails;
                if (this.isRifPaymentNonBMOAccount()) {
                    this.onlyVoidChequeRequired = true;
                }
                this.isNotMixedScenario = false
                return true;
            }

        }
        else if (this.accountDetails.length > 0 && this.isApplicantExistingClient && (!this.isRIF || (this.isRifPaymentNonBMOAccount() && this.isSkipDocUpload)) && this.isProAccount) {
            this.onlyVoidChequeRequired = true;
            return true;
        }
        return false;
    }

    copyAccountNumber(toolTip) {
        this.showTooltip = toolTip;
        clearTimeout(this.toolTipTimer);
        this.toolTipTimer = setTimeout(() => {
            this.showTooltip = false;
        }, 3000);
    }

}
