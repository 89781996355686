import { ApplicationService } from './../../shared/service/application.service';
import { FormService } from './../../shared/service/form.service';
import { NgForm, FormControl } from '@angular/forms';
import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX , productTypeAD , productTypeSD} from '../../config/app-config.constants';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../shared/utils/utils.class';
import { Subscription } from 'rxjs';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
export class AccountPassword {
    password = null;
    confirmPassword = null;
    hasBMODebitCard =  null;
    newDebitCardYesNo =  null;
    debitCardNumber =  null;
    consentToAccountLink = null;
}


@Component({
    selector: 'app-account-password',
    templateUrl: './account-password.component.html'
})

export class AccountPasswordComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    public APP_ROUTES = APP_ROUTES;
    @ViewChild('accountPasswordForm', {static: true}) accountPasswordForm: NgForm;

    // For accessibility purposes
    @ViewChild('accountPasswordCtrl', {static: true}) accountPasswordCtrl: FormControl;
    @ViewChild('accountConfirmPasswordCtrl', {static: true}) accountConfirmPasswordCtrl: FormControl;
    
    @ViewChild('debitCardNumberCtrl', { static: true }) debitCardNumberCtrl: FormControl;

    public isFormSubmit: boolean;
    public accountPassword: AccountPassword;

    private isCurrentLangEnglish: boolean;
    private isCurrentLangFrench: boolean;
    public disableFinishLater;
    public showFinishLater;
    public backRoute : any;
    appNumber;
    public accountSetup;
    criticalError: boolean;
    public subProduct = '';
    public url: string;
    public isCdbOlbUser: boolean = false;
    public debitCardNumbers : any = [];
    public debitCardLogin : boolean = false;
    public creditCardLogin : boolean = false;
    public isNonRegisteredAccount: boolean = false;
    public isMultiAccountFlow: boolean = false;
    public selectedMultiAccount : any = [];
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    private onExitApplicationPerformSaveSubscriber;

    public passwordHide: Boolean = true;
    public isMultiAccountOpen: boolean;
    isSubProductAD: boolean;
    branchAssisted: boolean;
    public branchSDMultiAccount: Boolean = false;
    public isJourneyUser: boolean = false;
    public isMargin: boolean = false;
    public isCashAccount: boolean = false;

    constructor(private applicationService: ApplicationService,
        public titleService: Title,
        private winRef: WindowRefService,
        public formService: FormService,
        private configService: ConfigResolverService<any>,
        public analytics: AnalyticsService) {

        this.accountPassword = new AccountPassword();
        this.isFormSubmit = false;
        this.formService.forms.accountPassword.isVisited = true;
        this.formService.setActiveComponent('accountPassword', this);

        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();

        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'acctPwdFrm') {
                    this.onFinishLater();
                }
            });
            this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
                if (value) {
                    this.onFinishLater(true);
                }
            });
            this.url = '/' + (Utils.getIsOLBUser()?APP_ROUTES.account_access:APP_ROUTES.account_password);
    }

    ngOnInit() {

        this.isCdbOlbUser = Utils.getIsOLBUser();
        this.isJourneyUser = Utils.getIsJourneyUser() || Utils.getIsIlUser();
        this.subProduct = Utils.getSubProduct() === productTypeAD ? productTypeAD  : productTypeSD;
        this.isMultiAccountFlow =  Utils.isMultiAccountFlow();
        this.branchSDMultiAccount = Utils.isBranchSDMultiAccountFlow();
        this.selectedMultiAccount = Utils.selectedMultiAccounts || [];
        this.isSubProductAD = Utils.isSubProductAD();
        this.isCashAccount = this.applicationService.isCashAccount();
        this.isMargin = this.applicationService.isMargin();
        this.isNonRegisteredAccount = this.isMargin || this.isCashAccount;

        //back button functionality
        if ((Utils.getIsBranchReferral() === true && Utils.isCash() === false && Utils.isSubProductAD() || Utils.getIsOLBUser()) || (this.isJourneyUser === true && this.isNonRegisteredAccount === true)) {
            this.backRoute = APP_ROUTES.consents;
        } else {
            this.backRoute = APP_ROUTES.bmo_relationship
        }

        this.isMultiAccountOpen = this.applicationService.getMultiAccountOpenFeatureFlag();
        this.isSubProductAD = Utils.isSubProductAD();
        this.branchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;

        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        if((this.isCdbOlbUser || this.isJourneyUser) && this.isNonRegisteredAccount) {
            this.cardDetails(); 
        }

        this.titleService.setTitle(Utils.getPageTitleAccountPassword());
        this.formService.setActiveForm(this.accountPasswordForm);
        // already visited form and now visiting back again
        if (this.formService.forms.accountPassword.data != null) {
            this.accountPassword = this.formService.getFormData('accountPassword');
            return;
        } else {
            // first time on financial Information page instantiate the model
            this.accountPassword = new AccountPassword();
            if((this.isCdbOlbUser|| this.isJourneyUser) && this.isNonRegisteredAccount && this.debitCardNumbers.length === 1) {
                this.accountPassword.debitCardNumber = this.debitCardNumbers[0];
            }
        }

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

        setTimeout(() => {
            this.setGTM();
        }, 200);


    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {

                if (this.accountPasswordForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });
    }
    cardDetails() {
        const appObject = Utils.getApplicationObject();
        this.debitCardNumbers = [];
    if(appObject && appObject.accounts && appObject.accounts[0]?.ecifCardNumberList && (appObject.accounts[0]?.ecifCardNumberList.length > 0)) {
        const ecifCardNumberList = appObject.accounts[0].ecifCardNumberList;
         for(let i= 0 ; i < ecifCardNumberList.length ; i++ ) {
          if(ecifCardNumberList[i].split(':')[1] === appObject.accounts[0].debitCardNumber || this.isJourneyUser) {
           if(ecifCardNumberList[i].split(':')[0] === 'D') {
             this.debitCardLogin = true;
            this.debitCardNumbers.push(ecifCardNumberList[i].split(':')[1].slice(-4));
            break;
          } else if (ecifCardNumberList[i].split(':')[0] === 'C') {
            this.creditCardLogin = true;
            this.checkDebitCards(ecifCardNumberList);
            break;
          }
        }
        }
    }
    }
    checkDebitCards(ecifCardNumberList) {
        for(let i= 0 ; i < ecifCardNumberList.length ; i++ ) {
            if(ecifCardNumberList[i].split(':')[0] === 'D') {
                this.debitCardNumbers.push(ecifCardNumberList[i].split(':')[1].slice(-4));
            }
        }
    }
    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }

    ngAfterViewInit() {
        // this.analytics.setState('step 13');
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('AccountPassword');
        // this.analytics.setPPVID(ANALYTICS.account_password);
        this.checkFormValidityOnSignOut();

        // this.analytics.setvPageTitle(Utils.getPageTitleAccountPassword());
        // this.analytics.setOriginalLocation(Utils.getPageTitleAccountPassword());
        // this.analytics.setvURL(Utils.getvURL());
    }


    isError(ctrl: any) {
        return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
    }

    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {
        // console.log('onFinishLater: ');
        if (!this.accountPasswordForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }

        this.formService.setFormData('accountPassword', this.accountPassword);


        // To prepare payload with PI , CI, EI, FI , FD RD, Cons, BR and AP
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');
        const consents = this.formService.getFormData('consents');
        if ((Utils.getIsBranchReferral() === true && (Utils.isCash() === true || Utils.isMargin() === true)) || (Utils.getIsBranchReferral() === false)) {
            const bmoRelationship = this.formService.getFormData('bmoRelationship');
            this.formService.getPayload().setBMORelationship(bmoRelationship);
        }
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
        this.formService.getPayload().setConsents(consents);
        this.formService.getPayload().setAccountPassword(this.accountPassword);

        if (Utils.isSpousalRRSP()) {

            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }
        // save data in page only if form is valid
        if (!this.accountPasswordForm.valid) {
            const emptyAccountPassword = new AccountPassword();
            this.formService.getPayload().setAccountPassword(emptyAccountPassword);
        } else {
            if (this.accountPasswordForm.valid) {
                this.formService.getPayload().setAccountPassword(this.accountPassword);
            }
        }

        // console.log('payload with PI CI EI FI FD RD Consents BR AP', this.formService.getPayload());

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({appNumber:saveResponse.applicationNumber,isAccountSetup:true})
                } else {
                    this.criticalError = true;
                }

            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }

    saveOnSignOut() {

        this.formService.forms.accountPassword.isCompleted = true;
        this.formService.setFormData('accountPassword', this.accountPassword);

        // To prepare payload with PI , CI, EI, FI , FD RD, Cons, BR and AP
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');
        const consents = this.formService.getFormData('consents');
        if ((Utils.getIsBranchReferral() === true && (Utils.isCash() === true || Utils.isMargin() === true)) || (Utils.getIsBranchReferral() === false)) {
            const bmoRelationship = this.formService.getFormData('bmoRelationship');
            this.formService.getPayload().setBMORelationship(bmoRelationship);
        }
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
        this.formService.getPayload().setConsents(consents);
        this.formService.getPayload().setAccountPassword(this.accountPassword);

        if (Utils.isSpousalRRSP()) {

            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }

            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }

    trackPasswordCheck() {
        let temp = "valid - ";
        if (this.accountPasswordCtrl?.errors?.lengthMustBeGreaterThanFive){
            temp += 'lengthMustBeGreaterThanFive';
        }

        if (this.accountPasswordCtrl?.errors?.noLettersPresent) {
            temp += 'minoneletter';
        }

        if (this.accountPasswordCtrl?.errors?.noDigitsPresent){
            temp += 'minonenumber';
        }

        if (this.accountPasswordCtrl?.errors?.noSpecialCharactersAllowed) {
            temp += 'noSpecialChar';
        }

        this.analytics.triggerView(temp);
    }


    onSubmit() {
        this.isFormSubmit = true;

        // For accessibility purposes, set focus on the invalid element.
        if (!this.accountPasswordForm.valid) {
            this.setFocus();
        }

        if (this.accountPasswordForm.valid) {

            this.formService.forms.accountPassword.isCompleted = true;
            this.formService.setFormData('accountPassword', this.accountPassword);


            // To prepare payload with PI , CI, EI, FI , FD RD, Cons, BR and AP
            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');
            const employmentInformation = this.formService.getFormData('employmentInformation');
            const familyInformation = this.formService.getFormData('familyInformation');
            const financialInformation = this.formService.getFormData('financialInformation');
            const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');
            const consents = this.formService.getFormData('consents');

            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
            this.formService.getPayload().setFamilyInformation(familyInformation);
            this.formService.getPayload().setFinancialInformation(financialInformation);
            this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
            this.formService.getPayload().setConsents(consents);
            this.formService.getPayload().setAccountPassword(this.accountPassword);

            if ((Utils.getIsBranchReferral() === true && (Utils.isCash() === true || Utils.isMargin() === true)) || (Utils.getIsBranchReferral() === false)) {
                const bmoRelationship = this.formService.getFormData('bmoRelationship');
                this.formService.getPayload().setBMORelationship(bmoRelationship);
            }

            if (Utils.isSpousalRRSP()) {

                this.formService.getPayload().setSpousalRRSP(familyInformation);
            }


            // console.log('payload with PI CI EI FI FD RD Consents BR AP', this.formService.getPayload());

            if (Utils.getIsInSession() === false) {

                this.applicationService.saveAndcontinue(this.accountPassword, APP_ROUTES.review);

            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        // console.log(' save res: ', res.body);
                        // console.log(' save res status: ', res.status);
                        if (res.status === SUCCESS_RESPONSE) {

                            this.applicationService.saveAndcontinue(this.accountPassword, APP_ROUTES.review);

                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        // console.log('save err: ', err.status);
                        this.criticalError = true; // show error when save fails on top of page
                    });


            }

        } else {
            // console.log('form is invalid ');
        }
    }

    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {

        // setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.accountPasswordForm);
           }, 250)
    }

    ngOnDestroy() {
        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.formService.setFormData('accountPassword', this.accountPassword);
        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }

    setGTM() {

        const gtmDataLayerForAccountPassword: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleAccountPassword(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.ACCOUNT_PASSWORD,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.account_password,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForAccountPassword);
        // console.log('account pwd', this.winRef.nativeWindow.dataLayer);

    }
    togglePasswordVisibility(createPassword : string , confirmPassword : string) {
        this.passwordHide = !this.passwordHide;
        Utils.passwordVisibilityToggle(createPassword);
        Utils.passwordVisibilityToggle(confirmPassword);
    }

    resetDebitOptions() {
        if(this.accountPassword.hasBMODebitCard === "Yes") {
            this.accountPassword.newDebitCardYesNo = null;
        } else {
            this.accountPassword.debitCardNumber = null;
        }
    }
}
