
import {
    FormControl,
    ValidatorFn,
    Validator, AbstractControl, NG_VALIDATORS,
} from '@angular/forms';

import { Directive } from '@angular/core';

@Directive({
    selector: '[appAddressLengthValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: AddressValidator,
            multi: true
        }
    ]

})
export class AddressValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = this.addressValidator();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    addressValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value !== undefined && control.value !== null) {

                if (control.value.length <= 30) {
                    return null;        // if the pattern is correct, retrun null means no errors
                }
                return {
                    streetNumberName: {
                        maxlength: true
                    }
                };
            }

        };
    }
}
