import { Component, OnInit, forwardRef } from '@angular/core';
import { Input } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor, NgForm, NG_VALIDATORS, FormControl } from '@angular/forms';
import { RADIO_TYPES } from '../../../config/app-config.constants';
export const DEFAULT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioCardComponent),
  multi: true
};

export const NG_VALIDATORS_ACCESSOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RadioCardComponent),
  multi: true,
};


@Component({
  selector: 'app-radio-card',
  templateUrl: './radio-card.component.html',
  providers: [DEFAULT_VALUE_ACCESSOR, NG_VALIDATORS_ACCESSOR]
})
export class RadioCardComponent implements OnInit, ControlValueAccessor {
  @Input() options: any;
  @Input() section: any;
  @Input() name: any;
  @Input() imageSource: any;
  @Input() templateType: any;
  @Input() hide: any;
  @Input() form: NgForm;
  @Input() errorMessage: any;
  @Input() isError: boolean = false;
  @Input() model: any;
  @Input() data?: string;
  @Input() accessErrorId: any;
  @Input() defaultValue?: any;
  @Input() dataAna ?: any;
  @Input() isFullImageUrl?: boolean = false;
  @Input() hasDescription?: boolean = false;
  public RADIO_TYPES: any = RADIO_TYPES;

  fn = (value: any) => { };
  public innerValue: any = '';

  constructor() {


  }

  ngOnInit() {

  }
  ngAfterViewInit() {
  }
  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.fn(v);
    }
  }
  writeValue(value: any) {
    if (value === undefined || value === null) {
      value = this.defaultValue;
    }

    if (value !== this.innerValue) {
      this.innerValue = value;
    }

  }
  registerOnChange(fn) {
    this.fn = fn;
  }

  registerOnTouched() { }


  public validate(c: FormControl) {
    this.isError = (c.value === null || c.value === undefined || c.value === '') ? true : false;
    return (c.value) ? null : {
      required: {
        valid: false,
      },
    };

  }

}
