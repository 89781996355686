<span
  id="bilHeader"
  class="header-focus"></span>

<header>
  <div class="bmo-wrapper">
    <app-header [stepInfo]="progressBar.getProgressInfo()"></app-header>
  </div>
</header>
<app-account-name></app-account-name>

<router-outlet
  (activate)="changeOfRoutes()"
  *ngIf="isCookieEnabled"></router-outlet>

<!-- <main class="site-body" *ngIf="!isCookieEnabled">
    <div class="bmo-wrapper">
        <div class="bmo-container-flex">
            <div class="left-nav">
                <app-navigation></app-navigation>
            </div>
            <div class="flex-fill">
                <span id="bilBody" class="linkAnchor"></span>
                <app-set-expectation></app-set-expectation>
            </div>
        </div>
    </div>
</main> -->

<div
  class="site-body"
  *ngIf="!isCookieEnabled">
  <div class="bmo-wrapper">
    <div class="bmo-container-flex">
      <div class="left-nav">
        <app-no-nav></app-no-nav>
      </div>
      <!-- <main class="flex-fill">
                    <span id="bilBody" class="linkAnchor"></span>
                    <app-set-expectation></app-set-expectation>
                </main> -->
    </div>
  </div>
</div>

<div #investorOverlay></div>
<app-account-setup
  *ngIf="accountsetupDetails.status && !(isCdbOlbUser || isJourneyUser)"
  (dismissAccountSetup)="hideAccountSetup()"
  [applicationNumber]="accountsetupDetails.appNumber"></app-account-setup>
<app-resume-application
  *ngIf="resumeApplication"
  (dismiss)="hideResumeApplication()">
</app-resume-application>
<app-qualtrics
  *ngIf="enableSurvey && !sessionTimeModelEnabled"
  [qulatricsObj]="qulatricsObj"
  (dismiss)="disableSurvey()">
</app-qualtrics>

<!-- Application Sign out - once an account exists -->
<app-sign-out></app-sign-out>

<!-- Modal Other Accounts - list of other accounts -->
<!-- --- -->
<app-other-accounts-model *ngIf="showOtherAccountsModel && !onTimeout && !sessionTimeModelEnabled"></app-other-accounts-model>

<!-- SHOW this modal for the Document Upload tile -->
<!-- --- -->
<div class="bmo-modal bmo-doc-upload-setexpect">
  <form>
    <a
      role="button"
      attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
      attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
      class="btn-icon btn-close tooltip-show"
      (click)="hideDocUploadDocs()"
      href="javascript://">
      <span class="icon icon-large close-grey"></span>
    </a>
    <div class="modal-content-scroll no-margin content-left">

      <section>

        <h1 translate="modalSetExpectDocUpload.titleModal"></h1>

        <hr>

        <h2 translate="modalSetExpectDocUpload.section1Title"></h2>
        <div [innerHtml]="'modalSetExpectDocUpload.section1Content' | translate | safeHtml"></div>

        <hr>

        <h2 translate="modalSetExpectDocUpload.section2Title"></h2>
        <div [innerHtml]="'modalSetExpectDocUpload.section2Content' | translate | safeHtml"></div>

        <hr>

        <h2 translate="modalSetExpectDocUpload.section3Title"></h2>
        <div [innerHtml]="'modalSetExpectDocUpload.section3Content' | translate | safeHtml"></div>

        <div class="form-row form-page-buttons content-center">
          <button
            class="primary element-center"
            type="submit"
            translate="button.close"
            (click)="hideDocUploadDocs()"></button>
        </div>

      </section>

    </div>
  </form>
</div>

<!-- SHOW this modal for the Cookies Enable Modal -->

<app-cookie-modal
  [showModal]="showCookieModal"
  *ngIf="!isIEBrowser && !onTimeout && isCookieEnabled && isCookieModalFeatureEnabled"></app-cookie-modal>

<div class="bmo-modal bmo-session-timeout" role="dialog" aria-labelledby="ariaSessionTimeoutTitle">

  <a
    role="button"
    attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
    attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
    class="btn-icon btn-close tooltip-show"
    href="javascript://"
    (click)="handeIdleTimeNavigation('keepGoing')">
    <span class="icon icon-large close-grey"></span>
  </a>

  <div *ngIf="!onTimeout" class="form-wrapper no-margin">
    <div *ngIf="!showTimerOnRestartPopup">
      <div class="form-row flex-maxis-center content-center">
        <img class="artwork-timeout" src="assets/img/artwork-timeout.svg"
          attr.alt="{{ 'sessionTimeout.ariaLables.timeoutImg' | translate }}">
      </div>
      <div class="form-row flex-maxis-center content-center">
        <div class="form-col">
          <p translate="sessionTimeout.titleModal" class="font-bold font-22px" id="ariaSessionTimeoutTitle"></p>
          <p translate="sessionTimeout.descriptionModal"></p>
          <p class="timer">
            {{counter}}
            <span translate="sessionTimeout.seconds"></span>
          </p>
        </div>
      </div>
      <div class="form-row form-page-buttons">
        <button class="primary element-center" type="button" translate="button.keepGoing"
          (click)="handeIdleTimeNavigation('keepGoing')"></button>
      </div>
    </div>
    <div *ngIf="showTimerOnRestartPopup">
      <div class="form-row flex-maxis-center content-center">
        <div class="form-col">
          <p translate="sessionTimeout.titleModalISAM" class="font-bold font-22px" id="ariaSessionTimeoutTitle"></p>
          <p *ngIf="!isEnforceFinishLaterEnabled" [innerHTML]="'sessionTimeout.descriptionModalISAM01' | translate | safeHtml"></p>
          <p *ngIf="!isEnforceFinishLaterEnabled" [innerHTML]="'sessionTimeout.descriptionModalISAM02' | translate | safeHtml"></p>

          <p *ngIf="isEnforceFinishLaterEnabled" [innerHTML]="'sessionTimeout.descriptionModalISAM03' | translate | safeHtml"></p>
          <p *ngIf="isEnforceFinishLaterEnabled" [innerHTML]="'sessionTimeout.descriptionModalISAM04' | translate | safeHtml"></p>
          <p class="timer">
            {{restartCounter}}
            <span translate="sessionTimeout.seconds"></span>
          </p>
        </div>
      </div>
      <div class="form-row form-page-buttons">
        <button class="primary element-center" type="button"
          attr.aria-label="{{ 'button.ariaLabels.returnToApplication' | translate }}"
          translate="button.returnToApplication" (click)="handeIdleTimeNavigation('keepGoing')"></button>
      </div>
    </div>
  </div>

  <div *ngIf="onTimeout" class="form-wrapper no-margin">
    <div class="form-row flex-maxis-center content-center">
      <img class="artwork-header" src="assets/img/artwork-timeout.svg"
        attr.alt="{{ 'sessionTimeoutRestart.ariaLables.timeoutImg' | translate }}">
    </div>
    <div class="form-row flex-maxis-center content-center">
      <h1 translate="sessionTimeout.titleRestartModal"></h1>
      <p *ngIf="!isEnforceFinishLaterEnabled" [innerHtml]="'sessionTimeout.descriptionRestartModal' | translate | safeHtml"></p>
      <p *ngIf="isEnforceFinishLaterEnabled" [innerHtml]="'sessionTimeout.descriptionRestartModal02' | translate | safeHtml"></p>
    </div>
    <div class="form-row form-page-buttons">
      <button class="primary element-center" type="submit" translate="button.restart"
        (click)="handeIdleTimeNavigation('restart')"></button>
    </div>

  </div>

</div>

<!-- Margin Modal -->
<div class="bmo-modal bmo-margin-modal">

  <a
    role="button"
    attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
    attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
    class="btn-icon btn-close tooltip-show"
    href="javascript://"
    (click)="hideMarginModal()">
    <span class="icon icon-large close-grey"></span>
  </a>

  <div class="form-wrapper margin-modal no-margin modal-content-scroll">
    <div class="form-row flex-maxis-center content-center">
      <div class="form-col">
        <h1 translate="selectAccount.accounts.marginModalTitle"></h1>
        <p [innerHtml]="'selectAccount.accounts.marginModalDescription1' | translate | safeHtml"></p>
        <p *ngIf="!isBill64FeatureEnabled" [innerHtml]="'selectAccount.accounts.marginModalDescription2' | translate | safeHtml"></p>
        <p *ngIf="isBill64FeatureEnabled" [innerHtml]="'selectAccount.accounts.marginModalDescription2Bill64' | translate | safeHtml"></p>
        <p [innerHtml]="'selectAccount.accounts.marginModalDescription3' | translate | safeHtml"></p>
      </div>
    </div>
    <div class="form-wrapper">
      <div class="form-row form-page-buttons">
        <button
          class="primary element-center"
          type="submit"
          translate="button.close"
          (click)="hideMarginModal()"></button>
      </div>
    </div>
  </div>
</div>

<!-- Contact Us Modal -->
<!-- --- -->
<app-contact-us-model *ngIf="showContactUsModel && !onTimeout"></app-contact-us-model>

<!-- Save for Later Modal -->
<!-- --- -->
<div
  class="bmo-modal save-later-modal"
  *ngIf="false">

  <a
    role="button"
    attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
    attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
    class="btn-icon btn-close tooltip-show"
    href="javascript://"
    (click)="hideSaveLaterEnabled()">
    <span class="icon icon-large close-grey"></span>
  </a>

  <div class="form-wrapper no-margin">
    <div class="form-row flex-maxis-center content-center">
      <img
        class="artwork-header"
        src="assets/img/artwork-exit.svg">
    </div>
    <div class="form-row flex-maxis-center content-center">
      <div class="form-col">
        <h1 translate="saveLaterModal.titleModal1"></h1>
        <p [innerHtml]="'saveLaterModal.section1Title' | translate | safeHtml"></p>
      </div>
    </div>
    <div class="form-wrapper">
      <div class="form-row form-page-buttons">
        <div class="form-col left-side">
          <button
            class="secondary element-center"
            type="submit"
            translate="saveLaterModal.button.no"></button>
        </div>
        <div class="form-col right-side flex-xaxis-center">
          <button
            class="tertiary element-center flex-xaxis-center"
            type="submit"
            translate="saveLaterModal.button.finish"></button>
          <button
            class="primary element-center"
            type="submit"
            translate="saveLaterModal.button.yes"
            (click)="hideSaveLaterEnabled()"></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Save for Progress Modal -->
<!-- --- -->

<div
  class="bmo-modal save-progress-modal"
  *ngIf="false">

  <a
    role="button"
    attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
    attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
    class="btn-icon btn-close tooltip-show"
    href="javascript://"
    (click)="hideSaveProgressEnabled()">
    <span class="icon icon-large close-grey"></span>
  </a>

  <div class="form-wrapper no-margin">

    <div class="form-row flex-maxis-center">
      <div class="form-col">
        <h1 translate="saveLaterModal.titleModal2"></h1>
        <p>
            <span
            [innerHtml]="'saveLaterModal.section2Title' | translate | safeHtml"
            class="font-bold"></span>
            <span #accountnumber>123</span>
        </p>
        <p [innerHtml]="'saveLaterModal.section2description' | translate | safeHtml"></p>

        <div class="form-wrapper">
          <div class="form-row">

            <div class="flex-fill form-col">
              <div class="form-field">
                <div class="form-label-group">
                  <input
                    id="createPwd"
                    name="password"
                    type="password"
                    maxlength="8"
                    [(ngModel)]="accountSetup.password"
                    #accSetupPwdCtrl="ngModel"
                    InputValidator="ct4"
                    appAccSetupPasswordValidator
                    required
                    aria-describedby="passwordCheck1 passwordCheck2">
                  <label
                    class="slide-label"
                    for="createPwd"
                    [ngClass]="{'error-field':isError(accSetupPwdCtrl), 'active-label':accSetupPwdCtrl.value}">
                    <span
                      class="label-text"
                      translate="modalSaveForLater.labels.createPassword"></span>
                  </label>
                </div>
              </div>
              <div
                role="alert"
                class="error-message"
                *ngIf="isError(accSetupPwdCtrl)">
                <span translate="modalSaveForLater.errors.passwordFormat"></span>
              </div>
              <div class="password-checks">
                <div class="bmo-container-flex">
                  <div
                    id="passwordCheck1"
                    class="flex-40">
                    <span
                      class="icon-inline icon-error"
                      [ngClass]="accSetupPwdCtrl?.errors?.lengthMustBeGreaterThanSeven ? 'icon-error' : 'icon-check'"
                      translate="modalSaveForLater.labels.maxChar"></span>
                    <span
                      class="icon-inline icon-error"
                      [ngClass]="accSetupPwdCtrl?.errors?.noDigitsPresent ? 'icon-error' : 'icon-check'"
                      translate="modalSaveForLater.labels.minNumber"></span>
                    <span
                      class="icon-inline icon-error"
                      [ngClass]="accSetupPwdCtrl?.errors?.noLowerCasePresent ? 'icon-error' : 'icon-check'"
                      translate="modalSaveForLater.labels.minLowCase"></span>
                  </div>
                  <div
                    id="passwordCheck2"
                    class="flex-40">
                    <span
                      class="icon-inline icon-error"
                      [ngClass]="accSetupPwdCtrl?.errors?.noUpperCasePresent ? 'icon-error' : 'icon-check'"
                      translate="modalSaveForLater.labels.minUpCase"></span>
                    <span
                      class="icon-inline icon-error"
                      [ngClass]="accSetupPwdCtrl?.errors?.noSpecialCharactersAllowed ? 'icon-error' : 'icon-check'"
                      translate="modalSaveForLater.labels.noSpecialChar">
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="form-row">

            <div class="flex-fill form-col">
              <div class="form-field">
                <div class="form-label-group">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    aria-describedby="confirmPassword"
                    [(ngModel)]="accountSetup.confirmPassword"
                    #accSetupConfirmPwdCtrl="ngModel"
                    maxlength="8"
                    [appEqualTo]="accSetupPwdCtrl"
                    InputValidator="ct4"
                    required>
                  <label
                    class="slide-label"
                    for="confirmPassword"
                    [ngClass]="{'error-field':isError(accSetupConfirmPwdCtrl),
                    'active-label':accSetupConfirmPwdCtrl.value}">
                    <span
                      class="label-text"
                      translate="modalSaveForLater.labels.confirmPassword"></span>
                  </label>
                </div>
              </div>
              <div
                role="alert"
                class="error-message"
                *ngIf="isError(accSetupConfirmPwdCtrl)">
                <span translate="modalSaveForLater.errors.passwordMatch"></span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="form-wrapper">
      <div class="form-row form-page-buttons">
        <div class="form-col left-side">
          <button
            class="secondary element-center"
            type="submit"
            translate="saveLaterModal.button.close"
            (click)="hideSaveProgressEnabled()"></button>
        </div>
        <div class="form-col right-side flex-xaxis-center">
          <button
            class="primary element-center"
            type="submit"
            translate="saveLaterModal.button.save"></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- How Ad Works Modal -->
<!-- --- -->
<app-how-adworks-model *ngIf="showAdWorksModel && !onTimeout"></app-how-adworks-model>

<!-- Saved Modal -->
<!-- --- -->

<div
  class="bmo-modal saved-modal"
  *ngIf="false">

  <a
    role="button"
    attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
    attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
    class="btn-icon btn-close tooltip-show"
    href="javascript://"
    (click)="hideSavesEnabled()">
    <span class="icon icon-large close-grey"></span>
  </a>

  <div class="form-wrapper no-margin">
    <div class="form-row flex-maxis-center content-center">
      <img
        class="artwork-header"
        src="assets/img/artwork-locked.svg">
    </div>
    <div class="form-row flex-maxis-center content-center">
      <div class="form-col">
        <h1 translate="saveLaterModal.titleModal3"></h1>
        <p
          [innerHtml]="'saveLaterModal.section3Title' | translate | safeHtml"
          class="font-bold"></p>
        <p [innerHtml]="'saveLaterModal.section3description' | translate | safeHtml"></p>
      </div>
    </div>
  </div>
  <div class="form-row form-page-buttons">
    <button
      class="primary element-center"
      type="submit"
      translate="button.close"
      (click)="hideSavesEnabled()"></button>
  </div>

</div>

<!-- Terms and conditions -->
<div
  class="bmo-modal bmo-terms-conditions"
  role="dialog">
  <div class="bmo-container-flex">
    <div class="flex-80">
      <h1 translate="termsConditions.modalTermsConditions.titleModal"></h1>
      <p translate="termsConditions.modalTermsConditions.sectionContent01"></p>
      <p translate="termsConditions.modalTermsConditions.sectionContent02"></p>
    </div>
    <div class="bmo-container-flex flex-xaxis-center flex-maxis-center flex-20">
      <div class="loading spinner"></div>
    </div>
  </div>
</div>

<!-- OLB/CDB Login Modal -->
<!-- --- -->
<div
  *ngIf="!sessionTimeModelEnabled"
  class="bmo-modal bmo-cdb-login"
  role="dialog">
    <a
      (click)="hideOLBModal()"
      role="button"
      attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
      attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
      class="btn-icon btn-close tooltip-show"
      href="javascript://">
      <span class="icon icon-large close-grey"></span>
    </a>
    <div class="form-row" id="OLBLogin">
      <div class="form-col">
        <h1 translate="modalCDBLogin.modal1Title"></h1>
        <p [innerHtml]="'modalCDBLogin.section1Content' | translate | safeHtml"></p>
      </div>
    </div>

    <div class="form-page-buttons" id="OLBLoginButton">
      <div class="form-col right-side">
        <button
          data-ana="mywealth:onboarding:existing:no:continue"
          (click)="onOLBModalContinueButton()"
          class="tertiary"
          type="button"
          translate="modalCDBLogin.labels.noContinue"
          attr.aria-label="{{ 'button.ariaLabels.continue' | translate }}"></button>
        <button
          data-ana="mywealth:onboarding:existing:yes:sign in"
          (click)="onOLBModalSigninButton()"
          class="primary"
          type="button"
          translate="modalCDBLogin.labels.yesSignIn"
          attr.aria-label="{{ 'modalCDBLogin.ariaLabels.yesSignIn' | translate }}"></button>
      </div>
    </div>
</div>

<!-- OLB/CDB Logout Modal -->
<!-- --- -->
<div
  *ngIf="!sessionTimeModelEnabled && (isCdbOlbUser || isJourneyUser)"
  class="bmo-modal bmo-cdb-logout"
  role="dialog">
    <a (click)="hideCdbLogoutModal()"
      role="button"
      attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
      attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
      class="btn-icon btn-close tooltip-show"
      href="javascript://">
      <span class="icon icon-large close-grey"></span>
    </a>
    <div class="form-row">
      <div class="form-col" *ngIf="applicationSaved && !applicationSubmitted">
        <h1 translate="modalCDBLogout.modal1Title"></h1>
        <p [innerHtml]="'modalCDBLogout.section1Content' | translate | safeHtml"></p>
      </div>
      <div class="form-col" *ngIf="!applicationSaved && !applicationSubmitted">
        <h1 translate="modalCDBLogout.modal2Title"></h1>
        <p [innerHtml]="'modalCDBLogout.section2Content' | translate | safeHtml"></p>
      </div>
      <div class="form-col" *ngIf="applicationSubmitted">
        <h1 translate="modalCDBLogout.modal3Title"></h1>
        <p [innerHtml]="'modalCDBLogout.section3Content' | translate | safeHtml"></p>
      </div>
    </div>

    <div class="form-page-buttons">
      <div class="form-col right-side">
        <button (click)="hideCdbLogoutModal()"
          class="tertiary"
          type="button"
          translate="modalCDBLogout.labels.return"
          attr.aria-label="{{ 'modalCDBLogout.ariaLabels.return' | translate }}"></button>
        <button (click)="onCdbexitLogout()"
          class="primary"
          type="button"
          translate="modalCDBLogout.labels.exitLogout"
          attr.aria-label="{{ 'modalCDBLogout.ariaLabels.exitLogout' | translate }}"></button>
      </div>
    </div>
</div>

<!-- OLB/CDB Delete Application Modal -->
<!-- --- -->
<div *ngIf="!sessionTimeModelEnabled"
  class="bmo-modal bmo-cdb-delete-app"
  role="dialog">
  <app-critical-error *ngIf="systemCriticalError"></app-critical-error>
    <a (click)="hideCdbDeletetModal()"
      role="button"
      attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
      attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
      class="btn-icon btn-close tooltip-show"
      href="javascript://">
      <span class="icon icon-large close-grey"></span>
    </a>
    <div class="form-row">
      <div class="form-col">
        <h1 translate="inProgressApplication.modalDelete.heading"></h1>
        <p [innerHtml]="'inProgressApplication.modalDelete.subline01' | translate | safeHtml"></p>
      </div>
    </div>

    <div class="form-page-buttons">
      <div class="form-col right-side">
        <button (click)="hideCdbDeletetModal()"
          class="tertiary"
          type="button"
          translate="inProgressApplication.modalDelete.labels.cancel"
          attr.aria-label="{{ 'inProgressApplication.modalDelete.ariaLabels.cancel' | translate }}"></button>
        <button (click)="deleteOlbInprogress()"
          class="primary"
          type="button"
          translate="inProgressApplication.modalDelete.labels.delete"
          attr.aria-label="{{ 'inProgressApplication.modalDelete.ariaLabels.delete' | translate }}"></button>
      </div>
    </div>
</div>
<div *ngIf="!sessionTimeModelEnabled"
  class="bmo-modal bmo-cdb-error-app"
  role="dialog">
  <app-critical-error></app-critical-error>
    <a (click)="hideErrorModal()"
      role="button"
      attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
      attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
      class="btn-icon btn-close tooltip-show"
      href="javascript://">
      <span class="icon icon-large close-grey"></span>
    </a>
</div>

<!-- Cash & Margin Accounts Modal -->
<!-- TODO: delete modal when all info is moved to accordion -->
 <div [hidden]="!isMarginAccountPenetrationEnabled"
  class="bmo-modal bmo-cash-margin"
  role="dialog"
  aria-labelledby="ariaCashMarginModalTitle">
  <a (click)="hideCashMarginModal()"
    role="button"
    attr.aria-label="{{ 'ariaLabels.btnCloseModal' | translate }}"
    attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
    class="btn-icon btn-close tooltip-show"
    href="javascript://">
    <span class="icon icon-large close-grey"></span>
  </a>
  <div class="modal-content-scroll">
    <table class="bmo-table">
      <caption class="content-left">
        <div class="form-row">
          <div class="form-col">
            <h1 translate="selectAccount.accounts.cashMarginModalTitle" id="ariaCashMarginModalTitle"></h1>
            <p class="no-margin" [innerHTML]="'selectAccount.accounts.cashMarginModalDescription' | translate"></p>
          </div>
        </div>
      </caption>
      <thead class="col3">
        <tr>
          <td scope="col"></td>
          <th scope="col" translate="selectAccount.accounts.cashMarginModalCashTitle"></th>
          <th scope="col" translate="selectAccount.accounts.cashMarginModalMarginTitle"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="content-left">
            <div class="header-contextual-help">
              <p class="display-inline no-margin" translate="selectAccount.accounts.cashMarginModalInvestmentsTitle">
                  <app-contextual-help class="button-wrapper va-middle" [content]="!isSubProductAD ? 'selectAccount.contextualHelp.investmentTypesSDTooltip' : 'selectAccount.contextualHelp.investmentTypesADTooltip'"
                  arialabel="selectAccount.ariaLabels.investmentTypesTooltip" [changeContent]="10">
                  </app-contextual-help>
              </p>
            </div>
          </th>
          <td translate="selectAccount.accounts.cashMarginModalInvestmentsTypes"></td>
          <td translate="selectAccount.accounts.cashMarginModalInvestmentsTypes"></td>
        </tr>
        <tr>
          <th class="content-left">
            <div class="header-contextual-help">
              <p class="display-inline no-margin" translate="selectAccount.accounts.cashMarginModalBorrowingTitle">
                  <app-contextual-help class="button-wrapper va-middle" content="selectAccount.contextualHelp.borrowingSecuritiesTootip" arialabel="selectAccount.ariaLabels.borrowingSecuritiesTootip"
                  [changeContent]="10">
                  </app-contextual-help>
              </p>
            </div>
          </th>
          <td translate="selectAccount.labels.no"></td>
          <td translate="selectAccount.labels.yes"></td>
        </tr>
        <tr *ngIf="!isSubProductAD">
          <th class="content-left">
            <div class="header-contextual-help">
              <p class="display-inline no-margin" translate="selectAccount.accounts.cashMarginModalOptionsTitle">
                  <app-contextual-help class="button-wrapper va-middle" content="selectAccount.contextualHelp.optionsTooltip" arialabel="selectAccount.ariaLabels.optionsTooltip"
                  [changeContent]="10">
                  </app-contextual-help>
              </p>
            </div>
          </th>
          <td translate="selectAccount.labels.no"></td>
          <td translate="selectAccount.labels.yes"></td>
        </tr>
        <tr *ngIf="!isSubProductAD">
          <th class="content-left">
            <div class="header-contextual-help">
              <p class="display-inline no-margin" translate="selectAccount.accounts.cashMarginModalShortSellingTitle">
                  <app-contextual-help class="button-wrapper va-middle" content="selectAccount.contextualHelp.shortSellingTooltip" arialabel="selectAccount.ariaLabels.shortSellingTooltip"
                  [changeContent]="10">
                  </app-contextual-help>
              </p>
            </div>
          </th>
          <td translate="selectAccount.labels.no"></td>
          <td translate="selectAccount.labels.yes"></td>
        </tr>
      </tbody>
    </table>
    <div class="form-wrapper">
      <div class="form-row form-page-buttons bmo-table-buttons">
        <!--  leave empty  (below p tag)-- -->
        <p class="table-button no-margin" aria-hidden="true"></p>
        <div class="table-button flex-maxis-center">
          <button [class]="isCash?'primary': 'secondary'" type="submit" translate="selectAccount.labels.cash" attr.aria-label="{{ 'selectAccount.ariaLabels.cashAccount' | translate }}" (click)="selectCashOrMarginAccount(CASH_CODE)"></button>
        </div>
        <div class="table-button flex-maxis-center">
          <button [class]="isMargin?'primary': 'secondary'" type="submit" translate="selectAccount.labels.margin" attr.aria-label="{{ 'selectAccount.ariaLabels.marginAccount' | translate }}" (click)="selectCashOrMarginAccount(MARGIN_CODE)"></button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Net Worth Calculator Modal -->
<!-- --- -->
<div [hidden]="!isDataRationalizationEnabled" class="bmo-modal bmo-net-worth-modal" role="dialog" aria-labelledby="ariaNetWorthCalculator">
  <form #netWorthCalculatorForm="ngForm" (ngSubmit)="onNetWorthCalculatorForm()" novalidate>
  <a (click)="hideNetWorthModal(true)" role="button" attr.aria-label="{{ 'financialDetails.ariaLabels.close' | translate }}"
    attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}" class="btn-icon btn-close tooltip-show" href="javascript://">
    <span class="icon icon-large close-grey"></span>
  </a>

  <div class="modal-content-scroll">
    <div role="alert" class="alert alert-danger" *ngIf="isNetWorthFormSubmit && !netWorthCalculatorForm.valid">
      <p>
          <strong translate="financialDetails.errors.criticalError"></strong><br />
          <span translate="financialDetails.errors.criticalErrorDescription"></span>
      </p>
    </div>
    <div class="form-wrapper no-margin">
      <div class="form-row">
        <div class="form-col">
          <h1 translate="financialDetails.netWorthCalculatorTitle" id="ariaNetWorthCalculator"></h1>
          <p translate="financialDetails.netWorthCalculatorDescription"></p>
        </div>
      </div>

      <div class="form-wrapper">
        <div class="form-row">

          <div class="flex-50 form-col">
            <div class="form-field">
              <div class="form-label-group">
                <input id="totalLiquidAssets" name="totalLiquidAssets" [(ngModel)]="netWorthCalculator.totalLiquidAssets"
                #totalLiquidAssetsCtrl="ngModel" type="tel" appCurrency InputValidator='ct3' maxlength="9"
                [appErrorAccess]="{id:'totalLiquidAssets-error' ,  submitted: isNetWorthFormSubmit}" required />
                <label class="slide-label" for="totalLiquidAssets"
                [ngClass]="{'error-field': totalLiquidAssetsCtrl.invalid && totalLiquidAssetsCtrl.touched || (isNetWorthFormSubmit && totalLiquidAssetsCtrl.errors), 'active-label': totalLiquidAssetsCtrl.value}">
                  <span class="label-text" translate="financialDetails.labels.totalLiquidAssets"></span>
                </label>
              </div>
              <div class="form-field-button">
                <app-contextual-help class="button-wrapper" arialabel="financialDetails.ariaLabels.totalLiquidAssets"
                  content="financialDetails.contextualHelp.totalLiquidAssets">
                </app-contextual-help>
              </div>
            </div>
            <div role="alert" id="totalLiquidAssets-error" class="error-message"
            *ngIf="totalLiquidAssetsCtrl.invalid && totalLiquidAssetsCtrl.touched  || (isNetWorthFormSubmit && totalLiquidAssetsCtrl.errors)">
              <span translate="financialDetails.errors.totalLiquidAssets"></span>
            </div>
          </div>

          <div class="flex-50 form-col">
            <div class="form-field">
              <div class="form-label-group">
                <input id="totalFixedAssets" name="totalFixedAssets" [(ngModel)]="netWorthCalculator.totalFixedAssets"
                #totalFixedAssetsCtrl="ngModel" type="tel" appCurrency InputValidator='ct3' maxlength="9"
                [appErrorAccess]="{id:'totalFixedAssets-error',  submitted: isNetWorthFormSubmit}" required />
                <label class="slide-label" for="totalFixedAssets"
                [ngClass]="{'error-field': totalFixedAssetsCtrl.invalid && totalFixedAssetsCtrl.touched || (isNetWorthFormSubmit && totalFixedAssetsCtrl.errors), 'active-label': totalFixedAssetsCtrl.value}">
                  <span class="label-text" translate="financialDetails.labels.totalFixedAssets"></span>
                </label>
              </div>
              <div class="form-field-button">
                <app-contextual-help class="button-wrapper" arialabel="financialDetails.ariaLabels.totalFixedAssets"
                  content="financialDetails.contextualHelp.totalFixedAssets">
                </app-contextual-help>
              </div>
            </div>
            <div role="alert" id="totalFixedAssets-error" class="error-message"
            *ngIf="totalFixedAssetsCtrl.invalid && totalFixedAssetsCtrl.touched || (isNetWorthFormSubmit && totalFixedAssetsCtrl.errors)">
              <span translate="financialDetails.errors.totalFixedAssets"></span>
            </div>
          </div>

          <div class="flex-50 form-col">
            <div class="form-field">
              <div class="form-label-group">
                <input id="liabilities" name="liabilities" [(ngModel)]="netWorthCalculator.liabilities"
                 #liabilitiesCtrl="ngModel" type="tel" appCurrency InputValidator='ct3' maxlength="9"
                 [appErrorAccess]="{id:'liabilities-error',  submitted: isNetWorthFormSubmit}" required/>
                <label class="slide-label" for="liabilities"
                [ngClass]="{'error-field': liabilitiesCtrl.invalid && liabilitiesCtrl.touched || (isNetWorthFormSubmit && liabilitiesCtrl.errors), 'active-label': liabilitiesCtrl.value}">
                  <span class="label-text" translate="financialDetails.labels.liabilities"></span>
                </label>
              </div>
              <div class="form-field-button">
                <app-contextual-help class="button-wrapper" arialabel="financialDetails.ariaLabels.liabilities"
                  content="financialDetails.contextualHelp.liabilities">
                </app-contextual-help>
              </div>
            </div>
            <div role="alert" id="liabilities-error" class="error-message"
            *ngIf="liabilitiesCtrl.invalid && liabilitiesCtrl.touched || (isNetWorthFormSubmit && liabilitiesCtrl.errors)">
              <span translate="financialDetails.errors.liabilities"></span>
            </div>
          </div>

        </div>
      </div>

      <hr>

      <div class="form-wrapper no-margin">
        <div class="form-col">
          <div class="form-row form-page-buttons">
            <div class="form-row flex-equal justify-space-evenly align-center">
              <div class="content-center flex-basis-33">
                <strong translate="financialDetails.labels.totalAssets"></strong>
                <p class="no-margin" [inputValue]="+netWorthCalculator.totalLiquidAssets +  +netWorthCalculator.totalFixedAssets" appBindingCurrency>
                  {{ +netWorthCalculator.totalLiquidAssets +  +netWorthCalculator.totalFixedAssets}}</p>
              </div>
              <div class="content-center flex-basis-33">
                <strong>-</strong>
              </div>
              <div class="content-center flex-basis-33">
                <strong translate="financialDetails.labels.totalLiabilities"></strong>
                <p class="no-margin" [inputValue]="+netWorthCalculator.liabilities" appBindingCurrency>{{+netWorthCalculator.liabilities}}</p>
              </div>
              <div class="content-center">
                <strong>=</strong>
              </div>
              <div class="content-center">
                <strong translate="financialDetails.labels.totalNetWorth"></strong>
                <p class="no-margin" [inputValue]="netWorth" appBindingCurrency>
                    {{netWorth}}</p>
              </div>
            </div>

            <button class="primary element-center" type="submit" translate="financialDetails.labels.enter"
              attr.aria-label="{{ 'financialDetails.ariaLabels.enter' | translate }}">
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
  </form>
</div>

<div
class="bmo-modal bmo-skip-upload-login"
role="dialog"
aria-labelledby="ariaSkipDocumentUploadTitle">
  <a
    role="button"
    attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
    attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
    class="btn-icon btn-close tooltip-show"
    (click) = "cancelSkipDocumentUpload()" href="javascript://">
    <span class="icon icon-large close-grey"></span>
  </a>
  <div class="form-row">
    <div class="form-col">
      <h1 id="ariaSkipDocumentUploadTitle" translate="documentUpload.modalSkipDocUpload.modalSkipTitle"></h1>
      <p [innerHtml]="'documentUpload.modalSkipDocUpload.modalSkipDescription' | translate | safeHtml"></p>
    </div>
  </div>

  <div class="form-page-buttons" id="docUploadSkipButtons">
    <div class="form-col right-side">
      <button
        class="tertiary"
        type="button" (click)= "goToNextSteps()"
        translate="documentUpload.labels.modalSkipYesContinue"
        attr.aria-label="{{ 'documentUpload.ariaLabels.modalSkipYesContinue' | translate }}"></button>
      <button
        class="primary"
        type="button"
        (click) = "cancelSkipDocumentUpload()"
        translate="documentUpload.labels.modalSkipCancel"
        attr.aria-label="{{ 'documentUpload.ariaLabels.modalSkipCancel' | translate }}"></button>
    </div>
  </div>
</div>


<!-- Margin Downgrade Cancel Modal -->
<!-- --- -->
<div
  *ngIf="!sessionTimeModelEnabled"
  class="bmo-modal bmo-margin-downgrade"
  aria-labelledby="ariaMarginDowngrade"
  role="dialog">
    <a (click)="hideCancelMarginDowngradeModal()"
      role="button"
      attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
      attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
      class="btn-icon btn-close tooltip-show"
      href="javascript://">
      <span class="icon icon-large close-grey"></span>
    </a>
    <div class="form-row">
      <div class="form-col">
        <h1  id="ariaMarginDowngrade" translate="applicationApprovalDowngrade.modalDowngrade.modalTitleCancel"></h1>
        <p [innerHtml]="'applicationApprovalDowngrade.modalDowngrade.modalDescriptionCancel' | translate | safeHtml"></p>
      </div>
    </div>

    <div class="form-page-buttons">
      <div class="form-col right-side">
        <button  (click)="hideCancelMarginDowngradeModal()"
          class="tertiary"
          type="button"
          translate="applicationApprovalDowngrade.labels.noResume"
          attr.aria-label="{{ 'applicationApprovalDowngrade.ariaLabels.modalNoResume' | translate }}"></button>
        <button  (click)="onYesMarginDowngrade()"
          class="primary"
          type="button"
          translate="applicationApprovalDowngrade.labels.yesCancel"
          attr.aria-label="{{ 'applicationApprovalDowngrade.ariaLabels.modalYesCancel' | translate }}"></button>
      </div>
    </div>
</div>

<!-- Journey: App Saved Return to IL  -->
<!-- --- -->
<div
*ngIf="!sessionTimeModelEnabled"
  class="bmo-modal bmo-bil-sign-in"
  aria-labelledby="ariaJourneySignIn"
  role="dialog">
    <a (click)="hideCancelJourneySignInModal()"
      role="button"
      attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
      attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}"
      class="btn-icon btn-close tooltip-show"
      href="javascript://">
      <span class="icon icon-large close-grey"></span>
    </a>
    <div class="form-row">
      <div class="form-col">
        <h1  id="ariaJourneySignIn" translate="setExpectations.returnToBILModal.modalTitle"></h1>
        <p [innerHtml]="'setExpectations.returnToBILModal.modalDescription' | translate | safeHtml"></p>
      </div>
    </div>

    <div class="form-page-buttons">
      <div class="form-col right-side">
        <button  (click)="hideCancelJourneySignInModal()"
          class="tertiary"
          type="button"
          translate="setExpectations.labels.modalCancel"
          attr.aria-label="{{ 'setExpectations.ariaLabels.modalCancel' | translate }}"></button>
        <button  (click)="returntoBIL()"
          class="primary"
          type="button"
          translate="setExpectations.labels.modalReturnBIL"
          attr.aria-label="{{ 'setExpectations.ariaLabels.modalReturnBIL' | translate }}"></button>
      </div>
    </div>
</div>

<div class="bmo-modal bmo-loading"></div>
