<!-- <app-flood-light></app-flood-light> -->

<form #setExpectationForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="container-menuView">

            <div role="alert" class="alert alert-yellow" *ngIf="showCDBSessionCallError">
                <p>
                    <strong translate="selectAccount.errors.eCIFRetrievalError"></strong><br />
                    <span translate="selectAccount.errors.eCIFRetrievalErrorDescription"></span>
                </p>
            </div>

        <div role="alert" class="alert alert-danger" *ngIf="isCsaCfrAttorneyEnabled && displayRequiredErrorMessage()">
            <p>
                <strong translate="setExpectations.errors.criticalError"></strong><br>
                <span translate="setExpectations.errors.criticalErrorDescription"></span>
            </p>
        </div>

        <section>

            <div id="tyskSectionOneDescription" *ngIf="cmsMessage">


                <div class="alert alert-yellow">
                    <a role="button" attr.aria-label="{{ 'ariaLabels.btnClose' | translate }}"
                        attr.tooltip="{{ 'ariaLabels.btnClose' | translate }}" class="btn-icon btn-close tooltip-show"
                        href="javascript://" (click)="onCloseCMS()">
                        <span class="icon icon-large close-grey"></span>
                    </a>
                    <p>
                        <strong *ngIf="cmsMessageTitle!==''">{{cmsMessageTitle}}</strong><br />
                        <span *ngIf="cmsMessage!==''">{{cmsMessage}}</span>
                    </p>
                </div>

            </div>

                <h1 translate="setExpectations.titlePage"></h1>
                <p *ngIf="isSubProductAD"
                    [innerHtml]="'setExpectations.descriptionAdviceDirectPage1' | translate  | safeHtml"></p>
                <p *ngIf="!isSubProductAD"
                    [innerHtml]="'setExpectations.descriptionSelfDirectPage1' | translate  | safeHtml"></p>


                <div class="bmo-container-flex container-expectations">

                    <ul>

                        <!--AD: List Items-->
                        <li [innerHtml]="'setExpectations.descriptionAdviceDirectPage2' | translate | safeHtml"
                            class="icon-inline icon-money" *ngIf="isSubProductAD"></li>

                        <!--AD&SD: List Items-->
                        <li [innerHtml]="'setExpectations.resident' | translate | safeHtml"
                            class="icon-inline icon-house"></li>

                        <!--SIN-->
                        <!--SD only-->
                        <li [innerHtml]="'setExpectations.validSin' | translate | safeHtml"
                            class="icon-inline icon-card" *ngIf="!isSubProductAD"></li>

                        <!--AD only-->
                        <li *ngIf="isSubProductAD">
                            <!-- SHOW this for all other account types EXCEPT Sposal RRSP -->
                            <span [innerHtml]="'setExpectations.validSin' | translate | safeHtml"
                                class="icon-inline icon-card" id="tyskSectionOneValidSin" *ngIf="!isSRRSP"></span>

                            <!-- SHOW this for Spousal RRSP account only-->
                            <span [innerHtml]="'setExpectations.validSinSpousalRRSP' | translate | safeHtml"
                                class="icon-inline icon-card" id="tyskSectionOneValidSinSpousalRRSP"
                                *ngIf="isSRRSP"></span>
                        </li>


                        <!--AD&SD: List Items Cont-->
                        <li [innerHtml]="'setExpectations.ageMajority' | translate | safeHtml"
                            class="icon-inline icon-calendar"></li>
                    </ul>
                </div>
                <!-- AD Account available for AD-->
                <div id="tyskSectionThree" *ngIf="isSubProductAD" class="add-margin b32px">

                    <div class="more-info-wrapper">

                        <a role="button" aria-controls="bmoTitleLinkControlAD" id="bmoTitleLinkControlADLearnMore"
                            aria-expanded="false" class="btn-icon btn-more-info-show" href="javascript://"
                            attr.aria-label="{{ 'setExpectations.ariaLabels.newBMOTileLink3' | translate }}">
                            <span class="btn-icon-text-left" translate="setExpectations.newBMOTileLink3"></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                        </a>

                        <div class="more-info-content new-bmo-card-content" id="bmoTitleLinkControlAD">

                            <h2 class="font-bold" translate="setExpectations.newBMOTileTitle4AD"></h2>
                            <ul class="check-list">
                                <li [innerHtml]="'setExpectations.newBMOTileTitle4Point1' | translate | safeHtml">
                                </li>
                                <li [innerHtml]="'setExpectations.newBMOTileTitle4Point2' | translate | safeHtml">
                                </li>
                                <li [innerHtml]="'setExpectations.newBMOTileTitle4Point3' | translate | safeHtml">
                                </li>
                                <li [innerHtml]="'setExpectations.newBMOTileTitle4Point7' | translate | safeHtml">
                                </li>
                                <li [innerHtml]="'setExpectations.newBMOTileTitle4Point6' | translate | safeHtml">
                                </li>
                                <li [innerHtml]="'setExpectations.newBMOTileTitle4Point4' | translate | safeHtml">
                                </li>
                                <li [innerHtml]="'setExpectations.newBMOTileTitle4Point5' | translate | safeHtml">
                                </li>
                            </ul>
                            <p>
                                <a role="button" id="bmoTitleLinkCloseAD" aria-controls="bmoTitleLinkCloseAD"
                                    aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                    attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                                </a>
                            </p>

                        </div>

                    </div>

                </div>

                <!--New to BMO? Get your documents ready-->
                <div id="tyskSectionTwo" *ngIf="!hasDarCookie && !isBranchAssisted">

                    <div class="bmo-card-wrapper new-bmo-card">
                        <div class="card-tile">

                            <h2 class="font-bold" translate="setExpectations.newBMOTileTitle"></h2>
                            <p [innerHtml]="'setExpectations.newBMOTileContent' | translate | safeHtml"></p>

                            <div class="more-info-wrapper">

                                <a role="button" aria-controls="bmoTitleLinkControl"
                                    aria-expanded="false" data-ana="investorline:wealth:onboarding:whatyouneed:open"
                                    class="btn-icon btn-more-info-show" href="javascript://"
                                    attr.aria-label="{{ 'setExpectations.ariaLabels.newBMOTileLink' | translate }}" id="bmoTitleLinkControlLearnMore">
                                    <span class="btn-icon-text-left" translate="setExpectations.newBMOTileLink"></span>
                                    <span class="icon icon-large chevron-down-blue"></span>
                                </a>

                                <div class="more-info-content new-bmo-card-content" id="bmoTitleLinkControl">

                                    <div id="tyskSectionTwoWhatYouNeed">

                                        <!--<div [innerHTML]="'accountDetail.contextualHelp.shortSellingMarginAccountMoreInfo' | translate | safeHtml"></div>-->
                                        <h3 translate="setExpectations.newBMOTileTitle2"></h3>
                                        <p [innerHtml]="'setExpectations.newBMOTileContent2' | translate | safeHtml">
                                        </p>

                                        <div class="bmo-container-flex">
                                            <div class="flex-50">
                                                <h4
                                                    [innerHtml]="'setExpectations.newBMOTileGroupA' | translate | safeHtml">
                                                </h4>
                                                <ul>
                                                    <li [innerHtml]="'setExpectations.newBMOTileGroupA1' | translate | safeHtml"
                                                        class="icon-inline icon-document"></li>
                                                    <li [innerHtml]="'setExpectations.newBMOTileGroupA2' | translate | safeHtml"
                                                        class="icon-inline icon-document"></li>
                                                    <li [innerHtml]="'setExpectations.newBMOTileGroupA3' | translate | safeHtml"
                                                        class="icon-inline icon-document"></li>
                                                </ul>
                                            </div>
                                            <div class="flex-50">
                                                <h4
                                                    [innerHtml]="'setExpectations.newBMOTileGroupB' | translate | safeHtml">
                                                </h4>
                                                <ul>
                                                    <li [innerHtml]="'setExpectations.newBMOTileGroupB1' | translate | safeHtml"
                                                        class="icon-inline icon-document"></li>
                                                    <li [innerHtml]="'setExpectations.newBMOTileGroupB2' | translate | safeHtml"
                                                        class="icon-inline icon-document"></li>
                                                    <li [innerHtml]="'setExpectations.newBMOTileGroupB3' | translate | safeHtml"
                                                        class="icon-inline icon-document"></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                    <div id="tyskSectionTwoDocumentsMust">

                                        <h3 [innerHtml]="'setExpectations.newBMOTileTitle3' | translate | safeHtml">
                                        </h3>
                                        <ul class="disc">
                                            <li
                                                [innerHtml]="'setExpectations.newBMOTileTitle3Point1' | translate | safeHtml">
                                            </li>
                                            <li
                                                [innerHtml]="'setExpectations.newBMOTileTitle3Point2' | translate | safeHtml">
                                            </li>
                                            <li
                                                [innerHtml]="'setExpectations.newBMOTileTitle3Point3' | translate | safeHtml">
                                            </li>
                                            <li
                                                [innerHtml]="'setExpectations.newBMOTileTitle3Point4' | translate | safeHtml">
                                            </li>
                                        </ul>

                                    </div>

                                    <p>
                                        <a data-ana="investorline:wealth:onboarding:whatyouneed:close" role="button"
                                            id="bmoTitleLinkClose" aria-controls="bmoTitleLinkControl"
                                            aria-expanded="true" class="btn-icon btn-more-info-close"
                                            href="javascript://"
                                            attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                            <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                            <span class="icon icon-large chevron-up-blue"></span>
                                        </a>
                                    </p>

                                </div>

                            </div>

                        </div>
                    </div>

                </div>



                <!-- AD Why adviceDirect is right for you-->
                <div id="tyskSectionFour" *ngIf="isSubProductAD">
                    <div class="bmo-card-wrapper new-bmo-card">
                        <div class="card-tile">
                            <h2 class="font-bold" translate="setExpectations.newBMOTileTitle5"></h2>
                            <p [innerHtml]="'setExpectations.newBMOTileContent5' | translate | safeHtml"></p>
                            <p>
                                <a role="button" data-ana="investorline:wealth:onboarding:learn more"
                                    href="javascript://" (click)="showAdWorks()"
                                    translate="setExpectations.newBMOTileLink2"
                                    attr.aria-label="{{ 'setExpectations.ariaLabels.newBMOTileLink2' | translate }}"></a>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- SD/aD Power of Attourney Questionaire -->
                <div id="tyskSectionFive" *ngIf="isCsaCfrAttorneyEnabled" class="add-margin t48px">

                    <h2 class="font-bold" translate="setExpectations.titlePOAIsILRightForYou"></h2>
                    <p id="ariaPowerOfAttourney" [innerHtml]="'setExpectations.description01POAIsILRightForYou' | translate | safeHtml"></p>

                    <div class="form-wrapper">
                        <div class="form-row" role="radiogroup" aria-labelledby="ariaPowerOfAttourney">

                            <div class="flex-elements form-col no-margin">
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.isBmoRightForMe ===''}">
                                    <input id="yesBMORightForMe" [attr.data-ana]="!isSubProductAD ? 'investorline:wealth:onboarding:legal representative:yes' : null" type="radio" (change)="onBMORightForMeChange($event)" [(ngModel)]="setExpectationObj.isBmoRightForMe" [attr.disabled]="disableSetExpectationForm ? true : null" name="isBmoRightForMe" [value]="true" required />
                                    <label class="prefix" for="yesBMORightForMe"
                                           translate="button.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.isBmoRightForMe ===''}">
                                    <input id="noBMORightForMe"  [attr.data-ana]="!isSubProductAD ? 'investorline:wealth:onboarding:legal representative:no' : null" type="radio" (change)="onBMORightForMeChange($event)" [(ngModel)]="setExpectationObj.isBmoRightForMe" [attr.disabled]="disableSetExpectationForm ? true : null" name="isBmoRightForMe" [value]="false" required />
                                    <label class="prefix" for="noBMORightForMe"
                                           translate="button.no"></label>
                                </div>
                            </div>

                            <div class="error-message" role="alert" *ngIf="isButtonClicked && setExpectationObj.isBmoRightForMe ===''">
                                <span translate="setExpectations.errors.bmoRightForMeRequired"></span>
                            </div>
                        </div>

                        <div class="alert alert-yellow" role="alert" *ngIf="setExpectationObj.isBmoRightForMe === true">
                            <p [innerHtml]="'setExpectations.descriptionPOAIsILRightForYouSD' | translate | safeHtml" *ngIf="!isSubProductAD"></p>
                            <p [innerHtml]="'setExpectations.descriptionPOAIsILRightForYouAD' | translate | safeHtml" *ngIf="isSubProductAD"></p>
                        </div>
                    </div>

                    <ng-container *ngIf="setExpectationObj.isBmoRightForMe === false">
                        <h2 id="ariainvestmentService" translate="setExpectations.titleUsedProductService"></h2>

                        <div class="form-wrapper">
                            <div role="radiogroup" aria-labelledby="ariainvestmentService" class="form-row">

                                <div class="form-col no-margin">
                                    <div class="form-radio-checks radio-default" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.investmentServiceOptionCode ===''}">
                                        <input id="yesInvestedOnline" type="radio" [attr.data-ana]="!isSubProductAD ? 'investorline:wealth:onboarding:experience:yes' : null" (change)="onInvestmentServiceChange($event)" [(ngModel)]="setExpectationObj.investmentServiceOptionCode" [attr.disabled]="disableSetExpectationForm ? true : null"  name="investmentService" value="{{screenerQ1Options.A}}" required />
                                        <label class="prefix" for="yesInvestedOnline" translate="setExpectations.labels.yesInvestedOnline" ></label>
                                    </div>
                                    <div class="form-radio-checks radio-default" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.investmentServiceOptionCode ===''}">
                                        <input id="yesComfortableOnline" type="radio" [attr.data-ana]="!isSubProductAD ? 'investorline:wealth:onboarding:experience:no' : null" (change)="onInvestmentServiceChange($event)" [(ngModel)]="setExpectationObj.investmentServiceOptionCode" [attr.disabled]="disableSetExpectationForm ? true : null" name="investmentService" value="{{screenerQ1Options.B}}" required />
                                        <label class="prefix" for="yesComfortableOnline" translate="setExpectations.labels.yesComfortableOnline" ></label>
                                    </div>
                                    <div class="form-radio-checks radio-default" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.investmentServiceOptionCode ===''}">
                                        <input id="noUncomfortableOnline" type="radio" [attr.data-ana]="!isSubProductAD ? 'investorline:wealth:onboarding:experience:no uncomfortable' : null" (change)="onInvestmentServiceChange($event)" [(ngModel)]="setExpectationObj.investmentServiceOptionCode" [attr.disabled]="disableSetExpectationForm ? true : null" name="investmentService" value="{{screenerQ1Options.C}}" required />
                                        <label class="prefix" for="noUncomfortableOnline" translate="setExpectations.labels.noUncomfortableOnline" ></label>
                                    </div>
                                    <div role="alert" class="error-message" *ngIf="isButtonClicked && setExpectationObj.investmentServiceOptionCode ===''">
                                        <span translate="setExpectations.errors.usedProductServiceRequired"></span>
                                    </div>

                                </div>

                            </div>
                            <div class="alert alert-yellow" role="alert" *ngIf="errForFirstScreenerQuestion()">
                                <p [innerHTML]="'setExpectations.descriptionRequireAdviceSD01' | translate | safeHtml" *ngIf="!isSubProductAD"></p>
                                <p [innerHTML]="'setExpectations.descriptionRequireAdviceAD' | translate | safeHtml" *ngIf="isSubProductAD"></p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="setExpectationObj.isBmoRightForMe === false && enableSecondScreenerQuestion()">
                        <h2 id="ariaRequireAdvice" translate="setExpectations.titleRequireInvestmentAdviceSD" *ngIf="!isSubProductAD"></h2>
                        <h2 id="ariaRequireAdvice" translate="setExpectations.titleRequireInvestmentAdviceAD" *ngIf="isSubProductAD"></h2>

                        <div class="form-wrapper">
                            <div role="radiogroup" aria-labelledby="ariaRequireAdvice" class="form-row">

                                <div class="form-col no-margin">
                                    <!-- SD -->
                                    <ng-container *ngIf="!isSubProductAD">
                                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.adviceRequiredOptionCode ===''}">
                                            <input id="noAdviceRequiredSD" type="radio" data-ana="investorline:wealth:onboarding:advice:no" (change)="onAdviceRequiredChange($event)" [(ngModel)]="setExpectationObj.adviceRequiredOptionCode" [attr.disabled]="disableSetExpectationForm ? true : null" name="adviceRequired" value="{{screenerQ2Options.A}}" required />
                                            <label class="prefix" for="noAdviceRequiredSD" translate="setExpectations.labels.noAdviceRequiredSD" ></label>
                                        </div>
                                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.adviceRequiredOptionCode ===''}">
                                            <input id="yesAdviceRequiredSD" type="radio" data-ana="investorline:wealth:onboarding:advice:yes" (change)="onAdviceRequiredChange($event)" [(ngModel)]="setExpectationObj.adviceRequiredOptionCode" [attr.disabled]="disableSetExpectationForm ? true : null" name="adviceRequired" value="{{screenerQ2Options.B}}" required />
                                            <label class="prefix" for="yesAdviceRequiredSD" translate="setExpectations.labels.yesAdviceRequiredSD" ></label>
                                        </div>
                                    </ng-container>
                                    <!-- aD -->
                                    <ng-container *ngIf="isSubProductAD">
                                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.adviceRequiredOptionCode ===''}">
                                            <input id="noAdviceRequiredAD" type="radio" (change)="onAdviceRequiredChange($event)" [(ngModel)]="setExpectationObj.adviceRequiredOptionCode" [attr.disabled]="disableSetExpectationForm ? true : null" name="adviceRequired" value="{{screenerQ2Options.C}}" required />
                                            <label class="prefix" for="noAdviceRequiredAD" translate="setExpectations.labels.noAdviceRequiredAD" ></label>
                                        </div>
                                        <div class="form-radio-checks radio-default" [ngClass]="{'error-field':isButtonClicked && setExpectationObj.adviceRequiredOptionCode ===''}">
                                            <input id="yesAdviceRequiredAD" type="radio" (change)="onAdviceRequiredChange($event)" [(ngModel)]="setExpectationObj.adviceRequiredOptionCode" [attr.disabled]="disableSetExpectationForm ? true : null" name="adviceRequired" value="{{screenerQ2Options.D}}" required />
                                            <label class="prefix" for="yesAdviceRequiredAD" translate="setExpectations.labels.yesAdviceRequiredAD" ></label>
                                        </div>
                                    </ng-container>
                                    <div role="alert" class="error-message" *ngIf="isButtonClicked && setExpectationObj.adviceRequiredOptionCode ===''">
                                        <span translate="setExpectations.errors.receiveAdviceRequiredSD" *ngIf="!isSubProductAD"></span>
                                        <span translate="setExpectations.errors.receiveAdviceRequiredAD" *ngIf="isSubProductAD"></span>
                                    </div>
                                </div>

                            </div>

                            <div class="alert alert-yellow" role="alert" *ngIf="errForSecondScreenerQuestion()">
                                <p [innerHTML]="'setExpectations.descriptionRequireAdviceSD02' | translate | safeHtml" *ngIf="!isSubProductAD"></p>
                                <p [innerHTML]="'setExpectations.descriptionRequireAdviceAD' | translate | safeHtml" *ngIf="isSubProductAD"></p>
                            </div>
                            <div class="alert alert-info" role="alert" *ngIf="displayDisclaimerPopup()">
                                <p [innerHTML]="'setExpectations.descriptionResponseConfirmation' | translate | safeHtml"></p>
                            </div>
                        </div>
                    </ng-container>

                </div>


            <p id="tyskDescriptionPagePrivacy" *ngIf="!isBill64FeatureEnabled"
                [innerHtml]="'setExpectations.descriptionPagePrivacy' | translate | safeHtml"
                class="text-left add-margin t48px">
            </p>

            <div id="tyskDescriptionPagePrivacy" *ngIf="isBill64FeatureEnabled" class="text-left add-margin t48px">
                <p [innerHtml]="'setExpectations.descriptionPagePrivacyBill64' | translate | safeHtml"></p>
                <p [innerHtml]="'setExpectations.descriptionPagePrivacyBill64Desc' | translate | safeHtml"></p>
            </div>

            <p translate="setExpectations.byContinuing" class="text-left"></p>

        <div class="form-wrapper"*ngIf="!isJourneyUser">
            <div class="form-row form-page-buttons">
                <div class="form-col left-side">
                </div>
                <div class="form-col right-side">
					<button *ngIf="!resumeApp && !isOlbCdbUser && !isNonPilotUser" id="resumeApplicationBottom" class="tertiary" type="button"
						(click)="onResumeApplicationClick()" translate="button.resumeApplication">
                    </button>
                    <button data-ana="mywealth:onboarding:tysk:continueBtn" class="primary" type="submit"
                        *ngIf="resumeApp || formService.forms.setExpectation.isCompleted || ( !isLmeUser && (isOlbCdbUser || isNonPilotUser))" translate="button.continue"
                        attr.aria-label="{{ 'button.ariaLabels.continue' | translate }}">
                    </button>
                    <button data-ana="mywealth:onboarding:tysk:newApplicationBtn" class="primary" type="submit"
                        *ngIf="(isLmeUser && !formService.forms.setExpectation.isCompleted) ||  !resumeApp && !formService.forms.setExpectation.isCompleted &&  !isOlbCdbUser && !isNonPilotUser" translate="button.newApplicationLabel"
                        attr.aria-label="{{ 'button.ariaLabels.newApplication' | translate }}">
                    </button>
                </div>
            </div>
        </div>

        <div class="form-wrapper" *ngIf="isJourneyUser && isSubProductSD">
            <div class="form-row form-page-buttons">
                <div class="form-col left-side">
                    <button id="resumeApplicationBottom" class="secondary" type="button"
                        (click)="journeySavedClick()" attr.aria-label="{{navBackToBil ?  ('button.ariaLabels.backToBILPage' | translate) :  ('button.ariaLabels.backToBIL' | translate)}}"
                        translate="button.backToBIL">
                    </button>
                </div>
                <div class="form-col right-side">
                    <button class="primary" type="submit"
                        attr.aria-label="{{ 'button.ariaLabels.newApplication' | translate }}"
                        translate="button.continue">
                    </button>
                </div>
            </div>
        </div>
	</section>
    </div>

</form>
