/**
 * @purpose: Consents
 * @ created:
 * @ last updated by: Aravind Anantharaman (24 April, 2018)
 * @created on:
 *
 */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormService } from '../../shared/service/form.service';
import { NgForm, FormControl } from '@angular/forms';
import { ApplicationService } from '../../shared/service/application.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Title } from '@angular/platform-browser';
import { Utils } from '../../shared/utils/utils.class';
import { Subscription } from 'rxjs';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, productTypeAD, productTypeSD } from './../../config/app-config.constants';

export class Consents {
    personalInfo = null;
    marketingPref = null;
    shareholder = null;
    securityholder = null;
    securityholderOption = null;
    taxDocuments = null;
}


@Component({
    selector: 'app-consents',
    templateUrl: './consents.component.html'
})
export class ConsentsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @ViewChild('consentsForm', {static: true}) consentsForm: NgForm;
    // For accessibility purposes
    @ViewChild('personalInfoCtrl', {static: true}) personalInfoCtrl: FormControl;
    @ViewChild('marketingPrefCtrl', {static: true}) marketingPrefCtrl: FormControl;
    @ViewChild('shareholderCtrl', {static: true}) shareholderCtrl: FormControl;
    @ViewChild('securityholderCtrl', {static: true}) securityholderCtrl: FormControl;
    @ViewChild('securityholderOptionCtrl', {static: true}) securityholderOptionCtrl: FormControl;
    @ViewChild('taxDocumentCtrl', {static: true}) taxDocumentCtrl: FormControl;

    public APP_ROUTES = APP_ROUTES;
    public productTypeAD = productTypeAD;
    public isFormSubmit = false;
    public consents: Consents = new Consents();
    public disableFinishLater;
    public showFinishLater;
    public BILAssisted = false;
    public appNumber;
    public accountSetup;

    public criticalError: boolean;
    public url: any;
    public isJourneyUser: boolean = false;
    public isNonRegisteredAccount: boolean = false;
    public isMargin: boolean = false;
    public isCashAccount: boolean = false;
    public isSubProductAD: Boolean = null;
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    private onExitApplicationPerformSaveSubscriber;
    public isDataRationalizationEnabled: boolean = false;

    constructor(private formService: FormService,
        public titleService: Title,
        private winRef: WindowRefService,
        private applicationService: ApplicationService,
        public analytics: AnalyticsService) {

        this.formService.forms.consents.isVisited = true;
        // perform save operation on finish later
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'consentsfrm') {
                    this.onFinishLater();
                }
            });
            this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
                if (value) {
                    this.onFinishLater(true);
                }
            });
        this.formService.setActiveComponent('consents', this);

        this.url = '/' + APP_ROUTES.consents;
    }

    ngAfterViewInit() {
        // this.analytics.setState('step 11');
        this.analytics.setPageState(this.url);

        // this.analytics.setPPVID(ANALYTICS.consents);
        this.checkFormValidityOnSignOut();

        // this.analytics.setvPageTitle(Utils.getPageTitleConsents());
        // this.analytics.setOriginalLocation(Utils.getPageTitleConsents());
        // this.analytics.setvURL(Utils.getvURL());
        this.analytics.triggerView('Consents');
    }

    ngOnChanges() {
        // this.checkFormValidityOnSignOut();
    }

    ngOnInit() {
        this.isJourneyUser = Utils.getIsJourneyUser();
        this.isDataRationalizationEnabled = this.applicationService.getDataRationalizationFeatureFlag();
        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        this.BILAssisted = Utils.getIsBranchReferral();
        this.titleService.setTitle(Utils.getPageTitleConsents());

        if (this.formService.forms.consents.data != null) {
            // already visited form and now visiting back again
            this.consents = this.formService.getFormData('consents');
        } else {
            this.consents = new Consents();  // coming to form for the first time
            this.consents.taxDocuments = "electronic"
        }

        this.formService.forms.consents.isVisited = true;
        this.formService.setActiveForm(this.consentsForm);

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

        this.isSubProductAD = Utils.isSubProductAD();
        this.isCashAccount = this.applicationService.isCashAccount();
        this.isMargin = this.applicationService.isMargin();
        this.isNonRegisteredAccount = this.isMargin || this.isCashAccount;

        setTimeout(() => {
            this.setGTM();
        }, 200);
    }


    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {
                if (this.consentsForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });
    }

    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {
        // console.log('onFinishLater: ');
        if (!this.consentsForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }

        this.resetComponentObjectAttr();
        if (Utils.getIsOLBUser()  && this.consentsForm.valid) {
            this.formService.forms.consents.isCompleted = true;
        }
        this.formService.setFormData('consents', this.consents);

        // To prepare payload with PI , CI, EI, FI , FD RD, Cons
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);


        if (Utils.isSpousalRRSP()) {
            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        // save current page if form is valid
        if (!this.formService.forms.consents.isCompleted) {
            const emptyConsents = new Consents();
            this.formService.getPayload().setConsents(emptyConsents);
        } else {
            if (this.consentsForm.valid) {
                this.formService.getPayload().setConsents(this.consents);
            }
        }

        // console.log('payload', this.formService.getPayload());
        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({appNumber:saveResponse.applicationNumber,isAccountSetup:true})
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });

    }

    saveOnSignOut() {

        this.resetComponentObjectAttr();
        this.formService.forms.consents.isCompleted = true;
        this.formService.setFormData('consents', this.consents);

        // To prepare payload with PI , CI, EI, FI , FD RD, Cons
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
        // this.formService.getPayload().setConsents(this.consents);

        if (Utils.isSpousalRRSP()) {

            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        // save current page if form is valid
        if (!this.formService.forms.consents.isCompleted) {
            const emptyConsents = new Consents();
            this.formService.getPayload().setConsents(emptyConsents);
        } else {
            if (this.consentsForm.valid) {
                this.formService.getPayload().setConsents(this.consents);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });
    }

    onSubmit() {

        this.isFormSubmit = true;
        // For accessibility purposes, set focus on the invalid element.
        if (!this.consentsForm.valid) {
            this.setFocus();
        }

        if (this.consentsForm.valid) {
            this.resetComponentObjectAttr();
            this.formService.forms.consents.isCompleted = true;
            this.formService.setFormData('consents', this.consents);
            // To prepare payload with PI , CI, EI, FI , FD RD, Cons
            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');
            const employmentInformation = this.formService.getFormData('employmentInformation');
            const familyInformation = this.formService.getFormData('familyInformation');
            const financialInformation = this.formService.getFormData('financialInformation');
            const regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');

            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
            this.formService.getPayload().setFamilyInformation(familyInformation);
            this.formService.getPayload().setFinancialInformation(financialInformation);
            this.formService.getPayload().setRegulatoryDisclosure(regulatoryDisclosure);
            this.formService.getPayload().setConsents(this.consents);

            if (Utils.isSpousalRRSP()) {
                this.formService.getPayload().setSpousalRRSP(familyInformation);
            }

            // console.log('payload with PI CI EI FI FD RD Consents', this.formService.getPayload());
            if (Utils.getIsInSession() === false) {
                if (this.BILAssisted === true && Utils.isCash() === false || Utils.getIsOLBUser()) {
                    this.applicationService.saveAndcontinue(this.consentsForm, Utils.getIsOLBUser()?APP_ROUTES.account_access:APP_ROUTES.account_password);
                } else if (this.isJourneyUser && this.isNonRegisteredAccount) {
                    this.applicationService.saveAndcontinue(this.consentsForm, APP_ROUTES.account_access ) ;
                } else if (this.isJourneyUser && !this.isNonRegisteredAccount) {
                    this.applicationService.saveAndcontinue(this.consentsForm, APP_ROUTES.review ) ;
                } else {
                    this.applicationService.saveAndcontinue(this.consentsForm, APP_ROUTES.bmo_relationship);
                }
            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        // console.log(' save res: ', res.body);
                        // console.log(' save res status: ', res.status);
                        if (res.status === SUCCESS_RESPONSE) {

                            if (this.BILAssisted === true && Utils.isCash() === false && Utils.isSubProductAD() || Utils.getIsOLBUser()) {
                                this.applicationService.saveAndcontinue(this.consentsForm, Utils.getIsOLBUser()? APP_ROUTES.account_access : APP_ROUTES.account_password);
                            } else if (this.isJourneyUser === true && this.isNonRegisteredAccount === true) {
                                this.applicationService.saveAndcontinue(this.consentsForm, APP_ROUTES.account_access ) ;
                            } else if (this.isJourneyUser === true && this.isNonRegisteredAccount === false) {
                                this.applicationService.saveAndcontinue(this.consentsForm, APP_ROUTES.review ) ;
                            } else {
                                this.applicationService.saveAndcontinue(this.consentsForm, APP_ROUTES.bmo_relationship);
                            }

                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        // console.log('save err: ', err.status);
                        this.criticalError = true; // show error when save fails on top of page
                    });
                }
            } else {
            // console.log('not valid form');
        }
    }

    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {
        // setTimeout() method has been used since DOM needs to wait for its handlers.

        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.consentsForm);
           }, 250)

    }

    resetComponentObjectAttr() {
        if (!this.consents.securityholder) {
            this.consents.securityholderOption = '';
        }
    }

    ngOnDestroy() {
        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.formService.setFormData('consents', this.consents);
        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }

    setGTM() {

        const gtmDataLayerForConsents: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleConsents(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.CONSENTS_DETAILS,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.consents,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForConsents);
        // console.log('consents', this.winRef.nativeWindow.dataLayer);

    }

}

