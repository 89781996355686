import { AccountPassword } from './../../sections/account-password/account-password.component';

import { BeneficiaryInformationObj } from './../../sections/beneficiary/beneficiary.component';
import { FinancialInformation } from './../../sections/financial-details/financial-details.component';
import { ContactInformation } from '../../sections/contact-information/contact-information.component';
import { PersonalInformation } from '../../sections/personal-information/personal-information.component';
import { FamilyInformation } from '../../sections/family-information/family-information.component';
import { Consents } from '../../sections/consents/consents.component';
import { RegulatoryDisclosure } from '../../sections/regulatory-disclosures/regulatory-disclosures.component';
import { BmoRelationship } from '../../sections/bmo-relationship/bmo-relationship.component';
import { EMPLOYMENTSTATUS, COUNTRY_CODE, ACCOUNT_TYPE, INVESTMENT_KNOWLEDGE_TYPES, RIF_PAYMENT_METHOD, ACCOUNT_CODES } from '../../config/app-config.constants';
import { Utils } from '../../shared/utils/utils.class';
import { SelectAccount } from '../../sections/select-account/select-account.component';
import { AccountDetail } from '../../sections/account-detail/account-detail.component';
import { InvestmentKnowledge } from '../../sections/investment-knowledge/investment-knowledge.component';

import { AccountInvestmentObjectives } from './../../sections/investment-objectives/investment-objectives.component';
import { InvestorProfile } from './../../sections/investor-profile/investor-profile.component';
import { BranchReferral } from './../../sections/branch-referral/branch-referral.component';
import { RiskAndReturn } from './../../sections/risk-and-return/risk-and-return.component';
import { uniq } from 'lodash';



/**
 * @purpose: This class was created to send back data to CS services in the same structure as they are following for PCD.
 *           This class will have only one object throughout the application and during the course of application we will
 *           add data from different forms to the object of this class and eventually send the object to CS service.
 * @ created: Mayur Kode
 * @ last updated by: Mayur Kode
 */

let uniquePartyRefId = Utils.getPartyRefId();

export class BeneficiaryAccountObj {
    beneficiaryRefId = null;
    contingentBeneficiaryRefIds = null;
    entitlementPercentage = null;
    hasContingentBeneficiaries = false;
}

export class MultiAccountObject {

    type = null;
    usDollarAccountLinkFlag = null;
    isProAccount = null;
    accountLinkType = null;
    primaryApplicantPartyRefId = uniquePartyRefId; // same as party Ref ID ... don't generate new
    linkDebitCard = null;
    isSpousal = false;
    debitCardNumber = null;
    ecifCardNumberList = [];
    accountNumber = null;
    bankUsFunds = null;
    hasOptionsTrading = null;
    optionsTrading = null;
    promoCode = null;
    tncChecked = false;
    tncDocName = null;
    currencies = [
        '*'
    ];
    jointAccountDetails = null;
    subTypes = null;
    intendedUse = '99';
    otherDescription = null;
    ilInvestmentTimeHorizon = null;
    investmentTimeHorizonForWithDraw = null;
    investmentTimeHorizon = null;
    investmentObjectives = {
        riskToleranceQ1: null,
        riskToleranceQ2: null,
        annualIncReq: '',
        objective: '',
        ilRiskTolerance: null,
        riskLevelAcceptedForAgeSeventy: null,
        investAssetPercentage: null
    };
    refId = '';
    name = '';
    hasBeneficiaries = null;
    beneficiaries = [];
    areOtherPartiesSpecified = false;
    tradingAuthorityPartyRefIds = null;
    guarantorRefIds = null;
    guaranteeRefIds = null;
    hasSuccessorAnnuitant = null;
    contributor = null;
    hasEftSetup = null;
    inboundEftDetails = null;
    outboundEftDetails = {
        institutionCode: null,
        institutionName: null,
        transitCode: null,
        isJointAccount: null,
        ownershipOfBankAccountVerified: null,
        accountNumber: null
    };
    rifPayment = {
        isSpouseAgeUsed: false,
        frequency: null,
        whenInMonth: null,
        startMonth: null,
        startYear: null,
        greaterThanMinimumAmountType: null,
        amountType: null,
        amount: null,
        method: null,
        transferAccountNumber: null
    };
    hasThirdPartyInterest = false;
    jointApplicantPartyRefIds = null;
    accountInvestmentObjectives = {
        income: null,
        shortTerm: null,
        mediumTerm: null,
        longTerm: null
    };
    accountFeatures = {
        isShortSelling: null,
        isOptionTrading: null,
        //creditBureautncChecked : null, MWB-12514 - fix
        optionsTrading: {
            optionTypeExperiences: [],
            optionTypes: [],
            experienceInYears: null,
            optionsLevel: null, // UI is not passing this. It should be updated.
            knowledge: '',
        }
    };
}
export class BeneficiaryPayloadObj {
    beneAddress = null;
    beneficiaryRefId = null;
    custodialInfo = null;
    dateOfBirth = null;
    gender = null;
    hasPrimaryApplicantSpouse = null;
    hasSameAsPrimaryAddress = null;
    isRespBeneficiary = null;
    name = {
        firstName: '',
        lastName: ''
    };
    primaryCaregiverInfo = null;
    relationshipToPlanHolder = null;
    socialInsuranceNumber = null;
}

export class Payload {  // do not add member variables to this class... only methods are allowed

    applicationSubProduct = '';  // set this subProduct depend on AD or SD
    applicationLob = 'il'; // changed as per CS guidance
    applicationId = 'placehoder-applicationID'; // should not be null.
    applicationNumber = '';
    status: 'DataCollection';
    // branchCode: '565';
    multiApplicantsInvestmentExperiences = [];
    guarantors = [];
    guarantees = [];
    isApplicantExistingPBClient = null;
    olbuser = null;
    ilUser = null;
    optionsTradingForAllAccounts = null;
    pageStatus = {
        thingsYouShouldKnow: null,
        personalInformation: null,
        contactInformation: null,
        employmentInformation: null,
        familyInformation: null,
        beneficiaryInformation: null,
        financialInformation: null,
        investmentKnowledge: null,
        investmentObjective: null,
        investorProfile: null,
        regulatoryDisclosures: null,
        withdrawal: null,
        consents: null,
        bmoRelationship: null,
        accountPassword: null,
        termsConditions: null
    };
    beneficiaries = [];
    branchReferral = {
        isBranchReferral: null,
        designationCode: null,
        fpName: null,
        fsmName: null,
        ein: null,
        fpPhone: null,
        fsmPhone: null,
        transit: null,
        fpCode: null,
        nbIaName: null,
        nbIaPhone: null,
        nbIaSalesCode: null,
        iaSalesAssistant: null,
        isBMOReferral: null,
        isAdditionalReferral: null
    };
    olapPayload = null;
    networkType = null;
    parties = this.getPayLoadParties();
    locale: 'en-ca';
    clientMetadata = {
        selectAccounts: 'true',
        onlineAccountAccess: 'true',
        employmentDetails: 'true',
        contactDetails: 'true',
        marketingDetails: 'true',
        regulatoryDetails: 'true',
        clientIdentification: 'true',
        personalDetails: 'true',
        documentPreferences: 'true',
        objectives: 'true',
        sourceOfWealthDetails: 'true',
        shareholderCommunication: 'true',
        experienceAndKnowledge: 'true',
        incomeDetails: 'true',
        isGatewayExisting: 'declineAccess',
        multiApplicantsInvestmentExperiences: 'true'
    };
    lastUpdated: '2018-04-06T10:14:29.941-04:00';
    // remoed  validationErrors from here .. Not required for BIL
    /* clientAccessId = {
        hasDeclinedOnlineAccess: true
    }; */
    accounts = Array(
        new MultiAccountObject()
    );

    setNetworkType(value: string) {
        this.networkType = value;
    }

    // removed featureFlags ... not required in BIL

    release: 'R18';  // change : this will be updated later to R1

    setApplicationSubProduct(value: string) {
        this.applicationSubProduct = value;
    }
    setOLAPPayload(OLAPpayload) {
        this.olapPayload = OLAPpayload;

    }

    getPayLoadParties() {
        return [
            {
                trustedPartyRefId: null,
                partyRefId: uniquePartyRefId,
                trustedContactPersonRequired: null,
                spousePartyRefId: null,
                password: '',
                roles: [
                    'PRIMARY_APPLICANT'
                ],
                personal: {
                    identity: {
                        primaryEmailAddress: '',
                        socialInsuranceNumber: '',
                        dateOfBirth: '',
                        maritalStatus: '',
                        hasDependents: false,
                        numOfDependents: null,
                        preferredLanguage: '100',
                        hasPreferredName: null,
                        legalName: {
                            title: '',
                            firstName: '',
                            middleName: '',
                            lastName: ''
                        },
                        preferredName: {
                            // firstName: 'Garry',
                            // lastName: 'Sandhu'
                        },
                        citizenships: []
                    },
                    residence: {
                        primaryAddress: {
                            streetAddress: '',
                            postalCode: '',
                            city: '',
                            province: '',
                            country: '',
                            unitNumber: ''
                        },
                        previousAddress: {},
                        primaryPhone: {
                            phoneNumber: ''
                        },
                        // secondaryPhone: {},
                        // mobile
                        secondaryPhone: {
                            phoneNumber: '',
                            mobile: null,
                            phoneExtension: null
                        },
                        alternateAddress: {},
                        hasAlternateAddress: null
                    },
                    employment: {
                        bmoGroup: null,
                        employerBusinessName: '',
                        employmentStatus: '',
                        isBmoEmployee: null,
                        jobTitle: '',
                        natureOfBusiness: '',
                        occupation: '',
                        primaryBusinessPhone: {
                            phoneNumber: '',
                            phoneExtension: ''
                        },
                        employmentAddress: {
                            city: '',
                            country: '',
                            postalCode: '',
                            province: '',
                            streetAddress: '',
                            unitNumber: ''
                        }
                    },
                    hasTaxResidency: false,
                    hasCanadianTaxResidency: false,
                    hasUSTaxResidency: false,
                    residencyForTax: [
                        {
                            province: null,
                            country: '',
                            taxIdentificationNumber: null,
                            taxIdentificationNumberMissingReason: null,
                            taxIdentificationNumberMissingReasonDescription: null,
                            haveNoTaxIdentificationNumber: null
                        }
                    ]
                },
                financial: {
                    status: {
                        income: {
                            annualEmploymentIncome: null,
                            annualInvestmentIncome: null,
                            annualOtherIncome: null,
                            otherIncomeSource: null,
                            otherIncomeSourceDescription: null,
                        },
                        netWorth: {
                            liquidAssets: null,
                            fixedAssets: null
                        }
                    },
                    other: {
                        sourcesOfWealth: [''],
                        otherSourceOfWealth: null,
                        otherInvestmentInstitution: '',
                        hasOtherInvestmentAccounts: null,
                        isBorrowingToInvest: null
                    },
                    investment: {
                        pastExperience: [],
                        investmentKnowledge: null,
                        ilInvestmentKnowledge: '',
                        altInvestmentExperience: null
                    }
                },
                investmentObjectives: {
                    objective: null,
                    riskTolerance: {
                        low: null,
                        medium: null,
                        high: null
                    },
                    targetAllocation: {
                        cash: null,
                        fixedIncome: null,
                        equities: null
                    }
                },
                preferences: {
                    sharePersonalInfo: null,
                    receiveDirectMarketingMaterials: null,
                    discloseBeneficialOwner: null,
                    receiveElectronicDelivery: true,
                    isReceiveStatementByMail: null,
                    isSetupAlternateMailings: null,
                    isAccountStatements: null,
                    isTradeConfirmations: null,
                    alternateMailingAddresses: null,
                    documentPreferenceOption: null,
                    receiveSecurityHolderMaterials: '',
                    iseTaxConsentProvided: null
                },
                bmoRelationship: {
                    hasBMODebitCard: null,
                    bmoAccountNumber: null,
                    sendNewBMODebitCard: null,
                    isApplicantExistingClient: null,
                    bmoProduct: null,
                    isApplicantExistingPBClient: null,
                    consentToAccountLink: null
                },
                taxation: {
                    isIrsW8FormProvided: null,
                    beneficialOwnerCountry: null,
                    beneficialOwnerCountryReason: null,
                    isIrsW9FormProvided: null,
                    taxIdentificationNumber: null
                },
                regulatoryDisclosures: {
                    iirocMember: null,
                    iirocOrganization: null,
                    haveInsiderRelationship: null,
                    insiderRelationships: [],
                    controlRelationships: [],
                    isReportingInsider: null,
                    reportingInsiderRelationships: [],
                    significantShareholder: null,
                    significantShareholdings: [],
                    verification: {},
                    controlRelationship: null,
                    isPoliticallyExposedPerson: null,
                    politicallyExposedPersonType: null,
                    politicallyExposedPersonPosition: null
                },
                creditBureauConsent: null, //  as per CS guidance ,
                existingAccount: { // MWB-14172 & MWB-14173
                    amlFlag: undefined
                }
            }
        ];
    }

    processSIN(sinNumber) {
        // backend cs services need sinNumber without - in it.

        if (sinNumber) {
            sinNumber = sinNumber.split('-').join('');
        } else {
            sinNumber = null;
        }
        return sinNumber;
    }

    processPhoneNumber(phoneNumber) {
        // backend cs services need Phone number  without special character in it.
        if (phoneNumber !== undefined) {
            phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
        }
        return phoneNumber;
    }

    processPostalCode(postalCode) {
        // backend cs services need Postal code  without space in it.
        if (postalCode !== undefined && postalCode != null) {
            postalCode = postalCode.replace(' ', '');
        }

        return postalCode;
    }

    setAccountType(selectAccount: SelectAccount) {

        this.accounts[0].type = selectAccount.selectedAccount;
        // console.log(' this.accounts[0].type: ', this.accounts[0].type);

        if (selectAccount.selectedAccount === '100391-SPOUSAL') {  // even if SRRSP set type as same as RRSP
            this.accounts[0].type = '100391';
            this.accounts[0].isSpousal = true;
        }

        if (selectAccount.selectedAccount === '100544') {
            // selected accout is cash / individual so subtypes is 1 ');
            this.accounts[0].subTypes = ['1'];

        } else if (selectAccount.selectedAccount === '100544-MARGIN') {
            // for margin account backend expects below values
            this.accounts[0].type = '100544';
            this.accounts[0].subTypes = ['2'];

        } else {
            this.accounts[0].subTypes = ['1'];  // MWB-7539;
        }

    }

    setMultiAccountTypes(selectAccount: SelectAccount) {

        for (let i in selectAccount.selectedAccountTypes) {
            if (!this.accounts[i]) {
                this.accounts[i] = new MultiAccountObject;
            }
            this.accounts[i].primaryApplicantPartyRefId = Utils.getPartyRefId();
            this.accounts[i].type = selectAccount.selectedAccountTypes[i];
            this.accounts[i].subTypes = ['1'];
            if (selectAccount.selectedAccountTypes[i] === '100391-SPOUSAL') {
                this.accounts[i].type = '100391';
                this.accounts[i].isSpousal = true;
            } else if (selectAccount.selectedAccountTypes[i] === '100544') {
                // selected accout is cash / individual so subtypes is 1 ');
                this.accounts[i].subTypes = ['1'];
            } else if (selectAccount.selectedAccountTypes[i] === '100544-MARGIN') {
                // for margin account backend expects below values
                this.accounts[i].type = '100544';
                this.accounts[i].subTypes = ['2'];
            }
        }
        console.log(this.accounts, 'payloadd');
    }

    setCardDetails(account: any) {
        for (let i in this.accounts) {
            this.accounts[i].debitCardNumber = account.debitCardNumber;
            this.accounts[i].ecifCardNumberList = account.ecifCardNumberList;
        }
    }

    setAccountDetails(accountDetail: any) {

        // in case of AD, options trading is not applicable
        if (Utils.isSubProductAD()) {

            this.accounts[0].accountFeatures.isOptionTrading = false;


        } else {

            this.accounts[0].accountFeatures.isOptionTrading = this.booleanRadioMapper(accountDetail.needOptionsTrading);
            this.accounts[0].accountFeatures.isShortSelling = this.booleanRadioMapper(accountDetail.needShortSelling);
            this.accounts[0].accountFeatures.optionsTrading.experienceInYears = parseInt(accountDetail.yearsOfExperience);
            this.accounts[0].accountFeatures.optionsTrading.knowledge = accountDetail.tradingKnowledgeLevel;
            this.accounts[0].accountFeatures.optionsTrading.optionTypeExperiences = Utils.getCodes(accountDetail.typesOfOptionsTradedBefore);
            this.accounts[0].accountFeatures.optionsTrading.optionTypes = Utils.getCodes(accountDetail.typesOfOptionsTradedNow);


            if (accountDetail.needOptionsTrading == 'Yes') {
                this.accounts[0].accountFeatures.optionsTrading.optionsLevel = 1;
                if (Utils.isMargin() && this.accounts[0].accountFeatures.optionsTrading.optionTypes.includes(3)) {
                    this.accounts[0].accountFeatures.optionsTrading.optionsLevel = 3;
                }
            } else if (accountDetail.needOptionsTrading == 'No') {
                this.accounts[0].accountFeatures.optionsTrading.optionsLevel = null;
            }


        }

        // setting up promo code in payload
        if (accountDetail.promocode != null && accountDetail.promocode != '' && accountDetail.tncChecked) {
            this.accounts[0].promoCode = accountDetail.promocode;
            this.accounts[0].tncChecked = accountDetail.tncChecked;
            this.accounts[0].tncDocName = accountDetail.tncDocName;
        } else {
            delete this.accounts[0].promoCode;
            this.accounts[0].tncChecked = false;
        }

        if (accountDetail.creditBureautncChecked != null && accountDetail.creditBureautncChecked !== '') {
            this.parties[0].creditBureauConsent = accountDetail.creditBureautncChecked;
        } else {
            delete this.parties[0].creditBureauConsent;
        }


    }

    setMultiAccountDetails(selectAccount: SelectAccount) {



        this.optionsTradingForAllAccounts = selectAccount.optionsTradingForAllAccounts;
        for (let i in selectAccount.selectedAccountTypes) {
            if (selectAccount.needOptionsTrading == 'Yes' && selectAccount.selectedAccountTypes[i] != ACCOUNT_TYPE.CASH.toString()) {
                if (selectAccount.optionsTradingForAllAccounts) {
                    this.accounts[i].accountFeatures.isOptionTrading = this.booleanRadioMapper(selectAccount.needOptionsTrading);
                } else {
                    this.accounts[i].accountFeatures.isOptionTrading = this.booleanRadioMapper(selectAccount.optionTradingMulti[i]);
                }

                if (this.accounts[i].accountFeatures.isOptionTrading) {
                    this.accounts[i].accountFeatures.optionsTrading.experienceInYears = parseInt(selectAccount.yearsOfExperience);
                    this.accounts[i].accountFeatures.optionsTrading.knowledge = selectAccount.tradingKnowledgeLevel;
                    this.accounts[i].accountFeatures.optionsTrading.optionTypeExperiences = Utils.getCodes(selectAccount.typesOfOptionsTradedBefore);
                    this.accounts[i].accountFeatures.optionsTrading.optionTypes = Utils.getCodes(selectAccount.typesOfOptionsTradedNow);

                    this.accounts[i].accountFeatures.optionsTrading.optionsLevel = 1;
                    if (selectAccount.selectedAccountTypes[i] == ACCOUNT_TYPE.MARGIN && this.accounts[i].accountFeatures.optionsTrading.optionTypes.includes(3)) {
                        this.accounts[i].accountFeatures.optionsTrading.optionsLevel = 3;
                    } else if (selectAccount.selectedAccountTypes[i] != ACCOUNT_TYPE.MARGIN &&
                        this.accounts[i].accountFeatures.optionsTrading.optionTypes.includes(3)) { // remove spreads for non-margin accounts
                        const spreadsIndex = this.accounts[i].accountFeatures.optionsTrading.optionTypes.indexOf(3);
                        this.accounts[i].accountFeatures.optionsTrading.optionTypes.splice(spreadsIndex, 1);
                    }
                } else {
                    this.accounts[i].accountFeatures.optionsTrading.experienceInYears = null;
                    this.accounts[i].accountFeatures.optionsTrading.knowledge = null;
                    this.accounts[i].accountFeatures.optionsTrading.optionTypeExperiences = [];
                    this.accounts[i].accountFeatures.optionsTrading.optionTypes = [];
                    this.accounts[i].accountFeatures.optionsTrading.optionsLevel = null;
                }
            } else {
                if (selectAccount.selectedAccountTypes[i] == ACCOUNT_TYPE.CASH.toString()) {
                    this.accounts[i].accountFeatures.isOptionTrading = null;
                } else {
                    this.accounts[i].accountFeatures.isOptionTrading = this.booleanRadioMapper(selectAccount.needOptionsTrading);
                }
                this.accounts[i].accountFeatures.optionsTrading.experienceInYears = null;
                this.accounts[i].accountFeatures.optionsTrading.knowledge = null;
                this.accounts[i].accountFeatures.optionsTrading.optionTypeExperiences = [];
                this.accounts[i].accountFeatures.optionsTrading.optionTypes = [];
                this.accounts[i].accountFeatures.optionsTrading.optionsLevel = null;
            }
            if (selectAccount.selectedAccountTypes[i] == ACCOUNT_TYPE.MARGIN) {
                this.accounts[i].accountFeatures.isShortSelling = this.booleanRadioMapper(selectAccount.needShortSelling);
            } else {
                this.accounts[i].accountFeatures.isShortSelling = null;
            }

            // setting up promo code in payload
            if (selectAccount.promocode != null && selectAccount.promocode != '' && selectAccount.tncChecked) {
                this.accounts[i].promoCode = selectAccount.promocode;
                this.accounts[i].tncChecked = selectAccount.tncChecked;
                this.accounts[i].tncDocName = selectAccount.tncDocName;
            } else {
                delete this.accounts[i].promoCode;
                this.accounts[i].tncChecked = false;
            }

        }




        if (selectAccount.creditBureautncChecked != null && selectAccount.creditBureautncChecked !== '') {
            this.parties[0].creditBureauConsent = selectAccount.creditBureautncChecked;
        } else {
            delete this.parties[0].creditBureauConsent;
        }


    }

    setWithdrawalInformation(withdrawalInformationObj: any) {
        if (Utils.selectedMultiAccounts?.length > 0) {
            Utils.selectedMultiAccounts.forEach((accountType, i) => {
                if (accountType.type == ACCOUNT_CODES.RIF) {
                    this.accounts[i].rifPayment.isSpouseAgeUsed = withdrawalInformationObj.isSpouseAgeUsed === 'true' ? true : false;
                    this.accounts[i].rifPayment.frequency = withdrawalInformationObj.frequency;
                    this.accounts[i].rifPayment.whenInMonth = withdrawalInformationObj.whenInMonth;
                    this.accounts[i].rifPayment.startMonth = +withdrawalInformationObj.startMonth;
                    this.accounts[i].rifPayment.startYear = withdrawalInformationObj.startYear;
                    this.accounts[i].rifPayment.greaterThanMinimumAmountType = withdrawalInformationObj.greaterThanMinimumAmountType;
                    this.accounts[i].rifPayment.amountType = withdrawalInformationObj.amountType;
                    this.accounts[i].rifPayment.amount = +withdrawalInformationObj.amount;
                    this.accounts[i].rifPayment.method = withdrawalInformationObj.method;
                    if (this.accounts[i].rifPayment.method === RIF_PAYMENT_METHOD.TFR) {
                        this.accounts[i].rifPayment.transferAccountNumber = withdrawalInformationObj.transferAccountNumber;
                        this.accounts[i].outboundEftDetails.transitCode = null;
                        this.accounts[i].outboundEftDetails.accountNumber = null;
                    } else {
                        this.accounts[i].outboundEftDetails.transitCode = withdrawalInformationObj.transitCode;
                        this.accounts[i].outboundEftDetails.accountNumber = withdrawalInformationObj.transferAccountNumber;
                        this.accounts[i].rifPayment.transferAccountNumber = null;
                    }
                    if (this.accounts[i].rifPayment.amountType != '2') {
                        this.accounts[i].rifPayment.greaterThanMinimumAmountType = '1';
                        this.accounts[i].rifPayment.amount = null;
                    }
                    this.accounts[i].outboundEftDetails.institutionCode = null;
                    this.accounts[i].outboundEftDetails.institutionName = null;
                    this.accounts[i].outboundEftDetails.isJointAccount = null;
                    if (this.accounts[i].rifPayment.method === RIF_PAYMENT_METHOD.NBMOEFT) {
                        this.accounts[i].outboundEftDetails.institutionCode = withdrawalInformationObj.institutionCode;
                        this.accounts[i].outboundEftDetails.institutionName = withdrawalInformationObj.institutionName;
                        this.accounts[i].outboundEftDetails.isJointAccount = withdrawalInformationObj.isJointAccount === 'true' ? true : false;
                    } else if (this.accounts[i].rifPayment.method === RIF_PAYMENT_METHOD.BMOEFT) {
                        this.accounts[i].outboundEftDetails.institutionCode = withdrawalInformationObj.bmoInstitutionCode;
                        this.accounts[i].outboundEftDetails.institutionName = withdrawalInformationObj.bmoInstitutionName;
                    }
                    this.accounts[i].outboundEftDetails.ownershipOfBankAccountVerified = withdrawalInformationObj.validAccount;
                    // let spouseFirstName = null;
                }
            })
        }
        else {
            this.accounts[0].rifPayment.isSpouseAgeUsed = withdrawalInformationObj.isSpouseAgeUsed === 'true' ? true : false;
            this.accounts[0].rifPayment.frequency = withdrawalInformationObj.frequency;
            this.accounts[0].rifPayment.whenInMonth = withdrawalInformationObj.whenInMonth;
            this.accounts[0].rifPayment.startMonth = +withdrawalInformationObj.startMonth;
            this.accounts[0].rifPayment.startYear = withdrawalInformationObj.startYear;
            this.accounts[0].rifPayment.greaterThanMinimumAmountType = withdrawalInformationObj.greaterThanMinimumAmountType;
            this.accounts[0].rifPayment.amountType = withdrawalInformationObj.amountType;
            this.accounts[0].rifPayment.amount = +withdrawalInformationObj.amount;
            this.accounts[0].rifPayment.method = withdrawalInformationObj.method;
            if (this.accounts[0].rifPayment.method === RIF_PAYMENT_METHOD.TFR) {
                this.accounts[0].rifPayment.transferAccountNumber = withdrawalInformationObj.transferAccountNumber;
                this.accounts[0].outboundEftDetails.transitCode = null;
                this.accounts[0].outboundEftDetails.accountNumber = null;
            } else {
                this.accounts[0].outboundEftDetails.transitCode = withdrawalInformationObj.transitCode;
                this.accounts[0].outboundEftDetails.accountNumber = withdrawalInformationObj.transferAccountNumber;
                this.accounts[0].rifPayment.transferAccountNumber = null;
            }
            if (this.accounts[0].rifPayment.amountType != '2') {
                this.accounts[0].rifPayment.greaterThanMinimumAmountType = '1';
                this.accounts[0].rifPayment.amount = null;
            }
            this.accounts[0].outboundEftDetails.institutionCode = null;
            this.accounts[0].outboundEftDetails.institutionName = null;
            this.accounts[0].outboundEftDetails.isJointAccount = null;
            if (this.accounts[0].rifPayment.method === RIF_PAYMENT_METHOD.NBMOEFT) {
                this.accounts[0].outboundEftDetails.institutionCode = withdrawalInformationObj.institutionCode;
                this.accounts[0].outboundEftDetails.institutionName = withdrawalInformationObj.institutionName;
                this.accounts[0].outboundEftDetails.isJointAccount = withdrawalInformationObj.isJointAccount === 'true' ? true : false;
            } else if (this.accounts[0].rifPayment.method === RIF_PAYMENT_METHOD.BMOEFT) {
                this.accounts[0].outboundEftDetails.institutionCode = withdrawalInformationObj.bmoInstitutionCode;
                this.accounts[0].outboundEftDetails.institutionName = withdrawalInformationObj.bmoInstitutionName;
            }
            this.accounts[0].outboundEftDetails.ownershipOfBankAccountVerified = withdrawalInformationObj.validAccount;
        }
        if (this.parties && this.parties.length > 1 && withdrawalInformationObj.spouseDateOfBirth) {
            this.parties[1].personal.identity.dateOfBirth = withdrawalInformationObj.spouseDateOfBirth;
        }
        // let spouseFirstName = null;
        // let spouseLastName = null;
        // let spouseMiddleName = null;
        // if(this.parties && this.parties.length > 1 && withdrawalInformationObj.spouseDateOfBirth){
        //     // spouseFirstName = this.parties[1].personal.identity.legalName.firstName;
        //     // spouseLastName = this.parties[1].personal.identity.legalName.lastName;
        //     // spouseMiddleName = this.parties[1].personal.identity.legalName.middleName;
        //     if(!this.parties[1].personal.identity.dateOfBirth){
        //         this.parties[1].personal.identity.dateOfBirth = withdrawalInformationObj.spouseDateOfBirth;
        //     }
        //  }
        // this.accounts[0].contributor = {
        //     dateOfBirth: withdrawalInformationObj.spouseDateOfBirth,
        //     name: {
        //         firstName: spouseFirstName,
        //         lastName: spouseLastName,
        //         middleName: spouseMiddleName
        //     }
        // }
    }

    setPersonalInformation(personalInformation: PersonalInformation) {

        // set personal information details and set them in resepcitve fields
        // for other values, keep dafaults


        this.parties[0].personal.identity.preferredLanguage = personalInformation.currentLanguage;

        this.parties[0].personal.identity = {
            socialInsuranceNumber: this.processSIN(personalInformation.sinNumber),
            dateOfBirth: personalInformation.dateOfBirth,
            maritalStatus: this.parties[0].personal.identity.maritalStatus,
            hasDependents: this.parties[0].personal.identity.hasDependents,
            numOfDependents: this.parties[0].personal.identity.numOfDependents,
            preferredLanguage: this.parties[0].personal.identity.preferredLanguage,
            hasPreferredName: personalInformation.likeToUsePreferredName,
            primaryEmailAddress: this.parties[0].personal.identity.primaryEmailAddress,
            legalName: {
                title: personalInformation.prefix,
                firstName: personalInformation.firstname,
                middleName: personalInformation.initial,
                lastName: personalInformation.lastname
            },
            preferredName: {
                firstName: personalInformation.preferredFirstName,
                lastName: personalInformation.preferredLastName,
                middleName: personalInformation.preferredMiddleName
            },
            citizenships: []
        };

        this.parties[0].personal.identity.primaryEmailAddress = personalInformation.emailAddress;

        /**Add citizenships iff there'a value - To fix MWB-10822*/
        if (personalInformation.citizenship) {
            this.parties[0].personal.identity.citizenships.push(personalInformation.citizenship);
        }

        //Push USA Country Code to citizenship Array   MWB-15447
        if (personalInformation.usTaxResidentCheckBox && (Utils.isCash || Utils.isMargin)) {
            // Following condition  added for checking USA Code is already there or not. If Not then insert USA Code
            if (this.parties[0].personal.identity.citizenships.indexOf(COUNTRY_CODE.USA) == -1 && personalInformation.usResidentCheckBox) {
                this.parties[0].personal.identity.citizenships.push(COUNTRY_CODE.USA);
            }
            if (!Utils.isCRATaxResidencyEnabled()) {
                this.parties[0].personal.hasUSTaxResidency = Utils.convertYesNoToBoolean(personalInformation.usCitizenship);
            }
        }



        if (personalInformation.alternateCitizenship) {

            this.parties[0].personal.identity.citizenships.push(personalInformation.alternateCitizenship);
        }

        if (Utils.isCRATaxResidencyEnabled()) {
            //this pushes the selected citixen to the citizenship array
            (personalInformation.selectedCitizenCountries || []).forEach(country => {
                if (country.code && this.parties[0].personal.identity.citizenships.indexOf(country.code) == -1) {
                    this.parties[0].personal.identity.citizenships.push(country.code);
                }
            });
        }

        this.parties[0].personal.hasTaxResidency = Utils.convertYesNoToBoolean(personalInformation.otherCitizenshipOrTaxResidency);
        this.parties[0].personal.hasCanadianTaxResidency = Utils.convertYesNoToBoolean(personalInformation.isCanadaTaxResidency);
        // This resetting is required, when the user goes to edit from review page
        this.parties[0].personal.residencyForTax = [
            {
                province: null,
                country: '100000',
                taxIdentificationNumber: null,
                taxIdentificationNumberMissingReason: null,
                taxIdentificationNumberMissingReasonDescription: null,
                haveNoTaxIdentificationNumber: null
            }];
        this.parties[0].taxation = {
            isIrsW8FormProvided: false, // this has to be refined, when contact phone is US in contact page..
            beneficialOwnerCountry: null,
            beneficialOwnerCountryReason: null,
            isIrsW9FormProvided: null,
            taxIdentificationNumber: null
        };

        // For TFSA,CASH accounts, residencyForTax and taxation block has to be set.
        if (Utils.isCash() || Utils.isMargin() || Utils.isTFSA()) {

            this.parties[0].taxation = {
                isIrsW8FormProvided: true, // Defaulted with true
                beneficialOwnerCountry: COUNTRY_CODE.CANADA, // MWB-6813 - Canada is passed as default for W8 forms
                beneficialOwnerCountryReason: null,
                isIrsW9FormProvided: null,
                taxIdentificationNumber: null
            };

            //to Add USA tax residency- MWB-15447
            if (personalInformation.usTaxResidentCheckBox && (Utils.isCash() || Utils.isMargin())) {
                this.parties[0].personal.residencyForTax.push({
                    province: null,
                    country: COUNTRY_CODE.USA,
                    taxIdentificationNumber: personalInformation.usSINNumber,
                    taxIdentificationNumberMissingReason: '',
                    taxIdentificationNumberMissingReasonDescription: '',
                    haveNoTaxIdentificationNumber: false
                });
                if (Utils.isCRATaxResidencyEnabled()) {
                    this.parties[0].taxation = {
                        isIrsW8FormProvided: false, // Turned false as a country is USA
                        beneficialOwnerCountry: null,
                        beneficialOwnerCountryReason: null,
                        isIrsW9FormProvided: (personalInformation.usTaxResidentCheckBox || personalInformation.usResidentCheckBox),
                        // isIrsW9FormProvided is set to true as country is USA
                        taxIdentificationNumber: personalInformation.usSINNumber  // SSN is updated as country is USA
                    };
                } else if (!Utils.isCRATaxResidencyEnabled()) {
                    this.parties[0].taxation = {
                        isIrsW8FormProvided: false, // Turned false as a country is USA
                        beneficialOwnerCountry: null,
                        beneficialOwnerCountryReason: null,
                        isIrsW9FormProvided: (personalInformation.usTaxResidentCheckBox || personalInformation.usResidentCheckBox),
                        // isIrsW9FormProvided is set to true as country is USA
                        taxIdentificationNumber: personalInformation.usSINNumber  // SSN is updated as country is USA
                    };
                }

            }

            personalInformation.selectedCountries.forEach(residency => {
                if (residency.isTaxResident && residency?.code) {

                    this.parties[0].personal.residencyForTax.push({
                        province: null,
                        country: residency.code,
                        taxIdentificationNumber: (residency.code === COUNTRY_CODE.USA) ? residency.ssn : residency.tin,
                        taxIdentificationNumberMissingReason: residency.reasonSelected,
                        taxIdentificationNumberMissingReasonDescription: residency.otherReason,
                        haveNoTaxIdentificationNumber: residency.noTaxIdentificationNum
                    });
                }

                // the generation of w8 and w9 form is based on this block.
                // For RRSP its always false, as no w8/w9 generated
                if (residency.code === COUNTRY_CODE.USA) {

                    this.parties[0].taxation = {
                        isIrsW8FormProvided: false, // Turned false as a country is USA
                        beneficialOwnerCountry: null,
                        beneficialOwnerCountryReason: null,
                        isIrsW9FormProvided: (residency.isCitizen || residency.isTaxResident),
                        // isIrsW9FormProvided is set to true as country is USA
                        taxIdentificationNumber: residency.ssn   // SSN is updated as country is USA
                    };


                }

                if (residency.isCitizen && residency.code) {

                    let check: boolean = false;

                    /**To fix MWB-10728 */
                    for (let i = 0; i < this.parties[0].personal.identity.citizenships.length; i++) {
                        if (this.parties[0].personal.identity.citizenships[i] == residency.code) {
                            check = true;
                        }
                    }

                    /**Add citizenship iff it does not exist. */
                    if (!check) {
                        this.parties[0].personal.identity.citizenships.push(residency.code);
                    }

                }
            });
        }

        // MWB-1536 if user selects NA as title, do not pass title in payload
        if (personalInformation.prefix === 'null') {  // don't change this 'null' string to null object
            delete this.parties[0].personal.identity.legalName.title;
        }

        // MWB-4848  SOLUTION: do not add blank citizenship if not canadian
        const index = this.parties[0].personal.identity.citizenships.indexOf(' ', 0);
        if (index > -1) {
            this.parties[0].personal.identity.citizenships.splice(index, 1);
        }
        this.parties[0].existingAccount.amlFlag = personalInformation.AML_ENABLED;  // MWB-14172 & MWB-14173
        // To maintain only uniq citizenship for payload
        this.parties[0].personal.identity.citizenships = uniq(this.parties[0].personal.identity.citizenships);
    }

    setContactInformation(contactInformation: ContactInformation) {

        // set contact information details and set them in respective fields
        // for other values, keep dafaults

        this.parties[0].personal.residence = {
            primaryAddress: {
                streetAddress: contactInformation.streetNumberName,
                postalCode: this.processPostalCode(contactInformation.postalCode),
                city: contactInformation.city,
                province: contactInformation.province,
                country: '100000',
                unitNumber: contactInformation.unit
            },
            previousAddress: {},
            primaryPhone: {
                phoneNumber: ''
                // phoneExtension : '' // phoneExtension is added when user adds phone extension
            },
            // secondaryPhone: {},

            secondaryPhone: {
                phoneNumber: '',
                mobile: false, // Added Mobile  as Adviced  by CG team
                phoneExtension: null // phoneExtension is added when user adds phone extension
            },
            alternateAddress: {},  // alternate address will be added conditionally
            hasAlternateAddress: contactInformation.diffAddressRequired // set this value
        };

        if (contactInformation.trustedContactPerson.isTrustedPerson !== null) {
            this.parties[0].trustedContactPersonRequired = contactInformation.trustedContactPerson.isTrustedPerson;
            if (contactInformation.trustedContactPerson.isTrustedPerson) {
                this.setTrustedContactPersonInformation(contactInformation);
            } else {

                this.checkAndDeleteTcpParty(this.parties[0].trustedPartyRefId)

            }

        }

        if (contactInformation.diffAddressRequired === true) {
            // if different address is not specified by User then don't populate alternate address at all.
            this.parties[0].personal.residence.alternateAddress = {
                streetAddress: contactInformation.diffStreetNumberName,
                postalCode: this.processPostalCode(contactInformation.diffPostalCode),
                city: contactInformation.diffCity,
                province: contactInformation.diffProvince,
                country: '100000',
                unitNumber: contactInformation.diffUnit
                // unit: contactInformation.diffUnit
            };
        }
        const isDataRationalizationEnabled = Utils.getCmsMessageObject()['isDataRationalizationEnabled'];

        if (isDataRationalizationEnabled === false) {
            if (contactInformation.phoneType === 'contactInformation.labels.phoneHome') {
                //  delete this.parties[0].personal.residence.secondaryPhone.mobile;
                delete this.parties[0].personal.residence.secondaryPhone.phoneNumber;
                this.parties[0].personal.residence.primaryPhone.phoneNumber = this.processPhoneNumber(contactInformation.phoneNumber);

            } else if (contactInformation.phoneType === 'contactInformation.labels.phoneMobile') {
                // delete this.parties[0].personal.residence.primaryPhone.phoneNumber;
                this.parties[0].personal.residence.secondaryPhone.phoneNumber = this.processPhoneNumber(contactInformation.phoneNumber);
                this.parties[0].personal.residence.secondaryPhone.mobile = true; // Added Mobile true as Adviced by CG team

            } else if (contactInformation.phoneType === 'contactInformation.labels.phoneWork') {
                this.parties[0].personal.employment.primaryBusinessPhone.phoneNumber = this.processPhoneNumber(contactInformation.phoneNumber);
                this.parties[0].personal.employment.primaryBusinessPhone.phoneExtension = contactInformation.phoneExtension;
            }
        } else if (isDataRationalizationEnabled === true) { //MWB-14413 MWB-14412 : Removed Phone Type
            this.parties[0].personal.residence.secondaryPhone.phoneNumber = this.processPhoneNumber(contactInformation.phoneNumber);
            this.parties[0].personal.residence.secondaryPhone.phoneExtension = contactInformation.phoneExtension ? contactInformation.phoneExtension : null;
        }

    }

    checkAndDeleteTcpParty(tcpPartyRefId) {
        let updatedPartyList = this.parties.filter(party => party.trustedPartyRefId == tcpPartyRefId);
        this.parties = []
        updatedPartyList.forEach(party => {
            this.parties.push(party);
        });
        this.parties[0].trustedPartyRefId = null;
        //this.parties.pushupdatedPartyList;
    }

    setEmploymentInformation(employmentInformation, contactInformation) {

        // set employment information details and set them in respective fields
        // for other values, keep dafaults

        // if the employee is not presently employed then employmentAddres should be null object

        // Utils.log('occumepation' + employmentInformation.selectOccupation);

        if (employmentInformation.selectOccupation !== null) {

            this.parties[0].personal.employment = {
                bmoGroup: null,
                employerBusinessName: employmentInformation.employerName,
                employmentStatus: employmentInformation.employmentStatus,
                isBmoEmployee: false,  // discuss this wih gurpreet
                jobTitle: employmentInformation.jobTitle,
                natureOfBusiness: employmentInformation.selectNatureOfBusiness,
                occupation: employmentInformation.selectOccupation,
                primaryBusinessPhone: {
                    phoneNumber: '',
                    phoneExtension: ''
                },
                employmentAddress: {
                    city: employmentInformation.city,
                    country: employmentInformation.selectCountry,
                    postalCode: this.processPostalCode(employmentInformation.postalCode),
                    province: employmentInformation.province,
                    streetAddress: employmentInformation.streetNumberName,
                    unitNumber: employmentInformation.unit
                }
            };
            if (this.parties[0].personal.employment.employmentAddress.country != '100000' &&
                this.parties[0].personal.employment.employmentAddress.country != '100001') {
                delete this.parties[0].personal.employment.employmentAddress.postalCode;
                delete this.parties[0].personal.employment.employmentAddress.province;
            }

            if (employmentInformation.zipCode !== null && employmentInformation.zipCode !== '') {
                this.parties[0].personal.employment.employmentAddress.postalCode = this.processPostalCode(employmentInformation.zipCode);
            }
            // If USA add states and postal code
            if (this.parties[0].personal.employment.employmentAddress.country === COUNTRY_CODE.USA && employmentInformation.state !== null && employmentInformation.state !== undefined) {
                this.parties[0].personal.employment.employmentAddress.province = employmentInformation.state;
            }
            if (this.parties[0].personal.employment.employmentAddress.country === COUNTRY_CODE.USA && employmentInformation.zipCode !== null && employmentInformation.zipCode !== undefined && employmentInformation.zipCode !== '') {
                this.parties[0].personal.employment.employmentAddress.postalCode = this.processPostalCode(employmentInformation.zipCode);
            }

        } else {

            this.parties[0].personal.employment = {
                bmoGroup: null,
                employerBusinessName: employmentInformation.employerName,
                employmentStatus: employmentInformation.employmentStatus,
                isBmoEmployee: false,  // discuss this wih gurpreet
                jobTitle: employmentInformation.jobTitle,
                natureOfBusiness: employmentInformation.selectNatureOfBusiness,
                occupation: employmentInformation.selectOccupation,
                primaryBusinessPhone: {
                    phoneNumber: '',
                    phoneExtension: ''
                },
                // employmentAddress: {} changed for MWB-4583
                employmentAddress: {
                    city: '',
                    country: '',
                    postalCode: '',
                    province: '',
                    streetAddress: '',
                    unitNumber: ''
                }
            };
            delete this.parties[0].personal.employment.employmentAddress.city;
            delete this.parties[0].personal.employment.employmentAddress.country;
            delete this.parties[0].personal.employment.employmentAddress.postalCode;
            delete this.parties[0].personal.employment.employmentAddress.province;
            delete this.parties[0].personal.employment.employmentAddress.streetAddress;
            delete this.parties[0].personal.employment.employmentAddress.unitNumber;

        }
        if (contactInformation.phoneType === 'contactInformation.labels.phoneWork') {
            this.parties[0].personal.employment.primaryBusinessPhone.phoneNumber = this.processPhoneNumber(contactInformation.phoneNumber);
            this.parties[0].personal.employment.primaryBusinessPhone.phoneExtension = contactInformation.phoneExtension;
        }
        // If EMP Status is  RETIRED map values for natureOfBusiness and occupation
        if (employmentInformation.employmentStatus === EMPLOYMENTSTATUS.RETIRED) {
            this.parties[0].personal.employment.natureOfBusiness = employmentInformation.selectNatureOfBusiness;
            this.parties[0].personal.employment.occupation = employmentInformation.selectPreviousOccupation;
        }
        // If User is BMO employee add bmoGroup to the payload
        if (employmentInformation.bmoGroupOption) {
            this.parties[0].personal.employment.bmoGroup = employmentInformation.bmoGroupOption;
        } else {
            delete this.parties[0].personal.employment.bmoGroup;
        }
    }

    processAnnualEmploymentIncome(annualEmploymentIncome): number {  // AnnualEmploymentIncome should be in number
        if (annualEmploymentIncome !== undefined) {
            annualEmploymentIncome = annualEmploymentIncome.replace(/[^0-9\.]/g, '');
        }
        return annualEmploymentIncome;
    }

    setFinancialInformation(financialInformation: FinancialInformation) {
        // there is no equivalent for grossAnnualIncome or netWorth in the financialInformation model in this.parties
        // mapped as follows:
        this.parties[0].financial.status.income.annualEmploymentIncome = financialInformation.grossAnnualIncome;
        this.accounts[0].investmentObjectives.annualIncReq = financialInformation.annualIncReq;
        this.parties[0].financial.status.netWorth.fixedAssets = financialInformation.netWorth;
        this.parties[0].financial.status.netWorth.liquidAssets = financialInformation.netLiquidAssets;
        this.parties[0].financial.other.otherSourceOfWealth = financialInformation.otherSourceOfWealth;
        this.parties[0].financial.other.sourcesOfWealth = financialInformation.sourcesOfWealth;
        this.parties[0].financial.other.isBorrowingToInvest = financialInformation.borrowingMoney;
        this.accounts[0].investmentObjectives.investAssetPercentage = financialInformation.saveInvestibleAsset;
        this.parties[0].financial.status.netWorth.liquidAssets = financialInformation.netLiquidAssets;
        Utils.netWorth = financialInformation.netWorth; // setting this for GTM DataLayer
    }

    setTrustedContactPersonInformation(contactInfomation: ContactInformation) {
        let tcpParty = null;
        let primaryParty = this.parties[0];
        this.parties.forEach((party, index) => {
            if (primaryParty.trustedPartyRefId === party.partyRefId) {
                this.parties.splice(index, 1)
            }
        });
        const tcpPartyInformation = contactInfomation.trustedContactPerson;
        //right now we have added the trusted party only for primary applicant when joint need to add for joint we need update the code

        tcpParty = {
            partyRefId: Utils.getUUID(),
            trustedPartyRefId: primaryParty.partyRefId,
            trustedContactPersonRequired: null,
            password: null,
            relationToAccHolder: tcpPartyInformation.tcpRelationShip,
            spousePartyRefId: null,
            roles: [
                // keep this value empty
            ],
            personal: {
                identity: {

                    primaryEmailAddress: tcpPartyInformation.emailAddress || '',
                    socialInsuranceNumber: null,
                    dateOfBirth: null,
                    maritalStatus: '',
                    hasDependents: null,
                    preferredLanguage: null,
                    hasPreferredName: null,
                    legalName: {
                        title: '',
                        firstName: tcpPartyInformation.firstname,
                        middleName: tcpPartyInformation.initial,
                        lastName: tcpPartyInformation.lastname
                    },
                    preferredName: {
                        // firstName: 'Garry',
                        // lastName: 'Sandhu'
                    },
                    citizenships: [
                        ''
                    ]
                },
                residence: {
                    primaryAddress: {
                        streetAddress: tcpPartyInformation.streetNumberName,
                        postalCode: tcpPartyInformation.postalCode,
                        city: tcpPartyInformation.city,
                        province: tcpPartyInformation.province,
                        country: tcpPartyInformation.country,
                        unitNumber: tcpPartyInformation.unit
                    },
                    previousAddress: {},
                    primaryPhone: {
                        isInternationalNumber: tcpPartyInformation.isInternationalNumber,
                        phoneNumber: tcpPartyInformation.phoneNumberHome
                    },
                    secondaryPhone: {
                        isInternationalNumber: tcpPartyInformation.isInternationalNumber,
                        phoneNumber: tcpPartyInformation.phoneNumberOther,
                        mobile: ''
                    },
                    alternateAddress: {},
                    hasAlternateAddress: null
                },

                employment: {
                    bmoGroup: null,
                    employerBusinessName: null,
                    employmentStatus: null,
                    isBmoEmployee: null,
                    jobTitle: null,
                    natureOfBusiness: null,
                    occupation: null,
                    primaryBusinessPhone: {
                        phoneNumber: '',
                        phoneExtension: ''
                    },  // work
                    employmentAddress: {
                        city: '',
                        country: '',
                        postalCode: '',
                        province: '',
                        streetAddress: '',
                        unitNumber: ''
                    }
                },
                hasTaxResidency: false,
                residencyForTax: [
                    {
                        province: null,
                        country: '',
                        taxIdentificationNumber: null,
                        taxIdentificationNumberMissingReason: null,
                        taxIdentificationNumberMissingReasonDescription: null,
                        haveNoTaxIdentificationNumber: null
                    }
                ]
            },
            financial: {
                status: {
                    income: {
                        annualEmploymentIncome: null,
                        annualInvestmentIncome: null,
                        annualOtherIncome: null,
                        otherIncomeSource: null,
                        otherIncomeSourceDescription: null
                    },
                    netWorth: {
                        liquidAssets: null,
                        fixedAssets: null
                    }
                },
                other: {
                    sourcesOfWealth: [],
                    otherSourceOfWealth: null,
                    otherInvestmentInstitution: '',
                    hasOtherInvestmentAccounts: null,
                    isBorrowingToInvest: null
                },
                investment: {
                    pastExperience: [],
                    investmentKnowledge: null,
                    ilInvestmentKnowledge: '',
                    altInvestmentExperience: null
                }
            },
            investmentObjectives: {
                objective: null,
                riskTolerance: {
                    low: null,
                    medium: null,
                    high: null
                },
                targetAllocation: {
                    cash: null,
                    fixedIncome: null,
                    equities: null
                }
            },
            preferences: {
                sharePersonalInfo: null,
                receiveDirectMarketingMaterials: null,
                discloseBeneficialOwner: null,
                receiveElectronicDelivery: null,
                isReceiveStatementByMail: null,
                isSetupAlternateMailings: null,
                isAccountStatements: null,
                isTradeConfirmations: null,
                alternateMailingAddresses: null,
                documentPreferenceOption: null,
                receiveSecurityHolderMaterials: null,
                iseTaxConsentProvided: null
            },
            bmoRelationship: {
                hasBMODebitCard: null,
                bmoAccountNumber: null,
                sendNewBMODebitCard: null,
                isApplicantExistingClient: null,
                bmoProduct: null,
                isApplicantExistingPBClient: null
            },
            taxation: {
                isIrsW8FormProvided: null,
                beneficialOwnerCountry: null,
                beneficialOwnerCountryReason: null,
                isIrsW9FormProvided: null,
                taxIdentificationNumber: null
            },
            regulatoryDisclosures: {
                iirocMember: null,
                iirocOrganization: null,
                haveInsiderRelationship: null,
                insiderRelationships: [],
                controlRelationships: [],
                isReportingInsider: null,
                reportingInsiderRelationships: [],
                significantShareholder: null,
                significantShareholdings: [],
                verification: {},
                controlRelationship: null,
                isPoliticallyExposedPerson: null,
                politicallyExposedPersonType: null,
                politicallyExposedPersonPosition: null
            }
        };
        this.parties[0].trustedPartyRefId = tcpParty.partyRefId;
        //this.parties[0].trustedContactPersonRequired =  tcpPartyInformation.isTrustedPerson || contactInfomation.;
        if (this.parties[0].trustedContactPersonRequired) {
            this.parties.push(tcpParty);
        }

    }

    setFamilyInformation(familyInformation: FamilyInformation) {

        uniquePartyRefId = Utils.getPartyRefId();

        if (!familyInformation.isMarried()) { // if user is not married(married or common-law) .. don't populate payload

            // if user is not married .. add one more party and fill in details');


            if ((this.parties || []).length >= 1) {
                // this.parties.splice(1);  // keep only one party i.e. main applicant and remove spouse details

                this.parties.forEach((party, index) => {
                    if (party.spousePartyRefId != null && party.roles[0] != "PRIMARY_APPLICANT") {
                        this.parties.splice(index, 1);  // keep only one party i.e. main applicant and remove spouse details
                    }
                });
            }
            if ((Utils.isMultiAccountFlow() || Utils.isBranchSDMultiAccountFlow()) && Utils.isSpousalRRSP()) {
                this.parties[0].personal.identity.maritalStatus = familyInformation.spousalMaritalStatus;
            } else {
                this.parties[0].personal.identity.maritalStatus = familyInformation.maritalStatus;
            }
            this.parties[0].spousePartyRefId = null;  // setting spousePartyRefId to null as there is no spouse


        } else {

            // if user is married(married or common-law) .. add one more party and fill in details

            // if user is married .. add one more party and fill in details');

            if ((Utils.isMultiAccountFlow() || Utils.isBranchSDMultiAccountFlow()) && Utils.isSpousalRRSP()) {
                this.parties[0].personal.identity.maritalStatus = familyInformation.spousalMaritalStatus;
            } else {
                this.parties[0].personal.identity.maritalStatus = familyInformation.maritalStatus;
            }
            if ((this.parties || []).length >= 1) {
                // this.parties.splice(1);  // keep only one party i.e. main applicant and remove spouse details

                this.parties.forEach((party, index) => {
                    if (party.spousePartyRefId != null && party.roles[0] != "PRIMARY_APPLICANT") {
                        this.parties.splice(index, 1);  // keep only valid party i.e. main applicant and remove spouse details
                    }
                });
            }
            // there should be only one spouse details
            const partyRefIdForSpouse = Utils.getUUID();

            this.accounts[0].hasSuccessorAnnuitant = familyInformation.hasSuccessorAnnuitant;

            // Utils.log('spouse party ref id ' + partyRefIdForSpouse);

            // setting primary applicant spousePartyRefId to newly generated partyRefIdForSpouse
            this.parties[0].spousePartyRefId = partyRefIdForSpouse;

            // changing to uppercase
            const spouseInitial = familyInformation.initial.toUpperCase();

            const party2 = {
                trustedPartyRefId: null,
                partyRefId: partyRefIdForSpouse,
                trustedContactPersonRequired: null,
                password: null,
                spousePartyRefId: uniquePartyRefId,  // same as primary applicant ID
                roles: [
                    // keep this value empty
                ],
                personal: {
                    identity: {
                        primaryEmailAddress: '',
                        socialInsuranceNumber: this.processSIN(familyInformation.sinNumber),
                        // storing sin number of spouse in case of spousal RRSP account
                        dateOfBirth: familyInformation.spouseDateOfBirth,
                        maritalStatus: '',
                        hasDependents: familyInformation.dependents,
                        numOfDependents: familyInformation.dependentsCount,
                        preferredLanguage: '100',
                        hasPreferredName: null,
                        legalName: {
                            title: '100000~100004',
                            firstName: familyInformation.firstName,
                            middleName: spouseInitial,
                            lastName: familyInformation.lastName
                        },
                        preferredName: {
                            // firstName: 'Garry',
                            // lastName: 'Sandhu'
                        },
                        citizenships: [
                            '100000'
                        ]
                    },
                    residence: {
                        primaryAddress: {
                            streetAddress: '',
                            postalCode: '',
                            city: '',
                            province: '',
                            country: '',
                            unitNumber: ''
                        },
                        previousAddress: {},
                        primaryPhone: {
                            phoneNumber: ''
                        },
                        secondaryPhone: {
                            phoneNumber: '',
                            mobile: null,
                            phoneExtension: ''
                        },
                        alternateAddress: {},
                        hasAlternateAddress: null
                    },

                    employment: {
                        bmoGroup: familyInformation.bmoGroupOption,
                        employerBusinessName: familyInformation.employerName,
                        employmentStatus: familyInformation.employmentStatus,  // this is code number sent as string e.g '10001'
                        isBmoEmployee: familyInformation.isBMOEmployee,  // boolean value true or false
                        jobTitle: familyInformation.jobTitle, // string value
                        natureOfBusiness: familyInformation.selectNatureOfBusiness, // this is code number sent as string e.g '10001'
                        occupation: familyInformation.selectOccupation, //  this is code number sent as string e.g '11'
                        primaryBusinessPhone: {
                            phoneNumber: '',
                            phoneExtension: ''
                        },  // work
                        employmentAddress: {
                            city: '',
                            country: '',
                            postalCode: '',
                            province: '',
                            streetAddress: '',
                            unitNumber: ''
                        }
                    },
                    hasTaxResidency: false,
                    hasCanadianTaxResidency: false,
                    hasUSTaxResidency: false,
                    residencyForTax: [
                        {
                            province: null,
                            country: '100000',
                            taxIdentificationNumber: null,
                            taxIdentificationNumberMissingReason: null,
                            taxIdentificationNumberMissingReasonDescription: null,
                            haveNoTaxIdentificationNumber: null
                        }
                    ]
                },
                financial: {
                    status: {
                        income: {
                            annualEmploymentIncome: null,
                            annualInvestmentIncome: null,
                            annualOtherIncome: null,
                            otherIncomeSource: null,
                            otherIncomeSourceDescription: null
                        },
                        netWorth: {
                            liquidAssets: null,
                            fixedAssets: null
                        }
                    },
                    other: {
                        sourcesOfWealth: [],
                        otherSourceOfWealth: null,
                        otherInvestmentInstitution: '',
                        hasOtherInvestmentAccounts: null,
                        isBorrowingToInvest: null
                    },
                    investment: {
                        pastExperience: [],
                        investmentKnowledge: null,
                        ilInvestmentKnowledge: '',
                        altInvestmentExperience: null
                    }
                },
                investmentObjectives: {
                    objective: null,
                    riskTolerance: {
                        low: null,
                        medium: null,
                        high: null
                    },
                    targetAllocation: {
                        cash: null,
                        fixedIncome: null,
                        equities: null
                    }
                },
                preferences: {
                    sharePersonalInfo: null,
                    receiveDirectMarketingMaterials: null,
                    discloseBeneficialOwner: null,
                    receiveElectronicDelivery: null,
                    isReceiveStatementByMail: null,
                    isSetupAlternateMailings: null,
                    isAccountStatements: null,
                    isTradeConfirmations: null,
                    alternateMailingAddresses: null,
                    documentPreferenceOption: null,
                    receiveSecurityHolderMaterials: null,
                    iseTaxConsentProvided: null
                },
                bmoRelationship: {
                    hasBMODebitCard: null,
                    bmoAccountNumber: null,
                    sendNewBMODebitCard: null,
                    isApplicantExistingClient: null,
                    bmoProduct: null,
                    isApplicantExistingPBClient: null,
                    consentToAccountLink: null
                },
                taxation: {
                    isIrsW8FormProvided: null,
                    beneficialOwnerCountry: null,
                    beneficialOwnerCountryReason: null,
                    isIrsW9FormProvided: null,
                    taxIdentificationNumber: null
                },
                regulatoryDisclosures: {
                    iirocMember: null,
                    iirocOrganization: null,
                    haveInsiderRelationship: null,
                    insiderRelationships: [],
                    controlRelationships: [],
                    isReportingInsider: null,
                    reportingInsiderRelationships: [],
                    significantShareholder: null,
                    significantShareholdings: [],
                    verification: {},
                    controlRelationship: null,
                    isPoliticallyExposedPerson: null,
                    politicallyExposedPersonType: null,
                    politicallyExposedPersonPosition: null
                },
                creditBureauConsent: null, //  as per CS guidance
                existingAccount: {
                    amlFlag: undefined // MWB-14172 & MWB-14173
                }
            };

            this.parties.push(party2);  // spouse details added

            if (familyInformation.isBMOEmployee) {
                this.parties[1].personal.employment.bmoGroup = familyInformation.bmoGroupOption;
            } else {
                delete this.parties[1].personal.employment.bmoGroup;
            }


        }
        this.parties[0].personal.identity.hasDependents = familyInformation.dependents;
        this.parties[0].personal.identity.numOfDependents = familyInformation.dependentsCount;
        // Utils.log('total parties ' + this.parties);
        // Utils.log('After adding spose details');
        // Utils.log(this.parties);
    }

    setBenificiaryInformation(beneficiaries: any) {

        // clear it out and add again
        this.accounts[0].beneficiaries = [];
        this.beneficiaries = [];

        if (this.accounts[0].hasBeneficiaries === true) {
            for (let i = 0; i < beneficiaries.length; i++) {

                const uniqueBeneficiaryRefId = Utils.getUUID();

                // add bene into accounts->bene
                this.accounts[0].beneficiaries.push(new BeneficiaryAccountObj());

                this.accounts[0].beneficiaries[i].beneficiaryRefId = uniqueBeneficiaryRefId;
                this.accounts[0].beneficiaries[i].entitlementPercentage = beneficiaries[i].entitlementPercentage;

                // add into beneficiary
                this.beneficiaries.push(new BeneficiaryPayloadObj());

                this.beneficiaries[i].beneficiaryRefId = uniqueBeneficiaryRefId;
                this.beneficiaries[i].name.firstName = beneficiaries[i].firstName;
                this.beneficiaries[i].name.lastName = beneficiaries[i].lastName;
                this.beneficiaries[i].relationshipToPlanHolder = beneficiaries[i].relationshipToPlanHolder;
                this.beneficiaries[i].dateOfBirth = beneficiaries[i].dateOfBirth;

            }

        }
    }

    setRegulatoryDisclosure(regulatoryDisclosures: RegulatoryDisclosure) {

        this.parties[0].regulatoryDisclosures.iirocMember
            = this.booleanRadioMapper(regulatoryDisclosures.relationShip); // Relationships radio
        for (let i in this.accounts) {
            // MWB-785 -- If memberFirm is other than BMO InvestorLine Inc. isProaccount is true
            if ((regulatoryDisclosures.memberFirm !== "BMO InvestorLine Inc." && regulatoryDisclosures.memberFirm !== "BMO Ligne d'action Inc.") && (this.parties[0].regulatoryDisclosures.iirocMember)) {
                // BMO InvestorLine Inc. is the value received from EmploymentInfoService So no constant used to validate
                this.accounts[i].isProAccount = true;
            } else {
                this.accounts[i].isProAccount = false;
            }

        }

        this.parties[0].regulatoryDisclosures.iirocOrganization = regulatoryDisclosures.memberFirm;

        this.parties[0].regulatoryDisclosures.haveInsiderRelationship =
            this.booleanRadioMapper(regulatoryDisclosures.insiderRelationship);   // insider relationships radio
        if (this.parties[0].regulatoryDisclosures.haveInsiderRelationship) {
            // Add Relationships
            this.parties[0].regulatoryDisclosures.insiderRelationships = regulatoryDisclosures.insiderRelationships;
            // Check for Spouse Reporting Insider
            this.parties[0].regulatoryDisclosures.isReportingInsider =
                this.booleanRadioMapper(regulatoryDisclosures.spouseInsiderRelationship);
            if (this.parties[0].regulatoryDisclosures.isReportingInsider) { // If Spouse true
                // Add Relationships
                this.parties[0].regulatoryDisclosures.reportingInsiderRelationships = regulatoryDisclosures.spouseInsiderRelationships;
                this.removeMapID(this.parties[0].regulatoryDisclosures.reportingInsiderRelationships);
            }
            this.removeMapID(this.parties[0].regulatoryDisclosures.insiderRelationships);
        } else {
            this.parties[0].regulatoryDisclosures.insiderRelationships = [];
        }
        this.parties[0].regulatoryDisclosures.significantShareholder =
            this.booleanRadioMapper(regulatoryDisclosures.shareholderRelationship); // Significant shareholder relationships Radio

        if (this.parties[0].regulatoryDisclosures.significantShareholder) {
            // Add Relationships
            this.parties[0].regulatoryDisclosures.significantShareholdings = regulatoryDisclosures.shareholderRelationships;
            this.removeMapID(this.parties[0].regulatoryDisclosures.significantShareholdings);
        }
        this.parties[0].regulatoryDisclosures.controlRelationship =
            this.booleanRadioMapper(regulatoryDisclosures.controlRelationship); // Control Relation ship Radio
        if (this.parties[0].regulatoryDisclosures.controlRelationship) {
            // Add Relationships
            this.parties[0].regulatoryDisclosures.controlRelationships = regulatoryDisclosures.controlRelationships;
            this.removeMapID(this.parties[0].regulatoryDisclosures.controlRelationships);
        }
        const isDataRationalization4Enabled = Utils.getCmsMessageObject() ? Utils.getCmsMessageObject()['isDataRationalization4Enabled'] : false;
        if (isDataRationalization4Enabled) {
            this.parties[0].regulatoryDisclosures.isPoliticallyExposedPerson = null;
            this.parties[0].regulatoryDisclosures.politicallyExposedPersonType = null;
            this.parties[0].regulatoryDisclosures.politicallyExposedPersonPosition = null;
        } else {
            this.parties[0].regulatoryDisclosures.isPoliticallyExposedPerson =
                this.booleanRadioMapper(regulatoryDisclosures.governmentalRelationship); // Politically exposed Radio

            if (this.parties[0].regulatoryDisclosures.isPoliticallyExposedPerson) {
                if (regulatoryDisclosures.governmentRelationPosition) {
                    this.parties[0].regulatoryDisclosures.politicallyExposedPersonType = regulatoryDisclosures.governmentRelationPosition.charAt(0);
                    if (this.parties[0].regulatoryDisclosures.politicallyExposedPersonType === 'D') { //  If Select Domestic  Position
                        this.parties[0].regulatoryDisclosures.politicallyExposedPersonPosition = regulatoryDisclosures.selectDomesticTitle;
                    } else { // Else Select Foregin Position
                        this.parties[0].regulatoryDisclosures.politicallyExposedPersonPosition = regulatoryDisclosures.selectForeignTitle;
                    }
                }
            } else {
                this.parties[0].regulatoryDisclosures.politicallyExposedPersonType = null;
                this.parties[0].regulatoryDisclosures.politicallyExposedPersonPosition = null;
            }
        }
        if (Utils.selectedMultiAccounts?.length > 0) {
            Utils.selectedMultiAccounts.forEach((accountType, i) => {
                if (accountType.id === 'CASH' || accountType.id.toLocaleUpperCase() === 'MARGIN') {
                    this.accounts[i].intendedUse = regulatoryDisclosures.intendedUseOfAccount;
                }
                else {
                    this.accounts[i].intendedUse = null;
                }
            })
        } else {
            if (regulatoryDisclosures.intendedUseOfAccount) {
                this.accounts[0].intendedUse = regulatoryDisclosures.intendedUseOfAccount;
            }
            else {
                this.accounts[0].intendedUse = null;
            }
        }
    }

    setConsents(consents: Consents) {

        this.parties[0].preferences.sharePersonalInfo = consents.personalInfo;

        this.parties[0].preferences.receiveDirectMarketingMaterials = consents.marketingPref;

        this.parties[0].preferences.discloseBeneficialOwner = consents.shareholder;

        this.parties[0].preferences.iseTaxConsentProvided = consents.taxDocuments === 'electronic' ? true : false;

        if (!consents.securityholder) {
            this.parties[0].preferences.receiveSecurityHolderMaterials = '2';
            this.parties[0].preferences.receiveElectronicDelivery = false;
        } else if (consents.securityholderOption === 'ProxiesAnualReports') {
            this.parties[0].preferences.receiveSecurityHolderMaterials = '1';
        } else if (consents.securityholderOption === 'Proxies') {
            this.parties[0].preferences.receiveSecurityHolderMaterials = '3';
        }
    }
    setBranchBMORelationship(isApplicantExistingClient) {
        this.parties[0].bmoRelationship.isApplicantExistingClient = isApplicantExistingClient;
    }
    setPrivateBankingClient(isApplicantExistingPBClient) {
        this.parties[0].bmoRelationship.isApplicantExistingPBClient = this.booleanRadioMapper(isApplicantExistingPBClient);
    }

    isAccountType(accountType: any) {
        let isAccount: boolean = false;
        //
        if (Utils.isMultiAccountFlow() || Utils.isBranchSDMultiAccountFlow()) {
            (Utils.selectedMultiAccounts || []).forEach(account => {

                if (account.type == accountType)
                    isAccount = true;

            })
        } else {
            return Utils.getSelectedAccountType() == accountType;
        }
        return isAccount;
    }

    setBMORelationship(relationBMO: BmoRelationship) {
        if (Utils.getIsOLBUser() || Utils.getIsJourneyUser()) {
            return;
        }

        if ((this.isAccountType(ACCOUNT_TYPE.RRSP) || this.isAccountType(ACCOUNT_TYPE.TFSA) || this.isAccountType(ACCOUNT_TYPE.SRRSP) || this.isAccountType(ACCOUNT_TYPE.RIF)) && !(this.isAccountType(ACCOUNT_TYPE.CASH) || this.isAccountType(ACCOUNT_TYPE.MARGIN))) {
            this.parties[0].bmoRelationship.isApplicantExistingClient = this.booleanRadioMapper(relationBMO.bmoRelationshipClient);
            // update relationBMO.bmoProduct to assign as string.
            this.parties[0].bmoRelationship.bmoProduct = relationBMO.bmoProduct;
            this.parties[0].bmoRelationship.bmoAccountNumber = relationBMO.accountNumber;
            delete this.parties[0].bmoRelationship.sendNewBMODebitCard;
            delete this.parties[0].bmoRelationship.hasBMODebitCard;
            delete this.parties[0].bmoRelationship.consentToAccountLink;
        } else {

            this.parties[0].bmoRelationship.hasBMODebitCard = this.booleanRadioMapper(relationBMO.hasBMODebitCard);
            this.parties[0].bmoRelationship.consentToAccountLink = relationBMO.consentToAccountLink;
            if (this.booleanRadioMapper(relationBMO.hasBMODebitCard)) {
                this.parties[0].bmoRelationship.bmoAccountNumber = relationBMO.debitCardNumber;
                this.parties[0].bmoRelationship.isApplicantExistingClient = true;
                this.parties[0].bmoRelationship.bmoProduct = '1';
                delete this.parties[0].bmoRelationship.sendNewBMODebitCard;
            } else {
                this.parties[0].bmoRelationship.sendNewBMODebitCard = this.booleanRadioMapper(relationBMO.newDebitCardYesNo);
                this.parties[0].bmoRelationship.isApplicantExistingClient = this.booleanRadioMapper(relationBMO.isApplicantExistingClient);
                if (this.booleanRadioMapper(relationBMO.isApplicantExistingClient)) {
                    this.parties[0].bmoRelationship.bmoProduct = relationBMO.bmoProductCode;
                } else {
                    delete this.parties[0].bmoRelationship.bmoProduct;
                }
                delete this.parties[0].bmoRelationship.bmoAccountNumber;
            }
            if (Utils.getIsBranchAssisted()) {
                this.setBranchBMORelationship(true);
            }
        }

        // updates payload to add
        /* if (this.isAccountType(ACCOUNT_TYPE.CASH)) {
            if(this.accounts.length > 1) {
                const cashIndex = this.accounts.findIndex(account => account.type == ACCOUNT_CODES.CASH);
                if(this.accounts[cashIndex]) {
                    this.accounts[cashIndex].usDollarAccountLinkFlag = this.booleanRadioMapper(relationBMO.accessUS);
                }
            } else {
                this.accounts[0].usDollarAccountLinkFlag = this.booleanRadioMapper(relationBMO.accessUS);  // MWB-10222
            }

        } */
    }

    setAccountPassword(accountPassword: AccountPassword) {
        // to do

        this.parties[0].password = accountPassword.password;
        if ((Utils.getIsOLBUser() || Utils.getIsJourneyUser() || Utils.getIsIlUser()) && (Utils.isCash() === true || Utils.isMargin() === true)) {
            this.parties[0].bmoRelationship.sendNewBMODebitCard = this.booleanRadioMapper(accountPassword.newDebitCardYesNo);
            if (this.parties[0].bmoRelationship.sendNewBMODebitCard === true || this.parties[0].bmoRelationship.sendNewBMODebitCard === false) {
                this.parties[0].bmoRelationship.hasBMODebitCard = false;
            } else {
                this.parties[0].bmoRelationship.hasBMODebitCard = this.booleanRadioMapper(accountPassword.hasBMODebitCard);
                if (this.parties[0].bmoRelationship.hasBMODebitCard === false) {
                    this.parties[0].bmoRelationship.sendNewBMODebitCard = false;
                }
            }
            this.parties[0].bmoRelationship.bmoAccountNumber = null;
            if (this.parties[0].bmoRelationship.hasBMODebitCard === true) {
                this.parties[0].bmoRelationship.bmoAccountNumber = accountPassword.debitCardNumber;
            }
            this.parties[0].bmoRelationship.consentToAccountLink = accountPassword.consentToAccountLink;
        }
    }


    setSpousalRRSP(familyInformation: FamilyInformation) {

        this.parties[0].personal.identity.maritalStatus = familyInformation.spousalMaritalStatus;
        if (Utils.selectedMultiAccounts && (Utils.isMultiAccountFlow() || Utils.isBranchSDMultiAccountFlow())) {
            Utils.selectedMultiAccounts.forEach((accountType, i) => {
                if (accountType.type === ACCOUNT_TYPE.SRRSP) {
                    this.accounts[i].contributor = {
                        name: {
                            firstName: familyInformation.firstName,
                            lastName: familyInformation.lastName
                        },
                        socialInsuranceNumber: this.processSIN(familyInformation.sinNumber),
                        dateOfBirth: '1984-05-13' // need to discuss UI does not accept it
                    };
                }
            })
        }
        else {
            this.accounts[0].contributor = {
                name: {
                    firstName: familyInformation.firstName,
                    lastName: familyInformation.lastName
                },
                socialInsuranceNumber: this.processSIN(familyInformation.sinNumber),
                dateOfBirth: '1984-05-13' // need to discuss UI does not accept it
            };
        }

    }

    setInvestmentKnowledge(investmentKnowledge: InvestmentKnowledge) {
        this.getPrimaryApplicant().financial.investment.ilInvestmentKnowledge = investmentKnowledge.ilInvestmentKnowledge;
        this.getPrimaryApplicant().financial.investment.pastExperience = (investmentKnowledge.ilInvestmentKnowledge != INVESTMENT_KNOWLEDGE_TYPES.NONE) ? investmentKnowledge.pastExperience : [];
        this.getPrimaryAccount().ilInvestmentTimeHorizon = investmentKnowledge.ilInvestmentTimeHorizon;
        this.getPrimaryAccount().investmentTimeHorizonForWithDraw = investmentKnowledge.investmentTimeHorizonForWithDraw;
    }

    setInvestmentObjectives(accountInvestmentObjectives: AccountInvestmentObjectives) {
        this.getPrimaryAccount().accountInvestmentObjectives.income = accountInvestmentObjectives.income;
        this.getPrimaryAccount().accountInvestmentObjectives.shortTerm = accountInvestmentObjectives.shortTerm;
        this.getPrimaryAccount().accountInvestmentObjectives.mediumTerm = accountInvestmentObjectives.mediumTerm;
        this.getPrimaryAccount().accountInvestmentObjectives.longTerm = accountInvestmentObjectives.longTerm;
    }
    setRiskAndReturn(riskAndReturn: RiskAndReturn) {
        //Integration Done as per SubTask WMPWO-28538
        this.getPrimaryAccount().investmentObjectives.riskToleranceQ1 = riskAndReturn.riskTolerance;
        this.getPrimaryAccount().investmentObjectives.riskToleranceQ2 = riskAndReturn.riskReturnInvestment;

    }

    setInvestorProfile(investorProfile: InvestorProfile) {
        this.getPrimaryAccount().investmentObjectives.ilRiskTolerance = parseInt(investorProfile.ilRiskTolerance, 10);
        this.getPrimaryAccount().investmentObjectives.objective = investorProfile.objective;

        // convert 'riskLevelAcceptedForAgeSeventy' to boolean
        const isBoolean = typeof investorProfile.riskLevelAcceptedForAgeSeventy === 'boolean';

        if (!isBoolean) { // direct-save from investor-profile screen
            if (investorProfile.riskLevelAcceptedForAgeSeventy === 'true') {
                this.getPrimaryAccount().investmentObjectives.riskLevelAcceptedForAgeSeventy = true;
            } else if (investorProfile.riskLevelAcceptedForAgeSeventy === 'false') {
                this.getPrimaryAccount().investmentObjectives.riskLevelAcceptedForAgeSeventy = false;
            }
        } else { // on reverse-mapping
            this.getPrimaryAccount().investmentObjectives.riskLevelAcceptedForAgeSeventy = investorProfile.riskLevelAcceptedForAgeSeventy;
        }
    }

    setBMOReferral(selectAccountObj: SelectAccount) {
        this.branchReferral.isBMOReferral = this.booleanRadioMapper(selectAccountObj.isBmoStaff);
        if (!this.booleanRadioMapper(selectAccountObj.isBmoStaff)) {
            this.branchReferral.designationCode = null
        } else {
            this.branchReferral.designationCode = selectAccountObj.BmoStaffReferrer;
        }
        this.branchReferral.isAdditionalReferral = this.booleanRadioMapper(selectAccountObj.isAdditionalReferral);
        this.branchReferral.ein = selectAccountObj.EIN;
        this.branchReferral.fpCode = selectAccountObj.referralCode;
        this.branchReferral.transit = selectAccountObj.transit;
    }

    setBranchReferral(branchReferralObj: BranchReferral) {
        this.branchReferral.isBranchReferral = Utils.getIsBranchReferral();
        this.branchReferral.designationCode = branchReferralObj.branchPersonnel;
        this.branchReferral.fpName = branchReferralObj.fpName;
        this.branchReferral.fsmName = branchReferralObj.fsmName;
        this.branchReferral.ein = branchReferralObj.EIN;
        this.branchReferral.fpPhone = branchReferralObj.fpPhone;
        this.branchReferral.fsmPhone = branchReferralObj.fsmPhone;

        this.branchReferral.transit = branchReferralObj.transit;
        this.branchReferral.fpCode = branchReferralObj.FPCode;

        // add sub-product
        this.applicationSubProduct = Utils.isSubProductAD() ? 'AD' : 'SD';
    }
    setIsApplicantExistingPBClient(value) {
        this.isApplicantExistingPBClient = value;
    }
    setOlbUser(value) {
        this.olbuser = value;
    }
    setIlUser(value) {
        this.ilUser = value;
    }
    getPrimaryAccount() {
        return this.accounts[0];
    }
    getPrimaryApplicant() {
        return this.parties[0];
    }
    /**
     * @description: Mapping boolean Values to radio button
     * @param: formElement
     * @return: boolean
     */
    booleanRadioMapper(formElement) {
        if ((formElement === 'Yes') || (formElement === 'YES') || (formElement === true)) {
            return true;
        } else if ((formElement === 'No') || (formElement === 'NO') || (formElement === false)) {
            return false;
        } else {
            return null;
        }
    }

    /**
     * @description: Remove map id from pay load in reg disclosure of party service. Weird but no option
     * @param: formElement
     * @return: boolean
     */
    removeMapID(arr) {
        arr.forEach(element => {
            if ('mapId' in element) {
                delete element.mapId;
            }
        });
        return;
    }

    /* setExpectation object to hold screener questions responses */

    screenerQuestions = {
        isBmoRightForMe: '',
        investmentServiceOptionCode: '',
        adviceRequiredOptionCode: ''
    }

    setScreenerQuestionsData(obj) {
        this.screenerQuestions = obj;
    }

    setClientAccessID(clientAccessId) {
        this['clientAccessId'] = clientAccessId;
    }
}


