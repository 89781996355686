import { Directive, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';

/* Author Dilraj 2018
  <input #ctrl1>
  <input #ctrl2 [appEqualTo]=ctrl >
 * Checks if the control value of the first input field (ctrl1.value) matches the control value of the second one (ctrl2.value).
 * Any changes made to the first control value (ctrl1) will be automatically checked against ctrl2.value. 
*/

const EQUAL_TO_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ConfirmEqualValidatorDirective),
  multi: true
};

@Directive({
  selector: '[appEqualTo][formControlName],[appEqualTo][formControl],[appEqualTo][ngModel]',
  providers: [EQUAL_TO_VALIDATOR]
})
export class ConfirmEqualValidatorDirective implements Validator, OnInit {
  @Input() appEqualTo: FormControl;

  private validator: ValidatorFn;

  ngOnInit() {
    this.validator = equalTo(this.appEqualTo);
  }

  validate(c: AbstractControl): {[key: string]: any} {
    return this.validator(c);
  }
}

export const equalTo = (equalControl: AbstractControl): ValidatorFn => {
  let subscribe = false;

  return (control: AbstractControl): {[key: string]: boolean} => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }

    const v: string = control.value;

    if (!equalControl) {
      return {equalTo: true};
    }

    return equalControl.value === v ? null : {equalTo: true};
  };
};
