import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnChanges } from '@angular/core';

import { FormGroup, ControlContainer, NgForm } from '@angular/forms';
import { MIN_DEPENDENTS, MAX_DEPENDENTS, BACKSPACE } from  '../../../config/app-config.constants';


const noop = () => { };
@Component({
  selector: 'app-number-increment',
  templateUrl: './number-increment.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class NumberIncrementComponent implements OnInit, OnChanges {
  public onChangeCallback: (_: any) => void = noop;
  public onTouchedCallback: () => void = noop;
  @Input() name: string;
  @Input() title: string;
  @Input() dependency: any;
  @Input() form: FormGroup;
  @Input() section: string;
  @Input() model: any;
  @Input() maxLength:any;
  @Input() minLength:any;
  @Input() minValue:any;
  @Input() maxValue:any;
  @Output() incrementModelChange: EventEmitter<any> = new EventEmitter<any>();

  public incrementModel: any;
  
  
  constructor() { }
  ngOnInit() {
    if (!this.model) {
      this.incrementModel = 1;
    } else {
      this.incrementModel = this.model;
    }
    this.valChange();
  }

  ngOnChanges() {
    if (this.model) {
      this.incrementModel = this.model;
    }
  }
  decrement() {
    this.incrementModel--;
    if (this.incrementModel < MIN_DEPENDENTS) {
      this.incrementModel = null;
    }
    this.incrementModelChange.emit(this.incrementModel);
  }
  increment() {
    this.incrementModel++;
    if (this.incrementModel > MAX_DEPENDENTS) {
      this.incrementModel = MAX_DEPENDENTS;
    }
    this.incrementModelChange.emit(this.incrementModel);
  }

  valChange() {
    if (this.incrementModel === '' || this.incrementModel === null || this.incrementModel === undefined ) {
      this.incrementModel = MIN_DEPENDENTS;
    }
  }

  eventHandler(e) {
    const key = e.keyCode;
    if (key === BACKSPACE) {
      if (this.incrementModel > MIN_DEPENDENTS) {
      } else {
        this.incrementModel = MIN_DEPENDENTS;
      }
    }
    if (!(parseInt(e.target.value)>=MIN_DEPENDENTS && parseInt(e.target.value)<=MAX_DEPENDENTS))
    {
      this.incrementModel = MIN_DEPENDENTS;

    }
    this.incrementModelChange.emit(this.incrementModel);
  }




}

