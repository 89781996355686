import {
    ReactiveFormsModule,
    NG_VALIDATORS,
    FormsModule,
    FormGroup,
    FormControl,
    ValidatorFn,
    Validator, AbstractControl
} from '@angular/forms';

import { Directive } from '@angular/core';

@Directive({
    selector: '[appSinValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: SinValidator,
            multi: true
        }
    ]
})
export class SinValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = this.sinValidator();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    sinValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if ( control.value == null ) {
                return;
            }

            let sin = control.value.replace(/[-\s.]/g, '');
            let check, even, tot;

            if (typeof sin === 'number') {
                sin = sin.toString();
            }

            if (sin.length === 9) {
                if (sin === '000000000') {
                    return {
                        sinNumber: {
                            invalidSinNumber: true
                        }
                    };
                }
                // convert to an array & pop off the check digit
                sin = sin.split('');
                check = +sin.pop();

                even = sin
                // take the digits at the even indices
                    .filter(function (_, i) {
                        return i % 2;
                    })
                    // multiply them by two
                    .map(function (n) {
                        return n * 2;
                    })
                    // and split them into individual digits
                    .join('').split('');

                tot = sin
                // take the digits at the odd indices
                    .filter(function (_, i) {
                        return !(i % 2);
                    })
                    // concatenate them with the transformed numbers above
                    .concat(even)
                    // it's currently an array of strings; we want numbers
                    .map(function (n) {
                        return +n;
                    })
                    // and take the sum
                    .reduce(function (acc, cur) {
                        return acc + cur;
                    });

                // compare the result against the check digit
                const val = check === (10 - (tot % 10)) % 10;

                if (val) {
                    return null;
                }
            }

            return {
                sinNumber: {
                    invalidSinNumber: false
                }
            };

        };
    }
}
