<!-- <app-flood-light></app-flood-light> -->

<!--<div class="form-row form-page-buttons content-center">
   <button class="primary element-center" type="button" (click)="onResumeApplication()">ResumeApplication</button>
</div>-->

<form #selectAccountForm="ngForm" (ngSubmit)="onSubmit()" novalidate >

    <app-critical-error *ngIf="systemCriticalError"></app-critical-error>
    <div class="container-menuView" id="accountSelectSection">

        <div role="alert" class="alert alert-danger" *ngIf="(isFormSubmit && selectAccountForm.invalid && formErrorChanges) || criticalError">
            <p>
                <strong translate="selectAccount.errors.criticalError"></strong><br />
                <span translate="selectAccount.errors.criticalErrorDescription"></span>
            </p>
        </div>

        <section id="accountSelectSectionOne">

            <div class="alert alert-info" role="alert" *ngIf="isFiveStarProgramEligible">
                <strong translate="selectAccount.olbAuthenticated.fiveStarProgramTitle"></strong><br />
                <p [innerHTML]="'selectAccount.olbAuthenticated.fiveStarProgramDescription' | translate | safeHtml"></p>
            </div>

            <div id="accountSelectSectionOneDescription">
                <h1 *ngIf="!isCdbOlbUser && !isJourneyUser && ((isSubProductAD || branchAssisted || !isMultiAccountOpen) && !branchSDMultiAccount)" translate="selectAccount.accountTitle"></h1>
                <h1 *ngIf="!isCdbOlbUser && !isJourneyUser &&((!isSubProductAD && !branchAssisted && isMultiAccountOpen) || branchSDMultiAccount)" translate="selectAccount.accountTitle02"></h1>


                <h1 *ngIf="isCdbOlbUser || isJourneyUser">
                    <span translate="selectAccount.olbAuthenticated.title01"></span>
                    <span class="word-break">{{ cdbOlbUserFirstName }}</span>
                    <span *ngIf= "(isSubProductAD || branchAssisted || !isMultiAccountOpen) && !branchSDMultiAccount" translate="selectAccount.olbAuthenticated.title02"></span>
                    <span *ngIf= "(!isSubProductAD && !branchAssisted && isMultiAccountOpen)  || branchSDMultiAccount" translate="selectAccount.olbAuthenticated.title03"></span>
                </h1>

                <p *ngIf= "((isSubProductAD || branchAssisted || !isMultiAccountOpen) && !branchSDMultiAccount)" id="ariaSelectAccount" [innerHTML]="'selectAccount.descriptionPage01' | translate"></p>
                <p *ngIf="((!isSubProductAD && !branchAssisted && isMultiAccountOpen) || branchSDMultiAccount)" id="ariaSelectAccount" [innerHTML]="'selectAccount.descriptionPage02' | translate"></p>
            </div>

            <div class="form-wrapper add-margin t24px">
                <div role="group" aria-labelledby="ariaSelectAccount" class="form-row" id='parent-accountSelect-error'>

                    <ng-container *ngFor="let account of accountTypes; let idx = index">
                        <div *ngIf="((!isSubProductAD && !branchAssisted && isMultiAccountOpen) || branchSDMultiAccount) && !(account.type === 'other')"
                             class="flex-33 form-radio-checks selection-card flow-card"
                             [ngClass]="{'error-field': isAccountTypeNotSelected && isFormSubmit}"
                             [id]="'selectAccount'+account.id">


                             <input *ngIf="account.type !== cashMargin.type" [id]="account.type"
                                   [attr.data-ana]="'Investorline:wealth:onboarding:'+account.id"
                                   type="checkbox"
                                   [name]="account.type"
                                   [appErrorAccess]="{ id: 'accountSelect-error', parentId: 'parent-accountSelect-error', submitted: selectAccountForm.submitted }"
                                   [value]="account.type"
                                   [(ngModel)]="account.isChecked"
                                   (change)="onMultiAccountTypeSelectionChange(account, $event.target.checked)"
                                   [required]="selectAccount.selectedAccountTypes.length <= 0"
                                   />

                                   <input *ngIf="account.type === cashMargin.type" [id]="account.type"
                                   [attr.data-ana]="'Investorline:wealth:onboarding:'+account.id"
                                   type="checkbox"
                                   [name]="account.type"
                                   [appErrorAccess]="{ id: 'accountSelect-error', parentId: 'parent-accountSelect-error', submitted: selectAccountForm.submitted }"
                                   [value]="account.type"
                                   [(ngModel)]="account.isChecked"
                                  (click)="onMarginOrCashTile($event.target.checked)"
                                  [required]="isAccountTypeNotSelected"
                                  [attr.aria-label]="(selectAccount.selectedAccountTypes.includes(CASH_CODE) && account.isChecked == true ? 'selectAccount.ariaLabels.cashAccountRemove' :  selectAccount.selectedAccountTypes.includes(MARGIN_CODE) && account.isChecked == true ? 'selectAccount.ariaLabels.marginAccountRemove' : 'selectAccount.ariaLabels.selectCashMarginModal' ) | translate" />

                            <label [ngClass]="{'disabled': disableAccounts, 'other':account.type === 'other'}" class="large-card content-left" [for]="account.type">
                                <span class="check"></span>
                                <span class="one-col justify-space-between">
                                    <span>
                                        <span *ngIf="account.type !== cashMargin.type" class="title no-margin-title" [innerHTML]="account.title | translate | safeHtml"></span>
                                        <span *ngIf="isSubProductAD && account.type === cashMargin.type" class="title no-margin-title" [innerHTML]="(selectAccount.selectedAccount == CASH_CODE ? 'selectAccount.accounts.CASHTitle' : selectAccount.selectedAccount == MARGIN_CODE ? 'selectAccount.accounts.MARGINTitle' : account.title ) | translate | safeHtml"></span>
                                        <span *ngIf="account.type === cashMargin.type" class="title no-margin-title" [innerHTML]="(selectAccount.selectedAccountTypes.includes(CASH_CODE) && account.isChecked == true? 'selectAccount.accounts.CASHTitle' : selectAccount.selectedAccountTypes.includes(MARGIN_CODE) && account.isChecked == true ? 'selectAccount.accounts.MARGINTitle' : account.title ) | translate | safeHtml"></span>
                                        <!-- <span class="description" *ngIf="account.type === 'other'"
                                        [innerHTML]="account.description | translate | safeHtml"></span>
                                         <span *ngIf="account.type === 'other'"><a class="text-transform-uppercase" (click)="showBmoOtherAccounts(true)" (keyup.enter)="showBmoOtherAccounts(true)" href="javascript://" translate="linkButton.learnMore" attr.aria-label="{{ 'selectAccount.ariaLabels.learnPopUp' | translate }}"></a></span> -->
                                    </span>
                                    <!-- MWB-15571 -->
                                    <span aria-hidden="true">
                                        <span class="color-code blue-light" [ngClass]="{'disabled': disableAccounts && !account.isChecked}"></span>
                                        <span class="footer" [ngClass]="[!account.isChecked && !disableAccounts ? 'card-icon' : '', account.isChecked && !disableAccounts ? 'card-icon' : '', !account.isChecked && disableAccounts ? 'disabled' : '']">
                                            <span *ngIf="account.type !== cashMargin.type && !disableAccounts" class="icon icon-medium" [ngClass]="{'plus-circle-blue': !account.isChecked, 'minus-circle-blue':  account.isChecked}"></span>
                                            <span *ngIf="account.type !== cashMargin.type && !disableAccounts" class="btn-icon-text" translate="{{(account.isChecked) ? 'selectAccount.labels.remove' : 'selectAccount.labels.add'}}"></span>
                                            <span *ngIf="account.type !== cashMargin.type && disableAccounts" class="btn-icon-text" translate="{{(account.isChecked) ? 'selectAccount.labels.selected' : 'selectAccount.labels.notSelected'}}"></span>

                                            <!-- cash/margin modal -->
                                            <span *ngIf="account.type === cashMargin.type && !disableAccounts" class="icon icon-medium plus-circle-blue" [ngClass]="{'plus-circle-blue': !account.isChecked, 'minus-circle-blue':  account.isChecked}"></span>
                                            <span *ngIf="account.type === cashMargin.type && !disableAccounts" class="btn-icon-text" translate="{{(account.isChecked == true) ? 'selectAccount.labels.remove' : 'selectAccount.labels.add'}}"></span>
                                            <span *ngIf="account.type === cashMargin.type && disableAccounts" class="btn-icon-text" translate="{{(account.isChecked) ? 'selectAccount.labels.selected' : 'selectAccount.labels.notSelected'}}"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>

                        </div>
                    </ng-container>

                <ng-container *ngFor="let account of accountTypes; let idx = index">
                    <div *ngIf="(isSubProductAD || branchAssisted || !isMultiAccountOpen) && !branchSDMultiAccount && !(account.type === 'other')"
                         class="flex-33 form-radio-checks selection-card flow-card"
                         [ngClass]="{'error-field': selectAccountForm.invalid && isFormSubmit && !selectAccount.selectedAccount}"
                         [id]="'selectAccount'+account.id">


                         <input *ngIf="account.type !== cashMargin.type" [id]="account.type"
                               [attr.data-ana]="'Investorline:wealth:onboarding:'+account.id"
                               type="radio"
                               name="accountType"
                               [appErrorAccess]="{ id: 'accountSelect-error', parentId: 'parent-accountSelect-error', submitted: selectAccountForm.submitted }"
                               [value]="account.type"
                               [(ngModel)]="selectAccount.selectedAccount"
                               (change)="onSelectionChange(account, true)"
                               [checked]="account.type === selectAccount.selectedAccount" required/>

                               <input *ngIf="account.type === cashMargin.type" [id]="account.type"
                               [attr.data-ana]="'Investorline:wealth:onboarding:'+account.id"
                               type="radio"
                               name="accountType"
                               [appErrorAccess]="{ id: 'accountSelect-error', parentId: 'parent-accountSelect-error', submitted: selectAccountForm.submitted }"
                               [value]="account.type"
                              (click)="onMarginOrCashTile($event.target.checked)"
                               attr.aria-label="{{ 'selectAccount.ariaLabels.selectCashMarginModal' | translate }}"
                               [checked]="selectAccount.selectedAccount == CASH_CODE || selectAccount.selectedAccount == MARGIN_CODE"
                               required />

                        <label [ngClass]="{'disabled': disableAccounts, 'other':account.type === 'other'}" class="large-card content-left" [for]="account.type">
                            <span class="check"></span>
                            <span class="one-col justify-space-between">
                                <span>
                                    <span *ngIf="account.type !== cashMargin.type" class="title no-margin-title" [innerHTML]="account.title | translate | safeHtml"></span>
                                    <span *ngIf="account.type === cashMargin.type" class="title no-margin-title" [innerHTML]="(selectAccount.selectedAccount == CASH_CODE ? 'selectAccount.accounts.CASHTitle' : selectAccount.selectedAccount == MARGIN_CODE ? 'selectAccount.accounts.MARGINTitle' : account.title ) | translate | safeHtml"></span>
                                    <!-- <span class="description" *ngIf="account.type === 'other'"
                                    [innerHTML]="account.description | translate | safeHtml"></span>
                            <span *ngIf="account.type === 'other'"><a class="text-transform-uppercase" (click)="showBmoOtherAccounts(true)" (keyup.enter)="showBmoOtherAccounts(true)" href="javascript://" translate="linkButton.learnMore" attr.aria-label="{{ 'selectAccount.ariaLabels.learnPopUp' | translate }}"></a></span> -->
                                </span>
                                <span aria-hidden="true">
                                    <span class="color-code blue-light"></span>
                                    <span *ngIf="account.type !== cashMargin.type" class="footer" translate="{{(account.type === selectAccount.selectedAccount) ? 'selectAccount.labels.selected' : 'selectAccount.labels.select'}}"></span>
                                    <span *ngIf="account.type === cashMargin.type" class="footer" translate="{{(selectAccount.selectedAccount == CASH_CODE || selectAccount.selectedAccount == MARGIN_CODE) ? 'selectAccount.labels.changeAccountType' : 'selectAccount.labels.select'}}"></span>
                                </span>
                            </span>
                        </label>

                    </div>
                </ng-container>
                    <div class="flex-33 form-radio-checks selection-card flow-card">
                        <label class="large-card content-left other">
                            <span class="title" translate="selectAccount.accounts.otherTitle"></span>
                            <span class="description" translate="selectAccount.accounts.otherDescription">
                                <a href="javascript://"
                                   data-ana="investorline:wealth:onboarding:learn more"
                                   translate="linkButton.learnMore"
                                   attr.aria-label="{{ 'linkButton.ariaLabels.learnMore' | translate }}"
                                   (click)="showBmoOtherAccounts()"
                                   (keyup.enter)="showBmoOtherAccounts()"
                                   id="showBmoOtherAccountsID"
                                   role="button"></a>
                            </span>
                            <span>
                                <a class="text-transform-uppercase"
                                   href="javascript://"
                                   data-ana="investorline:wealth:onboarding:link to olap"
                                   (click)="takeMeThere()"
                                   translate="linkButton.takeMeThere"
                                   attr.aria-label="{{ 'linkButton.ariaLabels.takeMeThere' | translate }}"
                                   id="takeMeThereID"
                                   role="link"></a>
                            </span>

                        </label>
                    </div>

                    <div role="alert" id='accountSelect-error' class="error-message content-left"
                    *ngIf="isSubProductAD && selectAccountForm.invalid && isFormSubmit && !selectAccount.selectedAccount">
                   <span translate="selectAccount.errors.accountSelection"></span>
               </div>

                    <div role="alert" id='accountSelect-error' class="error-message content-left"
                         *ngIf="!isSubProductAD && selectAccountForm.submitted && selectAccount.selectedAccountTypes.length == 0">
                        <span translate="selectAccount.errors.accountSelection"></span>
                    </div>
                </div>
            </div>

            <div class="alert alert-info" role="alert" *ngIf="showRifInfoMessage">
                <p [innerHTML]="'selectAccount.spouseRRIFSetUp' | translate | safeHtml"></p>
            </div>

            <div id="accountSelectSectionTwo" class="more-info-wrapper content-left">

                <a role="button" id="accountSelectSectionTwoLearnMoreOpen"
                aria-controls="accountSelectSectionTwoControls"
                aria-expanded="false" data-ana="investorline:wealth:onboarding:learnMoreAccounts:open"
                class="btn-icon btn-more-info-show" href="javascript://"
                attr.aria-label="{{ 'selectAccount.ariaLabels.learnMoreAccounts' | translate }}">
                    <span class="btn-icon-text-left" translate="selectAccount.accounts.learnMoreAccounts" ></span>
                    <span class="icon icon-large chevron-down-blue"></span>
                </a>

                <div class="more-info-content" id="accountSelectSectionTwoControls">

                    <h2 [innerHTML]="'selectAccount.accounts.learnMoreAccounts' | translate | safeHtml"></h2>
                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle1' | translate | safeHtml" class="font-bold"></h3>

                    <p *ngIf="!isSubProductAD" [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes1SD' | translate | safeHtml"></p>
                    <p *ngIf="isSubProductAD" [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes1AD' | translate | safeHtml"></p>

                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle2' | translate | safeHtml" class="font-bold"></h3>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes2' | translate | safeHtml"></p>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes2Title2' | translate | safeHtml" class="font-bold"></p>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes2Des2' | translate | safeHtml"></p>

                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle3' | translate | safeHtml" class="font-bold"></h3>
                    <p *ngIf="!isSubProductAD" [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes3SD' | translate | safeHtml"></p>
                    <p *ngIf="isSubProductAD" [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes3AD' | translate | safeHtml"></p>

                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle4' | translate | safeHtml" class="font-bold"></h3>
                    <p *ngIf="!isSubProductAD" [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes4SD' | translate | safeHtml"></p>
                    <p *ngIf="isSubProductAD" [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes4AD' | translate | safeHtml"></p>


                    <div class="header-contextual-help">
                        <h3 class="display-inline font-bold" translate="selectAccount.accounts.learnMoreAccountsSubTitle5">
                        </h3>
                        <app-contextual-help class="button-wrapper va-middle"
                        arialabel="selectAccount.ariaLabels.rrifToolTip"
                        content="selectAccount.contextualHelp.learnMoreAccountsSubDes5a">
                    </app-contextual-help>
                    </div>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes5a' | translate | safeHtml"></p>

                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle6' | translate | safeHtml" class="font-bold"></h3>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes6a' | translate | safeHtml"></p>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes6b' | translate | safeHtml"></p>

                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle7' | translate | safeHtml" class="font-bold"></h3>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes7' | translate | safeHtml"></p>

                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle8' | translate | safeHtml" class="font-bold"></h3>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes8' | translate | safeHtml"></p>

                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle9' | translate | safeHtml" class="font-bold"></h3>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes9' | translate | safeHtml"></p>
                    <ul class="disc" [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubList9' | translate | safeHtml"></ul>

                    <h3 [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubTitle10' | translate | safeHtml" class="font-bold"></h3>
                    <p [innerHTML]="'selectAccount.accounts.learnMoreAccountsSubDes10' | translate | safeHtml"></p>

                    <p>
                        <a role="button"
                        id="accountSelectSectionTwoLearnMoreClose"
                        aria-controls="accountSelectSectionTwoControls"
                        aria-expanded="true" data-ana="investorline:wealth:onboarding:learnMoreAccounts:close"
                        class="btn-icon btn-more-info-close" href="javascript://"
                        attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                            <span class="btn-icon-text-left" translate="linkButton.close"></span>
                            <span class="icon icon-large chevron-up-blue"></span>
                        </a>
                    </p>

                </div>

            </div>

        </section>

        <section id="creditBureauTermsWrapper" class="bmo-card-wrapper margin-account" *ngIf="selectAccount.selectedAccount === '100544-MARGIN' || (selectAccount.selectedAccountTypes.includes(MARGIN_CODE))">
            <div class="card-tile">
                <div class="bmo-container-flex">
                    <div class="flex-70">
                        <h1 class="font-bold" translate="selectAccount.accounts.marginCardTitle"></h1>
                        <p>
                        <ng-template [ngIf]="((!isSubProductAD && !branchAssisted && isMultiAccountOpen)
                        || branchSDMultiAccount) && selectAccount.selectedAccountTypes?.length > 1" [ngIfElse]="creditBureauSingleAccount">
                            <span  translate="selectAccount.accounts.marginCardDescriptionMAO1"></span>
                            <a href="javascript://"
                                (click)="showMarginModal()" id="creditBureauTermsLink"
                                data-ana="Investorline:wealth:onboarding:creditBureauTerms"
                                [attr.aria-label]="'selectAccount.ariaLabels.creditBureauMAO' | translate"
                                [innerHTML]="'selectAccount.accounts.marginCardDescriptionMAO2' | translate | safeHtml">
                            </a>
                        </ng-template>
                        <ng-template #creditBureauSingleAccount>
                              <span  translate="selectAccount.accounts.marginCardDescription1"></span>
                            <a href="javascript://"
                                (click)="showMarginModal()" id="creditBureauTermsLink"
                                data-ana="Investorline:wealth:onboarding:creditBureauTerms"
                                [attr.aria-label]="'selectAccount.labels.creditBureau' | translate"
                                [innerHTML]="'selectAccount.accounts.marginCardDescription2' | translate | safeHtml">
                            </a>
                        </ng-template>
                            <span translate="selectAccount.accounts.marginCardDescription3"></span>
                        </p>
                        <div id="creditBureauTermsCheckWrapper" class="form-wrapper no-margin">
                            <div class="form-row">
                                <div class="form-col">
                                    <div class="form-input-checks check-default">
                                        <input id="creditBureau"
                                                data-ana="Investorline:wealth:onboarding:creditBureauCheck"
                                                type="checkbox"
                                                name="creditBureau"
                                                [(ngModel)]="selectAccount.creditBureautncChecked"
                                                #creditBureauCtrl="ngModel"
                                                (change)="onCreditBureauChecked($event.target.checked)"
                                                [disabled]="(selectAccount.creditBureautncChecked && formService.forms.selectAccount.isCompleted)" required />
                                        <label for="creditBureau" translate="selectAccount.labels.marginTerms"></label>
                                    </div>
                                    <div *ngIf="creditBureauCtrl.errors && creditBureauCtrl.touched || (isFormSubmit && creditBureauCtrl.errors)" role="alert" class="error-message">
                                        <span translate="selectAccount.errors.creditBureauError"></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-30">
                        <div class="check-img credit-check"></div>
                    </div>
                </div>
            </div>
        </section>

        <section *ngIf="tradingOptionFields" class="content-left add-margin t32px">
            <div id="accountSelectSectionThree">

                <div *ngIf="selectAccount.selectedAccount === '100544-MARGIN' || (selectAccount.selectedAccountTypes.includes(MARGIN_CODE))">
                    <ng-template [ngIf]="((!branchAssisted && isMultiAccountOpen) || branchSDMultiAccount) &&
                                       selectAccount.selectedAccountTypes?.length > 1" [ngIfElse]="shortSellSingleAccount">
                        <h1  translate="selectAccount.sdAccountFeatureTitleMAO"></h1>
                        <h2  id="ariaShortSell" translate="selectAccount.accounts.shortSellMAO"></h2>
                        <p  [innerHTML]="'selectAccount.accounts.shortSellDescriptionMAO' | translate | safeHtml"></p>
                        <div id="accountSelectSectionThreeShortSell" class="more-info-wrapper content-left">

                            <a role="button" id="accountSelectSectionThreeShortSellLearnMoreOpen"
                            aria-controls="accountSelectSectionThreeShortSellControls"
                            aria-expanded="false"  class="btn-icon btn-more-info-show" href="javascript://"
                            attr.aria-label="{{ 'selectAccount.ariaLabels.learnMoreShortSelling' | translate }}">
                                <span class="btn-icon-text-left" translate="linkButton.learnMore" ></span>
                                <span class="icon icon-large chevron-down-blue"></span>
                            </a>

                            <div class="more-info-content" id="accountSelectSectionThreeShortSellControls">

                                <p [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContentMAODesc' | translate | safeHtml"></p>
                                <p [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent1' | translate | safeHtml"></p>
                                <p [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent2' | translate | safeHtml"></p>

                                <ul class="disc">
                                    <li [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent3' | translate | safeHtml"></li>
                                    <li [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent4' | translate | safeHtml"></li>
                                    <li [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent5' | translate | safeHtml"></li>
                                </ul>

                                <p>
                                    <a role="button"
                                    id="accountSelectSectionThreeShortSellLearnMoreClose"
                                    aria-controls="accountSelectSectionThreeShortSellControls"
                                    aria-expanded="true" class="btn-icon btn-more-info-close" href="javascript://"
                                    attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                        <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                        <span class="icon icon-large chevron-up-blue"></span>
                                    </a>
                                </p>

                            </div>

                        </div>
                    </ng-template>
                    <ng-template #shortSellSingleAccount>
                        <h1 translate="selectAccount.sdAccountFeatureTitle"></h1>
                        <h2  id="ariaShortSell" translate="selectAccount.accounts.shortSell"></h2>
                            <p [innerHTML]="'selectAccount.accounts.shortSellDescription' | translate | safeHtml"></p>
                            <p [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent1' | translate | safeHtml"></p>
                            <p [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent2' | translate | safeHtml"></p>

                            <ul class="disc">
                                <li [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent3' | translate | safeHtml"></li>
                                <li [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent4' | translate | safeHtml"></li>
                                <li [innerHTML]="'selectAccount.accounts.shortSellLearnMoreContent5' | translate | safeHtml"></li>
                            </ul>
                    </ng-template>
                    <div class="form-wrapper">
                        <div class="form-row" role="radiogroup" aria-labelledby="ariaShortSell">

                            <div class="flex-elements form-col no-margin">
                                <div class="form-radio-checks radio-buttons"
                                     [ngClass]="{'error-field': shortTradingrelationShipCtrl.errors && shortTradingrelationShipCtrl.touched || (isFormSubmit && shortTradingrelationShipCtrl.errors), 'active-label': shortTradingrelationShipCtrl.value}">
                                    <input id="yesshortSellTrading" type="radio" name="shortTrading" value="Yes"
                                           [(ngModel)]="selectAccount.needShortSelling" #shortTradingrelationShipCtrl="ngModel"
                                           [appErrorAccess]="{ id: 'relationShip-error', parentId: 'parent-relationShip-error', submitted: isFormSubmit}"
                                            data-ana="Investorline:wealth:onboarding:shorttrading:yes" required/>
                                    <label class="prefix" for="yesshortSellTrading"
                                           translate="accountDetail.labels.yes"></label>
                                </div>
                                <div class="form-radio-checks radio-buttons"
                                     [ngClass]="{'error-field': shortTradingrelationShipCtrl.errors && shortTradingrelationShipCtrl.touched || (isFormSubmit && shortTradingrelationShipCtrl.errors), 'active-label': shortTradingrelationShipCtrl.value}">
                                    <input id="noshortSellTrading" type="radio" name="shortTrading" value="No"
                                           [(ngModel)]="selectAccount.needShortSelling" #shortTradingrelationShipCtrl="ngModel"
                                           [appErrorAccess]="{ id: 'relationShip-error', parentId: 'parent-relationShip-error', submitted: isFormSubmit}"
                                            data-ana="Investorline:wealth:onboarding:shorttrading:no" required/>
                                    <label class="prefix" for="noshortSellTrading"
                                           translate="accountDetail.labels.no"></label>
                                </div>
                            </div>

                            <div class="error-message" id="relationShip-error"
                                 *ngIf="shortTradingrelationShipCtrl.errors && shortTradingrelationShipCtrl.touched || (isFormSubmit && shortTradingrelationShipCtrl.errors)">
                                 <ng-template [ngIf]="((!branchAssisted && isMultiAccountOpen) || branchSDMultiAccount) && selectAccount.selectedAccountTypes?.length > 1" [ngIfElse]="shortSellErrorSingleAccount">
                                 <span  translate="accountDetail.errors.shortSellingErrorMAO"></span>
                                 </ng-template>
                                 <ng-template #shortSellErrorSingleAccount>
                                    <span  translate="accountDetail.errors.shortSellingError"></span>
                                 </ng-template>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="add-margin" [ngClass]="{'t32px': selectAccount.selectedAccount === '100544-MARGIN'}">
                    <ng-container *ngIf="((!isMultiAccountOpen || branchAssisted) && !branchSDMultiAccount) ||
                       ( (selectAccount.selectedAccountTypes?.includes(CASH_CODE) && selectAccount.selectedAccountTypes?.length == 2) ||
                        (!selectAccount.selectedAccountTypes?.includes(CASH_CODE) && selectAccount.selectedAccountTypes?.length == 1))">
                        <h1 id="ariaRelationShip" *ngIf="selectAccount.selectedAccount !== '100544-MARGIN' " translate="selectAccount.accountFeatures"></h1>
                        <h2 id="ariaRelationShip" *ngIf="selectAccount.selectedAccount === '100544-MARGIN' " translate="selectAccount.accountFeatures"></h2>
                        <p  [innerHTML]="'selectAccount.accountFeaturesDescription1' | translate | safeHtml"></p>
                        <p  [innerHTML]="'selectAccount.accountFeaturesDescription2' | translate | safeHtml"></p>
                    </ng-container>
                    <ng-container *ngIf="((isMultiAccountOpen  && !branchAssisted) || branchSDMultiAccount) &&
                    ( (selectAccount.selectedAccountTypes?.includes(CASH_CODE) && selectAccount.selectedAccountTypes?.length > 2) ||
                    (!selectAccount.selectedAccountTypes?.includes(CASH_CODE) && selectAccount.selectedAccountTypes?.length > 1))" >
                    <h1 id="ariaRelationShip" *ngIf="!selectAccount.selectedAccountTypes.includes(MARGIN_CODE)" translate="selectAccount.accountFeatures"></h1>
                    <h2 id="ariaRelationShip" *ngIf="selectAccount.selectedAccountTypes.includes(MARGIN_CODE) " translate="selectAccount.accountFeatures"></h2>
                    <p translate="selectAccount.accountFeaturesDescription3"></p>
                    <div  id="accountSelectOptionsTrading" class="more-info-wrapper content-left">

                        <a role="button" id="accountSelectOptionsTradingLearnMoreOpen"
                        aria-controls="accountSelectOptionsTradingControls"
                        aria-expanded="false"
                        class="btn-icon btn-more-info-show" href="javascript://"
                        attr.aria-label="{{ 'selectAccount.ariaLabels.learnMoreOptionsTrading' | translate }}">
                            <span class="btn-icon-text-left" translate="linkButton.learnMore" ></span>
                            <span class="icon icon-large chevron-down-blue"></span>
                        </a>

                        <div class="more-info-content" id="accountSelectOptionsTradingControls">

                            <p [innerHTML]="'selectAccount.accountFeaturesDescription1' | translate | safeHtml"></p>
                            <p [innerHTML]="'selectAccount.accountFeaturesDescription4' | translate | safeHtml"></p>

                            <p>
                                <a role="button"
                                id="accountSelectOptionsTradingLearnMoreClose"
                                aria-controls="accountSelectOptionsTradingControls"
                                aria-expanded="true"
                                class="btn-icon btn-more-info-close" href="javascript://"
                                attr.aria-label="{{ 'linkButton.ariaLabels.close' | translate }}">
                                    <span class="btn-icon-text-left" translate="linkButton.close"></span>
                                    <span class="icon icon-large chevron-up-blue"></span>
                                </a>
                            </p>

                        </div>

                    </div>
                </ng-container>
                </div>

                <div class="form-wrapper">
                    <div class="form-row" role="radiogroup" aria-labelledby="ariaRelationShip" id='parent-relationShip-error'>
                        <div class="flex-elements form-col no-margin">

                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors), 'active-label': relationShipCtrl.value}">
                                <input id="yesOptionTrading" type="radio" name="optionTrading" value="Yes"
                                       [(ngModel)]="selectAccount.needOptionsTrading" #relationShipCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'relationShip-error', parentId: 'parent-relationShip-error', submitted: isFormSubmit}"
                                       aria-required="true" data-ana="Investorline:wealth:onboarding:options:yes" (click)="analytics.triggerView('TradingAccountOptionsYes')" />
                                <label data-ana="Investorline:wealth:onboarding:yesOptionTrading" class="prefix" for="yesOptionTrading" translate="accountDetail.labels.yes"></label>
                            </div>

                            <div class="form-radio-checks radio-buttons"
                                 [ngClass]="{'error-field': relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors), 'active-label': relationShipCtrl.value}">
                                <input id="noOptionTrading" type="radio" name="optionTrading" value="No"
                                       [(ngModel)]="selectAccount.needOptionsTrading" #relationShipCtrl="ngModel"
                                       [appErrorAccess]="{ id: 'relationShip-error', parentId: 'parent-relationShip-error', submitted: isFormSubmit}"
                                       aria-required="true" data-ana="Investorline:wealth:onboarding:options:no" (click)="analytics.triggerView('TradingAccountOptionsNo')"/>
                                <label data-ana="Investorline:wealth:onboarding:noOptionTrading" class="prefix" for="noOptionTrading" translate="accountDetail.labels.no"></label>
                            </div>

                        </div>
                        <div class="error-message" id="relationShip-error"
                             *ngIf="relationShipCtrl.errors && relationShipCtrl.touched || (isFormSubmit && relationShipCtrl.errors)">
                            <span translate="accountDetail.errors.optionTradingError"></span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="((isMultiAccountOpen && !branchAssisted) || branchSDMultiAccount) && selectAccount.needOptionsTrading === 'Yes' && ( (selectAccount.selectedAccountTypes?.includes(CASH_CODE) && selectAccount.selectedAccountTypes?.length > 2) ||
                                (!selectAccount.selectedAccountTypes?.includes(CASH_CODE) && selectAccount.selectedAccountTypes?.length > 1))">

                <div id="sectionThreeIndividualOptionsTrading" *ngIf="!selectAccount.optionsTradingForAllAccounts">
                    <ng-container *ngFor="let accountCode of selectAccount.selectedAccountTypes; let idx = index">
                        <ng-container *ngIf="accountCode != CASH_CODE">
                        <h2 *ngIf="(!selectAccount.selectedAccountTypes?.includes(MARGIN_CODE))" [id]="'aria'+accountTypeId[accountCode]+'Title'"  [innerHTML]="'selectAccount.labels.individualOptions'+accountTypeId[accountCode]  | translate | safeHtml"></h2>
                        <h3 *ngIf="(selectAccount.selectedAccountTypes?.includes(MARGIN_CODE))" [id]="'aria'+accountTypeId[accountCode]+'Title'"  [innerHTML]="'selectAccount.labels.individualOptions'+accountTypeId[accountCode]  | translate | safeHtml"></h3>

                        <div class="form-wrapper">

                            <div class="form-row" role="radiogroup" [attr.aria-labelledby]="'ariaRelationShip' + ' aria'+accountTypeId[accountCode]+'Title'"  [id]="'parent-'+accountTypeId[accountCode]+'Options-error'">
                                <div class="flex-elements form-col no-margin">

                                    <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': optionTradingMultiCtrl.errors && (optionTradingMultiCtrl.touched || isFormSubmit), 'active-label': optionTradingMultiCtrl.value}">
                                        <input [id]="'yesIndividualOptions'+accountTypeId[accountCode]" type="radio" [(ngModel)]="selectAccount.optionTradingMulti[idx]" #optionTradingMultiCtrl="ngModel" [name]="'individualOptions'+accountTypeId[accountCode]"
                                        (change)="changeOptionTradingMulti($event.target.value ,accountCode)" value="Yes" aria-required="true"  [attr.data-ana]="'Investorline:wealth:onboarding:options:'+ accountTypeId[accountCode] +':yes'" required/>
                                        <label class="prefix" [for]="'yesIndividualOptions'+accountTypeId[accountCode]" translate="accountDetail.labels.yes"></label>
                                    </div>

                                    <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': optionTradingMultiCtrl.errors && (optionTradingMultiCtrl.touched || isFormSubmit), 'active-label': optionTradingMultiCtrl.value}">
                                        <input [id]="'noIndividualOptions'+accountTypeId[accountCode]" type="radio" [(ngModel)]="selectAccount.optionTradingMulti[idx]" #optionTradingMultiCtrl="ngModel" [name]="'individualOptions'+accountTypeId[accountCode]"
                                        (change)="changeOptionTradingMulti($event.target.value ,accountCode)"    value="No" aria-required="true" [attr.data-ana]="'Investorline:wealth:onboarding:options:'+ accountTypeId[accountCode] +':no'" required/>
                                        <label class="prefix" [for]="'noIndividualOptions'+accountTypeId[accountCode]" translate="accountDetail.labels.no"></label>
                                    </div>

                                </div>
                                <div class="error-message" [id]="accountTypeId[accountCode]+'Options-error'" *ngIf="optionTradingMultiCtrl.errors && (optionTradingMultiCtrl.touched || isFormSubmit)">
                                    <span [innerHTML]="'selectAccount.errors.individualOptionsError'+accountTypeId[accountCode] | translate | safeHtml"></span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    </ng-container>

                </div>
                <div id="sectionThreeoptionsTradingAllAccounts">
                    <div class="form-wrapper">
                        <div class="form-row" role="group" attr.aria-label="{{ 'selectAccount.ariaLabels.addEligibleOptions' | translate }}"
                             id="parent-optionsTradingAllAccounts-error">
                            <div class="form-col" checkboxValidator>
                                <div class="form-input-checks check-default">
                                    <input id="optionsTradingAllAccounts" type="checkbox" name="optionsTradingAllAccounts" (change)="changeOptionTradingMulti()"
                                    [(ngModel)]="selectAccount.optionsTradingForAllAccounts" #optionsTradingAllAccountsCtrl="ngModel" data-ana="investorline:wealth:onboarding:options:all accounts:checkbox" />
                                    <label for="optionsTradingAllAccounts" translate="selectAccount.labels.addEligibleOptions"></label>
                                </div>
                                <!-- <div class="error-message" id="optionsTradingAllAccounts-error" *ngIf="false">
                                    <span translate="accountDetail.errors.typeOptionPrevError"></span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            </div>

            <!--if STPfeatureflag is false this section-->
            <div id="accountSelectSectionFour" *ngIf = "!isSubProductAD && !isSTPOptionsEnabled">

                <div id="sectionFourYearsOfExperience" *ngIf="selectAccount.needOptionsTrading === 'Yes'">

                    <h2 *ngIf="selectAccount.selectedAccount !== '100544-MARGIN'" [innerHTML]="'accountDetail.descriptionFeatures03' | translate | safeHtml"></h2>
                    <h3 *ngIf="selectAccount.selectedAccount === '100544-MARGIN'" [innerHTML]="'accountDetail.descriptionFeatures03' | translate | safeHtml"></h3>

                    <div class="form-wrapper">
                        <div class="form-row">
                            <div class="flex-25 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <label class="slide-label" for="numberOfYears"
                                               [ngClass]="{'error-field':yearsOfExperienceCtrl.invalid && yearsOfExperienceCtrl.touched  || (isFormSubmit && yearsOfExperienceCtrl.errors) ,'active-label': yearsOfExperienceCtrl.value==0 ? true : yearsOfExperienceCtrl.value }">
                                            <span class="label-text"
                                                  translate="accountDetail.labels.numberOfYears"></span>
                                        </label>
                                        <input id="numberOfYears" type="tel" name="yearsOfExperience"
                                               [(ngModel)]="selectAccount.yearsOfExperience"
                                               #yearsOfExperienceCtrl="ngModel" InputValidator="ct3"
                                               maxlength="2"
                                               [appErrorAccess]="{id:'yearsOfExperience-error',  submitted: isFormSubmit }"
                                                data-ana="Investorline:wealth:onboarding:options:years" required/>
                                    </div>
                                </div>
                                <div class="error-message" id="yearsOfExperience-error"
                                     *ngIf="yearsOfExperienceCtrl.errors && yearsOfExperienceCtrl.touched || (isFormSubmit && yearsOfExperienceCtrl.errors)">
                                    <span translate="accountDetail.errors.invalidNumberOfYears"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="sectionFourTradingKnowledgeLevel" *ngIf="selectAccount.needOptionsTrading === 'Yes'">

                    <h2 *ngIf="selectAccount.selectedAccount !== '100544-MARGIN'" [innerHTML]="'accountDetail.descriptionFeatures04' | translate | safeHtml"></h2>
                    <h3 *ngIf="selectAccount.selectedAccount === '100544-MARGIN'" [innerHTML]="'accountDetail.descriptionFeatures04' | translate | safeHtml"></h3>

                    <div class="form-wrapper">
                        <div class="form-row">
                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <select id="tradingKnowledge" name="tradingKnowledgeLevel"
                                                [(ngModel)]="selectAccount.tradingKnowledgeLevel"
                                                #tradingKnowledgeLevelCtrl="ngModel"
                                                [appErrorAccess]="{id:'tradingKnowledgeLevel-error',  submitted: isFormSubmit}"
                                                required>
                                            <option *ngFor="let type of listOfOptionsTradingKnowledge"
                                                    [value]="type.code" [selected]="type.selected"
                                                    [attr.data-ana]="'Investorline:wealth:onboarding:options:' + type.label">
                                                {{type.label}}
                                            </option>
                                        </select>
                                        <label [ngClass]="{'error-field': tradingKnowledgeLevelCtrl.errors && tradingKnowledgeLevelCtrl.touched || (isFormSubmit && tradingKnowledgeLevelCtrl.errors), 'active-label': tradingKnowledgeLevelCtrl.value}"
                                               class="slide-label" for="tradingKnowledge">
                                            <span class="label-text"
                                                  translate="accountDetail.labels.tradingKnowledge"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="error-message" id="tradingKnowledgeLevel-error"
                                     *ngIf="tradingKnowledgeLevelCtrl?.errors && tradingKnowledgeLevelCtrl?.touched || (isFormSubmit && tradingKnowledgeLevelCtrl?.errors)">
                                    <span translate="accountDetail.errors.optionTradionKnowledgeError"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="sectionFourTradeHistory" *ngIf="selectAccount.needOptionsTrading === 'Yes'">

                    <h2 *ngIf="selectAccount.selectedAccount !== '100544-MARGIN'" id="ariaTradeHistory" [innerHTML]="'selectAccount.descriptionFeatures05' | translate | safeHtml"></h2>
                    <h3 *ngIf="selectAccount.selectedAccount === '100544-MARGIN'" id="ariaTradeHistory" [innerHTML]="'selectAccount.descriptionFeatures05' | translate | safeHtml"></h3>

                    <div class="form-wrapper">
                        <div class="form-row" role="group" attr.aria-label="{{ 'selectAccount.ariaLabels.tradeHistory' | translate }}"
                             id="parent-typesOfOptionsTradedBefore-error">
                            <div class="form-col" ngModelGroup="typesOfOptionsTradedBefore"
                                 #typesOfOptionsTradedBeforeCtrl="ngModelGroup" checkboxValidator>
                                <div class="form-input-checks check-default" *ngFor="let i of optionsTypeExperiences"
                                     [ngClass]="{'error-field': typesOfOptionsTradedBeforeCtrl.errors && isFormSubmit}">
                                    <input [id]="'OB'+i.code" type="checkbox" [name]="i.code" [value]="i.code"
                                           [disabled]="((i.code=== '3' && disableNeverTraded) || (i.code !== '3' && !disableNeverTraded && disableNeverTraded !== null))"
                                           [(ngModel)]="i.checked"
                                           [appErrorAccess]="{ id: 'typesOfOptionsTradedBefore-error', parentId: 'parent-typesOfOptionsTradedBefore-error' ,submitted: isFormSubmit}"
                                           (change)="getOptionsOfTradedBefore()"
                                           [attr.data-ana]="'Investorline:wealth:onboarding:options:' + i.label" />
                                    <label [for]="'OB'+i.code"> {{i.label}}</label>
                                </div>
                                <div class="error-message" id="typesOfOptionsTradedBefore-error"
                                     *ngIf="typesOfOptionsTradedBeforeCtrl.errors && typesOfOptionsTradedBeforeCtrl.touched || (isFormSubmit && typesOfOptionsTradedBeforeCtrl.errors)">
                                    <span translate="accountDetail.errors.typeOptionPrevError"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="sectionFourTradeNowOptions" *ngIf="selectAccount.needOptionsTrading === 'Yes'">

                    <h2 *ngIf="selectAccount.selectedAccount !== '100544-MARGIN'" id="ariaTradeNowOptions" [innerHTML]="'accountDetail.descriptionFeatures06' | translate | safeHtml"></h2>
                    <h3 *ngIf="selectAccount.selectedAccount === '100544-MARGIN'" id="ariaTradeNowOptions" [innerHTML]="'accountDetail.descriptionFeatures06' | translate | safeHtml"></h3>

                    <div class="form-wrapper no-margin">
                        <div class="form-row" role="group" attr.aria-label="{{ 'selectAccount.ariaLabels.tradeNowOptions' | translate }}"
                             id="parent-optionTypes-error">
                            <div class="form-col" ngModelGroup="optionTypes" #optionTypesCtrl="ngModelGroup"
                                 checkboxValidator>
                                <div class="form-input-checks check-default" *ngFor="let i of optionTypes"
                                     [ngClass]="{'error-field': optionTypesCtrl.errors && isFormSubmit}">
                                    <input [id]="'OT'+i.code" type="checkbox" [name]="i.code" [value]="i.code"
                                           [(ngModel)]="i.checked"
                                           [appErrorAccess]="{ id: 'optionTypes-error', parentId: 'parent-optionTypes-error',submitted: isFormSubmit}"
                                           (change)="getOptionTypes()"
                                           [attr.data-ana]="'Investorline:wealth:onboarding:options:' + i.label" />
                                    <label [for]="'OT'+i.code"> {{i.label}}<span *ngIf="(i.code == '3' || i.code == '1') && isMultiAccountOpen" [innerHTML]="(i.code == '3' ? 'accountDetail.labels.marginOnly' : i.code == '1' ? '*' : '' ) | translate | safeHtml"></span></label>
                                </div>
                                <div class="error-message" id="optionTypes-error"
                                     *ngIf="optionTypesCtrl.errors && optionTypesCtrl.touched || (isFormSubmit && optionTypesCtrl.errors)">
                                    <span translate="accountDetail.errors.typeOptionNextError"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>
                        <span *ngIf="isMultiAccountOpen">*&nbsp;</span>
                        <span translate="accountDetail.descriptionOptionTrade"></span>
                    </p>

                </div>

            </div>

            <!--if STPfeatureflag is true this section-->
            <div id="accountSelectSectionFour"  *ngIf = "(!isSubProductAD || branchSDMultiAccount ) && isSTPOptionsEnabled && needOptionTradingMulti && selectAccount.needOptionsTrading === 'Yes'">
                <div id="sectionFourTradingKnowledgeLevel">
                    <h2 *ngIf="selectAccount.selectedAccount !== '100544-MARGIN'" [innerHTML]="'accountDetail.descriptionFeatures04' | translate | safeHtml" id="ariaTradingExperience"></h2>
                    <h3 *ngIf="selectAccount.selectedAccount === '100544-MARGIN'" [innerHTML]="'accountDetail.descriptionFeatures04' | translate | safeHtml" id="ariaTradingExperience"></h3>

                    <div class="form-wrapper">
                        <div class="form-row">
                            <div class="flex-70 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <select id="tradingKnowledge" name="tradingKnowledgeLevel" (change)="changetradingKnowledgeLevel($event)"
                                                [(ngModel)]="selectAccount.tradingKnowledgeLevel"
                                                #tradingKnowledgeLevelCtrl="ngModel"
                                                aria-labelledby="ariaTradingExperience tradingKnowledgeLabel"
                                                [appErrorAccess]="{id:'tradingKnowledgeLevel-error',  submitted: isFormSubmit}"
                                                required>
                                            <option *ngFor="let type of listOfOptionsTradingKnowledge"
                                                    [value]="type.code" [selected]="type.selected"
                                                    [attr.data-ana]="'Investorline:wealth:onboarding:options:' + type.label">
                                                {{type.label}}
                                            </option>
                                        </select>
                                        <label [ngClass]="{'error-field': tradingKnowledgeLevelCtrl.errors && tradingKnowledgeLevelCtrl.touched || (isFormSubmit && tradingKnowledgeLevelCtrl.errors), 'active-label': tradingKnowledgeLevelCtrl.value}"
                                               class="slide-label" for="tradingKnowledge" id="tradingKnowledgeLabel">
                                            <span class="label-text"
                                                  translate="accountDetail.labels.tradingKnowledge"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="error-message" id="tradingKnowledgeLevel-error"
                                     *ngIf="tradingKnowledgeLevelCtrl?.errors && tradingKnowledgeLevelCtrl?.touched || (isFormSubmit && tradingKnowledgeLevelCtrl?.errors)">
                                    <span translate="accountDetail.errors.optionTradionKnowledgeError"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="sectionFourYearsOfExperience" *ngIf="selectAccount.needOptionsTrading === 'Yes' && selectAccount.hasTradingKnowledge == true">

                    <h2 *ngIf="selectAccount.selectedAccount !== '100544-MARGIN'" [innerHTML]="'accountDetail.descriptionFeatures03' | translate | safeHtml" id="ariaYearsOfExperience"></h2>
                    <h3 *ngIf="selectAccount.selectedAccount === '100544-MARGIN'" [innerHTML]="'accountDetail.descriptionFeatures03' | translate | safeHtml" id="ariaYearsOfExperience"></h3>

                    <div class="form-wrapper">
                        <div class="form-row">
                            <div class="flex-25 form-col">
                                <div class="form-field">
                                    <div class="form-label-group">
                                        <select id="numberOfYears" name="yearsOfExperience"
                                                aria-labelledby="ariaYearsOfExperience numberOfYearsLabel"
                                                [(ngModel)]="selectAccount.yearsOfExperience"
                                                #yearsOfExperienceCtrl="ngModel"
                                                [appErrorAccess]="{id:'yearsOfExperience-error',  submitted: isFormSubmit }"
                                                required>
                                                <option *ngFor="let type of listOfYearsTradingExperience"
                                                        [value]="type.code" [selected]="type.selected"
                                                        data-ana="Investorline:wealth:onboarding:options:years">{{type.label}}
                                                </option>
                                        </select>
                                        <label class="slide-label" for="numberOfYears" id="numberOfYearsLabel"
                                               [ngClass]="{'error-field':yearsOfExperienceCtrl.invalid && yearsOfExperienceCtrl.touched  || (isFormSubmit && yearsOfExperienceCtrl.errors) ,'active-label': yearsOfExperienceCtrl.value==0 ? true : yearsOfExperienceCtrl.value }">
                                            <span class="label-text"
                                                  translate="accountDetail.labels.numberOfYears"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="error-message" id="yearsOfExperience-error"
                                     *ngIf="yearsOfExperienceCtrl.errors && yearsOfExperienceCtrl.touched || (isFormSubmit && yearsOfExperienceCtrl.errors)">
                                    <span translate="accountDetail.errors.invalidNumberOfYears"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- need to add feature flag condition -->
    <!-- <div id="sectionFourTradeHistory" *ngIf="selectAccount.needOptionsTrading === 'Yes' && selectAccount.hasTradingKnowledge == true">

                        <h2 *ngIf="selectAccount.selectedAccount !== '100544-MARGIN'" id="ariaTradeHistory" [innerHTML]="'selectAccount.descriptionFeatures05' | translate | safeHtml"></h2>
                        <h3 *ngIf="selectAccount.selectedAccount === '100544-MARGIN'" id="ariaTradeHistory" [innerHTML]="'selectAccount.descriptionFeatures05' | translate | safeHtml"></h3>

                        <div class="form-wrapper">
                            <div class="form-row" role="group" aria-labelledby="ariaTradeHistory"
                                id="parent-typesOfOptionsTradedBefore-error">
                                <div class="form-col" ngModelGroup="typesOfOptionsTradedBefore"
                                    #typesOfOptionsTradedBeforeCtrl="ngModelGroup" checkboxValidator>
                                    <div class="form-input-checks check-default" *ngFor="let i of optionsTypeExperiences"
                                        [ngClass]="{'error-field': typesOfOptionsTradedBeforeCtrl.errors && isFormSubmit}">
                                        <input [id]="'OB'+i.code" type="checkbox" [name]="i.code" [value]="i.code"
                                            [disabled]="((i.code=== '3' && disableNeverTraded) || (i.code !== '3' && !disableNeverTraded && disableNeverTraded !== null))"
                                            [(ngModel)]="i.checked"
                                            [appErrorAccess]="{ id: 'typesOfOptionsTradedBefore-error', parentId: 'parent-typesOfOptionsTradedBefore-error' ,submitted: isFormSubmit}"
                                            (change)="getOptionsOfTradedBefore()"
                                            [attr.data-ana]="'Investorline:wealth:onboarding:options:' + i.label" />
                                        <label [for]="'OB'+i.code"> {{i.label}}</label>
                                    </div>
                                    <div class="error-message" id="typesOfOptionsTradedBefore-error"
                                        *ngIf="typesOfOptionsTradedBeforeCtrl.errors && typesOfOptionsTradedBeforeCtrl.touched || (isFormSubmit && typesOfOptionsTradedBeforeCtrl.errors)">
                                        <span translate="accountDetail.errors.typeOptionPrevError"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> -->
                <div id="sectionFourTradeNowOptions" *ngIf="selectAccount.needOptionsTrading === 'Yes' && selectAccount.hasTradingKnowledge == true">

                    <h2 *ngIf="selectAccount.selectedAccount !== '100544-MARGIN'" id="ariaTradeNowOptions" [innerHTML]="'accountDetail.descriptionFeatures06' | translate | safeHtml"></h2>
                    <h3 *ngIf="selectAccount.selectedAccount === '100544-MARGIN'" id="ariaTradeNowOptions" [innerHTML]="'accountDetail.descriptionFeatures06' | translate | safeHtml"></h3>

                    <div *ngIf="isMultiAccountOpen && !branchAssisted || branchSDMultiAccount" class="form-wrapper no-margin">
                        <div class="form-row" role="group" attr.aria-label="{{ 'selectAccount.ariaLabels.tradeNowOptions' | translate }}"
                             id="parent-optionTypes-error">
                            <div class="form-col" ngModelGroup="optionTypes" #optionTypesCtrl="ngModelGroup">
                                <div class="form-input-checks check-default" *ngFor="let i of optionTypes"
                                     [ngClass]="{'error-field': optionTypesCtrl.errors && isFormSubmit}">
                                    <input [id]="'OT'+i.code" type="checkbox" [name]="i.code" [value]="i.code"
                                           [(ngModel)]="i.checked"
                                           [appErrorAccess]="{ id: 'optionTypes-error', parentId: 'parent-optionTypes-error',submitted: isFormSubmit}"
                                           (change)="getOptionTypes()"
                                           [required]="isOptionTypesNotSelected"
                                           [attr.data-ana]="'Investorline:wealth:onboarding:options:' + i.label" />
                                    <label [for]="'OT'+i.code"> {{i.label}}<span *ngIf="(i.code == '3' || i.code == '1')" [innerHTML]="(i.code == '3' ? 'accountDetail.labels.marginOnly' : i.code == '1' ? '*' : '' ) | translate | safeHtml"></span></label>
                                </div>
                                <div class="error-message" id="optionTypes-error" role="alert"
                                     *ngIf="isOptionTypesNotSelected && optionTypesCtrl.touched || (isFormSubmit && isOptionTypesNotSelected)">
                                    <span translate="accountDetail.errors.typeOptionNextError"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isMultiAccountOpen || branchAssisted && !branchSDMultiAccount" class="form-wrapper no-margin">
                        <div class="form-row" role="group" attr.aria-label="{{ 'selectAccount.ariaLabels.tradeNowOptions' | translate }}"
                             id="parent-optionTypes-error">
                            <div class="form-col" ngModelGroup="optionTypes" #optionTypesCtrl="ngModelGroup"
                                 checkboxValidator>
                                <div class="form-input-checks check-default" *ngFor="let i of optionTypes"
                                     [ngClass]="{'error-field': optionTypesCtrl.errors && isFormSubmit}">
                                    <input [id]="'OT'+i.code" type="checkbox" [name]="i.code" [value]="i.code"
                                           [(ngModel)]="i.checked"
                                           [appErrorAccess]="{ id: 'optionTypes-error', parentId: 'parent-optionTypes-error',submitted: isFormSubmit}"
                                           (change)="getOptionTypes()"
                                           [attr.data-ana]="'Investorline:wealth:onboarding:options:' + i.label" />
                                           <label [for]="'OT'+i.code"> {{i.label}}</label>
                                </div>
                                <div class="error-message" id="optionTypes-error" role="alert"
                                     *ngIf="optionTypesCtrl.errors && optionTypesCtrl.touched || (isFormSubmit && optionTypesCtrl.errors)">
                                    <span translate="accountDetail.errors.typeOptionNextError"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>
                        <span *ngIf="isMultiAccountOpen">*&nbsp;</span>
                        <span translate="accountDetail.descriptionOptionTrade"></span>
                    </p>

                </div>

            </div>
        </section>

        <section id="accountSelectSectionFive" *ngIf="!specailOfferPromo" class="content-left add-margin  t32px">

            <h1 translate="selectAccount.promoCodeTitle"></h1>
            <p translate="selectAccount.promoCodeDesc"></p>

            <div class="form-wrapper add-margin t28px">
                <div id="accountSelectPromoVerifySection" class="form-row">
                    <div id="accountSelectPromoSection" class="flex-40 form-col">
                        <div class="form-field">
                            <div class="form-label-group">

                                <input id="promoCode" type="text" name="applyPromocode" [(ngModel)]="selectAccount.promocode"
                                       #promocodeCtrl="ngModel" InputValidator="ct4" [blockPaste]="true" maxlength="14"
                                       data-ana="Investorline:wealth:onboarding:enter code" [value]="promocodeCtrl.value"
                                       (input)="onPromoCodeChange($event.target.value)">

                                <label class="slide-label content-left" for="promoCode"
                                       [ngClass]="{'active-label': promocodeCtrl.value}">

                                    <span class="label-text" translate="accountDetail.labels.promoCode"></span>

                                </label>
                            </div>
                        </div>
                        <div *ngIf="(isCodeApplied && !promoApplied) || isReferenceCallFailed" role="alert" class="error-message">
                            <span translate="accountDetail.errors.invalidCode"></span>
                        </div>

                        <div *ngIf="isCodeApplied && promoApplied" role="alert" class="success-message">
                            <span translate="accountDetail.success.validCode"></span>
                        </div>
                    </div>
                    <div class="flex-10 form-col field-button">
                        <button class="tertiary" data-ana="Investorline:wealth:onboarding:apply code" type="button" (click)="promoCodeApplied(true)">
                            <span translate="accountDetail.labels.promoButton"></span>
                        </button>
                    </div>
                </div>

                <div class="form-row" *ngIf="isCodeApplied && promoApplied">
                    <div class="form-col">
                        <div class="form-input-checks check-default">
                            <input id="promoTerms" type="checkbox" name="promoTerms" [(ngModel)]="selectAccount.tncChecked"
                                   #tncCtrl="ngModel" (change)="onTNCChecked($event.target.checked)" required />
                            <label for="promoTerms"
                                   [innerHTML]="('selectAccount.acknowledgeTC01' | translate).replace('[TNCDOCNAME]', selectAccount.tncDocName)"></label>
                        </div>
                        <div class="error-message" id="tncCtrl-error" role="alert"
                             *ngIf="tncCtrl.errors && tncCtrl.touched || (isFormSubmit && tncCtrl.errors)">
                            <span translate="selectAccount.errors.promoCodeTNC"></span>
                        </div>
                    </div>
                </div>
            </div>

            <!--Please note to dynamiccly add this when promo is applied -->
            <!-- <div class="bmo-card-wrapper promo-code" *ngIf="isCodeApplied && promoApplied && promoCodeDescription">
                <div class="card-tile">
                    <p>{{promoCodeDescription}}</p>
                </div>
            </div> -->

        </section>

        <!-- <section  *ngIf="specailOfferPromo" class="content-left add-margin  t32px">
              <h1 translate="selectAccount.specailOfferTitle"></h1>
              <p translate="selectAccount.specailOfferDesc"></p>

              <div class="bmo-card-wrapper promo-code">
                 <div class="card-tile">
                    <h2>[[Promo Code Example]]</h2>
                    <p>[[Campaign description with bonus amount]]</p>
                 </div>
               </div>

              Input: Please note: this needs to be in an applied state with the promo code being used
              <div class="form-wrapper no-margin">
                 <div class="form-row">
                    <div class="flex-40 form-col">
                       <div class="form-field">
                          <div class="form-label-group">

                             <input id="promoCode" type="text" name="applyPromocode" [(ngModel)]="selectAccount.promocode"
                                #promocodeCtrl="ngModel" InputValidator="ct4" [blockPaste]="true" maxlength="14"
                                data-ana="Investorline:wealth:onboarding:enter code" [value]="promocodeCtrl.value"
                                (input)="onPromoCodeChange($event.target.value)" (focusout)="promoCodeApplied()">

                             <label class="slide-label content-left" for="promoCode"
                                [ngClass]="{'active-label': promocodeCtrl.value}">

                                <span class="label-text" translate="accountDetail.labels.promoCode"></span>

                             </label>
                          </div>
                       </div>

                       <div *ngIf="isCodeApplied && !promoApplied" role="alert" class="error-message">
                          <span translate="accountDetail.errors.invalidCode"></span>
                       </div>

                       <div *ngIf="isCodeApplied && promoApplied" role="alert" class="success-message">
                          <span translate="accountDetail.success.validCode"></span>
                       </div>
                    </div>
                 </div>

                 <div class="form-row" *ngIf="isCodeApplied && promoApplied">
                    <div class="form-col">
                       <div class="form-input-checks check-default">
                          <input id="promoTerms" type="checkbox" name="promoTerms" [(ngModel)]="selectAccount.tncChecked"
                             #tncCtrl="ngModel" (change)="onTNCChecked($event.target.checked)" required/>
                             <label for="promoTerms"
                             [innerHTML]="('selectAccount.acknowledgeTC01' | translate).replace('[TNCDOCNAME]', selectAccount.tncDocName)"></label>
                             <div class="error-message" id="tncCtrl-error"
                                *ngIf="tncCtrl.errors && tncCtrl.touched || (isFormSubmit && tncCtrl.errors)">
                                <span translate="selectAccount.errors.promoCodeTNC"></span>
                             </div>
                          </div>
                    </div>
                 </div>
              </div>

        </section> -->

        <section id="accountSelectSectionSix" class="content-left add-margin t32px" *ngIf="!isSubProductAD && !disableReferAfriend">
            <h1 translate="accountDetail.titleSectionReferFriend"></h1>
            <div class="bmo-card-wrapper recommend-friend">
                <p [innerHTML]="'accountDetail.descriptionReferFriend' | translate | safeHtml"></p>
            </div>
        </section>

        <section id="accountSelectSectionSeven" class="content-left add-margin  t32px" *ngIf="enableRSM && isBMONetwork">

            <h1 translate="selectAccount.bmoStaff" id="ariaBmoStaff"></h1>
            <p translate="selectAccount.bmoStaffDesc"></p>

            <div class="form-wrapper" >
                <div class="form-row" role="radiogroup" aria-labelledby="ariaBmoStaff" id='parent-BmoStaff-error'>
                    <div class="flex-elements form-col no-margin">
                        <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': BmoRelationShipCtrl.errors && BmoRelationShipCtrl.touched || (isFormSubmit && BmoRelationShipCtrl.errors), 'active-label': BmoRelationShipCtrl.value}">
                            <input id="yesBmoStaff" type="radio" name="BmoStaff" value="Yes" aria-required="true" data-ana="investorline:wealth:onboarding:employee assist:yes" [(ngModel)]="selectAccount.isBmoStaff"
                             #BmoRelationShipCtrl="ngModel" (click)="analytics.triggerView('yesBmoStaff')" [appErrorAccess]="{ id: 'BmoStaff-error', parentId: 'parent-BmoStaff-error', submitted: isFormSubmit}"
                             required/>
                            <label data-ana="" class="prefix" for="yesBmoStaff" translate="accountDetail.labels.yes"></label>
                        </div>

                        <div class="form-radio-checks radio-buttons" [ngClass]="{'error-field': BmoRelationShipCtrl.errors && BmoRelationShipCtrl.touched || (isFormSubmit && BmoRelationShipCtrl.errors), 'active-label': BmoRelationShipCtrl.value}">
                            <input id="noBmoStaff" type="radio" name="BmoStaff" value="No" aria-required="true" data-ana="investorline:wealth:onboarding:employee assist:no" [(ngModel)]="selectAccount.isBmoStaff"
                             #BmoRelationShipCtrl="ngModel" (click)="onNoBmoStaff()" [appErrorAccess]="{ id: 'BmoStaff-error', parentId: 'parent-BmoStaff-error', submitted: isFormSubmit}"
                             required />
                            <label data-ana="" class="prefix" for="noBmoStaff" translate="accountDetail.labels.no"></label>
                        </div>
                    </div>
                    <div class="error-message" id="BmoStaff-error"
                         *ngIf="BmoRelationShipCtrl.errors && BmoRelationShipCtrl.touched || (isFormSubmit && BmoRelationShipCtrl.errors)">
                        <span translate="accountDetail.errors.BmoStaffError"></span>
                    </div>
                </div>
            </div>

            <div class="form-wrapper" *ngIf="selectAccount.isBmoStaff === 'Yes'">
                <div class="form-row">
                    <div class="flex-40 form-col">
                        <div class="form-field">
                            <div class="form-label-group">
                                <select id="BmoStaffReferrer" data-ana="investorline:wealth:onboarding:employee assist:referrer" name="BmoStaffReferrer" [(ngModel)]="selectAccount.BmoStaffReferrer" #BmoStaffReferrerCtrl="ngModel"
                                (change)="onChangeBmoStaffReferrer($event.target.value)" [appErrorAccess]="{id:'BmoStaffReferrer-error',  submitted: isFormSubmit}" required>
                                    <option *ngFor="let staff of BmoStaffReferrer" [selected]="staff.selected" [value]="staff.code">
                                    {{staff.label}} </option>

                                </select>
                                <label [ngClass]="{'error-field': BmoStaffReferrerCtrl.errors && BmoStaffReferrerCtrl.touched || (isFormSubmit && BmoStaffReferrerCtrl.errors), 'active-label': BmoStaffReferrerCtrl.value}"
                                class="slide-label" for="BmoStaffReferrer">
                                    <span class="label-text"
                                          translate="selectAccount.labels.BmoStaffReferrer"></span>
                                </label>
                            </div>
                        </div>
                        <div class="error-message" id="BmoStaffReferrerLevel-error"
                             *ngIf="BmoStaffReferrerCtrl?.errors && BmoStaffReferrerCtrl?.touched || (isFormSubmit && BmoStaffReferrerCtrl?.errors)">
                            <span translate="accountDetail.errors.selectionError"></span>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="selectAccount.BmoStaffReferrer == rsmReferalRole.RSM_FP_IFS_PRIVATE_BANKER || selectAccount.BmoStaffReferrer == rsmReferalRole.PERSONAL_BANKER">
                <h2 id="ariaReferralDetails" translate="selectAccount.referralDetailsTitle"></h2>
                <div class="form-wrapper">
                   <div class="form-row" aria-labelledby="ariaReferralDetails">
                       <div *ngIf="selectAccount.BmoStaffReferrer == rsmReferalRole.RSM_FP_IFS_PRIVATE_BANKER" class="flex-40 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="referralCode" name="referralCode" type="text" minlength="3" maxlength="3" InputValidator="ct4"
                                    [(ngModel)]="selectAccount.referralCode" #referralCodeCtrl="ngModel"
                                    data-ana="investorline:wealth:onboarding:employee assist:details:referral code"
                                    [appErrorAccess]="{id:'referralCode-error',  submitted: isFormSubmit}" required />
                                     <label class="slide-label" for="referralCode"
                                     [ngClass]="{'error-field': referralCodeCtrl.errors && referralCodeCtrl.touched || (isFormSubmit && referralCodeCtrl.errors), 'active-label':referralCodeCtrl.value}">
                                        <span class="label-text" translate="selectAccount.labels.referralCode"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="referralCode-error" class="error-message"
                            *ngIf="referralCodeCtrl?.errors && referralCodeCtrl?.touched || (isFormSubmit && referralCodeCtrl?.errors)">
                                <span translate="accountDetail.errors.enterReferralCode"></span>
                            </div>
                       </div>
                       <div class="flex-40 form-col">
                            <div class="form-field">
                                <div class="form-label-group">
                                    <input id="transitNumber" name="transitNumber" type="text" minlength="4" maxlength="5"  InputValidator="ct21"
                                    [(ngModel)]="selectAccount.transit" #transitCtrl="ngModel"
                                    data-ana="investorline:wealth:onboarding:employee assist:referrer:transit"
                                    [appErrorAccess]="{id:'transitNumber-error',  submitted: isFormSubmit}" required/>
                                    <label class="slide-label" for="transitNumber"
                                    [ngClass]="{'error-field': transitCtrl.errors && transitCtrl.touched || (isFormSubmit && transitCtrl.errors), 'active-label':transitCtrl.value}">
                                        <span class="label-text" translate="selectAccount.labels.transitNumber"></span>
                                    </label>
                                </div>
                            </div>
                            <div role="alert" id="transitNumber-error" class="error-message"
                            *ngIf="transitCtrl?.errors && transitCtrl?.touched || (isFormSubmit && transitCtrl?.errors)">
                                <span translate="accountDetail.errors.enterBranchNumber"></span>
                            </div>
                       </div>
                       <div *ngIf="selectAccount.BmoStaffReferrer == rsmReferalRole.PERSONAL_BANKER " class="flex-40 form-col">
                        <div class="form-field">
                            <div class="form-label-group">
                                <input id="einNumber" name="einNumber" type="text" minlength="9"
                                data-ana="investorline:wealth:onboarding:employee assist:referrer:id" maxlength="9"
                                 InputValidator="ct21"   [(ngModel)]="selectAccount.EIN" #EINCtrl="ngModel"
                              [appErrorAccess]="{id:'einNumber-error',  submitted: isFormSubmit}" required/>
                                <label class="slide-label" for="einNumber"
                                [ngClass]="{'error-field': EINCtrl.errors && EINCtrl.touched || (isFormSubmit && EINCtrl.errors), 'active-label':EINCtrl.value}">
                                    <span class="label-text" translate="selectAccount.labels.einNumber"></span>
                                </label>
                            </div>
                        </div>
                        <div role="alert" id="einNumber-error" class="error-message"
                         *ngIf="EINCtrl.errors && EINCtrl.touched || (isFormSubmit && EINCtrl.errors)">
                            <span translate="accountDetail.errors.enterEIN"></span>
                        </div>
                   </div>
                   </div>
               </div>
             <ng-container  *ngIf="selectAccount.BmoStaffReferrer == rsmReferalRole.RSM_FP_IFS_PRIVATE_BANKER">
               <h2 id="ariaReferredByEmployee" translate="selectAccount.referralDetailsDescription"></h2>

               <div class="form-wrapper">
                    <div class="form-row" role="radiogroup" aria-labelledby="ariaReferredByEmployee" id='parent-referredByEmployee-error'>

                        <div class="flex-elements form-col no-margin">
                            <div class="form-radio-checks radio-buttons"  [ngClass]="{'error-field': additionReferralCtrl.errors && additionReferralCtrl.touched || (isFormSubmit && additionReferralCtrl.errors), 'active-label': additionReferralCtrl.value}">
                                <input id="yesReferredByEmployee" type="radio" name="yesReferredByEmployee" value="Yes" aria-required="true" data-ana="investorline:wealth:onboarding:employee assist:bmo employee:yes"
                                       attr.aria-label="{{ 'selectAccount.ariaLabels.yesReferredByEmployee' | translate }}"
                                       [(ngModel)]="selectAccount.isAdditionalReferral" #additionReferralCtrl="ngModel" (click)="analytics.triggerView('yesReferredByEmployee')"
                                       [appErrorAccess]="{ id: 'referredByEmployee-error', parentId: 'parent-referredByEmployee-error', submitted: isFormSubmit}" required/>
                                <label data-ana="" class="prefix" for="yesReferredByEmployee" translate="accountDetail.labels.yes"></label>
                            </div>
                            <div class="form-radio-checks radio-buttons"  [ngClass]="{'error-field': additionReferralCtrl.errors && additionReferralCtrl.touched || (isFormSubmit && additionReferralCtrl.errors), 'active-label': additionReferralCtrl.value}">
                                <input id="noReferredByEmployee" type="radio" name="noReferredByEmployee" value="No" aria-required="true" data-ana="investorline:wealth:onboarding:employee assist:bmo employee:no"
                                       attr.aria-label="{{ 'selectAccount.ariaLabels.noReferredByEmployee' | translate }}"
                                       [(ngModel)]="selectAccount.isAdditionalReferral" #additionReferralCtrl="ngModel"
                                       (click)="onNoReferredByEmployee()"
                                      [appErrorAccess]="{ id: 'referredByEmployee-error', parentId: 'parent-referredByEmployee-error', submitted: isFormSubmit}" required/>
                                <label data-ana="" class="prefix" for="noReferredByEmployee" translate="accountDetail.labels.no"></label>
                            </div>
                        </div>

                        <div role="alert" class="error-message" id="referredByEmployee-error"
                        *ngIf="additionReferralCtrl.errors && additionReferralCtrl.touched || (isFormSubmit && additionReferralCtrl.errors)">
                            <span translate="accountDetail.errors.referredByEmployee"></span>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectAccount.isAdditionalReferral == 'Yes' " class="form-wrapper">
                    <div class="form-row" aria-labelledby="ariaReferrersEIN">
                        <div class="flex-40 form-col">
                             <div class="form-field">
                                 <div class="form-label-group">
                                     <input id="einNumber" name="einNumber" type="text" minlength="9"
                                     data-ana="investorline:wealth:onboarding:employee assist:bmo employee:id" maxlength="9"
                                      InputValidator="ct21"   [(ngModel)]="selectAccount.EIN" #EINCtrl="ngModel"
                                   [appErrorAccess]="{id:'einNumber-error',  submitted: isFormSubmit}" required/>
                                     <label class="slide-label" for="einNumber"
                                     [ngClass]="{'error-field': EINCtrl.errors && EINCtrl.touched || (isFormSubmit && EINCtrl.errors), 'active-label':EINCtrl.value}">
                                         <span class="label-text" translate="selectAccount.labels.einNumber"></span>
                                     </label>
                                 </div>
                             </div>
                             <div role="alert" id="einNumber-error" class="error-message"
                              *ngIf="EINCtrl.errors && EINCtrl.touched || (isFormSubmit && EINCtrl.errors)">
                                 <span translate="accountDetail.errors.enterEIN"></span>
                             </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            </ng-container>

        </section>
        <section id="accountSelectFooterButtonSection" class="content-left add-margin t32px">
            <div class="form-wrapper">
                <div class="form-row form-page-buttons">
                    <div class="form-col left-side">
                        <button *ngIf="showBackButton" data-ana="mywealth:onboarding:accountSelect:backBtn" class="secondary" type="button" (click)="onBack()" translate="button.back" attr.aria-label="{{ 'button.ariaLabels.back' | translate }}"></button>
                    </div>
                    <div class="form-col right-side">
                        <button data-ana="mywealth:onboarding:accountSelect:continueBtn" (click)="isAccountSelected()" class="primary" type="submit" translate="button.letsGo" attr.aria-label="{{ 'button.ariaLabels.letsGo' | translate }}"></button>
                    </div>
                </div>
            </div>
        </section>

    </div>

</form>
<!-- <div class="modal-overlay-component"></div> -->
<!-- FORM to post directly to OLAP -->
<form class="hidden-element" ngNoForm name="input" #OlAPForm [action]='OLAPFormUrl' method="post">
    <input aria-hidden="true" type="hidden" name="OLAP_ExternalInterface" size="1000" length="1000"
           [value]='OLAPData' />
    <input aria-hidden="true" type="hidden" name="method" size="120" length="120" value="prefill" />
</form>
