import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, EventEmitter, Renderer2 } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX } from '../../config/app-config.constants';
import { FormService } from '../../shared/service/form.service';
import { Subscriber, Observable, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Utils } from '../../shared/utils/utils.class';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
export class BeneficiaryInformationObj {
    entitlementPercentage = null;
    dateOfBirth = null;
    firstName: String = '';
    lastName: String = '';
    relationshipToPlanHolder = null;
}

@Component({
    selector: 'app-beneficiary',
    templateUrl: './beneficiary.component.html'
})

export class BeneficiaryComponent implements OnInit, OnDestroy, AfterViewInit {

    // For accessibility purposes
    @ViewChild('firstNameCtrl', {static: true}) firstNameCtrl: FormControl;
    @ViewChild('lastnameCtrl', {static: true}) lastnameCtrl: FormControl;
    @ViewChild('dateOfBirthCtrl', {static: true}) dateOfBirthCtrl: FormControl;
    @ViewChild('selectRelationshipStatusCtrl', {static: true}) selectRelationshipStatusCtrl: FormControl;
    @ViewChild('entitlementPercentageCtrl', {static: true}) entitlementPercentageCtrl: FormControl;

    @ViewChild('beneficiaryInformationForm', {static: true}) beneficiaryInformationForm: NgForm;

    public hasBeneficiaries = null;

    public beneficiariesList = [];

    public isCurrentLangEnglish: boolean;
    public isCurrentLangFrench: boolean;
    public isFormSubmit: boolean;
    public maxBeneficiaries = 5;
    public invalidTotal: Boolean = false;
    public disablePercentageField: Boolean = false;

    public totalValue: any;

    public showFinishLater = true;
    public criticalError: Boolean = false;
    public isReferenceCallFailed: Boolean = false;
    appNumber;
    public accountSetup;
    public APP_ROUTES = APP_ROUTES;

    public url: any;
    public saveOperation: Boolean = true;
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();

    constructor(private applicationService: ApplicationService,
        public titleService: Title,
        private winRef: WindowRefService,
        public formService: FormService,
        private route: ActivatedRoute,
        private analytics: AnalyticsService,
        public renderer: Renderer2) {

            console.log('BENE CONSTRUCTOR CALLED')

        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.formService.forms.beneficiaryInformation.isVisited = true;

        this.formService.setActiveComponent('beneficiaryInformation', this); // setting this component as active for data retention
        this.url = '/' + APP_ROUTES.beneficiary;
    }

    ngOnInit() {

        console.log('BENE ONINIT FIRST CALLED')

        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        this.formService.setActiveForm(this.beneficiaryInformationForm);

        // SET TITLE
        this.titleService.setTitle(Utils.getPageTitleBeneficiaryInformation());

        // coming to the page after first time (already visited)
        if (this.formService.forms.beneficiaryInformation.data != null) {

            this.beneficiariesList = this.formService.getFormData('beneficiaryInformation');

            // set has beneficiaries value
            if (this.formService.getPayload().accounts[0].hasBeneficiaries === true) {
                this.hasBeneficiaries = 'Yes';
            } else if (this.formService.getPayload().accounts[0].hasBeneficiaries === false) {
                this.hasBeneficiaries = 'No';
            } else {
                this.hasBeneficiaries = '';
            }

        } else {
            // coming to form for the first time
            this.beneficiariesList.push(new BeneficiaryInformationObj());
        }

        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'beneficiary') {
                    this.onFinishLater();
                }
            });

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data === 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {
                this.finishLaterSubscriber.unsubscribe();
            }
        });

        // set % entitlement
        this.setPercentageEntitlement();

        setTimeout(() => {
            this.setGTM();
        }, 200);

        console.log('BENE ONINIT LAST CALLED')

    }

    setPercentageEntitlement() {

        // MWB-11462
        if (this.hasBeneficiaries === 'Yes') {

            // If bene == yes && only 1 bene
            if (this.beneficiariesList.length === 1) {
                this.beneficiariesList[0].entitlementPercentage = 100;
                this.disablePercentageField = true;
            }

            // if more than 1 bene's
            if (this.beneficiariesList.length > 1) {

                if (this.beneficiariesList[0].entitlementPercentage === 100) {
                    this.beneficiariesList[0].entitlementPercentage = '';
                }
                this.disablePercentageField = false;
            }
                this.setInvalidTotal();
        }

    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {

        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data === 'clicked on ' + this.url) {
                if (this.hasBeneficiaries === 'Yes') {
                    this.setInvalidTotal();
                }

                if (this.beneficiaryInformationForm.valid && !this.invalidTotal) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });
    }

    ngAfterViewInit() {
        console.log('BENE AFTERVIEWINIT FIRST CALLED')
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('Beneficiary');
        this.checkFormValidityOnSignOut();
    }

    ngOnDestroy() {
        this.formService.setFormData('beneficiaryInformation', this.beneficiariesList);
        this.finishLaterSubscriber.unsubscribe();
    }

    isError(ctrl: any) {
        return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
    }

    hideAccountSetup() {
        this.accountSetup = false;
    }

    setHasBeneficiariesValue() {
        this.analytics.triggerView('HasBeneficiaries' + this.hasBeneficiaries);
        if (this.hasBeneficiaries === 'Yes') {
            this.formService.getPayload().accounts[0].hasBeneficiaries = true;

            // set % entitlement
            this.setPercentageEntitlement();

        } else if (this.hasBeneficiaries === 'No') {

            // reset invalid total value
            this.invalidTotal = false;

            this.formService.getPayload().accounts[0].hasBeneficiaries = false;
        } else {
            this.formService.getPayload().accounts[0].hasBeneficiaries = null;
        }
    }

    resetComponentObjectAttr() {

        if (this.hasBeneficiaries === 'No') {
            for (let i = 0; i < this.beneficiariesList.length; i++) {
                this.beneficiariesList[i].firstName = '';
                this.beneficiariesList[i].lastName = '';
                this.beneficiariesList[i].dateOfBirth = null;
                this.beneficiariesList[i].entitlementPercentage = null;
                this.beneficiariesList[i].relationshipToPlanHolder = null;
            }
        }
    }

    removeSelectedBeneficiary(index) {
       

        this.beneficiariesList.splice(index, 1);

        this.analytics.triggerView('RemoveBeneficiary' + (index +1));
        // reset invalid total value
        this.invalidTotal = false;

        // set % entitlement
        this.setPercentageEntitlement();

        // set total value = 100 in case of 1 bene only
        if (this.beneficiariesList.length === 1) {
            this.totalValue = 100;
        }

        this.addAccessTextToDom('#del-hidden-container');

       
    }

    addAnotherBeneficiary() {
        this.beneficiariesList.push(new BeneficiaryInformationObj());

        this.analytics.triggerView('AddAnotherBeneficiary' + this.beneficiariesList.length);
        // reset invalid total value
        this.invalidTotal = false;

        // set % entitlement
        this.setPercentageEntitlement();
        this.addAccessTextToDom('#add-hidden-container');
    
    }

    setInvalidTotal($event?) {
        this.totalValue = 0;

        // Check if total is 100% else throw error.
        for (let i = 0; i < this.beneficiariesList.length; i++) {
            this.totalValue += parseFloat(this.beneficiariesList[i].entitlementPercentage);
        }

        // set invalid total
        if ($event) {
            this.invalidTotal = $event;
        } else {
            if (this.totalValue !== 100) {
                this.invalidTotal = true;
            } else {
                this.invalidTotal = false;
            }
        }

    }

    addAccessTextToDom(id: any) {
        if (!id) {
            return ;
        }
       const ele = this.renderer.selectRootElement(id, true);
       if (ele) {
       this.renderer.setStyle(ele, 'display', 'block');
       this.sleepAwait(5000).then(() => {
       this.renderer.setStyle(ele, 'display', 'none');
       });
    }
    }

    sleepAwait(milliseconds: number) {
        return new Promise(resolve => { setTimeout(resolve, milliseconds) } )
      }

    onSubmit() {
        this.isFormSubmit = true;

        // clear % fields - MWB-11462
        if (this.invalidTotal) {

            for (let i = 0; i < this.beneficiariesList.length; i++) {
                // this.beneficiariesList[i].percentageEntitlement = '';
                document.getElementById('percentageEntitlement' + i).focus();
                (<HTMLInputElement>document.getElementById('percentageEntitlement' + i)).value = '';
            }
        }

        if (!this.beneficiaryInformationForm.valid || this.invalidTotal) {
            this.setFocus();

        } else {
            this.resetComponentObjectAttr();
            this.formService.forms.beneficiaryInformation.isCompleted = true;

            this.formService.setFormData('beneficiaryInformation', this.beneficiariesList);

            this.formService.getPayload().setBenificiaryInformation(this.beneficiariesList);
            if (Utils.getIsInSession() === false) {

                // MWB-11640 & MWB-11647
                if (Utils.getIsBranchReferral() === false && Utils.getWithdrawalPageCondition() === true) {
                    this.applicationService.saveAndcontinue(this.beneficiaryInformationForm, APP_ROUTES.withdrawal);
                } else if (Utils.getIsBranchReferral() === false && !this.formService.forms.celebrationInformation.isVisited){
                    this.formService.forms.celebrationInformation.isVisited = true;
                    this.applicationService.saveAndcontinue(this.beneficiaryInformationForm, APP_ROUTES.celebration);
                }else{
                    this.applicationService.saveAndcontinue(this.beneficiaryInformationForm, APP_ROUTES.financial_details);
                }
            } else {
                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        if (res.status === SUCCESS_RESPONSE) {
                            if ( Utils.getWithdrawalPageCondition() === true) {
                                this.applicationService.saveAndcontinue(this.beneficiaryInformationForm, APP_ROUTES.withdrawal);
                            } else if (!this.formService.forms.celebrationInformation.isVisited){
                                this.formService.forms.celebrationInformation.isVisited = true;
                                this.applicationService.saveAndcontinue(this.beneficiaryInformationForm, APP_ROUTES.celebration);
                            }else{
                                this.applicationService.saveAndcontinue(this.beneficiaryInformationForm, APP_ROUTES.financial_details);
                            }
                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        this.criticalError = true; // show error when save fails on top of page
                    });
            }
        }
    }

    onFinishLater() {

        this.resetComponentObjectAttr();

        this.formService.setFormData('beneficiaryInformation', this.beneficiariesList);

        // save current page if form is valid
        if (!this.formService.forms.beneficiaryInformation.isCompleted) {
            const emptyBeneficiary = new BeneficiaryInformationObj();
            this.formService.getPayload().setBenificiaryInformation(emptyBeneficiary);
        } else {
            if (this.beneficiaryInformationForm.valid) {
                this.formService.getPayload().setBenificiaryInformation(this.beneficiariesList);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({appNumber:saveResponse.applicationNumber,isAccountSetup:true})
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true;
            });

    }

    saveOnSignOut() {
        this.resetComponentObjectAttr();
        this.formService.forms.beneficiaryInformation.isCompleted = true;
        this.formService.setFormData('beneficiaryInformation', this.beneficiariesList);

        // To prepare payload with PI , CI, EI, FI
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');

        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);

        // save current page if form is valid
        if (Utils.getIsBranchReferral()) {
            if (this.beneficiaryInformationForm.valid) {
                this.formService.getPayload().setBenificiaryInformation(this.beneficiariesList);
            }
        } else {
            if (!this.formService.forms.beneficiaryInformation.isCompleted) {
                const emptyBeneficiary = new BeneficiaryInformationObj();
                this.formService.getPayload().setBenificiaryInformation(emptyBeneficiary);
            } else {
                if (this.beneficiaryInformationForm.valid) {
                    this.formService.getPayload().setBenificiaryInformation(this.beneficiariesList);
                }
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {

                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true;
            });

    }

    setFocus() {

        // setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.beneficiaryInformationForm);
           }, 250);

    }

    setGTM() {

        const gtmData: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleBeneficiaryInformation(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.BENEFICIARY_INFO,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.beneficiary_information,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmData);

    }

}
