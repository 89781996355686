import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[graphValueValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: GraphValueValidator,
            multi: true
        }
    ]
})
export class GraphValueValidator implements Validator {
    validator: ValidatorFn;

    constructor() {
        this.validator = this.validateRequiredValueInGraph();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    validateRequiredValueInGraph(): ValidatorFn {
        return (control: AbstractControl) => {
            let controlValue = control.value;

            if (control.value !== undefined && control.value !== null) {

                if (control.value == 100) {
                    return null;        // if the pattern is correct, retrun null means no errors
                }
                return {
                    invalidTotalError: {
                        invalidTotal: true
                    }
                };
            }

        };
    }
}
