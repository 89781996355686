import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ViewContainerRef, Renderer2 } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormService } from '../../shared/service/form.service';
import {
    RADIO_TYPES, INVESTMENT_KNOWLEDGE_TYPES, INVESTMENT_WITHDRAW, INVESTOR_PROFILE,
    INVESTMENT_TIME, INVESTMENT_TYPES, APP_ROUTES, SUCCESS_RESPONSE, URL, ANALYTICS, INVESTMENT_PROFILE
} from '../../config/app-config.constants';
import { Subscription } from 'rxjs';
import { Utils } from '../../shared/utils/utils.class';
import { NgForm, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { InvestorProfileOverlayComponent } from '../../shared/components/investor-profile-overlay/investor-profile-overlay.component';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { TranslateService } from '@ngx-translate/core';


export class InvestorProfile {
    public objective: any = null;
    public ilRiskTolerance: any = null;
    public riskLevelAcceptedForAgeSeventy: any = null;
    public investorProfile: any = null;
    public InvestmentProfile: any = null;
}

@Component({
    selector: 'app-investor-profile',
    templateUrl: './investor-profile.component.html'
})
export class InvestorProfileComponent implements OnInit, AfterViewInit, OnDestroy {

    public APP_ROUTES = APP_ROUTES;
    public party;
    public account;
    public criticalError: boolean;
    public investorProfile: InvestorProfile;
    public investorProfileArray: InvestorProfile[];
    public INVESTMENT_KNOWLEDGE_TYPES: any = INVESTMENT_KNOWLEDGE_TYPES;
    public RADIO_TYPES: any = RADIO_TYPES;
    public isFormSubmit = false;
    public lang: any;
    public dateOfBirth: any;
    public ageEqualSeventyOrGreater: boolean;

    public tolrancePercentage = 'X';
    public showFinishLater: boolean;
    public url;
    public incomeRiskX = { high: '80', medium: '16', low: '8', mediumToHigh: '24' };
    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    public accountSetup;
    public appNumber;
    private onExitApplicationPerformSaveSubscriber;
    public investorProfileData = {
        investmentObjectives: [
            { code: "INC", label: "investorProfile.labels.income" },
            { code: "BAL", label: "investorProfile.labels.balanced" },
            { code: "GRW", label: "investorProfile.labels.growth" },
            { code: "AGRW", label: "investorProfile.labels.aggressiveGrowth" },
        ],
        ilRiskTolerance: [
            {
                code: '1', description: 'investorProfile.labels.riskLowMediumDescription', label: 'investorProfile.labels.riskLowMedium'
            },
            {
                code: '2', description: 'investorProfile.labels.riskMediumDescription', label: 'investorProfile.labels.rishMedium'
            },
            {
                code: '3', description: 'investorProfile.labels.riskMediumHighDescription', label: 'investorProfile.labels.riskMediumHigh'
            },
            {
                code: '4', description: 'investorProfile.labels.riskHighDescription', label: 'investorProfile.labels.riskHigh'
            }
        ]
    }

    @ViewChild('investorProfileForm', {static: true}) investorProfileForm: NgForm;
    @ViewChild('investorProfileCtrl', {static: true}) investorProfileCtrl: FormControl;

    constructor(public applicationService: ApplicationService,
        public formService: FormService,
        public titleService: Title,
        private analytics: AnalyticsService,
        private winRef: WindowRefService,
        public renderer: Renderer2,
        public translate: TranslateService) {

        this.investorProfile = new InvestorProfile();
        this.formService.forms.investorProfile.isVisited = true;
        this.formService.setActiveComponent('investorProfile', this);

        this.url = URL.INVESTOR_PROFILE;
    }

    mapDescriptionFixedIncomeEquities(referenceList, constantList) {
        
        (referenceList || []).forEach((refInvestment) => {
            constantList.forEach(constInvestment=> {
                if(refInvestment.code === constInvestment.code) {
                    refInvestment.description = constInvestment.description;
                    refInvestment.fixInc=constInvestment.fixInc;
                    refInvestment.equiValues=constInvestment.equiValues;
                }
            });
        });
        return referenceList;
    }

    ngOnInit() {

        /*HIDE/SHOW Finish later & signout button*/
        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        /**Title */
        this.titleService.setTitle(Utils.getPageTitleInvestorProfile());

        this.dateOfBirth = this.formService.forms.personalInformation.data.dateOfBirth;

        this.applicationService.getReferences(this.lang).then(({references}: any) => {
            if(references){
                this.investorProfileArray = this.mapDescriptionFixedIncomeEquities(references.ilInvestmentProfile, INVESTMENT_PROFILE); 
            }
        });

        this.ageEqualSeventyOrGreater = Utils.ageEqualSeventyOrGreater(this.dateOfBirth);

        // convert 'riskLevelAcceptedForAgeSeventy' default
        if (this.ageEqualSeventyOrGreater && this.investorProfile.riskLevelAcceptedForAgeSeventy === null) {
            this.investorProfile.riskLevelAcceptedForAgeSeventy = false;
        }

        // this.changeValueRisklevel(+)  //need to call this function with model value

        /*set active form*/
        this.formService.setActiveForm(this.investorProfileForm);

        // already visited form and now visiting back again (Pre=population)
        if (this.formService.forms.investorProfile.data != null) {
            this.investorProfile = this.formService.getFormData('investorProfile');

            // convert 'ilRiskTolerance' to string
            if (this.investorProfile.ilRiskTolerance) {
                this.investorProfile.ilRiskTolerance = this.investorProfile.ilRiskTolerance.toString();
            }

            if (this.ageEqualSeventyOrGreater && this.investorProfile.riskLevelAcceptedForAgeSeventy === null) {
                this.investorProfile.riskLevelAcceptedForAgeSeventy = (this.investorProfile.riskLevelAcceptedForAgeSeventy === null) ? false : this.investorProfile.riskLevelAcceptedForAgeSeventy;
            }

        }

        /**Finish later & Sign-out */
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'investorProfileForm') {
                    this.onFinishLater();
                }
            });
            this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
                if (value) {
                    this.onFinishLater(true);
                }
            });

        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {
                this.finishLaterSubscriber.unsubscribe();
            }
        });
        this.selectedRiskTolerance(); // to set the data for X

        setTimeout(() => {
            this.setGTM();
        }, 200);
    }

    ngOnDestroy() {
        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval
        this.formService.setFormData('investorProfile', this.investorProfile);
        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }

    ngAfterViewInit() {
        this.checkFormValidityOnSignOut();
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('InvestorProfile');
    }

    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == 'clicked on ' + this.url) {

                if (this.investorProfileForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }

    hideAccountSetup() {
        this.accountSetup = false;
    }


    selectedRiskTolerance() {

        if (this.investorProfile.ilRiskTolerance === INVESTOR_PROFILE.high) {
            this.tolrancePercentage = this.incomeRiskX.high;
        } else if (this.investorProfile.ilRiskTolerance === INVESTOR_PROFILE.mediumToHigh) {
            this.tolrancePercentage = this.incomeRiskX.mediumToHigh;
        } else if (this.investorProfile.ilRiskTolerance === INVESTOR_PROFILE.medium) {
            this.tolrancePercentage = this.incomeRiskX.medium;
        } else if (this.investorProfile.ilRiskTolerance === INVESTOR_PROFILE.low) {
            this.tolrancePercentage = this.incomeRiskX.low;
        }

    }

    setFocus() {

        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.investorProfileForm);
           }, 250);

    }

    onSubmit() {
        this.isFormSubmit = true;

        if (!this.investorProfileForm.valid) {
            this.setFocus();

        } else {
            this.formService.forms.investorProfile.isCompleted = true;
            this.formService.setFormData('investorProfile', this.investorProfile);


            this.formService.getPayload().setInvestorProfile(this.investorProfile);

            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');
            const employmentInformation = this.formService.getFormData('employmentInformation');
            const familyInformation = this.formService.getFormData('familyInformation');
            const financialInformation = this.formService.getFormData('financialInformation');
            const investmentKnowledge = this.formService.getFormData('investmentKnowledge');
            const riskAndReturn = this.formService.getFormData('riskAndReturn');
            const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');
    
            this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
            this.formService.getPayload().setFamilyInformation(familyInformation);
            this.formService.getPayload().setFinancialInformation(financialInformation);
            this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);
            this.formService.getPayload().setRiskAndReturn(riskAndReturn);

            if (Utils.getIsInSession() === false) {
                this.applicationService.saveAndcontinue(this.investorProfileForm, APP_ROUTES.regulatory_disclosures);
            } else {
                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        if (res.status === SUCCESS_RESPONSE) {
                            this.applicationService.saveAndcontinue(this.investorProfileForm, APP_ROUTES.regulatory_disclosures);
                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        this.criticalError = true; // show error when save fails on top of page
                    });
            }
        }
    }

    onFinishLater(isExitModal = false) {

        if (!this.investorProfileForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }
        this.formService.forms.investorProfile.isCompleted = true;
        this.formService.setFormData('investorProfile', this.investorProfile);

        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const investmentKnowledge = this.formService.getFormData('investmentKnowledge');
        const riskAndReturn = this.formService.getFormData('riskAndReturn');
        const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

        this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);
        this.formService.getPayload().setRiskAndReturn(riskAndReturn);

        // save current page if form is valid
        if (!this.formService.forms.investorProfile.isCompleted) {
            const emptyInvestorProfile = new InvestorProfile();
            this.formService.getPayload().setInvestorProfile(emptyInvestorProfile);
        } else {
            if (this.investorProfileForm.valid) {
                this.formService.getPayload().setInvestorProfile(this.investorProfile);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                        this.applicationService.openFinishLater({appNumber:saveResponse.applicationNumber,isAccountSetup:true})
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true; // show error when save fails on top of page
            });
    }

    saveOnSignOut() {
        this.formService.forms.investorProfile.isCompleted = true;
        this.formService.setFormData('investorProfile', this.investorProfile);

        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');
        const investmentKnowledge = this.formService.getFormData('investmentKnowledge');
        const riskAndReturn = this.formService.getFormData('riskAndReturn');
        const beneficiaryInformation = this.formService.getFormData('beneficiaryInformation');

        this.formService.getPayload().setBenificiaryInformation(beneficiaryInformation);
        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        this.formService.getPayload().setInvestmentKnowledge(investmentKnowledge);
        this.formService.getPayload().setRiskAndReturn(riskAndReturn);

        // save current page if form is valid
        if (!this.formService.forms.investorProfile.isCompleted) {
            const emptyInvestorProfile = new InvestorProfile();
            this.formService.getPayload().setInvestorProfile(emptyInvestorProfile);
        } else {
            if (this.investorProfileForm.valid) {
                this.formService.getPayload().setInvestorProfile(this.investorProfile);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;

                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true; // show error when save fails on top of page
            });
    }

    openInvestorOverlay() {

       this.applicationService.openInvestorModel();
    }

    setGTM() {

        const gtmDataLayerForInvestorProfile: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleInvestorProfile(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.INVESTOR_PROFILE,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.investor_profile,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForInvestorProfile);
        // console.log('investor profile', this.winRef.nativeWindow.dataLayer);

    }


}
