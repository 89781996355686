import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { QUALTRICS_CONST } from '../../../../app/config/app-config.constants';
import { AnalyticsService } from '../../service/analytics.service';
import { ApplicationService } from '../../service/application.service';
import { QualtricsDelayMonitorService } from '../../service/qualtrics-delay-monitor.service';
import { Jquery } from '../../utils/jquery.class';
import { Utils } from '../../utils/utils.class';

export class QulatricsObj {
  surveyType: any = null;
  timeDelayInSecs: any = null;
}

@Component({
  selector: 'app-qualtrics',
  templateUrl: './qualtrics.component.html'
})
export class QualtricsComponent implements OnInit {
  @Output() dismiss: EventEmitter<any> = new EventEmitter();
  @Input() qulatricsObj: QulatricsObj;
  enablePostTransSurvey: boolean = false;
  enableAbandomentSurvey: boolean = false;
  startTimerSubscriber: Subscription;
  queryParamsAnalytics: {
    eVar4,
    eVar5,
    eVar7,
    eVar97,
    eVar96,
    eVar14
  } = {} as any;
  

  constructor(private router: Router, private analytics: AnalyticsService, private applicationService: ApplicationService, private qualtricsDelayMonitor: QualtricsDelayMonitorService) { }

  ngOnInit() {
    if (this.qulatricsObj.timeDelayInSecs) {
      this.qualtricsDelayMonitor.setIdleValues(this.qulatricsObj.timeDelayInSecs);
    }
    this.displaySurveyPopup();
  }
  displaySurveyPopup() {
    this.qualtricsDelayMonitor.startWatching();
    this.startTimerSubscriber = this.qualtricsDelayMonitor.onTimerStart().subscribe(count => {
      //console.log("timer running", count);
      if (($('.modal-overlay-component').length === 0 || $('.modal-overlay-component').css('display') === 'none')
        && $('.modal-overlay').css('display') === 'none') {
        if ((<any>window).s) {
          if (!this.queryParamsAnalytics.eVar4 || !this.queryParamsAnalytics.eVar5) {
            this.queryParamsAnalytics.eVar4 = (window as any).s.prop6;
            this.queryParamsAnalytics.eVar5 = (window as any).s.prop7;
          }
          if (!this.queryParamsAnalytics.eVar7 || !this.queryParamsAnalytics.eVar97
              || !this.queryParamsAnalytics.eVar96 || this.queryParamsAnalytics.eVar14 != (window as any).BMODATA.productTotalNumber) {
            this.queryParamsAnalytics.eVar7 = (window as any).BMODATA.pageName;
            this.queryParamsAnalytics.eVar97 = (window as any).BMODATA.customerSource;
            this.queryParamsAnalytics.eVar96 = (window as any).BMODATA.applicationFlow;
            this.queryParamsAnalytics.eVar14 = (window as any).BMODATA.productTotalNumber;
          }
        }
          
        this.enablePostTransSurvey = this.qulatricsObj.surveyType === QUALTRICS_CONST.POST_TRANSACTION_SURVEY;
        this.enableAbandomentSurvey = this.qulatricsObj.surveyType === QUALTRICS_CONST.ABANDOMENT_SURVEY;
        if (this.enablePostTransSurvey || this.enableAbandomentSurvey) {
          $('.modal-overlay').show();
          $('.bmo-feedback').show();
          Jquery.modalFocusTrap('bmo-feedback');
          Jquery.modalScrollHeight();
        }
        this.qualtricsDelayMonitor.stopWatching();
        this.startTimerSubscriber.unsubscribe();
      } else if (count === 10) {
        this.qualtricsDelayMonitor.stopWatching();
        this.startTimerSubscriber.unsubscribe();
        this.displaySurveyPopup();
      }
    });

  }

  ngAfterViewInit(): void {

    console.log(this.router.url.split('?')[0] , 'router');
    this.analytics.setPageState(this.router.url.split('?')[0]);
    this.analytics.triggerView('qualtrics');
    if ((window as any).s) {
      this.queryParamsAnalytics = {
        eVar4: (window as any).s.prop6,
        eVar5: (window as any).s.prop7,
        eVar7: (window as any).BMODATA.pageName,
        eVar97: (window as any).BMODATA.customerSource,
        eVar96: (window as any).BMODATA.applicationFlow,
        eVar14: (window as any).BMODATA.productTotalNumber
      };
    }
  }

  dismissSurvey() {

    let cmsMessageObject = Utils.getCmsMessageObject();
    let today = new Date();
    let cookieExpiryInDays = cmsMessageObject.selfServe.qualtricsParameters?.cookieExpiryInDays;
    let qsiCookieExpiry: Date = new Date(new Date().setDate(today.getDate() + cookieExpiryInDays ));
    this.applicationService.cookieStorage.setItem(QUALTRICS_CONST.QUALTRICS_COOKIE, 'passed', qsiCookieExpiry);
    this.startTimerSubscriber.unsubscribe();
    this.dismiss.emit();
    Jquery.modalDissableFocusTrap();

  }

  ngOnDestroy(): void {

    this.startTimerSubscriber.unsubscribe();
    this.qualtricsDelayMonitor.stopWatching();
    $('.modal-overlay').hide();
    $('.bmo-feedback').hide();
  }

  @HostListener("keydown", ["$event"])
  onKeyPress(event: KeyboardEvent) {
    if (event.code == "Escape") {
      this.dismissSurvey();
    }
  }

}
