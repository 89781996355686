import { Jquery } from "./shared/utils/jquery.class";
import {
    Component,
    OnInit,
    HostListener,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    Renderer2,
    ViewChild,
    ViewContainerRef,
    ChangeDetectorRef
} from "@angular/core";
import { ConfigResolverService } from "./config/external-config/service/config-resolver.service";
import {
    ActivatedRoute,
    NavigationEnd,
    Params,
    Router,
    NavigationExtras,
    NavigationStart,
} from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "./shared/service/application.service";
import { FormService } from "./shared/service/form.service";
import * as $ from "jquery";
import * as jQuery from "jquery";
import { Title } from "@angular/platform-browser";
import { UserIdleService } from "angular-user-idle";
import { AccountTypeService } from "./shared/service/account-type.service";
import { LoaderService } from "./shared/service/loader.service";
import { fromEvent, Subscription } from "rxjs";
import { Utils } from "./shared/utils/utils.class";
import { ProgressBarService } from "./shared/service/progress-bar.service";
import { APP_ROUTES, ANALYTICS, MODEL, LOGIN_SUCCESS, CDB, BILJ, CASH, MARGIN, DOWNGRADE_DECISIONS, productTypeAD, productTypeSD, AUTHORIZE_URL, JOURNEY_CLIENT_ID } from "./config/app-config.constants";
import { InvestorProfileOverlayComponent } from "./shared/components/investor-profile-overlay/investor-profile-overlay.component";
import { filter } from "rxjs/operators";
import { WindowRefService } from "./shared/service/window-ref.service";
import { get } from "lodash";
import { QulatricsObj } from "./shared/components/qualtrics/qualtrics.component";
import { CdbOlbService } from './shared/service/cdb-olb.service';
import { LocalStorageService } from "angular-2-local-storage";
import { AnalyticsService } from "./shared/service/analytics.service";
import { SelectAccount } from "./sections/select-account/select-account.component";
import { NgForm, FormControl } from '@angular/forms';
export class NetWorthCalculator {
    totalLiquidAssets = null;
    totalFixedAssets = null;
    liabilities = null;
}
import { SetExpectationObject } from "./sections/set-expectation/set-expectation.component";


class ExternalConfig { }

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    providers: [TranslateService, AccountTypeService],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    isSubProductAD: boolean;
    onTimeout;
    config: any;
    counter: number;
    resumeApplication: boolean = false;
    enableSurvey: boolean = false;
    showCookieModal: boolean = false;
    @ViewChild("investorOverlay", { static: true, read: ViewContainerRef })
    dialog: ViewContainerRef;
    resumeModelSubscriber: Subscription;
    investorModelSubscriber: Subscription;
    finishLaterModelSubscriber: Subscription;
    signOutModelSubscriber: Subscription;
    isHowAdworksSubscriber: Subscription;
    isContactUsSubscriber: Subscription;
    otherAccountsModelSubscriber: Subscription;
    pageUnloadSubscriber: Subscription;
    restartModalSubscriber: Subscription;
    showAdWorksModel: boolean;
    showContactUsModel: boolean;
    showOtherAccountsModel: boolean;
    isIEBrowser: boolean;
    pageUnload: boolean = true;
    count: number = 0;
    userIdlePingSubscriber: Subscription;
    userIdleStopPingSubscriber: Subscription;
    onLangChange: Subscription = undefined;
    accountsetupDetails: any = {
        status: false,
        appNumber: "",
    };
    isCookieEnabled: boolean;
    storageEvent = fromEvent(window, 'storage');
    storageEventSubscriber: Subscription;
    dateStamp: any;
    qulatricsObj: QulatricsObj;
    sessionTimeModelEnabled: boolean = false;
    public isCdbOlbUser: boolean = false;
    applicationSubmitted: boolean;
    applicationSaved: boolean;
    systemCriticalError: boolean;
    public setExpectation: any;
    netWorthCalculator: NetWorthCalculator;
    public isDataRationalizationEnabled: boolean = false;
    public isNetWorthFormSubmit: Boolean = false;
    public isEnforceFinishLaterEnabled: boolean;
    @ViewChild('netWorthCalculatorForm', { static: true }) netWorthCalculatorForm: NgForm;
    @ViewChild('totalLiquidAssetsCtrl', { static: true }) totalLiquidAssetsCtrl: FormControl;
    @ViewChild('totalFixedAssetsCtrl', { static: true }) totalFixedAssetsCtrl: FormControl;
    @ViewChild('liabilitiesCtrl', { static: true }) liabilitiesCtrl: FormControl;
    readonly MARGIN_CODE = MARGIN.type
    readonly CASH_CODE = CASH.type
    buttonClicked = this.MARGIN_CODE
    public isMarginAccountPenetrationEnabled: boolean = false;
    public selectAccount: SelectAccount = new SelectAccount();
    public showTimerOnRestartPopup: boolean;
    restartCounter: number;
    public isCookieModalFeatureEnabled: boolean;
    public isBill64FeatureEnabled: boolean;
    isJourneyUser: boolean = false;

    constructor(
        private accountTypeService: AccountTypeService,
        private configService: ConfigResolverService<any>,
        private translate: TranslateService,
        private applicationService: ApplicationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public titleService: Title,
        public progressBar: ProgressBarService,
        private route: ActivatedRoute,
        private userIdle: UserIdleService,
        private loaderService: LoaderService,
        private windowref: WindowRefService,
        private http: HttpClient,
        private formService: FormService,
        private renderer: Renderer2,
        public el: ElementRef,
        public cdr: ChangeDetectorRef,
        private cdbOlbService: CdbOlbService,
        private storage: LocalStorageService,
        private analytics: AnalyticsService,
        private windowRef: WindowRefService,
    ) {
        if (!this.configService.config) {
            if (environment.name === "prod-assisted") {
                this.configService.setConfig(
                    "./assets/config/prod.BRANCH-ASSITED.env.json"
                );
            } else if (environment.name === "prod-selfserve") {
                this.configService.setConfig(
                    "./assets/config/prod.SELF_SERVE.env.json"
                );
            } else if (environment.name === "sit-ba") {
                this.configService.setConfig("./assets/config/sit.BA.env.json");
            } else if (environment.name === "sit-ss") {
                this.configService.setConfig("./assets/config/sit.SS.env.json");
            } else if (environment.name === "sit") {
                this.configService.setConfig("./assets/config/sit.env.json");
            } else {
                this.configService.setConfig("./assets/config/dev.env.json");
            }
        }
        this.netWorthCalculator = new NetWorthCalculator();
        // this.translate.use("en");
    }

    ngOnInit() {
        //rebuild R26 for DIT3
        console.log('APP COUNTER', this.count++);
        this.sessionTimeModelEnabled = false;
        this.hideDocUploadDocs();
        this.hideSaveLaterEnabled();
        this.hideSaveProgressEnabled();
        this.hideSavesEnabled();
        this.hideMarginModal();
        Utils.setAppLaunchDateStamp();


        this.isIEBrowser = this.windowref.isIEBrowser();

        this.isCookieEnabled = navigator.cookieEnabled;

        // console.log(this.applicationService.cookieStorage.getItem("cookie")); // remove after DIT testing

        const url = window.location.href;
        let routePath =
            window.location.href.split("#/").length === 2
                ? window.location.href.split("#/")[1].split("?")[0]
                : "";

        if (this.configService.getConfig().BRANCH_ASSISTED !== "true" && routePath !== APP_ROUTES.next_bybranch_pdf && routePath !== APP_ROUTES.next_bymail_pdf
            && this.isCookieEnabled) {
            sessionStorage.setItem('appLaunched', Utils.getAppLaunchDateStamp());
            localStorage.setItem('getAppLaunched', Utils.getAppLaunchDateStamp());
            localStorage.removeItem('getAppLaunched');//remove it immediately as storage event payload will have data
            this.checkStorageEvent();

        }
        this.showCookieModal =
            this.applicationService.cookieStorage.getItem("cookie") !==
                "passed" &&
                this.configService.getConfig().BRANCH_ASSISTED === "false" &&
                routePath !== "next-steps" &&
                routePath !== "next-bybranch-pdf" &&
                routePath !== "next-bymail-pdf"
                ? true
                : false;

        const langRegex = new RegExp("[?&]lang(=([^&#]*)|&|#|$)"),
            langResults = langRegex.exec(url);
        const subProductRegex = new RegExp("[?&]subProduct(=([^&#]*)|&|#|$)"),
            subProductresults = subProductRegex.exec(url);
        const promoRegex = new RegExp("[?&]promocode(=([^&#]*)|&|#|$)"),
            promoResults = promoRegex.exec(url);
        const mboxEditRegex = new RegExp("[?&]mboxEdit(=([^&#]*)|&|#|$)"),
            mboxEditResults = mboxEditRegex.exec(url);
        const mboxDisableRegex = new RegExp("[?&]mboxDisable(=([^&#]*)|&|#|$)"),
            mboxDisableResults = mboxDisableRegex.exec(url);

        this.translate.get("pageTitle.branchReferral").subscribe((res) => {
            Utils.setPageTitleBranchReferral(res);
        });
        this.translate.get("pageTitle.branchSaveProgress").subscribe((res) => {
            Utils.setPageTitleSaveForLater(res);
        });
        this.translate.get("pageTitle.branchEsignature").subscribe((res) => {
            Utils.setpageTitleEsignLanding(res);
        });

        let lang = "";

        if (langResults !== null) {
            lang = decodeURIComponent(langResults[2].replace(/\+/g, " "));
        } else {
            lang = null;
        }

        // setting language application wide

        // sub-product
        this.setCurrentSubProduct();

        this.applicationService.setIdleTimeOut(false);

        const dest = this.applicationService.getQueryParams('dest');

        // this.applicationService.setCurrentLanguage();
        if (lang !== '' && lang !== 'undefined' && lang !== 'null' && lang !== null && lang !== undefined) {
            if (dest === BILJ) {
                lang = lang === 'en' || lang === 'fr' ? lang : 'en';
            }
            this.translate.use(lang)
        } else {
            this.translate.use('en')
        }
        Utils.setLanguage(lang);
        if ((Utils.getLanguage() || subProductresults) !== null) {
            const lang = Utils.getLanguage();
            // let lang;

            // SET LANGUAGE
            // if (langResults === null) {

            //     lang = 'en';

            // } else {
            //     lang = decodeURIComponent(langResults[2].replace(/\+/g, ' '));

            // }
            const subProduct =
                subProductresults !== null
                    ? decodeURIComponent(
                        subProductresults[2].replace(/\+/g, " ")
                    )
                    : "";
            const promoCode =
                promoResults !== null
                    ? decodeURIComponent(promoResults[2].replace(/\+/g, " "))
                    : "";

            if (promoResults !== null) {
                sessionStorage.setItem('promoCode', promoCode);
                this.applicationService.applyPromoCode$.next(promoCode);
            }
            if (promoResults == null && this.applicationService.getQueryParams('dest') != CDB) {
                sessionStorage.removeItem('promoCode');
            }
            const mboxEdit = mboxEditResults !== null
                ? decodeURIComponent(
                    mboxEditResults[2].replace(/\+/g, " ")
                )
                : "";
            const mboxDisable = mboxDisableResults !== null
                ? decodeURIComponent(
                    mboxDisableResults[2].replace(/\+/g, " ")
                )
                : "";
            if (
                routePath !== "next-steps" &&
                routePath !== "next-bybranch-pdf" &&
                routePath !== "next-bymail-pdf"
            ) {
                if (
                    routePath !== APP_ROUTES.branch_referral &&
                    routePath !== "branch-assisted" &&
                    routePath !== APP_ROUTES.technical_error &&
                    this.isCookieEnabled
                ) {
                    // IF ROUTE is other than  branch_referral
                    if (
                        this.configService.getConfig().BRANCH_ASSISTED ===
                        "false"
                    ) {

                        const icid = this.applicationService.getQueryParams('icid');
                        this.analytics.setTrackingCode(icid);
                        const dest = this.applicationService.getQueryParams('dest');
                        const code = this.applicationService.getQueryParams('code');
                        const state = this.applicationService.getQueryParams('state');
                        if (dest === BILJ) {
                            /** This is mostly for Local and DIt
                             * In Original redirect ecif and userID will not be in URL
                             *
                             */
                            //START:
                            if (this.applicationService.getQueryParams('ecifID')) {
                                Utils.setJourneyUserEcifId(this.applicationService.getQueryParams('ecifID'));
                            }
                            if (this.applicationService.getQueryParams('logInId')) {
                                Utils.setJourneyUserLoginId(this.applicationService.getQueryParams('logInId'));
                            }
                            // END:

                            if(sessionStorage.getItem("blockJourneyAnotherTabOpen") === "true"){
                                sessionStorage.removeItem("blockJourneyAnotherTabOpen");
                                return;
                            }
                            if (code && state === this.storage.get('state')) {
                                this.applicationService.initiateSession(true)
                                    .then(() => {
                                        this.sessionAndTokenCall(code, subProductresults, lang, subProduct, dest);
                                    });
                            } else {
                                this.applicationService.goToLoginPage(BILJ);
                            }
                        } else if (dest === CDB) { // CDB-OLB
                            let setExpectationObjFromStorage = Utils.getExpectationDataFromStorage();
                            if (setExpectationObjFromStorage !== null) {
                                /**SET FORM DATA*/
                                this.formService.setFormData('setExpectation', setExpectationObjFromStorage);

                                /**Payload */
                                this.formService.getPayload().setScreenerQuestionsData(setExpectationObjFromStorage);
                                this.applicationService.populateSetExpectationForm();
                                sessionStorage.removeItem('setExpectationObj');
                            }

                            if (code && state === this.storage.get('state')) { //CDB flow
                                this.sessionAndTokenCall(code, subProductresults, lang, subProduct, dest);
                            } else if (code && state && !this.storage.get('codeVerifier')) { //LME flow
                                this.applicationService.getCMSMessage().subscribe(response => { // cms messages json
                                    Utils.setCmsMessageObject(response);
                                    this.applicationService.cmsParametersExtracted(true);
                                    if (this.applicationService.getLmeFeatureFlag()) {   // LME feature flag enabled
                                        Utils.setIsLMEUser(true);
                                        sessionStorage.removeItem('promoCode');
                                        if (this.applicationService.getActualOlbLoginFeatureFlag()) { // LME actual flow flag
                                            this.applicationService.initiateSession().then((res: any) => { // setup api
                                                this.sessionAndTokenCall(code, subProductresults, lang, subProduct, dest);
                                            });
                                        } else { // Mock LME flow - No token and session call
                                            this.applicationService.initiateSession().then((res: any) => { // setup api
                                                this.mockLmeflow(code, subProductresults, lang, subProduct);
                                            });
                                        }
                                    } else {
                                        this.nonPilotselfServeFlow(lang, subProduct); // normal self serve flow
                                    }
                                });

                            } else { // non pilot card user - normal self serve flow
                                this.nonPilotselfServeFlow(lang, subProduct);
                            }
                        } else {
                            if (subProductresults !== null) {
                                if (mboxEdit && mboxDisable) {
                                    this.router.navigate(["set-expectation"], {
                                        queryParams: {
                                            lang: lang,
                                            subProduct: subProduct,
                                            mboxEdit: mboxEdit,
                                            mboxDisable: mboxDisable
                                        },
                                    });
                                } else {
                                    this.router.navigate(["set-expectation"], {
                                        queryParams: {
                                            lang: lang,
                                            subProduct: subProduct,
                                        },
                                    });
                                }
                            } else {

                                if (mboxEdit && mboxDisable) {
                                    this.router.navigate(["set-expectation"], {
                                        queryParams: {
                                            lang: lang,
                                            mboxEdit: mboxEdit,
                                            mboxDisable: mboxDisable
                                        },
                                    });
                                } else {
                                    this.router.navigate(["set-expectation"], {
                                        queryParams: { lang: lang },
                                    });
                                }
                            }
                        }
                    } else {
                        if (subProductresults !== null) {

                            if (mboxEdit && mboxDisable) {
                                this.router.navigate(["select-account"], {
                                    queryParams: {
                                        lang: lang,
                                        subProduct: subProduct,
                                        mboxEdit: mboxEdit,
                                        mboxDisable: mboxDisable
                                    },
                                });
                            } else {
                                this.router.navigate(["select-account"], {
                                    queryParams: {
                                        lang: lang,
                                        subProduct: subProduct,
                                    },
                                });
                            }


                        } else {

                            if (mboxEdit && mboxDisable) {
                                this.router.navigate(["select-account"], {
                                    queryParams: {
                                        lang: lang,
                                        mboxEdit: mboxEdit,
                                        mboxDisable: mboxDisable
                                    },
                                });
                            } else {
                                this.router.navigate(["select-account"], {
                                    queryParams: { lang: lang },
                                });
                            }

                        }
                    }
                }

                // IF ROUTE is branch_referral
                if (
                    subProductresults !== null &&
                    routePath === APP_ROUTES.branch_referral &&
                    routePath !== "branch-assisted" &&
                    routePath !== APP_ROUTES.technical_error
                ) {

                    if (mboxEdit && mboxDisable) {
                        this.router.navigate([APP_ROUTES.branch_referral], {
                            queryParams: {
                                lang: lang,
                                subProduct: subProduct,
                                mboxEdit: mboxEdit,
                                mboxDisable: mboxDisable
                            },
                        });
                    } else {
                        this.router.navigate([APP_ROUTES.branch_referral], {
                            queryParams: { lang: lang, subProduct: subProduct },
                        });
                    }
                } else if (routePath === APP_ROUTES.branch_referral) {
                    if (mboxEdit && mboxDisable) {
                        this.router.navigate([APP_ROUTES.branch_referral], {
                            queryParams: {
                                lang: lang,
                                mboxEdit: mboxEdit,
                                mboxDisable: mboxDisable
                            },
                        });
                    } else {
                        this.router.navigate([APP_ROUTES.branch_referral], {
                            queryParams: { lang: lang },
                        });
                    }

                }
            }

            // if user refershes on next step screen take it to select account page
            if (this.isCookieEnabled) {
                const nextStepAgain = sessionStorage.getItem("NextStep");


                // console.log('nextStepAgain--', nextStepAgain);
                // console.log('routepath--', routePath);

                if (
                    nextStepAgain !== undefined &&
                    nextStepAgain === "true" &&
                    routePath === "next-steps"
                ) {
                    if (subProductresults) {
                        if (mboxEdit && mboxDisable) {
                            this.router.navigate(["select-account"], {
                                queryParams: {
                                    lang: lang,
                                    subProduct: subProduct,
                                    mboxEdit: mboxEdit,
                                    mboxDisable: mboxDisable
                                },
                            });
                        } else {
                            this.router.navigate(["select-account"], {
                                queryParams: { lang: lang, subProduct: subProduct },
                            });
                        }

                    } else {
                        if (mboxEdit && mboxDisable) {
                            this.router.navigate(["select-account"], {
                                queryParams: {
                                    lang: lang,
                                    mboxEdit: mboxEdit,
                                    mboxDisable: mboxDisable
                                },
                            });
                        } else {
                            this.router.navigate(["select-account"], {
                                queryParams: { lang: lang },
                            });
                        }

                    }

                    sessionStorage.removeItem("NextStep");
                }
            }
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.configService.getConfig().BRANCH_ASSISTED === "true"
                    ? this.applicationService.setResumeLink(true)
                    : this.applicationService.setResumeLink(false);
            }
        });

        this.resumeModelSubscriber = this.applicationService
            .getResumeApplicationModel()
            .subscribe((value) => {
                this.resumeApplication = value;
            });

        this.investorModelSubscriber = this.applicationService
            .getInvestorApplicationModel()
            .subscribe((value) => {
                this.applicationService.openDialog(
                    this.dialog,
                    InvestorProfileOverlayComponent,
                    { dialogData: {} }
                );
            });

        this.finishLaterModelSubscriber = this.applicationService
            .getFinishLaterModel()
            .subscribe((value) => {
                this.accountsetupDetails.status = value.isAccountSetup;
                this.accountsetupDetails.appNumber = value.appNumber;
                if (this.isCdbOlbUser || this.isJourneyUser) {
                    this.showCdbLogoutModal();
                }
            });

        this.applicationService.exitApplicationModal.subscribe((status) => {
            /**
             * exit application will only be dispayed for Olb user and (AD or SD)
             * so not including the condition here
             */

            if (status) {
                this.showCdbLogoutModal();
            }
        })

        this.signOutModelSubscriber = this.applicationService
            .getModel(MODEL.signOut)
            .subscribe((value) => {
                this.applicationService.signOutClicked.emit(true);
            });

        this.isHowAdworksSubscriber = this.applicationService.showHowADworks$.subscribe(
            (value) => {
                this.showAdWorksModel = value;
            }
        );

        this.isContactUsSubscriber = this.applicationService.showContactUsModel$.subscribe(
            (value) => {
                this.showContactUsModel = value;
            }
        );

        this.otherAccountsModelSubscriber = this.applicationService.showOtherAccountsModel$.subscribe(
            (value) => {
                this.showOtherAccountsModel = value;
            }
        );

        this.pageUnloadSubscriber = this.applicationService.hidePageUndloadPopUp$.subscribe(
            (value) => {
                this.pageUnload = value;
            }
        );

        this.applicationService.cast.subscribe((onTimeout) => {
            this.onTimeout = onTimeout;

            let navParamenter: NavigationExtras = {};

            if (Utils.isSubProductAD()) {
                if (Utils.getMboxEdit() && Utils.getMboxDisable()) {
                    navParamenter = {
                        queryParams: {
                            lang: Utils.getLanguage(),
                            subProduct: Utils.getSubProduct(),
                            mboxEdit: Utils.getMboxEdit(),
                            mboxDisable: Utils.getMboxDisable()
                        },
                    };

                } else {
                    navParamenter = {
                        queryParams: {
                            lang: Utils.getLanguage(),
                            subProduct: Utils.getSubProduct(),
                        },
                    };
                }

            } else {
                if (Utils.getMboxEdit() && Utils.getMboxDisable()) {
                    navParamenter = {
                        queryParams: {
                            lang: Utils.getLanguage(),
                            mboxEdit: Utils.getMboxEdit(),
                            mboxDisable: Utils.getMboxDisable()
                        },
                    };

                } else {
                    navParamenter = {
                        queryParams: {
                            lang: Utils.getLanguage(),
                        },
                    };
                }

            }

            if (onTimeout) {
                const _url = ["/select-account/"];
                this.router.navigate(_url, navParamenter);
            }
        });

        // this.updateLanguage();
        this.onLangChange = this.translate.onLangChange.subscribe(() => {
            this.updateLanguage();
            this.applicationService.getCMSMessage().subscribe(response => {
                if (response && Utils.showCMSMessage) {
                    Utils.setCmsMessageObject(response);
                    this.isEnforceFinishLaterEnabled = this.applicationService.getEnforceFinishLaterFeatureFlag();
                    this.isCookieModalFeatureEnabled = this.applicationService.getCookieModalFeatureFlag();
                    this.isBill64FeatureEnabled = this.applicationService.getBill64FeatureFlag();
                    let countdown = (response.isamSessionTimeoutInMillisecs - response.isamSessionTimeoutInMillisecsCount) / 1000;
                    if (this.configService.getConfig().BRANCH_ASSISTED !== "true" && routePath == APP_ROUTES.next_steps &&
                        sessionStorage.getItem('sessionStartTime')) {  // next steps or document upload page
                        let spentSessionTime = Date.now() - Number(sessionStorage.getItem('sessionStartTime'));
                        let isamSessionTimeoutInMillisecsCount = response.isamSessionTimeoutInMillisecsCount - spentSessionTime;
                        let isamSessionTimeoutInMillisecs = response.isamSessionTimeoutInMillisecs - spentSessionTime;
                        this.sessionTimeOutAndCountDownModal(countdown, isamSessionTimeoutInMillisecsCount, isamSessionTimeoutInMillisecs);
                    } else { // application launch
                        if (this.configService.getConfig().BRANCH_ASSISTED !== "true") {
                            window.sessionStorage.setItem('sessionStartTime', Date.now().toString()); // current date & time in milli seconds
                        }
                        this.sessionTimeOutAndCountDownModal(countdown, response.isamSessionTimeoutInMillisecsCount, response.isamSessionTimeoutInMillisecs);
                    }
                    this.applicationService.cmsParametersExtracted(true);
                    if (this.configService.getConfig().BRANCH_ASSISTED !== "true" && routePath !== APP_ROUTES.technical_error_session && routePath !== APP_ROUTES.technical_error_branchurl) {
                        this.qulatricsObj = new QulatricsObj();
                        this.applicationService.getQualtricsParameters(this.qulatricsObj);
                        if (this.qulatricsObj.surveyType && this.qulatricsObj.timeDelayInSecs) {
                            this.enableSurvey = true;
                            this.cdr.detectChanges();
                        }
                    }
                }
            });
        });

        this.router.events
            .pipe(filter((x) => x instanceof NavigationEnd))
            .subscribe(() => {
                window.scrollTo(0, 0);
                const ele = this.renderer.selectRootElement("#bilHeader", true);
                if (ele) {
                    ele.setAttribute('tabindex', '0');
                    ele.focus();
                    setTimeout(() => {
                        ele.removeAttribute('tabindex');
                    }, 250);
                }
            });

        // this.router.events.subscribe((evt) => {
        //     if (!(evt instanceof NavigationEnd)) {
        //         return;
        //     }
        //     window.scrollTo(0, 0);
        //     document.getElementById('bilHeader').focus();
        // });
        this.isMarginAccountPenetrationEnabled = this.applicationService.getMarginAccountPenetrationFeatureFlag();
        this.getOLBUser();
        this.getJourneyUser();
        this.isDataRationalizationEnabled = this.applicationService.getDataRationalizationFeatureFlag();

        this.restartModalSubscriber = this.applicationService.triggerRestartModal$.subscribe((value) => {
            if (value) {
                this.triggerRestartModal();
                this.stopUserIdleTimer();
            }
        });



    }

    getJourneyUser() {
        this.applicationService.isJourneysUser$.subscribe(() => {
            this.isJourneyUser = Utils.getIsJourneyUser();
        });
    }

    private strRandom(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    sessionTimeOutAndCountDownModal(countdown, isamSessionTimeoutInMillisecsCount, isamSessionTimeoutInMillisecs) {
        setTimeout(() => { // display count down modal at 55th  min
            this.triggerRestartModalCounter(countdown); // 3 mins count down
            this.stopUserIdleTimer();
        }, isamSessionTimeoutInMillisecsCount);
        setTimeout(() => { // display timedout modal at 58th  min
            this.triggerRestartModal();
            this.stopUserIdleTimer();
        }, isamSessionTimeoutInMillisecs);
    }

    getOLBUser() {
        this.cdbOlbService.oLBUser.subscribe(data => {
            this.isCdbOlbUser = Utils.getIsOLBUser();
        });
    }
    hideResumeApplication() {
        this.resumeApplication = false;
    }

    hideCashMarginModal() {
        $('.bmo-cash-margin, .modal-overlay').hide();
        Jquery.modalDissableFocusTrap();
        $('#CASH-MARGIN').focus();
    }

    selectCashOrMarginAccount(cashOrMarginAccount) {
        this.buttonClicked = cashOrMarginAccount;
        this.hideCashMarginModal();
        if (cashOrMarginAccount == this.CASH_CODE) {
            CASH.isChecked = true;
            this.applicationService.emitSelectCashOrMarginAccount(CASH);
        }
        else if (cashOrMarginAccount == this.MARGIN_CODE) {
            MARGIN.isChecked = true;
            this.applicationService.emitSelectCashOrMarginAccount(MARGIN);
        }
    }


    disableSurvey() {
        this.enableSurvey = false;
    }

    hideAccountSetup() {
        this.accountsetupDetails.status = false;
    }

    // set sub-product
    setCurrentSubProduct = () => {
        this.route.queryParams.subscribe((params) => {
            if (params.subProduct) {
                Utils.setSubProduct(params.subProduct);
                this.isSubProductAD = Utils.isSubProductAD();
            }
            if (params.mboxEdit) {
                Utils.setMboxEdit(params.mboxEdit);
            }
            if (params.mboxDisable) {
                Utils.setMboxDisable(params.mboxDisable);
            }
        });
    };

    updateLanguage(): void {
        const lang = document.createAttribute("lang");
        lang.value = this.translate.currentLang;
        this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(
            lang
        );
    }

    hideDocUploadDocs() {
        $(".modal-overlay").hide();
        $(".bmo-doc-upload-setexpect").hide();
        Jquery.modalDissableFocusTrap();
    }

    hideSaveLaterEnabled() {
        $(".modal-overlay").hide();
        $(".save-later-modal").hide();
        Jquery.modalDissableFocusTrap();
    }
    hideSaveProgressEnabled() {
        $(".modal-overlay").hide();
        $(".save-progress-modal").hide();
        Jquery.modalDissableFocusTrap();
    }
    hideSavesEnabled() {
        $(".modal-overlay").hide();
        $(".saved-modal").hide();
        Jquery.modalDissableFocusTrap();
    }
    hideMarginModal() {
        $(".modal-overlay-component").hide();
        $(".bmo-margin-modal").hide();
        $(".modal-overlay").hide();
        $("#creditBureauTermsLink").focus();
        Jquery.modalDissableFocusTrap();
    }
    hideOLBModal() {
        $('.bmo-cdb-login, .modal-overlay').hide();
        Jquery.modalDissableFocusTrap();
        this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.CDB_OVERLAY_CLOSED);
    }
    showCdbLogoutModal() {
        this.applicationSubmitted = false;
        this.applicationSaved = false;

        if (Utils.getapplicationID() && this.applicationService.isNextStepPage()) {
            this.applicationSubmitted = true;
        } else if (Utils.getapplicationID()) {
            this.applicationSaved = true;
        }

        $('.bmo-cdb-logout , .modal-overlay').show();
        Jquery.modalFocusTrap('bmo-cdb-logout');
    }
    hideCdbLogoutModal() {
        this.applicationService.setExitApplicationModal(false);
        $('.bmo-cdb-logout, .modal-overlay').hide();
        Jquery.modalDissableFocusTrap();
    }
    hideCdbDeletetModal() {
        $('.bmo-cdb-delete-app, .modal-overlay').hide();
        Jquery.modalDissableFocusTrap();
    }
    hideErrorModal() {
        $('.bmo-cdb-error-app, .modal-overlay').hide();
        Jquery.modalDissableFocusTrap();
    }
    hideNetWorthModal(calculatorButtonFocus) {
        this.netWorthCalculator = new NetWorthCalculator();
        this.netWorthCalculatorForm.resetForm();
        this.isNetWorthFormSubmit = false;
        $('.bmo-net-worth-modal, .modal-overlay').hide();
        Jquery.modalDissableFocusTrap();
        if (calculatorButtonFocus && document.getElementById('openCalculatorButton')) {
            document.getElementById('openCalculatorButton').focus();
        }
    }
    mockLmeflow(code, subProductresults, lang, subProduct) {

        const ecif = this.applicationService.getQueryParams('ecif');
        const card = this.applicationService.getQueryParams('card');
        Utils.setOLBUserEcifId(ecif);
        Utils.setOLBUserCardNumber(card);
        Utils.setIsOLBUser(true);
        Utils.setAznCode(code);
        if (subProductresults !== null) {
            this.router.navigate(["olb-inprogress"], {
                queryParams: {
                    lang: lang,
                    subProduct: subProduct
                }
            });
        } else {
            this.router.navigate(["olb-inprogress"], {
                queryParams: { lang: lang }
            });
        }
    }

    sessionAndTokenCall(code, subProductresults, lang, subProduct, dest) {
        /* if (dest === CDB) {
            Utils.setIsOLBUser(true);
        } else if (dest === BILJ) {
            Utils.setIsJourneyUser(true);
            this.applicationService.isJourneysUser$.next(true);
        } */
        Utils.setAznCode(code);
        this.applicationService.exchangeIsamSessionFromOtherSysToMywealth(dest, subProduct).then((res: any) => {
            /**
             * tam_OP is for OLB
             * operation is for Journey
             */
            this.applicationService.setOLbJourneyStatus(dest === BILJ, dest === CDB);
            if (res.tam_OP === LOGIN_SUCCESS || res.operation === LOGIN_SUCCESS) {
                if (dest === BILJ && !Utils.getIsSDOwner()) {
                    this.router.navigate(["set-expectation"], {
                        queryParams: {
                            lang: lang,
                            subProduct: subProduct
                        }
                    })
                } else if (subProductresults !== null) {
                    this.router.navigate(["olb-inprogress"], {
                        queryParams: {
                            lang: lang,
                            subProduct: subProduct
                        }
                    });
                } else {
                    this.router.navigate(["olb-inprogress"], {
                        queryParams: { lang: lang }
                    });
                }
            } else {
                this.sessionCallErrorHandling(lang, subProduct);
            }
        }, error => {
            this.sessionCallErrorHandling(lang, subProduct);
        });
    }
    nonPilotselfServeFlow(lang, subProduct) {
        Utils.setIsNonPilotUser(true);
        this.storage.remove('state');
        this.storage.remove('codeVerifier');
        if (this.applicationService.getCsaCfrAttorneyFeatureFlag() === true) {
            this.setExpectation = new SetExpectationObject();
            this.setExpectation = this.formService.getPayload()?.screenerQuestions;
            if (this.setExpectation?.isBmoRightForMe !== undefined && this.setExpectation?.isBmoRightForMe !== null && this.setExpectation?.isBmoRightForMe !== '') {
                this.formService.forms.setExpectation.isCompleted = true;
                this.formService.forms.setExpectation.isVisited = true;
                this.applicationService.gotoSetExpectationOrSelectAccount(APP_ROUTES.select_account, lang, subProduct);
            } else {
                this.applicationService.gotoSetExpectationOrSelectAccount(APP_ROUTES.set_expectation, lang, subProduct);
            }

        } else {
            this.formService.forms.setExpectation.isCompleted = true;
            this.formService.forms.setExpectation.isVisited = true;
            this.applicationService.gotoSetExpectationOrSelectAccount(APP_ROUTES.select_account, lang, subProduct);
        }
    }
    sessionCallErrorHandling(lang, subProduct) {
        console.log('display error popup');
        this.applicationService.resetOlBJourneyStatus();
        /* Utils.setIsJourneyUser(false);
        this.applicationService.isJourneysUser$.next(false);
        Utils.setIsOLBUser(false); */
        Utils.setAznCode(null);
        Utils.setIsLMEUser(false);
        this.storage.remove('state');
        this.storage.remove('codeVerifier');
        Utils.setShowCDBSessionCallError(true);
        if (subProduct) {
            this.router.navigate(["set-expectation"], {
                queryParams: {
                    lang: lang,
                    subProduct: subProduct
                },
            });
        } else {
            this.router.navigate(["set-expectation"], {
                queryParams: { lang: lang },
            });
        }
    }
    deleteOlbInprogress() {
        this.applicationService.deleteApplicationOLB(Utils.getOLBInprogressWorkflowID(), Utils.getOLBInprogressilNum()).subscribe(
            (response: any) => {
                if (Utils.getIsLMEUser()) {
                    this.hideCdbDeletetModal();
                    this.applicationService.gotoSetExpectationOrSelectAccount(APP_ROUTES.set_expectation, Utils.getLanguage(), Utils.getSubProduct());
                    this.windowRef.nativeWindow._satellite.track('deleteApplication');
                } else if (this.applicationService.getCsaCfrAttorneyFeatureFlag() === true) {
                    this.setExpectation = new SetExpectationObject();
                    this.setExpectation = this.formService.getPayload()?.screenerQuestions;
                    if (this.setExpectation?.isBmoRightForMe !== undefined && this.setExpectation?.isBmoRightForMe !== null && this.setExpectation?.isBmoRightForMe !== '') {
                        this.selectAccoutnRedirection();
                    } else {
                        this.hideCdbDeletetModal();
                        const setExpectationObj = new SetExpectationObject(); //reset
                        this.formService.setFormData('setExpectation', setExpectationObj);
                        this.formService.getPayload().setScreenerQuestionsData(setExpectationObj);
                        this.applicationService.populateSetExpectationForm();
                        this.applicationService.gotoSetExpectationOrSelectAccount(APP_ROUTES.set_expectation, Utils.getLanguage(), Utils.getSubProduct());
                        this.windowRef.nativeWindow._satellite.track('deleteApplication');
                    }
                } else {
                    this.selectAccoutnRedirection();
                }
            },
            error => {
                this.systemCriticalError = true;
                setTimeout(() => {
                    this.hideCdbDeletetModal();
                }, 3000)
            });

    }
    selectAccoutnRedirection() {
        this.formService.forms.setExpectation.isCompleted = true;
        this.formService.forms.setExpectation.isVisited = true;
        Utils.setIsSelectAccountClicked(true);
        this.hideCdbDeletetModal();
        this.applicationService.saveAndcontinue(Utils.getExpectationFormData(), APP_ROUTES.select_account);
        this.windowRef.nativeWindow._satellite.track('deleteApplication');
    }
    onOLBModalContinueButton() {
        this.formService.forms.setExpectation.isCompleted = true;
        this.formService.forms.setExpectation.isVisited = true;
        Utils.setIsSelectAccountClicked(true);
        this.applicationService.saveAndcontinue(Utils.getExpectationFormData(), APP_ROUTES.select_account);
        this.hideOLBModal();
    }

    onOLBModalSigninButton() {
        if (this.formService.forms.setExpectation.data != null) {
            let setExpectationObj = this.formService.getFormData('setExpectation');
            Utils.setExpectationDataFromStorage(setExpectationObj);
        }
        if (!this.applicationService.getActualOlbLoginFeatureFlag()) {
            this.router.navigate(['/CDB/login'], { skipLocationChange: true }); // mock CDB  login
        } else {
            this.applicationService.goToLoginPage(CDB);
            // this.cdbOlbService.goToLoginPage();
        }
        this.hideOLBModal();
        // this.router.navigate(['/CDB/login' ] , {skipLocationChange : true}); // mock CDB  login
        this.analytics.setApplicationFlow(Utils.isMultiAccountFlow() ? ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_CDB_MULTI : ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_CDB);
    }


    onCdbexitLogout() {
        if (this.applicationService.isNextStepPage()) {
            this.applicationService.onSignOutNextStepsPage();
        } else {
            this.applicationService.onSignOut();
        }
        this.hideCdbLogoutModal();
    }
    ngAfterViewInit() {
        this.userIdle.startWatching();

        this.userIdle.onTimerStart().subscribe((count) => {
            if (count !== null && count !== undefined) {
                this.applicationService.setIdleTimeOut(true);
                this.closeCurrentModal();
                $(".modal-overlay").show();
                $(".bmo-session-timeout").show();
                this.sessionTimeModelEnabled = true;
                setTimeout(() => {
                    Jquery.modalFocusTrap("bmo-session-timeout");
                }, 100);
                // To display count down
                this.counter = 60 - count;
            }
        });

        this.userIdle.onTimeout().subscribe(() => {
            this.loaderService.setOverlay(false);
            this.applicationService.updateTimeout(true);
            //this.applicationService.onSignOut();
        });

        this.userIdlePingSubscriber = this.userIdle.ping$.subscribe(() => {
            this.loaderService.setOverlay(false);
            this.applicationService.pingToResetISAMSession();
            //this.loaderService.setOverlay(true);
        });

        this.userIdleStopPingSubscriber = this.applicationService.stopPing$.subscribe((value) => {
            if (value) {
                this.userIdle.stopTimer();
                this.userIdle.stopWatching();
                this.userIdlePingSubscriber.unsubscribe();
            }
        });

        // tslint:disable-next-line: prefer-const
        let scriptCall = this.renderer.createElement("script");
        scriptCall.type = `'text/javascript`;
        scriptCall.text = `
         _satellite.pageBottom();
         `;
        this.renderer.appendChild(document.body, scriptCall);
        setTimeout(() => {
            if (this.showCookieModal && !this.isIEBrowser && this.isCookieModalFeatureEnabled) {
                Jquery.modalFocusTrap('bmo-cookie-enabled');
            }
        }, 1000);

    }

    closeCurrentModal(){
        $('.bmo-modal').hide();
    }

    get isMargin() { return this.buttonClicked === this.MARGIN_CODE }
    get isCash() { return this.buttonClicked === this.CASH_CODE }

    ngOnDestroy() {
        if (this.isCookieEnabled)
            window.sessionStorage.setItem('InSession', 'false');
        this.userIdle.stopWatching();
        if (this.onLangChange !== undefined) {
            this.onLangChange.unsubscribe();
        }
        this.resumeModelSubscriber.unsubscribe();
        this.investorModelSubscriber.unsubscribe();
        this.userIdleStopPingSubscriber.unsubscribe();
        this.storageEventSubscriber.unsubscribe();
        this.restartModalSubscriber.unsubscribe();
    }

    stopUserIdleTimer() {
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        this.userIdlePingSubscriber.unsubscribe();
    }

    triggerRestartModal() {
        this.onTimeout = true;
        this.sessionTimeModelEnabled = true;
        setTimeout(() => {
            setTimeout(() => {
                this.closeCurrentModal();
                $(".modal-overlay").show();
                $(".bmo-session-timeout").show();
            }, 95);
                Jquery.modalFocusTrap("bmo-session-timeout");
        }, 100);
    }

    triggerRestartModalCounter(countdown) {
        this.showTimerOnRestartPopup = true;
        this.sessionTimeModelEnabled = true;
        setTimeout(() => {
            this.closeCurrentModal();
            $(".modal-overlay").show();
            $(".bmo-session-timeout").show();
        }, 95);
        setTimeout(() => {
            Jquery.modalFocusTrap("bmo-session-timeout");
        }, 100);
        this.restartCounter = countdown;
        const intervalID = setInterval(() => {
            if (this.restartCounter > 1) {
                this.restartCounter--;
            } else {
                this.triggerRestartModal();
                clearInterval(intervalID);
            }
        }, 1000);
    }

    changeOfRoutes() {
        window.onhashchange = (e) => {
            if (this.onTimeout) {
                this.applicationService.setIdleTimeOut(false);
                Jquery.modalDissableFocusTrap();
                $(".modal-overlay").hide();
                $(".bmo-session-timeout").hide();
                window.location.href = this.generateURL();
                location.reload();
            }
        };
    }

    generateURL() {
        if (Utils.isSubProductAD()) {
            return window.location.href
                .split("#/")[0]
                .concat("#/select-account?lang=")
                .concat(this.translate.currentLang)
                .concat("&subProduct=")
                .concat(Utils.getSubProduct())
        }
        return window.location.href
            .split("#/")[0]
            .concat("#/select-account?lang=")
            .concat(this.translate.currentLang);
    }

    handeIdleTimeNavigation(type: string) {
        this.userIdle.stopWatching();
        this.sessionTimeModelEnabled = false;
        setTimeout(() => {
            Jquery.modalDissableFocusTrap();
            $(".modal-overlay").hide();
            $(".bmo-session-timeout").hide();
        }, 100)

        if (type === "keepGoing") {
            this.showOtherAccountsModel = false;
            if (this.onTimeout) {
                this.applicationService.updateTimeout(false);
                if (this.configService.getConfig().BRANCH_ASSISTED === "false")
                    this.applicationService.hidePageUndloadPopUp$.next(false);
                location.reload();
            } else {
                this.userIdle.startWatching();
            }
        } else if (type === "restart") {
            // This block will never execute, as the Exit Application button is removed from the dialog window
            /* else if (type === 'exitApp') {
                this.onSignOut();
                if (this.applicationService.getCurrentLanguage() === 'en') {
                    document.location.href = 'https://www.bmo.com/investorline/self-directed/';
                }else if (this.applicationService.getCurrentLanguage() === 'fr') {
                    document.location.href = 'https://www.bmo.com/ligne-d-action/placements-autogeres/';
                }
            }*/
            this.applicationService.updateTimeout(false);
            this.userIdle.stopWatching();
            // make js call to refresh page discuss with Hamid
            // window.location.href = "http://localhost:4200/#/select-account?lang=en";
            window.location.href = this.generateURL();
            this.applicationService.hidePageUndloadPopUp$.next(false);
            location.reload();
        }
    }

    // MWB-11623
    @HostListener("document:keydown", ["$event"])
    onKeyDown(evt: KeyboardEvent) {
        if (evt.keyCode === 8 || evt.which === 8) {
            let doPrevent = true;
            const types = [
                "text",
                "password",
                "file",
                "search",
                "email",
                "number",
                "date",
                "color",
                "datetime",
                "datetime-local",
                "month",
                "range",
                "search",
                "tel",
                "time",
                "url",
                "week",
            ];
            const target = <HTMLInputElement>evt.target;

            const disabled =
                target.disabled || (<HTMLInputElement>event.target).readOnly;
            if (!disabled) {
                if (target.isContentEditable) {
                    doPrevent = false;
                } else if (target.nodeName === "INPUT") {
                    let type = target.type;
                    if (type) {
                        type = type.toLowerCase();
                    }
                    if (types.indexOf(type) > -1) {
                        doPrevent = false;
                    }
                } else if (target.nodeName === "TEXTAREA") {
                    doPrevent = false;
                }
            }
            if (doPrevent) {
                evt.preventDefault();
                return false;
            }
        }
    }

    deleteAllCookies() {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let perId = cookie.includes("cookie") || cookie.includes("dar");
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = (perId == true) ? cookie : name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    checkStorageEvent() {
        this.storageEventSubscriber = this.storageEvent?.subscribe(event => {
            let storageEventVar = <StorageEvent>event;
            if (storageEventVar.key == 'getAppLaunched' && storageEventVar.newValue != null && storageEventVar.newValue != Utils.getAppLaunchDateStamp()) {
                if (sessionStorage.getItem('appLaunched')) {
                    localStorage.setItem('appLaunchedMain', Utils.getAppLaunchDateStamp());
                    localStorage.removeItem('appLaunchedMain'); //remove it immediately as storage event payload will have data
                }
            }

            if (storageEventVar.key == 'appLaunchedMain' && storageEventVar.newValue != null && storageEventVar.newValue != Utils.getAppLaunchDateStamp()) {
                Utils.isSessionInitiated = true;
                let navParamenter: NavigationExtras = {};

                if (Utils.isSubProductAD()) {
                    navParamenter = {
                        queryParams: {
                            lang: Utils.getLanguage(),
                            subProduct: Utils.getSubProduct(),
                        },
                    };
                } else {
                    navParamenter = {
                        queryParams: {
                            lang: Utils.getLanguage(),
                        },
                    };
                }
                Utils.setCmsMessageCallNotRequired(true);
                /**
                 * Reason for introducing this session variable
                 * When same environment is open in two diffrent tabs or IL user tried to click open an account multiple times in the same browser
                 * The technical error page is loaded with a flicker,
                 * so adding this session variable and consuming the same in appcomponent and applicationservice to fix the flicker.
                 *
                 */
                sessionStorage.setItem("blockJourneyAnotherTabOpen", "true");
                this.router.navigate(["technical-error-session"], navParamenter);
            }

        })
    }
    get netWorth() {
        let netWorthValue = (+this.netWorthCalculator.totalLiquidAssets + +this.netWorthCalculator.totalFixedAssets) - (+this.netWorthCalculator.liabilities);
        return netWorthValue > 0 ? netWorthValue : 0;
    }

    goToNextSteps() {
        $('.modal-overlay').hide();
        $('.bmo-skip-upload-login').hide();
        Jquery.modalDissableFocusTrap('bmo-skip-upload-login');
        this.applicationService.continue('next-steps');
    }

    cancelSkipDocumentUpload() {
        Utils.setSkipDocUpload(false);
        $('.modal-overlay').hide();
        $('.bmo-skip-upload-login').hide();
        Jquery.modalDissableFocusTrap("bmo-skip-upload-login");
        if (document.getElementById('docSkipButton')) {
            document.getElementById('docSkipButton').focus();
        }
    }

    onNetWorthCalculatorForm() {
        this.isNetWorthFormSubmit = true;
        if (this.netWorthCalculatorForm.valid) {
            this.applicationService.setNetWorthAmount(this.netWorth);
            this.hideNetWorthModal(false);
        } else {
            this.setFocus();
        }
    }
    // For Accessibilty purpose, setFocus() method was created.
    setFocus() {
        // setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {
            if (this.totalLiquidAssetsCtrl.errors) {
                document.getElementById('totalLiquidAssets').focus();
            } else if (this.totalFixedAssetsCtrl.errors) {
                document.getElementById('totalFixedAssets').focus();
            } else if (this.liabilitiesCtrl.errors) {
                document.getElementById('liabilities').focus();
            }
        }, 250)
    }
    @HostListener("window:beforeunload", ["$event"])
    beforeunloadHandler(event) {
        if (this.isCookieEnabled) {
            window.sessionStorage.setItem('InSession', 'false');
            this.deleteAllCookies();
            if (!this.pageUnload) {
                return true;
            }
            window.scrollTo(0, 0);
            return false;
        }
    }

    hideSaveForLaterModal() {
        Jquery.modalDissableFocusTrap();
        this.accountsetupDetails.status = false;
    }
    hideCancelMarginDowngradeModal() {
        this.applicationService.marginCancelDowngrade('');
        $('.bmo-margin-downgrade, .modal-overlay').hide();
        Jquery.modalDissableFocusTrap();
    }

    hideCancelJourneySignInModal(){
        $('.bmo-bil-sign-in, .modal-overlay').hide();
        Jquery.modalDissableFocusTrap();
        this.loaderService.setDisableHideAction(false);
    }

    returntoBIL(){
       this.applicationService.navigateToJourneys();
    }

    onYesMarginDowngrade() {
        this.applicationService.marginCancelDowngrade(DOWNGRADE_DECISIONS.cancel_downgrade);
    }
    @HostListener("keydown", ["$event"])
    onKeyPress(event: KeyboardEvent) {
        if (event.code == "Space" && document.activeElement.tagName == "A" && document.activeElement.getAttribute('role') == 'button') {
            var srcElement = document.getElementById(document.activeElement.id);
            if (srcElement) {
                srcElement.click();
            }
        }
        if (event.code == "Escape") {
            this.hideDocUploadDocs();
            this.hideSaveLaterEnabled();
            this.hideSaveProgressEnabled();
            this.hideSavesEnabled();
            this.hideMarginModal();
            this.hideNetWorthModal(true);
            this.cancelSkipDocumentUpload();
            this.hideCashMarginModal();
            this.hideSaveForLaterModal();
            this.hideCancelMarginDowngradeModal();
            this.hideOLBModal();
            this.hideCancelJourneySignInModal();
        }
    }

}
