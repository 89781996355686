import { NG_VALIDATORS, FormControl, ValidatorFn, Validator, AbstractControl } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[emailValidator][ngModel]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: EmailValidator,
        multi: true
    }]
})
export class EmailValidator implements Validator {
    validator: ValidatorFn;
    constructor() {
        this.validator = this.EmailValidator();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    EmailValidator(): ValidatorFn {
        const emailPattern = new RegExp (/[a-zA-Z0-9._-]+@{1}[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/);
        return (control: AbstractControl) => {
            if (emailPattern.test(control.value)) {
                return null;
            } else {
                return { emailAddress: { invalidEmail: true } };
            }
        };
    }
}
