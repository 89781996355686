/* 
When user will click on finish later button from UI, this component will be Loaded
this component will be shown as PoP up modal and will be destroyed when user will click X 
this component will be loaded anywhere from contact-information screen and ahead whereever User has access to finish later button.
*/


import { Component, OnInit, EventEmitter, Output, Input, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { ApplicationService } from '../../service/application.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ConfigResolverService } from '../../../config/external-config/service/config-resolver.service';
import { Utils } from '../../utils/utils.class';
import { FormService } from '../../service/form.service';
import {  FormControl, NgForm } from '@angular/forms';
import { SUCCESS_RESPONSE , ANALYTICS, ENTER , APP_ROUTES } from '../../../config/app-config.constants';
import { Jquery } from '../../../shared/utils/jquery.class';
import { AnalyticsService } from '../../service/analytics.service';
export class AccountSetup {
    password = null;
    confirmPassword = null;
}
@Component({
    selector: 'app-account-setup',
    templateUrl: './account-setup.component.html'
})
export class AccountSetupComponent implements OnInit, AfterViewInit {

    @ViewChild('accSetupFrom', {static: false}) accountSetupForm: NgForm;
    @ViewChild('accSetupPwdCtrl', { static: false }) accSetupPwdCtrl: FormControl;
    @ViewChild('accSetupConfirmPwdCtrl', { static: false }) accSetupConfirmPwdCtrl: FormControl;
    @Input() applicationNumber;
    public accountSetup = null;
    public isCurrentLangEnglish: Boolean = null;
    public isCurrentLangFrench: Boolean = null;
    @Output() dismissAccountSetup: EventEmitter<any> = new EventEmitter();
    public isFormSubmit: Boolean = false;
    public criticalError: Boolean = false;
    private config: any = null;
    public success = false;
    public showTooltip = false;
    public toolTipTimer;
    public passwordHide: Boolean = true;
    public isEnforceFinishLaterEnabled: Boolean = false;
    constructor(public applicationService: ApplicationService, public formService: FormService,
        private translate: TranslateService, private http: HttpClient,
        private analytics: AnalyticsService,
        private configService: ConfigResolverService<any>) {

        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();

        if (this.isCurrentLangEnglish) {
            this.translate.use('en');
        } else if (this.isCurrentLangFrench) {
            this.translate.use('fr');
        }

    }

    ngOnInit() {
        // console.log('AccountSetupComponent ngOnInit: ');
        this.accountSetup = new AccountSetup();
        this.isEnforceFinishLaterEnabled = this.applicationService.getEnforceFinishLaterFeatureFlag();
    }

    ngAfterViewInit(): void {
        // $('.modal-overlay').show();
        // $('.bmo-doc-upload-setexpect').show();
       
        // Jquery.modalFocusTrap('bmo-doc-upload-setexpect'); 
        this.modelFocusTrap()
        // console.log('overlay opened - save for later');
        this.analytics.setOpenOverlay(ANALYTICS.MODEL.OVERLAY_OPENED.SAVE_FOR_LATER);
    }

    modelFocusTrap() {
        Jquery.modalScrollHeight();
            Jquery.modalFocusTrap('bmo-save-for-later');
    }

    copyApplicationNumber(toolTip) {
        this.showTooltip = toolTip;
        clearTimeout(this.toolTipTimer);
        this.toolTipTimer = setTimeout(() => {
            this.showTooltip = false;
        }, 3000);
    }

    isError(ctrl: any) {
        return (ctrl.invalid && ctrl.touched || (this.isFormSubmit && ctrl.errors));
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === ENTER && this.success) {
            this.onClose();
        }
    }
    onClose() {
        this.dismissAccountSetup.emit();
        if (!this.isEnforceFinishLaterEnabled) {
            Jquery.modalDissableFocusTrap('finishLaterID');
        } else {
            Jquery.modalDissableFocusTrap('continueButton1ID');
        }
        if(this.isEnforceFinishLaterEnabled && this.success) {
            this.applicationService.saveAndcontinue(null, APP_ROUTES.contact_information);
        }
        // console.log('this.success', this.success);
         (this.success === true) ? this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.SAVE_FOR_LATER_COMPLETED)
         : this.analytics.setCloseOverlay(ANALYTICS.MODEL.OVERLAY_CLOSED.SAVE_FOR_LATER);
    }

    createPassword() {
        // console.log('createPassword: ');
        this.isFormSubmit = true;
        if (this.accountSetupForm.valid) {

            this.config = this.configService.getConfig();
            // console.log('this.config: ', this.config);
            const accountSetupURL = this.config.CHANNELS_API.CONTEXT_ROOT + this.config.CHANNELS_API.ACCOUNTSETUP_URI;
            // console.log('accountSetupURL: ', accountSetupURL);
            // const accountSetupURL = 'assets/data/accSetup.mock.json';
            let headers = this.config.CHANNELS_API.ACCOUNTSETUP_HEADER;
            headers['workflow_id'] = localStorage.getItem('appId');  // workflow_id is required for account setup to work
            // console.log('workflow_id', localStorage.getItem('appId'));
            headers = new HttpHeaders(headers);

            const payload = { password: this.accountSetup.password };
            // // console.log('accountSetup payload: ', payload);
            // // console.log('accountSetup headers: ', headers);

            this.http.post(accountSetupURL, payload, { headers: headers, observe: 'response' })
                .toPromise()
                .then(response => {
                    
                    if (response.status === SUCCESS_RESPONSE) {
                        // console.log('save response: ', response.body);
                        const isUserInSession: boolean = JSON.parse(response.headers.get('isinsession'));
                        if (isUserInSession === true) {
                            Utils.setIsInSession(true);
                        } else if (isUserInSession === false) {
                            Utils.setIsInSession(false);
                        }
                        this.success = true;
                        setTimeout(() => {
                            this.modelFocusTrap(); 
                        }, 100);
                        this.applicationService.setSignOutLink(true);  // show sign out as user is logged in now
                        this.applicationService.setFinishLater(false); // hide finish later now as user is in session
                        // console.log('overlay opened - save for later complete');
                        this.analytics.setOpenOverlay(ANALYTICS.MODEL.OVERLAY_OPENED.SAVE_FOR_LATER_COMPLETED);
                    } else {
                        this.criticalError = true;
                    }   

                })
                .catch(e => {
                    // console.log('error : ', e.status, e.statusText);
                    this.criticalError = true;
                    this.analytics.setapiCallDetails({
                        apiCallMade: 'true', apiCallType: "CreatePwd", 
                        apiCallResponseCode: e.error?.errorInfo ? e.status?.toString() + "-" + e.error?.errorInfo.errorCode.toString() : e.status?.toString()

                    });
                });
               
        } else {
            this.setFocus();
            // console.log(' is account setup form valid:', this.accountSetupForm.valid);
        }
       
    }
    togglePasswordVisibility(createPassword : string , confirmPassword : string) {
        this.passwordHide = !this.passwordHide;
        Utils.passwordVisibilityToggle(createPassword);
        Utils.passwordVisibilityToggle(confirmPassword);
    }
        // For Accessibilty purpose, setFocus() method was created.
        setFocus() {

            // setTimeout() method has been used since DOM needs to wait for its handlers.
            setTimeout(() => {
                if (this.accSetupPwdCtrl.errors) { 
                    document.getElementById('createPwd').focus();
                } else if (this.accSetupConfirmPwdCtrl.errors) {
                    document.getElementById('confirmPassword').focus();
                }
            }, 250)

        }

        
}
