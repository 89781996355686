import { ResumeApplicationComponent } from './../../shared/components/resume-application/resume-application.component';
import { Component, OnInit, ViewChild, AfterViewInit, HostListener, Renderer2, OnDestroy } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormControl, NgForm } from '@angular/forms';
import { FormService } from '../../shared/service/form.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {INVESTMENT_KNOWLEDGE_LEVEL} from '../../config/app-config.constants'
import { UserIdleService } from 'angular-user-idle';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
    APP_ROUTES, SD, AD, productTypeAD, productTypeSD,
ANALYTICS, PAGE_TITLES, LOCATION_INDEX, ACCOUNT_CODES, SUCCESS_RESPONSE,
} from './../../config/app-config.constants';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { LoaderService } from '../../shared/service/loader.service';
import { AccountTypeService } from '../../shared/service/account-type.service';
import { filter, forEach, isNil, some, uniq } from 'lodash';
import { Utils } from '../../shared/utils/utils.class';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ContactInformation } from '../../sections/contact-information/contact-information.component';
import { EMPLOYMENTSTATUS, COUNTRY_CODE, ACCOUNT_TYPE , CASH_MARGIN, CASH, MARGIN, INTERNAL , RSM_REFERRAL_ROLE , ACCOUNT_TYPE_ID} from '../../config/app-config.constants';
import { EmploymentInformation } from './../../sections/employment-information/employment-information.component';
import { PersonalInformation } from './../../sections/personal-information/personal-information.component';
import { FamilyInformation } from './../family-information/family-information.component';
import { RegulatoryDisclosure } from './../regulatory-disclosures/regulatory-disclosures.component';
import { FinancialInformation } from './../financial-details/financial-details.component';
import { Consents } from './../consents/consents.component';
import { BmoRelationship } from './../bmo-relationship/bmo-relationship.component';
import { AccountDetail } from './../account-detail/account-detail.component';
import { AccountPassword } from './../account-password/account-password.component';
import { PartyService } from '../../shared/service/party.service';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';

import { Jquery } from './../../shared/utils/jquery.class';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { takeWhile } from 'rxjs/operators';
import { FiveStarProgram } from './../../shared/interfaces/common.model';
import { CdbOlbService } from './../../shared/service/cdb-olb.service';
import { PrefillService } from '../../shared/service/prefill.service';

// will need this in the future once we define more accounts
export class SelectAccount {
    selectedAccount = null;
    selectedAccountTypes = [];
    optionTradingMulti = [];
    promocode = null;
    tncChecked = null;
    tncDocName = null;
    optionsTradingForAllAccounts = true;
    needOptionsTrading = null;
    hasTradingKnowledge = null;
    yearsOfExperience = null;
    tradingKnowledgeLevel = null;
    typesOfOptionsTradedBefore = null;
    typesOfOptionsTradedNow = null;
    needShortSelling = null;
    creditBureautncChecked = null;
    isBmoStaff = null;
    BmoStaffReferrer = null;
    isAdditionalReferral = null;
    EIN = null; // ein
    referralCode = null; // fpCode
    transit = null;
}

@Component({
    selector: 'app-select-account',
    templateUrl: './select-account.component.html'
})

export class SelectAccountComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('selectAccountForm', { static: true }) selectAccountForm: NgForm;
    @ViewChild('OlAPForm', { static: true }) OLAPForm;
    public selectAccount: SelectAccount = new SelectAccount();
    public accountTypes;
    public products;
    public promoApplied: Boolean = false;
    public isCodeApplied: Boolean = false;
    public isCashAccount: Boolean = false;
    public isRRSPAccount: Boolean = false;
    public isTFSAAccount: Boolean = false;
    public disableLetsGo: Boolean = true;
    public accountSelected: boolean = false;
    private isCurrentLangEnglish: boolean;
    private isCurrentLangFrench: boolean;
    public isFormSubmit = false;
    public criticalError = false; // hide and show errors on page
    public systemCriticalError:boolean;
    public isReferenceCallFailed: Boolean = false;
    public formErrorChanges = false;
    public selectedEntry: { [key: string]: any } = {
        id: '',
        value: null,
        description: null
    };
    public  isBMONetwork: boolean = false;
    public lang: string;
    public displayBmoOtherAccounts: boolean;
    public branchSDMultiAccount: Boolean = false;
    public isSubProductAD: Boolean = false;
    public url;
    public showBackButton;
    public listOfOptionsTradingKnowledge;
    public investmentKnowledgeLevel = INVESTMENT_KNOWLEDGE_LEVEL;
    public hasTradingKnowledge: boolean;
    public optionsTypeExperiences;
    public optionTypes;
    public listOfYearsTradingExperience: any;
    public disableNeverTraded = null;
    public disableAccounts: boolean = false;
    public specailOfferPromo: boolean = false;
    public promoCodeDescription: any;
    accountType: any;
    public branchAssisted: boolean = false;
    public OLAPData: String;
    public OLAPFormUrl: String;
    private OLAPUrl_en = '';
    private OLAPUrl_fr = '';
    // tradeOptions: any;
    public isFiveStarProgramEligible = false;
    public cashMargin: any = {};
    public isCdbOlbUser: boolean = false;
    public isJourneyUser: boolean = false;
    public cdbOlbUserFirstName : String = '';
    public cmsMessageObject: any;
    public isSTPOptionsEnabled: any;
    public disableReferAfriend: boolean = false;
    public BmoStaffReferrer: any;
    public rsmReferalRole: any;
    public enableRSM: boolean = false;
    readonly MARGIN_CODE = MARGIN.type
    readonly CASH_CODE = CASH.type
    selectedMarginCashAccount: any = [];
    selectedRegisteredAccounts: any = [];
    selectedAccounts: any = [];
    isRequired: boolean;
    isCashSelected: boolean;
    isAccountTypeNotSelected: boolean;
    isMultiAccountOpen: any;
    accountTypeId = ACCOUNT_TYPE_ID;
    public needOptionTradingMulti: boolean = true;
    public isOptionTypesNotSelected: boolean = false;
    accountsOptionTradingInfo: any = {};
    regAccountsOptionTypes: {};
    isMultiAccountType: any = [];
    selectedMultiAccountId:any = [];
    selectedMultiAccountType:any = [];
    public showRifInfoMessage:boolean;
    //public isEmploymentDetailsFlag: boolean
    public isSDOwner: boolean = false;
    constructor(public titleService: Title,
        private winRef: WindowRefService,
        private applicationService: ApplicationService,
        private formService: FormService,
        private route: ActivatedRoute,
        private configService: ConfigResolverService<any>,
        private translate: TranslateService,
        private analytics: AnalyticsService,
        private router: Router,
        private accountTypeService: AccountTypeService,
        private http: HttpClient,
        private renderer: Renderer2, private partyService: PartyService ,
        public cdbOlbService : CdbOlbService ,
        private prefillService: PrefillService) {

        this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
        this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
        this.partyService.selectAccount = this; // Fix for Circular dependency issue
        this.formService.setActiveComponent('selectAccount', this);
        //
        if (this.isCurrentLangEnglish) {
            translate.use('en');
        } else if (this.isCurrentLangFrench) {
            translate.use('fr');
        }

        this.url = '/' + APP_ROUTES.select_account;
    }

    ngOnInit() {
        // $(".modal-overlay-component").hide();
        this.showBackButton = (this.configService.getConfig().BRANCH_ASSISTED === 'false') ? true : false;
        this.disableReferAfriend = this.applicationService.getReferAFriendFeatureFlag();
        // // console.log("Utils.getIsInSession() in select act--", Utils.getIsInSession());
        this.applicationService.setAutoSaveEnabled((this.configService.getConfig().AUTO_SAVE === 'true') ? true : false);
        this.configService.getConfig().BRANCH_ASSISTED === 'true' ? this.applicationService.setResumeLink(true) : this.applicationService.setResumeLink(false);
        this.branchAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;
        if (this.branchAssisted) {
            const appObject = Utils.getApplicationObject();
            this.OLAPData = (appObject) ? appObject.olapPayload : '';
            this.OLAPData = (this.OLAPData || '').replace('<![CDATA[', '').replace(']]>', '');
          }

        if (this.configService.getConfig() !== null) {
            this.OLAPUrl_en = this.configService.getConfig().ILOLAPWEB_EN;
            this.OLAPUrl_fr = this.configService.getConfig().ILOLAPWEB_FR;
            this.OLAPFormUrl = this.configService.getConfig().OLAP_ENDPOINT;
          }

        this.isSubProductAD = Utils.isSubProductAD();
        this.branchSDMultiAccount = Utils.isBranchSDMultiAccountFlow();
        this.isSTPOptionsEnabled = this.applicationService.getSTPOptionsFeatureFlag();
        this.isMultiAccountOpen = this.applicationService.getMultiAccountOpenFeatureFlag();
        //this.isEmploymentDetailsFlag = Utils.isEmploymentDetails();

        this.applicationService.fetchReferences(this.route);
        if( (Utils.getIsOLBUser() ||  Utils.getIsNonPilotUser() ) && !this.applicationService.appReferences) {
            this.systemCriticalError = true;
        }
        this.tradingOptions();
        if (Utils.getIsInSession() && !Utils.getSignOutClicked() && !Utils.getIsOLBUser()) { // Refresh

            if (!Utils.getIsSelectAccountClicked() && !Utils.backToSelectAccountPage) {
                this.applicationService.retrieveApplicationOnRefresh().subscribe(
                    (response: any) => {
                        if (response.status === 200) {
                            Utils.setApplicationObject(response.body);
                            if(Utils.isMultiAccountFlow() || Utils.isBranchSDMultiAccountFlow()) {
                                this.partyService.onResumeMultiAccountApplicationPrepopulate();
                            } else {
                                this.partyService.onResumeApplicationPrepopulate();
                            }
                            this.applicationService.setResumeLink(false);
                        }
                    },
                    error => {
                        // console.log('error', error);
                    });
            }
            Utils.setIsSelectAccountClicked(false);



        } else if (Utils.getIsInSession() && Utils.getSignOutClicked()) { // OnSignOut

            // this.applicationService.setSignOutLink(false);
            this.configService.getConfig().BRANCH_ASSISTED === 'true' ? this.applicationService.setResumeLink(true) : this.applicationService.setResumeLink(false);

        } else {

            this.applicationService.setSignOutLink(false);
            this.configService.getConfig().BRANCH_ASSISTED === 'true' ? this.applicationService.setResumeLink(true) : this.applicationService.setResumeLink(false);
        }


        // setting title of the page
        this.route.queryParams.subscribe(params => {

            this.titleService.setTitle(PAGE_TITLES.selectAccountEN);
            // English is set as Default

            if (params.lang === 'fr') {
                this.titleService.setTitle(PAGE_TITLES.selectAccountFR);
            }

        });

        this.applicationService.pageTitles();

        if (this.formService.forms.selectAccount.data) {
            if ((this.isSubProductAD || this.branchAssisted || !this.isMultiAccountOpen) && !this.branchSDMultiAccount) {
                this.disableAccounts = (this.formService.forms.selectAccount.isVisited && this.formService.forms.selectAccount.data.selectedAccount != null) ? true : false;
                this.accountType = this.formService.forms.selectAccount.data.selectedAccount ? this.formService.forms.selectAccount.data.selectedAccount : '';
                // this.tradeOptions = this.formService.forms.selectAccount.data.needOptionsTrading ? this.formService.forms.selectAccount.data.needOptionsTrading : '';
            }
            else if((!this.isSubProductAD && !this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
                this.disableAccounts = (this.formService.forms.selectAccount.isVisited && this.formService.forms.selectAccount.data.selectedAccountTypes.length != 0) ? true : false;
                this.accountType = this.formService.forms.selectAccount.data.selectedAccountTypes ? this.formService.forms.selectAccount.data.selectedAccountTypes : [];

            }
        }

        //need to add same subscription for multi account
        this.applicationService.selectAccount$.subscribe((res) => {
          if(res != null && res != undefined && res != '') {
            this.accountType = (res.type == ACCOUNT_TYPE.RRSP && res.isSpousal === true) ? ACCOUNT_TYPE.SRRSP : res.type;
          }
        })

       
         this.titleService.setTitle(Utils.getPageTitleAccountSelection());
         this.accountTypes = this.route.snapshot.data['accountTypes'];
         this.accountTypes.forEach(accountType => {
            accountType['isChecked'] = false  });

        if (this.formService.forms.selectAccount.data != null) {
            this.selectAccount = this.formService.getFormData('selectAccount');
            this.accountTypes.forEach((accountType, index) => {
                if(this.selectAccount.selectedAccountTypes.includes(accountType.type)){
                    accountType.isChecked = true;
                    console.log(accountType);
                 }
                });

            this.disableLetsGo = false;

            // already visited form and now visiting back again
        } else {
            this.selectAccount = new SelectAccount();  // coming to form for the first time
            this.selectAccount.selectedAccount = null; // to avoid default selection of account
        }
        this.formService.setActiveForm(this.selectAccountForm);
        this.displayBmoOtherAccounts = false;

        // this.applicationService.getOLAPURL();
        // getting account types from service
        // this.config = this.configService.getConfig();

        // if call to product eligibility url fails... we need to show critical error.
        if (this.accountTypes === undefined) {
            this.isReferenceCallFailed = true;
        }
        this.route.queryParams.subscribe(params => {
            if (params.lang) {
                this.lang = params.lang;
            } else {
                this.lang = 'en';
            }
            if (params.promocode) {
                this.selectAccount.promocode = params.promocode;
                this.specailOfferPromo = false;
                this.promoCodeApplied(true);
            }
        });

        this.applicationService.applyPromoCode$.subscribe((res) => {
            if (res !== '' && res !== null && res !== undefined) {
                this.selectAccount.promocode = res;
                this.specailOfferPromo = false;
                this.promoCodeApplied(false);
            }
        })

                /** To perform save operation on signout*/
                this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
                    if (data === 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
                        Utils.setsaveDoneOnSignOut(true);
                        this.saveOnSignOut();
                    }
                });
        if ((this.isSubProductAD || this.branchAssisted || !this.isMultiAccountOpen ) && !this.branchSDMultiAccount){
            if (this.formService.forms.selectAccount.isVisited) {

                if (this.formService.getPayload().accounts[0].type == ACCOUNT_TYPE.RRSP
                    && this.formService.getPayload().accounts[0].isSpousal === true) {
                    const selectedAcountValue = this.accountTypes.find(element => element.type === ACCOUNT_TYPE.SRRSP);
                    this.onSelectionChange(selectedAcountValue);
                } else if (this.formService.getPayload().accounts[0].type == ACCOUNT_TYPE.CASH
                    && ((Utils.getSelectedAccountType() === ACCOUNT_TYPE.MARGIN) || this.formService.getPayload().accounts[0].subTypes.includes('2'))) {
                    const selectedAcountValue = this.accountTypes.find(element => element.type === ACCOUNT_TYPE.MARGIN);
                    this.onSelectionChange(selectedAcountValue);
                } else {
                    const selectedAcountValue = this.accountTypes.find(element => element.type === (this.accountType ? this.accountType : this.formService.getPayload().accounts[0].type));
                    this.onSelectionChange(selectedAcountValue);
                }
                let formData = this.formService.getPayload().accounts[0];

                if (formData.promoCode) {
                    this.selectAccount.promocode = formData.promoCode;
                    this.isCodeApplied = true;
                    this.promoApplied = true;
                    this.selectAccount.tncChecked = formData.tncChecked;
                    this.selectAccount.tncDocName = formData.tncDocName;
                }
                if (this.formService.getPayload().parties[0].creditBureauConsent){
                    this.selectAccount.creditBureautncChecked = this.formService.getPayload().parties[0].creditBureauConsent;
                }
                if (this.tradingOptionFields) {

                        // this.selectAccount.needOptionsTrading = formData.accountFeatures.isOptionTrading ? "Yes" : "No";
                        if (formData.accountFeatures.isOptionTrading === true) {
                            this.selectAccount.needOptionsTrading == "Yes";
                        }
                        else if (formData.accountFeatures.isOptionTrading === false) {
                            this.selectAccount.needOptionsTrading == "No";
                        }
                        //  if (this.selectAccount.needOptionsTrading === "Yes") {       MWB-12634  - fix
                        if (this.selectAccount.selectedAccount && (this.selectAccount.selectedAccount === '100544-MARGIN')) {
                            this.optionTypes = this.applicationService.appReferences.references.optionTypes;
                        } else {
                            this.optionTypes = this.optionTypes.filter(function (obj) {
                                return obj.code !== '3';
                            });
                            this.selectAccount.typesOfOptionsTradedNow = this.selectAccount.typesOfOptionsTradedNow?.filter(function (obj) {
                                return obj.code !== '3';
                            });
                        }
                        this.selectAccount.yearsOfExperience = !isNaN(formData.accountFeatures.optionsTrading.experienceInYears) ?
                            formData.accountFeatures.optionsTrading.experienceInYears : null;
                        this.selectAccount.tradingKnowledgeLevel = formData.accountFeatures.optionsTrading.knowledge;
                        for (let index = 0; index < this.optionsTypeExperiences.length; index++) {
                            const element = this.optionsTypeExperiences[index];
                            if (formData.accountFeatures.optionsTrading.optionTypeExperiences.includes(parseInt(element.code)))
                                this.optionsTypeExperiences[index]['checked'] = true;
                        }
                        for (let index = 0; index < this.optionTypes.length; index++) {
                            const element = this.optionTypes[index];
                            if (formData.accountFeatures.optionsTrading.optionTypes.includes(parseInt(element.code)))
                                this.optionTypes[index]['checked'] = true;
                        }
                        // }

                }
            }
        }
        else if ((!this.isSubProductAD && !this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
            if (this.formService.forms.selectAccount.isVisited) {
                for(let i in this.selectAccount.selectedAccountTypes){
                    // this.onMultiAccountTypeSelectionChange(this.selectAccount.selectedAccountTypes[i]);
                     let payload = this.formService.getPayload();
                    if(payload.accounts[i].accountFeatures.isOptionTrading == true){
                        if(payload.accounts[i].isSpousal === true){
                            this.accountsOptionTradingInfo[ACCOUNT_TYPE.SRRSP] = 'Yes';
                        }
                        else if(payload.accounts[i].subTypes?.includes('2')){
                            this.accountsOptionTradingInfo[ACCOUNT_TYPE.MARGIN] = 'Yes';
                        }
                        else{
                            this.accountsOptionTradingInfo[payload.accounts[i].type] = 'Yes';
                        }
                    }

                    this.accountsOptionTradingInfo[this.selectAccount.selectedAccountTypes[i]] = this.selectAccount.optionTradingMulti[i];
                }

                this.changeOptionTradingMulti();
                 this.selectAccount.selectedAccountTypes.forEach((accType)=> this.onMultiAccountTypeSelectionChange(accType))
                   // if (this.formService.getPayload().accounts[0].type == ACCOUNT_TYPE.RRSP
                //     && this.formService.getPayload().accounts[0].isSpousal === true) {
                //     const selectedAcountValue = this.accountTypes.find(element => element.type === ACCOUNT_TYPE.SRRSP);
                //     this.onSelectionChange(selectedAcountValue);
                // } else if (this.formService.getPayload().accounts[0].type == ACCOUNT_TYPE.CASH
                //     && ((Utils.getSelectedAccountType() === ACCOUNT_TYPE.MARGIN) || this.formService.getPayload().accounts[0].subTypes.includes('2'))) {
                //     const selectedAcountValue = this.accountTypes.find(element => element.type === ACCOUNT_TYPE.MARGIN);
                //     this.onSelectionChange(selectedAcountValue);
                // } else {
                //     const selectedAcountValue = this.accountTypes.find(element => element.type === (this.accountType ? this.accountType : this.formService.getPayload().accounts[0].type));
                //     this.onSelectionChange(selectedAcountValue);
                // }

                let formData = this.formService.getPayload().accounts.find(account => account.accountFeatures.isOptionTrading ==true) || this.formService.getPayload().accounts[0];
                //needs to be changed once all select account functionality is done
                if (formData.promoCode) {
                    this.selectAccount.promocode = formData.promoCode;
                    this.isCodeApplied = true;
                    this.promoApplied = true;
                    this.selectAccount.tncChecked = formData.tncChecked;
                    this.selectAccount.tncDocName = formData.tncDocName;
                }
                if (this.formService.getPayload().parties[0].creditBureauConsent) {
                    this.selectAccount.creditBureautncChecked = this.formService.getPayload().parties[0].creditBureauConsent;
                }
                if (this.tradingOptionFields) {
                        // this.selectAccount.needOptionsTrading = formData.accountFeatures.isOptionTrading ? "Yes" : "No";
                        if (formData.accountFeatures.isOptionTrading === true) {
                            this.selectAccount.needOptionsTrading == "Yes";
                        }
                        else if (formData.accountFeatures.isOptionTrading === false) {
                            this.selectAccount.needOptionsTrading == "No";
                        }
                        //  if (this.selectAccount.needOptionsTrading === "Yes") {       MWB-12634  - fix
                        if (this.selectAccount.selectedAccountTypes?.includes('100544-MARGIN')) {
                            this.optionTypes = this.applicationService.appReferences?.references.optionTypes;
                            this.getOptionTypes();
                        } else {
                            this.optionTypes = this.optionTypes?.filter(function (obj) {
                                return obj.code !== '3';
                            });
                            this.selectAccount.typesOfOptionsTradedNow = this.selectAccount.typesOfOptionsTradedNow?.filter(function (obj) {
                                return obj.code !== '3';
                            });
                        }
                        this.selectAccount.yearsOfExperience = !isNaN(formData.accountFeatures.optionsTrading.experienceInYears) ?
                            formData.accountFeatures.optionsTrading.experienceInYears : null;
                        this.selectAccount.tradingKnowledgeLevel = formData.accountFeatures.optionsTrading.knowledge;
                        for (let index = 0; index < this.optionsTypeExperiences?.length; index++) {
                            const element = this.optionsTypeExperiences[index];
                            if (formData.accountFeatures.optionsTrading.optionTypeExperiences?.includes(parseInt(element.code)))
                                this.optionsTypeExperiences[index]['checked'] = true;
                        }

                        for (let index = 0; index < this.optionTypes?.length; index++) {
                            const element = this.optionTypes[index];
                            if (this.formService.getPayload().accounts[this.selectAccount.selectedAccountTypes?.length-1].accountFeatures.optionsTrading.optionTypes.includes(parseInt(element.code))) //getting OptionTypes from the last element in accounts array
                            {
                                this.optionTypes[index]['checked'] = true;
                            }
                            else{
                                this.optionTypes[index]['checked'] = false;
                            }
                        }

                        //  if (this.selectAccount.needOptionsTrading === "Yes") {       MWB-12634  - fix
                        if (this.selectAccount.selectedAccountTypes.length != 0 && this.selectAccount.selectedAccountTypes.includes('100544-MARGIN')) {
                            this.optionTypes = this.applicationService.appReferences?.references.optionTypes;
                        } else {
                            this.optionTypes = this.optionTypes?.filter(function (obj) {
                                return obj.code !== '3';
                            });
                            this.selectAccount.typesOfOptionsTradedNow = this.selectAccount.typesOfOptionsTradedNow?.filter(function (obj) {
                                return obj.code !== '3';
                            });
                        }
                        this.selectAccount.yearsOfExperience = !isNaN(formData.accountFeatures.optionsTrading.experienceInYears) ?
                            formData.accountFeatures.optionsTrading.experienceInYears : null;
                        this.selectAccount.tradingKnowledgeLevel = formData.accountFeatures.optionsTrading.knowledge;
                        for (let index = 0; index < this.optionsTypeExperiences?.length; index++) {
                            const element = this.optionsTypeExperiences[index];
                            if (formData.accountFeatures.optionsTrading.optionTypeExperiences.includes(parseInt(element.code)))
                                this.optionsTypeExperiences[index]['checked'] = true;
                        }
                        for (let index = 0; index < this.optionTypes?.length; index++) {
                            const element = this.optionTypes[index];
                            if (formData.accountFeatures.optionsTrading.optionTypes.includes(parseInt(element.code)))
                                this.optionTypes[index]['checked'] = true;
                        }
                        // }


                }
            }
        }
        // this.applicationService.fetchReferences(this.route);

        // // if call to references fails... we need to show critical error.
        // if (this.applicationService.appReferences !== undefined) {
        //     if (this.applicationService.appReferences.isReferenceCallFailed) {
        //         this.isReferenceCallFailed = true;
        //     }
        // }

        this.BmoStaffReferrer = this.applicationService.appReferences?.references.pwobRsmDesignation ;

        // if these values will be used in other components then move them to a service
        // if (!Utils.accountSelected && this.accountType === '') {
        //     this.selectedEntry = { id: 'none' };
        // }
        this.optionsTypeExperiences = this.selectAccount.typesOfOptionsTradedBefore ? this.selectAccount.typesOfOptionsTradedBefore : this.optionsTypeExperiences;
        const checkedTypeExp = filter(this.optionsTypeExperiences, (exp) => {
            return exp.checked;
        });

        this.disableNeverTraded = checkedTypeExp.length > 0 ? !some(checkedTypeExp, { code: '3' }) : null;
        this.optionTypes = this.selectAccount.typesOfOptionsTradedNow ? this.selectAccount.typesOfOptionsTradedNow : this.optionTypes;

        this.selectAccount.typesOfOptionsTradedNow = this.optionTypes;
        this.selectAccount.typesOfOptionsTradedBefore = this.optionsTypeExperiences;
        if (this.selectAccount.promocode && this.selectAccount.tncChecked) {
            this.isCodeApplied = true;
            this.promoApplied = true;
        }
        this.formService.forms.selectAccount.isVisited = true;
        setTimeout(() => {
            this.setGTM();
        }, 200);
        if(this.applicationService.getMarginAccountPenetrationFeatureFlag() === true) {
            this.accountTypes = this.accountTypes.filter( accountType => {
                return  ( accountType.type !== 'other' && accountType.type != ACCOUNT_TYPE.CASH && accountType.type != ACCOUNT_TYPE.MARGIN)
              });
              this.cashMargin = CASH_MARGIN;
              this.accountTypes.push(CASH_MARGIN);
        } else {
            this.accountTypes = this.accountTypes?.filter(accountType => accountType.type !== 'other');
        }

       if(this.selectAccount.selectedAccountTypes?.includes(this.CASH_CODE) || this.selectAccount.selectedAccountTypes.includes(this.MARGIN_CODE)){
            this.accountTypes[this.accountTypes.findIndex((account => account.type == 'CASH-MARGIN'))].isChecked = true;
        }
        this.selectAccountForm.valueChanges.subscribe(x => {
            this.formErrorChanges = false;
        })

        if(this.selectAccount.selectedAccountTypes?.includes(ACCOUNT_CODES.RIF)) {
            this.showRifInfoMessage = true;
        }

        setInterval(() => {
            if (Utils.callSelectAccount && this.formService.getActiveComponent().url == this.url &&
                this.configService.getConfig().BRANCH_ASSISTED === 'true') {
                 this.ngOnInit();
                Utils.callSelectAccount = false;

            }
        }, 0);

        if (this.configService.getConfig().BRANCH_ASSISTED === "true" && !Utils.getIsBranchAssisted()){
            Utils.setCmsMessageCallNotRequired(true);
            this.applicationService.continue(APP_ROUTES.technical_error_branchurl);
          }
          this.isCdbOlbUser = Utils.getIsOLBUser();
          this.isJourneyUser = Utils.getIsJourneyUser();
          this.isSDOwner = Utils.getIsSDOwner();
        (Utils.getIsOLBUser() || this.isJourneyUser) && this.getOlbUserDetails();

        this.getCashOrMarginAccount()

           if ( this.configService.getConfig().BRANCH_ASSISTED === "false" && !this.isCdbOlbUser && !this.isJourneyUser ) {
            let cmsMessageObject = Utils.getCmsMessageObject();
            if(cmsMessageObject) {
                this.enableRSM = cmsMessageObject.selfServe.enableRSM;
                if(this.enableRSM) {
                    this.RSMchanges();
                }
            }
        }
        if(this.selectAccount.selectedAccountTypes.includes(this.CASH_CODE) || this.selectAccount.selectedAccountTypes.includes(this.MARGIN_CODE)){
            this.accountTypes[this.accountTypes.findIndex((account => account.type == 'CASH-MARGIN'))].isChecked = true;
        }
        this.selectAccount.hasTradingKnowledge = this.applicationService.getHasTradingKnowledge();
        this.changeOptionTradingMulti();
    }
    onBack() {
        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.set_expectation);
    }
    ngAfterViewInit() {
        this.configureAnalytics();
        this.analytics.setPageState(this.url);
        this.analytics.triggerView('SelectAccount');
        this.checkFormValidityOnSignOut();
    }

    getOlbUserDetails() {
        const appObject = Utils.getApplicationObject();
        if(appObject && appObject.parties) {
           this.cdbOlbUserFirstName = appObject.parties[0].personal.identity.legalName.firstName;
           this.isFiveStarProgramEligible = Utils.getIsOLBUser() && !this.isSubProductAD
           && appObject.isApplicantExistingPBClient;
        }

        if( !Utils.getIsLMEUser() && Utils.getOlbAppRetrieved() === false && this.applicationService.appReferences) {
            const payload = {
                ecifId: Utils.getOLBUserEcifId() ?? Utils.getJourneyUserEcifId(),
                subProduct: Utils.getSubProduct(),
                cardNo: Utils.getOLBUserCardNumber(),
                userId: Utils.getJourneyUserLoginId()
            };
            this.applicationService.getOlbUserDetails(payload)
            .pipe(
                takeWhile(() => {return Utils.getAliveOlbService()})
            )
            .subscribe((data: any) => {
                let response = {} ;
                if(this.configService.getConfig().CHANNELS_API.CDB_OLB_MOCK) {
                    response = data[Utils.getOLBUserEcifId()] || data['ecifid2'];
                } else {
                    response = data;
                }
                this.isFiveStarProgramEligible = Utils.getIsOLBUser() && !this.isSubProductAD
                && response['isApplicantExistingPBClient'];
                this.cdbOlbUserFirstName = response['parties'][0].personal.identity.legalName.firstName;
                this.cdbOlbService.cdbOlbUserInfo(this.cdbOlbUserFirstName);


                if (response['parties'][0].personal.employment?.employmentStatus && this.applicationService.appReferences.references?.employmentStatuses) { // MWB-17954
                const userEmpStatus = response['parties'][0].personal.employment.employmentStatus;
                const employmentStatuses = this.applicationService.appReferences.references.employmentStatuses;
                const empStatusExist =  employmentStatuses.some(empStatus=>empStatus.code==userEmpStatus);

                if (!empStatusExist) { // setting users' employmentStatus as empty if it is not existing in reference api employmentStatuses list
                    response['parties'][0].personal.employment.employmentStatus = "";
                }
               }

                Utils.setApplicationObject(response);
                Utils.setOlbAppRetrieved(true);
                this.formService.getPayload().setIsApplicantExistingPBClient(response['isApplicantExistingPBClient']);
                // this.formService.getPayload().setOlbUser(response['olbuser']);
                this.applicationService.setOLbJourneyStatus(response['ilUser'],response['olbuser']);
                this.formService.getPayload().setOLAPPayload(response['olapPayload']); // Adding updated XML to Payload object
                if(response['parties'][0].bmoRelationship) {
                    this.formService.getPayload().setPrivateBankingClient(response['parties'][0].bmoRelationship.isApplicantExistingPBClient);
                }

                    this.formService.getPayload().setBranchBMORelationship(true); // If olb user  set BMO Relationship to true
                   if(response['accounts'] && response['accounts'][0]) {
                   this.formService.getPayload().setCardDetails(response['accounts'][0]);
                   }

                // CONTACT
                this.prefillService.contactInfoPrefill();
                this.prefillService.getEmployemntInfo();
                Utils.setAliveOlbService(false);
            },
            error => {
              this.systemCriticalError = true;
            });
        }

    }

    checkFormValidityOnSignOut() {
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == 'clicked on ' + this.url) {

                if (this.selectAccountForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });
    }

    takeMeThere() {

        if (this.branchAssisted) {
          this.OLAPForm.nativeElement.submit();
        } else {

          const olapURL = (this.isCurrentLangEnglish) ?
            this.OLAPUrl_en
            : this.OLAPUrl_fr;
          window.open(olapURL, 'self');
        }
      }

    showMarginModal() {
        $(".modal-overlay-component").show();
        $(".bmo-margin-modal").show();
        $(".modal-overlay").show();
        Jquery.modalFocusTrap('bmo-margin-modal');
        Jquery.modalScrollHeight();
    }
    // @HostListener('document:keydown', ['$event'])
    // onEscapeKey(event: KeyboardEvent) {
    //     if (this.displayBmoOtherAccounts && (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27)) {
    //         this.displayBmoOtherAccounts = false;
    //     }
    // }

    showBmoOtherAccounts() {
        this.applicationService.showOtherAccountsModel$.next(true)
        // this.renderer.setElementClass(document.body, 'hidden', true);
        // this.renderer.setElementStyle(document.querySelector('.modal-overlay'), 'display', 'block');
    }
    changetradingKnowledgeLevel(event: any){
        if(event.target.value == this.investmentKnowledgeLevel.Limited || event.target.value == this.investmentKnowledgeLevel.Knowledgeable ||  event.target.value == this.investmentKnowledgeLevel.Expert){
            this.selectAccount.hasTradingKnowledge = true;
            this.applicationService.setHasTradingKnowledge(true);
            if (!this.selectAccount.typesOfOptionsTradedNow) {
                this.selectAccount.typesOfOptionsTradedNow = this.optionTypes;
            }
        }
        else if(event.target.value == this.investmentKnowledgeLevel.None){
            this.selectAccount.hasTradingKnowledge = false;
            this.selectAccount.yearsOfExperience = null;
            this.selectAccount.typesOfOptionsTradedNow = null;
            this.applicationService.setHasTradingKnowledge(false);
        }
    }

    // hideBmoOtherAccounts() { // MWB-11621
    //     $('.modal-overlay').hide();
    //     $('.bmo-other-accounts').hide();
    //     Jquery.modalDissableFocusTrap();

    //     // this.renderer.setElementClass(document.body, 'hidden', false);
    //     // this.renderer.setElementStyle(document.querySelector('.modal-overlay'), 'display', null);
    // }

    isAccountSelected() {
        if (this.selectedEntry.id !== 'none') {
            this.accountSelected = true;
            this.isReferenceCallFailed = false;
        } else {
            this.accountSelected = false;
            this.isReferenceCallFailed = true;
        }
    }

    saveOnSignOut(){
        this.isFormSubmit = true;
        this.formErrorChanges = true;
        if((this.selectAccount.tncChecked || this.promoCodeApplied(true)) && this.selectAccountForm.valid){
        this.formService.forms.selectAccount.isCompleted = true;
        this.formService.forms.selectAccount.isVisited = true;
        this.formService.setFormData('selectAccount', this.selectAccount);
        if((!this.isSubProductAD && !this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
            this.formService.getPayload().setMultiAccountDetails(this.selectAccount);
            if(this.isCdbOlbUser) {
                this.formService.getPayload().setCardDetails(Utils.getApplicationObject()['accounts'][0]);
            }
        } else {
            this.formService.getPayload().setAccountDetails(this.selectAccount);
        }
        this.applicationService.save(this.formService.getPayload())
        .then((res: any) => {

            if (res.status === SUCCESS_RESPONSE) {
                const saveResponse = res.body;
                this.applicationService.applicationNumber = saveResponse.applicationNumber;
                this.applicationService.saveOperationPerformed.emit(true);
            } else {
                this.criticalError = true;
            }
        })
        .catch(err => {
            this.criticalError = true;
        });
        }
        else{
            this.isReferenceCallFailed = true;
        }
    }

    onMarginOrCashTile(cashMarginEvent?: boolean ) {
        if( Utils.getIsJourneyUser() && !cashMarginEvent){
            this.applicationService.setNonRegUnselected(false);
        }
        if ((this.isSubProductAD || this.branchAssisted || !this.isMultiAccountOpen) && !this.branchSDMultiAccount) {
            $('.bmo-cash-margin, .modal-overlay').show();
            Jquery.modalFocusTrap('bmo-cash-margin');
            if (this.selectAccount.selectedAccount == '' || this.selectAccount.selectedAccount != this.CASH_CODE || this.selectAccount.selectedAccount != this.MARGIN_CODE) {
                const cashMarginElement = document.getElementById('CASH-MARGIN') as HTMLInputElement;
                cashMarginElement.checked = false;
            }
        }
        else if ((!this.isSubProductAD && !this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
            if (cashMarginEvent) {
                $('.bmo-cash-margin, .modal-overlay').show();
                Jquery.modalFocusTrap('bmo-cash-margin');
                if(Utils.Detect_IE()) {
                    this.accountTypes[this.accountTypes.findIndex((account => account.type == 'CASH-MARGIN'))].isChecked = false;
                    return;
                }
                if (this.selectAccount.selectedAccountTypes.length == 0 || !this.selectAccount.selectedAccountTypes?.includes(this.CASH_CODE) || !this.selectAccount.selectedAccountTypes.includes(this.MARGIN_CODE)) {
                    const cashMarginElement = document.getElementById('CASH_MARGIN') as HTMLInputElement;
                    cashMarginElement.checked = false;
                }
            } else if (!cashMarginEvent){
                this.onMultiAccountTypeSelectionChange(this.selectedMarginCashAccount, false);
                const cashMarginIndex = this.selectAccount.selectedAccountTypes?.findIndex((accountType => (accountType == this.MARGIN_CODE || accountType == this.CASH_CODE)));
                if (cashMarginIndex > -1) { // only splice array when item is found
                    this.selectAccount.selectedAccountTypes?.splice(cashMarginIndex, 1);
                    this.selectedMarginCashAccount = [];
                }
                if(!this.selectAccount.selectedAccountTypes?.includes(this.MARGIN_CODE)){
                    this.optionTypes = this.optionTypes?.filter(function (obj) {
                        return obj.code !== '3';
                    });
                }
            }
            else {
                this.accountTypes[this.accountTypes?.findIndex((account => account.type == 'CASH-MARGIN'))].isChecked = false;
            }
        }
    }

    getCashOrMarginAccount() {
        if((this.isSubProductAD || this.branchAssisted == true || !this.isMultiAccountOpen) && !this.branchSDMultiAccount){
            this.applicationService.setCashMarginAccount.subscribe(data => {
                this.onSelectionChange(data, true);
            });
        }
        else {
            this.applicationService.setCashMarginAccount.subscribe(data => {
                this.onMultiAccountTypeSelectionChange(data, true);
            });
        }
      }

    onSelectionChange(selectedAccount, ChangeOptionypes?: boolean) {
        if (!selectedAccount)
            return;
    //    if(this.configService.getConfig().BRANCH_ASSISTED === 'true') {
    //         this.applicationService.fetchReferences(this.route);
    //         this.tradingOptions();
    //     }


        this.selectedEntry = Object.assign({}, this.selectedEntry, selectedAccount);
        // clone the object for immutability
        this.accountTypeService.setSelectedAccount(selectedAccount);
        // add analytics information
        // this.analytics.setProductName(this.translate.instant(selectedAccount.title));
        this.analytics.setProductName(selectedAccount.id);
        this.analytics.setProductNumber(selectedAccount.type);
        this.analytics.setDowngradeStatus("");
        // this sets selected account type in model
        this.selectAccount.selectedAccount = selectedAccount.type;
        if(this.selectAccount.selectedAccount  === ACCOUNT_CODES.RIF) {
            this.showRifInfoMessage = true;
        } else {
            this.showRifInfoMessage = false;
        }
        if(ChangeOptionypes) {
        if(this.selectAccount.selectedAccount && this.selectAccount.selectedAccount === '100544-MARGIN') {
            this.optionTypes = this.applicationService.appReferences.references.optionTypes;
            this.getOptionTypes();
            this.analytics.triggerView("Margin Account Options");
        }else {
            this.optionTypes = this.optionTypes.filter(function (obj) {
                return obj.code !== '3';
            });
            this.getOptionTypes();
        }

        if (this.selectAccount.selectedAccount && (this.selectAccount.selectedAccount  === ACCOUNT_CODES.TFSA) ||
        (this.selectAccount.selectedAccount  === ACCOUNT_CODES.RIF) ||
        (this.selectAccount.selectedAccount === ACCOUNT_CODES.RRSP || this.selectAccount.selectedAccount === ACCOUNT_CODES.SRRSP)) {
            if(!this.branchSDMultiAccount){
                Utils.setBeneficiaryPageCondition(true);
                this.applicationService.setBeneficiaryNavigationDisabled(false); // Enable Navigation -> Event Emitted
            }
            if ((!this.isSubProductAD)) {
                this.analytics.triggerView("Is Trading Account");
            }

        } else { // If cash Account

        Utils.setBeneficiaryPageCondition(false);
        this.applicationService.setBeneficiaryNavigationDisabled(true); // Disable Navigation -> Event Emitted
        }
        if (this.selectAccount.selectedAccount  === ACCOUNT_CODES.RIF || this.selectAccount.selectedAccountTypes.includes(ACCOUNT_CODES.RIF)) {
            Utils.setWithdrawalPageCondition(true);
            this.applicationService.setWithdrawalNavigationDisabled(false);
        } else {
            Utils.setWithdrawalPageCondition(false);
            this.applicationService.setWithdrawalNavigationDisabled(true);
        }
    }
        // if(selectedAccount.type === 'other') {
        //     this.applicationService.sethidePersonalInfor(true)
        // } else {
        //     this.applicationService.sethidePersonalInfor(false)
        // }

        // allow user to move to next screen by enabling Let's Go button
        this.disableLetsGo = false;
        if (!this.tradingOptionFields) {
            this.resetTradingOption();
        }
        this.resetShortSelling();

    }


    onMultiAccountTypeSelectionChange(selectedAccountType, ChangeOptionypes?: boolean) {
        if (!selectedAccountType)
            return;
        //    if(this.configService.getConfig().BRANCH_ASSISTED === 'true') {
        //         this.applicationService.fetchReferences(this.route);
        //         this.tradingOptions();
        //     }


        this.selectedEntry = Object.assign({}, this.selectedEntry, selectedAccountType);

        // clone the object for immutability
        // add analytics information
        // this.analytics.setProductName(this.translate.instant(selectedAccount.title));

        this.selectedMultiAccountId.push(selectedAccountType.id);
        if((this.selectedMultiAccountType || []).includes(selectedAccountType.type)) {
            (this.selectedMultiAccountType || []).splice(this.selectedMultiAccountType.indexOf(selectedAccountType.type),1);
        } else {
            this.selectedMultiAccountType.push(selectedAccountType.type);
        }

        this.selectedMultiAccountType = uniq(this.selectedMultiAccountType);
        this.analytics.setProductName(this.selectedMultiAccountId);
        this.analytics.setProductNumber(this.selectedMultiAccountType);
        this.analytics.setProductTotalNumber(this.selectedMultiAccountId.length);
        this.analytics.setDowngradeStatus("");
        // this sets selected account type in model
        if((this.selectedMultiAccountType || []).includes(ACCOUNT_CODES.RIF)) {
            this.showRifInfoMessage = true;
        } else {
            this.showRifInfoMessage = false;
        }

        if ((selectedAccountType.type == this.CASH_CODE || selectedAccountType.type == this.MARGIN_CODE) && ChangeOptionypes) {
            this.accountTypes[this.accountTypes.findIndex((account => account.type == 'CASH-MARGIN'))].isChecked = true;
            selectedAccountType.isChecked = true;
            this.selectedMarginCashAccount = selectedAccountType;
        }
        else if ((selectedAccountType.type == this.CASH_CODE || selectedAccountType.type == this.MARGIN_CODE) && !ChangeOptionypes){
            this.accountTypes[this.accountTypes.findIndex((account => account.type == 'CASH-MARGIN'))].isChecked = false;
            selectedAccountType.isChecked = false;
            this.selectedMarginCashAccount = [];
        } else {
            this.selectedRegisteredAccounts = this.accountTypes.filter(accountType => {
                return accountType.isChecked === true && accountType.type !== 'CASH-MARGIN'
            });
        }
        this.selectedAccounts = this.selectedRegisteredAccounts.concat(this.selectedMarginCashAccount);
        this.accountTypeService.setMultiSelectedAccount(this.selectedAccounts);

        if (!this.formService.forms.selectAccount.isCompleted){
        const accountIndex = this.selectAccount.selectedAccountTypes.indexOf(selectedAccountType.type);
        if(selectedAccountType.isChecked == false ){ // remove unselected account option trading index from optionTradingMulti array
           if (accountIndex > -1) {
            this.selectAccount.optionTradingMulti.splice(accountIndex, 1);
          }
        }
    }
        this.selectAccount.selectedAccountTypes = this.selectedAccounts.map(selectAccount => selectAccount.type);
        if(selectedAccountType.isChecked == false){
            delete this.accountsOptionTradingInfo[selectedAccountType.type];
        }

        if (!this.formService.forms.selectAccount.isCompleted){
            const accountIndex = this.selectAccount.selectedAccountTypes.indexOf(selectedAccountType.type);
            if(selectedAccountType.isChecked == true){ // add selected account option trading value as null
               if (accountIndex > -1) {
                this.selectAccount.optionTradingMulti.splice(accountIndex, 0, null);
              }
            }
        }


       this.checkOptionTypeSelectionRequired();
        if (ChangeOptionypes) {
            if (this.selectAccount.selectedAccountTypes?.includes('100544-MARGIN')) {
                this.getOptionTypes();
                this.analytics.triggerView("Margin Account Options");
            } else {
                this.optionTypes = this.optionTypes?.filter(function (obj) {
                    return obj.code !== '3';
                });
                this.getOptionTypes();
            }
            if (this.selectAccount.selectedAccountTypes.length != 0 && (this.selectAccount.selectedAccountTypes.includes(ACCOUNT_CODES.TFSA)) ||
                (this.selectAccount.selectedAccountTypes.includes(ACCOUNT_CODES.RIF)) ||
                (this.selectAccount.selectedAccountTypes.includes(ACCOUNT_CODES.RRSP) || this.selectAccount.selectedAccountTypes.includes(ACCOUNT_CODES.SRRSP))) {
                    if(!this.branchSDMultiAccount){
                        Utils.setBeneficiaryPageCondition(true);
                        this.applicationService.setBeneficiaryNavigationDisabled(false); // Enable Navigation -> Event
                    }


                if ((!this.isSubProductAD)) {
                    this.analytics.triggerView("Is Trading Account");
                }
            } else { // If cash Account
                Utils.setBeneficiaryPageCondition(false);
                this.applicationService.setBeneficiaryNavigationDisabled(true); // Disable Navigation -> Event Emitted
            }
            if (this.selectAccount.selectedAccountTypes?.includes(ACCOUNT_CODES.RIF)) {
                Utils.setWithdrawalPageCondition(true);
                this.applicationService.setWithdrawalNavigationDisabled(false);
            }
        }else {
            if (!this.selectAccount.selectedAccountTypes?.includes(ACCOUNT_CODES.RIF)) {
                Utils.setWithdrawalPageCondition(false);
                this.applicationService.setWithdrawalNavigationDisabled(true);
            }
        }
        // if(selectedAccount.type === 'other') {
        //     this.applicationService.sethidePersonalInfor(true)
        // } else {
        //     this.applicationService.sethidePersonalInfor(false)
        // }
        // allow user to move to next screen by enabling Let's Go button
        this.disableLetsGo = false;
        if (!this.tradingOptionFields) {
            this.resetTradingOption();
        }
        this.resetShortSelling();

        if(this.tradingOptionFields){
            setTimeout(() => {
                Jquery.moreInformation();
              }, 100)
        }
    }

    checkOptionTypeSelectionRequired(){
        if (this.selectAccount.optionsTradingForAllAccounts) {
            if (this.selectAccount.needOptionsTrading == 'Yes') {
                if (this.regAccountsOptionTypes) {
                    for (let i in this.regAccountsOptionTypes) {
                        this.regAccountsOptionTypes[i] = undefined;
                    }
                }
                // For one or more registered account types
                if ((this.selectAccount.selectedAccountTypes?.filter((accType) => accType !== '100544-MARGIN' && accType !== '100544')).length > 0) {
                    this.isOptionTypesNotSelected = true;
                    this.selectAccount.typesOfOptionsTradedNow?.forEach((optionSelected, index) => {
                        if ((optionSelected['code'] == '6' && optionSelected['checked'] == true) || (optionSelected['code'] == '1' && optionSelected['checked'] == true)) {
                            this.isOptionTypesNotSelected = false;
                        }
                    });
                }
                //user has selected only margin account
                if (this.selectAccount.selectedAccountTypes?.includes('100544-MARGIN') && this.selectAccount.selectedAccountTypes?.length == 1) {
                    if (this.selectAccount.typesOfOptionsTradedNow?.every((optionSelected) => optionSelected.checked == false)) {
                        this.isOptionTypesNotSelected = true;
                    }
                    else{
                        this.isOptionTypesNotSelected = false;
                    }
                }
            }
        }
        else {
            if (this.regAccountsOptionTypes) {
                //Yes for atleast one registered accounts
                if (Utils.getValues(this.regAccountsOptionTypes).includes('Yes')) {
                    this.isOptionTypesNotSelected = true;
                    this.selectAccount.typesOfOptionsTradedNow?.forEach((optionSelected, index) => {
                        if ((optionSelected['code'] == '6' && optionSelected['checked'] == true) || (optionSelected['code'] == '1' && optionSelected['checked'] == true)) {
                            this.isOptionTypesNotSelected = false;
                        }
                    })
                }
                else {
                    this.isOptionTypesNotSelected = false;
                }
                     //if user has selected Yes only for Margin Account
              if ((!Utils.getValues(this.regAccountsOptionTypes).includes('Yes') && Object.keys(this.accountsOptionTradingInfo).includes('100544-MARGIN') && this.accountsOptionTradingInfo['100544-MARGIN'] == 'Yes')) {
                if (this.selectAccount.typesOfOptionsTradedNow?.every((optionSelected) => optionSelected.checked == false)) {
                    this.isOptionTypesNotSelected = true;
                }
                else{
                    this.isOptionTypesNotSelected = false;
                }
            }

            }
        }
    }

    changeOptionTradingMulti(eventValue?: any, accTypeCode?) {
        if (this.selectAccount.optionsTradingForAllAccounts) {
            this.needOptionTradingMulti = true;
            if (!this.formService.forms.selectAccount.isCompleted) {
                this.selectAccount.optionTradingMulti = [];
            }
            this.getOptionTypes();
            this.checkOptionTypeSelectionRequired();

        } else {
            //let noOptionCount = this.selectAccount.optionTradingMulti.filter(value => value == 'No').length;
            this.needOptionTradingMulti = true;

            if (accTypeCode) {
                this.selectAccount.selectedAccountTypes?.forEach((type) => {
                    Object.defineProperty(this.accountsOptionTradingInfo, type, { writable: true, enumerable: true });
                })
                this.accountsOptionTradingInfo[accTypeCode] = eventValue;
                this.getOptionTypes();
            }
            this.regAccountsOptionTypes = Object.keys(this.accountsOptionTradingInfo)
                .filter((accType) => (accType !== '100544-MARGIN' && accType !== '100544'))
                .reduce((obj, accType) => {
                    return Object.assign(obj, {
                        [accType]: this.accountsOptionTradingInfo[accType]
                    });
                }, {});
            this.checkOptionTypeSelectionRequired();
            if (!Utils.getValues(this.selectAccount.optionTradingMulti).includes('Yes')) {//if user selects no for all options Trading
                this.needOptionTradingMulti = false;
            }
        }
    }

    get tradingOptionFields() {
        return ((this.selectAccount.selectedAccount == ACCOUNT_TYPE.RRSP ||
            this.selectAccount.selectedAccount == ACCOUNT_TYPE.TFSA ||
            this.selectAccount.selectedAccount == ACCOUNT_TYPE.SRRSP ||
            this.selectAccount.selectedAccount == ACCOUNT_TYPE.MARGIN ||
            this.selectAccount.selectedAccount == ACCOUNT_TYPE.RIF) &&
            (!this.isSubProductAD))||
            ((this.selectAccount.selectedAccountTypes?.length !== 0 &&
                (this.selectAccount.selectedAccountTypes?.includes('100391') ||
            this.selectAccount.selectedAccountTypes?.includes('100562') ||
            this.selectAccount.selectedAccountTypes?.includes(ACCOUNT_TYPE.SRRSP) ||
            this.selectAccount.selectedAccountTypes?.includes(this.MARGIN_CODE) ||
            this.selectAccount.selectedAccountTypes?.includes('100555') &&  (!this.isSubProductAD || this.branchSDMultiAccount))));
    }

    resetTradingOption() {
        if (!this.tradingOptionFields) {
            this.selectAccount.needOptionsTrading = null;
            this.selectAccount.needShortSelling = null;
        }
        this.selectAccount.yearsOfExperience = null;
        this.selectAccount.tradingKnowledgeLevel = null;
        this.selectAccount.typesOfOptionsTradedBefore = null;
        this.selectAccount.typesOfOptionsTradedNow = null;
        this.disableNeverTraded = null;

        this.tradingOptions();
    }
    resetShortSelling() {
        if((this.isSubProductAD || this.branchAssisted == true || !this.isMultiAccountOpen) && !this.branchSDMultiAccount){
        if(this.selectAccount.selectedAccount !== '100544-MARGIN') {
            //this.selectAccount.needShortSelling = null;
            this.selectAccount.creditBureautncChecked = null;
        }
    }
    else {
        if(!this.selectAccount.selectedAccountTypes.includes('100544-MARGIN')){
            this.selectAccount.creditBureautncChecked = null;
        }
    }
    }

    onNeedOptionsTradingSelected() {
        if (this.selectAccount.needOptionsTrading === 'No') {
            this.resetTradingOption();
        }
    }

    getOptionsOfTradedBefore() {
        this.selectAccount.typesOfOptionsTradedBefore = this.optionsTypeExperiences;
        const checkedTypeExp = filter(this.optionsTypeExperiences, (exp) => {
            return exp.checked;
        });
        this.disableNeverTraded = !some(checkedTypeExp, { code: '3' }) && checkedTypeExp.length > 0;
        if (checkedTypeExp.length === 0) {
            this.disableNeverTraded = null;
        }
    }

    getOptionTypes() {

        if ((this.selectAccount.selectedAccount && this.selectAccount.selectedAccount === '100544-MARGIN') || (this.selectAccount.selectedAccountTypes?.includes('100544-MARGIN'))) {
            if (this.accountsOptionTradingInfo['100544-MARGIN'] == 'Yes' || (this.selectAccount.selectedAccountTypes?.length == 1) || this.selectAccount.optionsTradingForAllAccounts) {
                this.optionTypes = this.applicationService.appReferences?.references.optionTypes;
                this.selectAccount.typesOfOptionsTradedNow = this.optionTypes;
            }
            else if (this.accountsOptionTradingInfo['100544-MARGIN'] == 'No' || !this.accountsOptionTradingInfo['100544-MARGIN']) {
                this.optionTypes = this.optionTypes.filter(function (obj) {
                    return obj.code !== '3';
                });
                this.selectAccount.typesOfOptionsTradedNow = this.optionTypes;
            }

            if ((!this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
                this.checkOptionTypeSelectionRequired();
            }
            else {
                this.isOptionTypesNotSelected = false;
            }
        } else {
            this.selectAccount.typesOfOptionsTradedNow = this.optionTypes?.filter(function (obj) {
                return obj.code !== '3';
            });
            //need to check if this has to be moved to checkOptionTypeSelectionRequired()
            if ((!this.isSubProductAD && !this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
                this.isOptionTypesNotSelected = true;
                this.selectAccount.typesOfOptionsTradedNow?.forEach((optionSelected, index) => {
                    if ((optionSelected['code'] == '6' && optionSelected['checked'] == true) || (optionSelected['code'] == '1' && optionSelected['checked'] == true)) {
                        this.isOptionTypesNotSelected = false;
                    }
                })
            }
        }
    }

    tradingOptions() {
        if (!isNil(this.applicationService.appReferences)) {
            this.listOfOptionsTradingKnowledge = this.applicationService.appReferences?.references.optionsKnowledge;
            this.optionsTypeExperiences = this.applicationService.appReferences?.references.optionsTypeExperiences;
            this.optionTypes = this.applicationService.appReferences?.references.optionTypes;
            this.listOfYearsTradingExperience = this.applicationService.appReferences?.references.pwobTradingExperience;

            for (let i = 0; i < this.optionsTypeExperiences.length; i++) {
                this.optionsTypeExperiences[i].checked = false;
            }

            for (let i = 0; i < this.optionTypes.length; i++) {
                if((!this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
                    this.optionTypes[i].checked = true;
                }
                else if((!this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
                if(this.selectAccount.selectedAccount || this.selectAccount.selectedAccountTypes?.length != 0) {
                    this.optionTypes = this.applicationService.appReferences?.references.optionTypes;
                    this.getOptionTypes();
                } else {
                    this.optionTypes = this.optionTypes.filter(function (obj) {
                        return obj.code !== '3';
                    });
                    this.getOptionTypes();
                }
                    this.optionTypes[i].checked = false;
            }

            }
        }
    }

    onPromoCodeChange(value) {
        Utils.tncDocName = null;
        this.selectAccount.tncChecked = false;
        this.isCodeApplied = false;
        if (value.length < 1) {
            this.isCodeApplied = false;
           this.disableLetsGo = false;
        }
        else {
         this.disableLetsGo = true;
        }
    }

    promoCodeApplied(submitKey: boolean) : boolean {
        if (this.selectAccount.promocode !== '' && this.selectAccount.promocode !== null && this.selectAccount.promocode !== undefined ) {
            Utils.tncDocName = null;
            this.applicationService.applyPromoCode(this.lang, this.selectAccount.promocode)
                .then((res: any) => {
                    this.isCodeApplied = true;
                    if (res.isReferenceCallFailed) {
                        this.isReferenceCallFailed = true;
                        this.isCodeApplied = false;
                        return false;
                    } else if (res.validPromo) {
                        this.selectAccount.tncChecked = false;
                        this.promoApplied = true;
                        this.selectAccount.tncDocName = res.termConditionlink;
                        Utils.tncDocName =  res.termConditionlink;
                        this.disableLetsGo = true;
                        this.promoCodeDescription = res.description ? res.description : false;
                        this.analytics.triggerView("Promo code T&C");
                        if (this.formService.getPayload().accounts[0].promoCode === this.selectAccount.promocode && !submitKey) {
                            this.selectAccount.tncChecked = true;
                            this.promoApplied = true;
                            this.selectAccount.tncDocName = res.termConditionlink;
                            Utils.tncDocName =  res.termConditionlink;
                            this.disableLetsGo = false;
                            return true;
                        }
                    } else {
                        this.selectAccountForm.form.setErrors({ 'valid': false });
                        this.formService.setActiveForm(this.selectAccountForm);
                        this.promoApplied = false;
                        this.disableLetsGo = false;
                        this.selectAccount.tncChecked = false;
                        return false;
                    }
                })
                .catch(err => {
                    this.isReferenceCallFailed = true;
                    return false;
                });
        }
        else{
            this.isReferenceCallFailed = false;
            this.promoApplied = false;
            this.disableLetsGo = false;
            return true;
        }
    }

    onTNCChecked(ischecked) {
        this.selectAccount.tncChecked = ischecked;
        this.disableLetsGo = ischecked ? false : true;
    }

    onCreditBureauChecked(ischecked) {
        this.selectAccount.creditBureautncChecked = ischecked;
        this.disableLetsGo = ischecked ? false : true;
    }

    onSubmit() {
        if ((!this.isSubProductAD && !this.branchAssisted && this.isMultiAccountOpen) || this.branchSDMultiAccount){
                    this.onSubmitMultiAccount();
        } else {

            if( (this.isCdbOlbUser || Utils.getIsNonPilotUser()) && this.systemCriticalError) {
                return;
            }

            this.isAccountTypeNotSelected =(this.selectAccount.selectedAccountTypes?.length <= 0)? true : false
            this.isFormSubmit = true;
            this.formErrorChanges = true;
            if(this.tradingOptionFields && !this.selectAccount.needOptionsTrading){
                this.selectAccountForm.form.setErrors({ 'valid': false });
                this.selectAccountForm.form.controls['optionTrading'].setErrors({'errors' : true})
            }
            setTimeout(() => {
                Utils.autoFocusFormFirstField(this.selectAccountForm);
            },250)
            if((this.selectAccount.tncChecked || this.promoCodeApplied(true)) && this.selectAccountForm.valid){
            Utils.accountSelected = this.accountSelected;
            this.applicationService.setResumeLink(false); // hide resume application link when leaving the page

            this.formService.forms.selectAccount.isCompleted = true;
            this.formService.forms.selectAccount.isVisited = true;
            if (this.selectedEntry.id !== 'other') {
                this.onNeedOptionsTradingSelected()
                // setting application sub product type = AD or SD
                if (this.isSubProductAD) {
                    this.formService.getPayload().setApplicationSubProduct(productTypeAD);
                } else {
                    this.formService.getPayload().setApplicationSubProduct(productTypeSD);
                }

                this.formService.forms.selectAccount.isCompleted = true;
                this.formService.setFormData('selectAccount', this.selectAccount);
                // To prepare final payload with selected account details
                    this.formService.getPayload().setAccountType(this.selectAccount);
                    this.formService.getPayload().setAccountDetails(this.selectAccount);



                if(this.isBMONetwork && this.configService.getConfig().BRANCH_ASSISTED === "false") {
                    this.formService.getPayload().setBMOReferral(this.selectAccount);
                }


                    // Set Bene navigation link
                    if (((this.formService.getPayload().accounts[0].type === ACCOUNT_CODES.TFSA) ||
                        (this.formService.getPayload().accounts[0].type === ACCOUNT_CODES.RRSP) ||
                        (this.formService.getPayload().accounts[0].type === ACCOUNT_CODES.RIF)) &&
                        this.formService.getPayload().parties[0].personal.residence.primaryAddress.province !== '110') {
                        if(!this.branchSDMultiAccount){
                            Utils.setBeneficiaryPageCondition(true);
                            this.applicationService.setBeneficiaryNavigationDisabled(false); // Enable Navigation -> Event Emitted
                        }
                    } else { // If cash Account
                        Utils.setBeneficiaryPageCondition(false);
                        this.applicationService.setBeneficiaryNavigationDisabled(true); // Disable Navigation -> Event Emitted
                    }

                    if (this.formService.getPayload().accounts[0].type === ACCOUNT_CODES.RIF) {
                        Utils.setWithdrawalPageCondition(true);
                        this.applicationService.setWithdrawalNavigationDisabled(false);
                    } else {
                        Utils.setWithdrawalPageCondition(false);
                        this.applicationService.setWithdrawalNavigationDisabled(true);
                    }




                if (Utils.getIsBranchReferral() && Utils.getIsInSession() != true) { // Branch-referral
                    this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.branch_save_progress);
                } else {
                    if(Utils.getIsBranchAssisted()) {
                        this.applicationService.save(this.formService.getPayload())
                        .then((res: any) => {
                            if (res.status === SUCCESS_RESPONSE) {
                                this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.personal_information);
                            } else {
                                this.criticalError = true;
                            }
                        });
                    } else {
                        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.personal_information);
                    }
                   // this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.personal_information);
                }
            } else {
                // get OLAP URL from application service in next update
            }

        }
        }

    }


    onSubmitMultiAccount() {

        if( (this.isCdbOlbUser || Utils.getIsNonPilotUser()) && this.systemCriticalError) {
            return;
        }

        this.isAccountTypeNotSelected =(this.selectAccount.selectedAccountTypes?.length <= 0)? true : false
        this.isFormSubmit = true;
        this.formErrorChanges = true;
        if(this.tradingOptionFields && !this.selectAccount.needOptionsTrading){
            this.selectAccountForm.form.setErrors({ 'valid': false });
            this.selectAccountForm.form.controls['optionTrading'].setErrors({'errors' : true})
        }
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.selectAccountForm);
        },250)
        if((this.selectAccount.tncChecked || this.promoCodeApplied(true)) && this.selectAccountForm.valid){
        Utils.accountSelected = this.accountSelected;
        this.applicationService.setResumeLink(false); // hide resume application link when leaving the page

        this.formService.forms.selectAccount.isCompleted = true;
        this.formService.forms.selectAccount.isVisited = true;
        if (this.selectedEntry.id !== 'other') {
            this.onNeedOptionsTradingSelected()
            // setting application sub product type = AD or SD
            if (this.isSubProductAD) {
                this.formService.getPayload().setApplicationSubProduct(productTypeAD);
            } else {
                this.formService.getPayload().setApplicationSubProduct(productTypeSD);
            }

            this.formService.forms.selectAccount.isCompleted = true;
            this.formService.setFormData('selectAccount', this.selectAccount);
            // To prepare final payload with selected account details
                this.formService.getPayload().setMultiAccountTypes(this.selectAccount);
                this.formService.getPayload().setMultiAccountDetails(this.selectAccount);
                if(this.isCdbOlbUser) {
                    this.formService.getPayload().setCardDetails(Utils.getApplicationObject()['accounts'][0]);
                }

            if(this.isBMONetwork && this.configService.getConfig().BRANCH_ASSISTED === "false") {
                this.formService.getPayload().setBMOReferral(this.selectAccount);
            }




               // Set Bene navigation link
               if (((this.formService.getPayload().accounts.some((account) => account.type == ACCOUNT_CODES.TFSA)) ||
                    (this.formService.getPayload().accounts.some((account) => account.type == ACCOUNT_CODES.RRSP)) ||
                    (this.formService.getPayload().accounts.some((account) => account.type == ACCOUNT_CODES.RIF))) &&
                    this.formService.getPayload().parties[0].personal.residence.primaryAddress.province !== '110') {
                        if(!this.branchSDMultiAccount){
                            Utils.setBeneficiaryPageCondition(true);
                            this.applicationService.setBeneficiaryNavigationDisabled(false); // Enable Navigation -> Event Emitted
                        }
                    } else { // If cash Account
                        Utils.setBeneficiaryPageCondition(false);
                        this.applicationService.setBeneficiaryNavigationDisabled(true); // Disable Navigation -> Event Emitted
                    }
                if (this.formService.getPayload().accounts.some((account) => account.type == ACCOUNT_CODES.RIF)) {
                    Utils.setWithdrawalPageCondition(true);
                    this.applicationService.setWithdrawalNavigationDisabled(false);
                } else {
                    Utils.setWithdrawalPageCondition(false);
                    this.applicationService.setWithdrawalNavigationDisabled(true);
                }

            if (Utils.getIsBranchReferral() && Utils.getIsInSession() != true) { // Branch-referral
                this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.branch_save_progress);
            } else {

                if(Utils.getIsBranchAssisted()) {
                    this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        if (res.status === SUCCESS_RESPONSE) {
                            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.personal_information);
                        } else {
                            this.criticalError = true;
                        }
                    });
                } else {
                    this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.personal_information);
                }


            }
        } else {
            // get OLAP URL from application service in next update
        }

    }
    }
    // this method gets value from json file to decide what should title for respective page
    // account selection page title is set in static way
    pageTitles() {

        this.translate.get('pageTitle.accountSelection').subscribe((res) => {
            Utils.setPageTitleAccountSelection(res);
        });

        this.translate.get('pageTitle.accountDetails').subscribe((res) => {
            Utils.setPageTitleAccountDetails(res);
        });

        this.translate.get('pageTitle.thingsYouShouldKnow').subscribe((res) => {
            Utils.setPageTitleThingsYouShouldKnow(res);
        });

        this.translate.get('pageTitle.personalInformation').subscribe((res) => {
            Utils.setPageTitlePersonalInformation(res);
        });

        this.translate.get('pageTitle.contactInformation').subscribe((res) => {
            Utils.setPageTitleContactInformation(res);
        });

        this.translate.get('pageTitle.employmentDetails').subscribe((res) => {
            Utils.setPageTitleEmploymentDetails(res);
        });

        this.translate.get('pageTitle.familyInformation').subscribe((res) => {
            Utils.setPageTitleFamilyInformation(res);
        });

        this.translate.get('pageTitle.beneficiary').subscribe((res) => {
            Utils.setPageTitleBeneficiaryInformation(res);
        });

        this.translate.get('pageTitle.financialDetails').subscribe((res) => {
            Utils.setPageTitleFinancialDetails(res);
        });

        if (Utils.isSubProductAD()) {

            this.translate.get('pageTitle.investmentKnowledge').subscribe((res) => {
                Utils.setPageTitleInvestmentKnowlwedge(res);
            });

            this.translate.get('pageTitle.riskAndReturn').subscribe((res) => {
                Utils.setPageTitleRiskAndReturn(res);
            });

            this.translate.get('pageTitle.investorProfile').subscribe((res) => {
                Utils.setPageTitleInvestorProfile(res);
            });
        }

        this.translate.get('pageTitle.regulatoryDisclosures').subscribe((res) => {
            Utils.setPageTitleRegulatoryDisclosures(res);
        });


        this.translate.get('pageTitle.consents').subscribe((res) => {
            Utils.setPageTitleConsents(res);
        });

        this.translate.get('pageTitle.BMORelationship').subscribe((res) => {
            Utils.setPageTitleBMORelationship(res);
        });

        this.translate.get('pageTitle.accountPassword').subscribe((res) => {
            Utils.setPageTitleAccountPassword(res);
        });

        this.translate.get('pageTitle.finalReview').subscribe((res) => {
            Utils.setPageTitleFinalReview(res);
        });

        this.translate.get('pageTitle.eSignature').subscribe((res) => {
            Utils.setPageTitleESignature(res);
        });

        this.translate.get('pageTitle.completion').subscribe((res) => {
            Utils.setPageTitleCompletion(res);
        });


        this.translate.get('pageTitle.celebrationHalfWay').subscribe((res) => {
            Utils.setPageTitleMidWayCelebration(res);
        });

        this.translate.get('pageTitle.celebrationFinal').subscribe((res) => {
            Utils.setPageTitleFinalCelebration(res);
        });
    }


    configureAnalytics() {

        // this.analytics.setState('step 1');

        // setting analytics property
        if (Utils.isSubProductAD()) {
            this.analytics.setCustomerSource(AD);
        } else {
            this.analytics.setCustomerSource(SD);
        }

        if (Utils.getIsBranchAssisted() === true) {
            this.analytics.setApplicationFlow(Utils.isBranchSDMultiAccountFlow()?ANALYTICS.APPLICATION_FLOW.MULTIASSISTED:ANALYTICS.APPLICATION_FLOW.ASSISTED);
        } else if (Utils.getIsOLBUser()) {
            this.analytics.setApplicationFlow(Utils.isMultiAccountFlow()?ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_CDB_MULTI:ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_CDB);
        } else {
            this.analytics.setApplicationFlow(Utils.isMultiAccountFlow()?ANALYTICS.APPLICATION_FLOW.NON_ASSISTED_MULTI:ANALYTICS.APPLICATION_FLOW.NON_ASSISTED);
        }
    }


    setGTM() {

        const gtmDataLayerForSelectAccount: GTMDataLayer = {
            'event': ANALYTICS.BMOVPageView,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleAccountSelection(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.SELECT_ACCOUNT,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': '',
            'prodNumber': '',
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.select_account,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': ''
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForSelectAccount);

    }

    ngOnDestroy() {
        Utils.callSelectAccount = false;
        if (!Utils.resumeApplicationLogin) {
            if((this.isSubProductAD || this.branchAssisted == true || !this.isMultiAccountOpen) && !this.branchSDMultiAccount){
                this.formService.setFormData('selectAccount', this.selectAccount);
                //this.formService.forms.selectAccount.isCompleted = true;
                this.formService.getPayload().setAccountType(this.selectAccount);
                this.formService.getPayload().setAccountDetails(this.selectAccount);
            } else {
                this.formService.setFormData('selectAccount', this.selectAccount);
                this.formService.getPayload().setMultiAccountTypes(this.selectAccount);
                this.formService.getPayload().setMultiAccountDetails(this.selectAccount);
                if(this.isCdbOlbUser) {
                    this.formService.getPayload().setCardDetails(Utils.getApplicationObject()['accounts'][0]);
                }
            }

        }
        else {
            Utils.resumeApplicationLogin = false;
        }
    }
    RSMchanges() {
        this.rsmReferalRole = RSM_REFERRAL_ROLE;
        this.isBMONetwork = Utils.getIsBMONetwork();
        if(Utils.getAliveCheckNetwork()) {
        this.applicationService.checkNetworkRSM().subscribe(
            (response: any) => {
              if(response?.body?.networkType) {
                this.formService.getPayload().setNetworkType(response.body.networkType);
                if(response.body.networkType === INTERNAL) {
                Utils.setIsBMONetwork(true);
                this.isBMONetwork = true;
                }
             }
             Utils.setAliveCheckNetwork(false);
            });
        }
    }
    onNoBmoStaff() {
        this.selectAccount.BmoStaffReferrer = null;
        this.selectAccount.isAdditionalReferral = null;
        this.selectAccount.EIN = null;
        this.selectAccount.referralCode = null;
        this.selectAccount.transit = null;
        this.analytics.triggerView('noBmoStaff');
    }
    onChangeBmoStaffReferrer(value) {
        this.selectAccount.isAdditionalReferral = null;
        this.selectAccount.EIN = null;
        this.selectAccount.referralCode = null;
    }
    onNoReferredByEmployee() {
        this.selectAccount.EIN = null;
        this.analytics.triggerView('noReferredByEmployee');
    }
}
