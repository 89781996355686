/**
 * @purpose: Regulatory Disclosure
 * @ created: Hari Prasad Kori
 * @ last updated by: Hari   (5th June, 2018)
 * @created on:
 *
 */
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, OnChanges, Renderer2 } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { NgForm, FormControl } from '@angular/forms';
import { FormService } from '../../shared/service/form.service';
import { APP_CONFIG, APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX } from '../../config/app-config.constants';
import { EmploymentInfoService } from '../../shared/service/employment-info.service';
import { isNil, map, remove, find, filter } from 'lodash';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { EMPLOYMENTSTATUS, YES_NO_VALUES } from './../../config/app-config.constants';
import { Utils } from '../../shared/utils/utils.class';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';


@Component({
    selector: 'app-regulatory-disclosures',
    templateUrl: './regulatory-disclosures.component.html',
    providers: [EmploymentInfoService]
})
export class RegulatoryDisclosuresComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @ViewChild('regulatoryDisclosuresForm', { static: true }) regulatoryDisclosuresForm: NgForm;

    // For accessibility purposes
    @ViewChild('selectIntendedUseOfAccountCtrl', { static: true }) selectIntendedUseOfAccountCtrl: FormControl;
    @ViewChild('relationShipCtrl', { static: true }) relationShipCtrl: FormControl;
    @ViewChild('selectMemberFirmCtrl', { static: true }) selectMemberFirmCtrl: FormControl;
    @ViewChild('insiderRelationShipCtrl', { static: true }) insiderRelationShipCtrl: FormControl;
    @ViewChild('companyNameIRCtrl', { static: true }) companyNameIRCtrl: FormControl;
    @ViewChild('companySymbolIRCtrl', { static: true }) companySymbolIRCtrl: FormControl;
    @ViewChild('spouseInsiderRelationshipCtrl', { static: true }) spouseInsiderRelationshipCtrl: FormControl;
    @ViewChild('companyNameIRSCtrl', { static: true }) companyNameIRSCtrl: FormControl;
    @ViewChild('companySymbolIRSCtrl', { static: true }) companySymbolIRSCtrl: FormControl;

    @ViewChild('shareHolderRelationShipCtrl', { static: true }) shareHolderRelationShipCtrl: FormControl;
    @ViewChild('companyNameSSRCtrl', { static: true }) companyNameSSRCtrl: FormControl;
    @ViewChild('companySymbolSSRCtrl', { static: true }) companySymbolSSRCtrl: FormControl;

    @ViewChild('controlRelationshipCtrl', { static: true }) controlRelationshipCtrl: FormControl;
    @ViewChild('companyNameCRCtrl', { static: true }) companyNameCRCtrl: FormControl;
    @ViewChild('companySymbolCRCtrl', { static: true }) companySymbolCRCtrl: FormControl;
    @ViewChild('governmentalRelationshipCtrl', { static: true }) governmentalRelationshipCtrl: FormControl;
    @ViewChild('grPositionCtrl', { static: true }) grPositionCtrl: FormControl;
    @ViewChild('selectDomesticTitleCtrl', { static: true }) selectDomesticTitleCtrl: FormControl;
    @ViewChild('selectForeignTitleCtrl', { static: true }) selectForeignTitleCtrl: FormControl;

    public regulatoryDisclosure: RegulatoryDisclosure = new RegulatoryDisclosure();
    public isFormSubmit = false;
    public memberFirm: any;
    public isPep = false;
    public isSpousePep = false;
    public employmentInformation = null;
    public familyInformation = null;
    public currentOccupation: any;
    public occupationPepOptions: any;
    public maxCompanyNameAndSymbol: any = 10;
    public insiderRelationArr: Array<number> = [];
    public spouseInsiderRelationArr: Array<number> = [];
    public shareHolderRelationArr: Array<number> = [];
    public controlRelationArr: Array<number> = [];
    public insiderRelationCounter: any = 1;
    public spouseInsiderRelationCounter: any = 1;
    public shareHolderRelationCounter: any = 1;
    public controlRelationCounter: any = 1;
    public removedInsiderElementArr: any = [];
    public removedSpouseInsiderElementArr: any = [];
    public removedShareHolderElementArr: any = [];
    public removedControlElementArr: any = [];
    public contextualSearchData: any;
    public isReferenceCallFailed: Boolean = false;
    public reference: any;
    public isTFSA: boolean = false;
    public isCash: boolean = false;
    public isMargin: boolean = false;
    public isRRIF: boolean = false;
    public isRrspAccount: boolean = false;
    public isCIROEnabled: boolean = false;
    public intendedAccounts: any = [];
    public disableFinishLater;
    public showFinishLater;
    public isDataRationalization4Enabled = false;
    public isFormsubmittedAndSaved = false;
    appNumber;
    public accountSetup;
    criticalError: boolean;
    companyName: string = '';

    public url: any;

    private finishLaterSubscriber: Subscription = new Subscription();
    private getFLSubscriber: Subscription = new Subscription();
    public adviceDirect = false;
    public selfDirected = false;
    public APP_ROUTES = APP_ROUTES;
    private onExitApplicationPerformSaveSubscriber;
    public isDataRationalizationEnabled: boolean = false;
    public isNewContentR5Enabled: boolean = false;
    public isMultiAccountOpenFlow: boolean = false;
    public isBranchSDMultiAccountOpenFlow: boolean = false;
    constructor(private formService: FormService,
        public titleService: Title,
        private winRef: WindowRefService,
        private applicationService: ApplicationService,
        private empInfoService: EmploymentInfoService,
        private route: ActivatedRoute,
        public analytics: AnalyticsService,
        public renderer: Renderer2
    ) {

        if (Utils.isSubProductAD()) {
            this.adviceDirect = true;
        } else {
            this.selfDirected = true;
        }

        this.memberFirm = this.empInfoService.getMemberFirmList();
        this.formService.forms.regulatoryDisclosures.isVisited = true;
        this.formService.setActiveComponent('regulatoryDisclosure', this);
        this.currentOccupation = this.empInfoService.getOccupationOptions();
        this.occupationPepOptions = this.empInfoService.getOccupationPep();
        this.isRrspAccount = Utils.isRRSP();
        this.isTFSA = Utils.isTFSA();
        //this.isCash = Utils.isCash();
        this.isCash = this.applicationService.isCashAccount();
        //this.isMargin = Utils.isMargin();
        this.isMargin = this.applicationService.isMargin();
        this.isRRIF = Utils.isRIF();
        this.finishLaterSubscriber = this.applicationService.getPartialSave()
            .subscribe(item => {
                if (item === 'regulatory') {
                    this.onFinishLater();
                }
            });
        this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
            if (value) {
                this.onFinishLater(true);
            }
        });
        if (!isNil(this.formService.forms.employmentInformation.data)) {
            this.employmentInformation = this.formService.getFormData('employmentInformation');
            const occupationToCheck = this.employmentInformation.employmentStatus === EMPLOYMENTSTATUS.RETIRED ?
                this.employmentInformation.selectPreviousOccupation : this.employmentInformation.selectOccupation;
            this.isPep = this.empInfoService.isOccupationPep(occupationToCheck);

        }
        if (!isNil(this.formService.forms.familyInformation.data)) {
            this.familyInformation = this.formService.getFormData('familyInformation');
            this.isSpousePep = this.empInfoService.isOccupationPep(this.familyInformation.selectOccupation);
        }
        this.setDefaultRemoveArray();
        this.formService.setActiveComponent('regulatoryDisclosures', this);

        this.url = '/' + APP_ROUTES.regulatory_disclosures;
    }

    ngAfterViewInit() {
        // this.analytics.setState('step 10');
        this.analytics.setPageState(this.url);
        // this.analytics.setPPVID(ANALYTICS.regulatory_disclosures);
        this.analytics.triggerView('RegulatoryDisclosures');
        this.checkFormValidityOnSignOut();

        // this.analytics.setvPageTitle(Utils.getPageTitleRegulatoryDisclosures());
        // this.analytics.setOriginalLocation(Utils.getPageTitleRegulatoryDisclosures());
        // this.analytics.setvURL(Utils.getvURL());
    }

    ngOnChanges() {
        //this.checkFormValidityOnSignOut();
    }

    ngOnInit() {
        this.isDataRationalizationEnabled = this.applicationService.getDataRationalizationFeatureFlag();
        this.isDataRationalization4Enabled = this.applicationService.getDataRationalization4FeatureFlag();
        this.isNewContentR5Enabled = this.applicationService.getNewContentR5EnabledFeatureFlag();
        this.isMultiAccountOpenFlow = Utils.isMultiAccountFlow();
        this.isBranchSDMultiAccountOpenFlow = Utils.isBranchSDMultiAccountFlow();
        this.isCIROEnabled = this.applicationService.getCIROEnabledFeatureFlag();

        // For branch-assisted
        if (!Utils.getIsBranchAssisted()) {
            if (Utils.getIsInSession()) {
                this.applicationService.setSignOutLink(true);
                this.showFinishLater = false;
            } else {
                this.applicationService.setSignOutLink(false);
                this.showFinishLater = true;
            }
        } else {
            this.applicationService.setSignOutLink(true);
            this.showFinishLater = false;
        }

        this.titleService.setTitle(Utils.getPageTitleRegulatoryDisclosures());

        this.applicationService.fetchReferences(this.route);
        if (this.applicationService.appReferences !== undefined) {
            if (this.applicationService.appReferences.isReferenceCallFailed) {
                this.isReferenceCallFailed = true;
            }
        }
        this.reference = this.applicationService.appReferences.references;
        if (this.formService.forms.regulatoryDisclosures.data != null) {
            // already visited form and now visiting back again
            this.regulatoryDisclosure = this.formService.getFormData('regulatoryDisclosures');

            if (this.regulatoryDisclosure.insiderRelationship && this.regulatoryDisclosure.insiderRelationships.length < 1) { //Insider relationship [] is null
                this.insiderRelationArr = [0];
            }
            if (this.regulatoryDisclosure.spouseInsiderRelationship && this.regulatoryDisclosure.spouseInsiderRelationships.length < 1) {//Spouse insider relationship [] is null
                this.spouseInsiderRelationArr = [0];
            }
            if (this.regulatoryDisclosure.shareholderRelationship && this.regulatoryDisclosure.shareholderRelationships.length < 1) {//Shareholder relationship [] is null
                this.shareHolderRelationArr = [0];
            }
            if (this.regulatoryDisclosure.controlRelationship && this.regulatoryDisclosure.controlRelationships.length < 1) {//Control relationship [] is null
                this.controlRelationArr = [0];
            }

            this.updateRelationships();
        } else {
            this.regulatoryDisclosure = new RegulatoryDisclosure();  // coming to form for the first time

            this.insiderRelationArr = [0];
            this.spouseInsiderRelationArr = [0];
            this.shareHolderRelationArr = [0];
            this.controlRelationArr = [0];
            if (!this.isDataRationalization4Enabled) {
                /* below code check condition is person is politically exposed and update the model*/
                if (this.employmentInformation
                    && !isNil(this.employmentInformation.selectOccupation)
                    && this.isPep) {
                    this.regulatoryDisclosure.governmentalRelationship = 'Yes';
                    this.regulatoryDisclosure.governmentRelationPosition = 'Domestic';
                    this.regulatoryDisclosure.selectDomesticTitle = this.employmentInformation.selectOccupation;
                }
                // if retired and politically exposed:
                if (this.employmentInformation && this.employmentInformation.employmentStatus === EMPLOYMENTSTATUS.RETIRED
                    && !isNil(this.employmentInformation.selectPreviousOccupation)
                    && this.isPep) {
                    this.regulatoryDisclosure.governmentalRelationship = 'Yes';
                    this.regulatoryDisclosure.governmentRelationPosition = 'Domestic';
                    this.regulatoryDisclosure.selectDomesticTitle = this.employmentInformation.selectPreviousOccupation;
                }
                if (this.familyInformation
                    && !isNil(this.familyInformation.selectOccupation)
                    && this.isSpousePep
                    && !this.isPep) {
                    this.regulatoryDisclosure.governmentalRelationship = 'Yes';
                    this.regulatoryDisclosure.governmentRelationPosition = 'Domestic';
                    this.regulatoryDisclosure.selectDomesticTitle = this.familyInformation.selectOccupation;
                }
            }
        }
        this.intendedAccounts = filter(this.reference.accountUses, (account) => {
            // 08 code for option 'Other' and 99  code is for 'Not Provided',
            // this will return the values excpt 'others' and 'not provided' values
            return account.code !== '08' && account.code !== '99';
        });

        this.formService.setActiveForm(this.regulatoryDisclosuresForm);
        /** To perform save operation on signout*/
        this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
            if (data == "perform save " + this.url && !Utils.getsaveDoneOnSignOut()) {
                Utils.setsaveDoneOnSignOut(true);
                this.saveOnSignOut();
            }
        });

        // user has successfully set up account so unsubscribe
        this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
            if (item === false) {

                this.finishLaterSubscriber.unsubscribe();
            }
        });

        setTimeout(() => {
            this.setGTM();
        }, 200);

    }
    verifySpouseInsiderRelationArr(event) { // Check  SpouseInsiderRelationArr length and initialize
        if ((event.target.value === YES_NO_VALUES.YES) && (this.spouseInsiderRelationArr.length === 0)) {
            this.spouseInsiderRelationArr = [0];
        }

    }
    /*For signout save operation*/
    checkFormValidityOnSignOut() {
        /**To check validity on signout */
        this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
            if (data == "clicked on " + this.url) {

                if (this.regulatoryDisclosuresForm.valid) {
                    this.applicationService.validForm = true;
                } else {
                    this.applicationService.validForm = false;
                }
            }
        });

    }

    setDefaultRemoveArray() {
        for (let i = 1; i < this.maxCompanyNameAndSymbol; i++) {
            this.removedInsiderElementArr.push(i);
            this.removedSpouseInsiderElementArr.push(i);
            this.removedShareHolderElementArr.push(i);
            this.removedControlElementArr.push(i);
        }
    }

    updateRelationships() {
        if (this.regulatoryDisclosure.insiderRelationships && this.regulatoryDisclosure.insiderRelationships.length > 0) {
            this.clearModelRelationshipValues('IR');
            this.regulatoryDisclosure.insiderRelationships.forEach((element, index) => {
                element.mapId = index;

                if (index >= 0) {
                    this.regulatoryDisclosure['companyNameIR' + index] = element.companyName;
                    this.regulatoryDisclosure['companySymbolIR' + index] = element.companyTradingSymbol;
                    this.insiderRelationArr.push(index);
                    const tempRemovedIR = remove(this.removedInsiderElementArr, (ele) => {
                        return ele === index;
                    });
                }
            });
        }

        if (this.regulatoryDisclosure.spouseInsiderRelationships && this.regulatoryDisclosure.spouseInsiderRelationships.length > 0) {
            this.clearModelRelationshipValues('IRS');
            this.regulatoryDisclosure.spouseInsiderRelationships.forEach((element, index) => {
                element.mapId = index;
                if (index >= 0) {
                    this.regulatoryDisclosure['companyNameIRS' + index] = element.companyName;
                    this.regulatoryDisclosure['companySymbolIRS' + index] = element.companyTradingSymbol;
                    this.spouseInsiderRelationArr.push(index);
                    const tempRemovedIRS = remove(this.removedSpouseInsiderElementArr, (ele) => {
                        return ele === index;
                    });
                }
            });
        }
        if (this.regulatoryDisclosure.shareholderRelationships && this.regulatoryDisclosure.shareholderRelationships.length > 0) {
            this.clearModelRelationshipValues('SSR');
            this.regulatoryDisclosure.shareholderRelationships.forEach((element, index) => {
                element.mapId = index;
                if (index >= 0) {
                    this.regulatoryDisclosure['companyNameSSR' + index] = element.companyName;
                    this.regulatoryDisclosure['companySymbolSSR' + index] = element.companyTradingSymbol;
                    this.shareHolderRelationArr.push(index);
                    const tempRemovedSSR = remove(this.removedShareHolderElementArr, (ele) => {
                        return ele === index;
                    });
                }
            });
        }
        if (this.regulatoryDisclosure.controlRelationships && this.regulatoryDisclosure.controlRelationships.length > 0) {
            this.clearModelRelationshipValues('CR');
            this.regulatoryDisclosure.controlRelationships.forEach((element, index) => {
                element.mapId = index;
                if (index >= 0) {
                    this.regulatoryDisclosure['companyNameCR' + index] = element.companyName;
                    this.regulatoryDisclosure['companySymbolCR' + index] = element.companyTradingSymbol;
                    this.controlRelationArr.push(index);
                    const tempRemovedCR = remove(this.removedControlElementArr, (ele) => {
                        return ele === index;
                    });
                }
            });
        }
        this.insiderRelationCounter = this.insiderRelationArr.length;
        this.spouseInsiderRelationCounter = this.spouseInsiderRelationArr.length;
        this.shareHolderRelationCounter = this.shareHolderRelationArr.length;
        this.controlRelationCounter = this.controlRelationArr.length;
    }

    clearModelRelationshipValues(relationshipType: any) {
        for (let i = 1; i < this.maxCompanyNameAndSymbol; i++) {
            this.regulatoryDisclosure['companyName' + relationshipType + i] = null;
            this.regulatoryDisclosure['companySymbol' + relationshipType + i] = null;
        }
    }

    addAccessTextToDom(id: any) {
        if (!id) {
            return;
        }
        const ele = this.renderer.selectRootElement(id, true);
        if (ele) {
            this.renderer.setStyle(ele, 'display', 'block');
            setTimeout(() => {
                this.renderer.setStyle(ele, 'display', 'none');
            }, 5000);
        }
    }

    addInsiderRelationship() {
        this.analytics.triggerView('AddInsiderRelationCompany' + this.insiderRelationArr.length);
        if ((this.insiderRelationArr && this.insiderRelationArr.length) < this.maxCompanyNameAndSymbol) {
            if (this.removedInsiderElementArr.length > 0) {
                this.insiderRelationArr.push(this.removedInsiderElementArr[0]);
                const temp = remove(this.removedInsiderElementArr, (ele) => {
                    return ele === this.removedInsiderElementArr[0];
                });
            }
            this.insiderRelationCounter++;
            let elementId = 'companyNameIR' + (this.insiderRelationArr[this.insiderRelationArr.length - 1]);
            this.setFocusByElem(elementId);
            this.addAccessTextToDom('#addInsideRelationships');
        }
    }
    removeInsiderRelationship(removeInsider, symbol) {
        this.analytics.triggerView('RemoveInsiderRelationCompany' + removeInsider);
        if (this.insiderRelationArr && this.insiderRelationArr.length > 1) {
            const removedEle = remove(this.insiderRelationArr, (element) => {
                return element === removeInsider;
            });
            if (this.regulatoryDisclosure['companyNameIR' + removedEle[0]] || this.regulatoryDisclosure['companySymbolIR' + removedEle[0]]) {
                this.companyName = this.regulatoryDisclosure['companyNameIR' + removedEle[0]];
                this.addAccessTextToDom('#deleteButton');
            }
            else {
                this.addAccessTextToDom('#emptyDeleteButton');
            }
            this.removedInsiderElementArr.push(removedEle[0]);
            this.regulatoryDisclosure['companyNameIR' + removedEle[0]] = null;
            this.regulatoryDisclosure['companySymbolIR' + removedEle[0]] = null;
            this.insiderRelationCounter--;
            const temp = remove(this.regulatoryDisclosure.insiderRelationships, (element) => {
                return element.mapId === removeInsider;
            });

            let elementId = 'insiderRelationshipAddMoreButton';
            this.setFocusByElem(elementId);
        }
    }
    addSpouseInsiderRelationship() {
        this.analytics.triggerView('AddSpouseInsiderRelationCompany' + this.spouseInsiderRelationArr.length);
        if ((this.spouseInsiderRelationArr && this.spouseInsiderRelationArr.length) < this.maxCompanyNameAndSymbol) {
            if (this.removedSpouseInsiderElementArr.length > 0) {
                this.spouseInsiderRelationArr.push(this.removedSpouseInsiderElementArr[0]);
                const temp = remove(this.removedSpouseInsiderElementArr, (ele) => {
                    return ele === this.removedSpouseInsiderElementArr[0];
                });
            }
            this.spouseInsiderRelationCounter++;
            let elementId = 'companyNameIRS' + (this.spouseInsiderRelationArr[this.spouseInsiderRelationArr.length - 1]);
            this.setFocusByElem(elementId);
            this.addAccessTextToDom('#addInsideRelationshipsSpouse');
        }
    }
    removeSpouseInsiderRelationship(removeSpouseInsider, symbol) {
        this.analytics.triggerView('RemoveSpouseInsiderRelationCompany' + removeSpouseInsider);
        if (this.spouseInsiderRelationArr && this.spouseInsiderRelationArr.length > 1) {
            const removedEle = remove(this.spouseInsiderRelationArr, (element) => {
                return element === removeSpouseInsider;
            });
            if (this.regulatoryDisclosure['companyNameIRS' + removedEle[0]] || this.regulatoryDisclosure['companySymbolIRS' + removedEle[0]]) {
                this.companyName = this.regulatoryDisclosure['companyNameIRS' + removedEle[0]];
                this.addAccessTextToDom('#deleteButton');
            }
            else {
                this.addAccessTextToDom('#emptyDeleteButton');
            }
            this.removedSpouseInsiderElementArr.push(removedEle[0]);
            this.regulatoryDisclosure['companyNameIRS' + removedEle[0]] = null;
            this.regulatoryDisclosure['companySymbolIRS' + removedEle[0]] = null;
            this.spouseInsiderRelationCounter--;
            const temp = remove(this.regulatoryDisclosure.spouseInsiderRelationships, (element) => {
                return element.mapId === removeSpouseInsider;
            });

            let elementId = 'spouseInsiderRelationshipAddMoreButton';
            this.setFocusByElem(elementId);
        }
    }
    addShareholderRelationship() {
        this.analytics.triggerView('AddShareholderRelationshipCompany' + this.shareHolderRelationArr.length);

        if ((this.shareHolderRelationArr && this.shareHolderRelationArr.length) < this.maxCompanyNameAndSymbol) {
            if (this.removedShareHolderElementArr.length > 0) {
                this.shareHolderRelationArr.push(this.removedShareHolderElementArr[0]);
                const temp = remove(this.removedShareHolderElementArr, (ele) => {
                    return ele === this.removedShareHolderElementArr[0];
                });
            }
            this.shareHolderRelationCounter++;
            let elementId = 'companyNameSSR' + (this.shareHolderRelationArr[this.shareHolderRelationArr.length - 1]);
            this.setFocusByElem(elementId);
            this.addAccessTextToDom('#addsignificantShareholderRelationships');
        }
    }
    removeShareholderRelationship(removeShareholder, symbol) {
        this.analytics.triggerView('RemoveShareholderRelationshipCompany' + removeShareholder);

        if (this.shareHolderRelationArr && this.shareHolderRelationArr.length > 1) {
            const removedEle = remove(this.shareHolderRelationArr, (element) => {
                return element === removeShareholder;
            });
            if (this.regulatoryDisclosure['companyNameSSR' + removedEle[0]] || this.regulatoryDisclosure['companySymbolSSR' + removedEle[0]]) {
                this.companyName = this.regulatoryDisclosure['companyNameSSR' + removedEle[0]];
                this.addAccessTextToDom('#deleteButton');
            }
            else {
                this.addAccessTextToDom('#emptyDeleteButton');
            }
            this.removedShareHolderElementArr.push(removedEle[0]);
            this.regulatoryDisclosure['companyNameSSR' + removedEle[0]] = null;
            this.regulatoryDisclosure['companySymbolSSR' + removedEle[0]] = null;
            this.shareHolderRelationCounter--;
            const temp = remove(this.regulatoryDisclosure.shareholderRelationships, (element) => {
                return element.mapId === removeShareholder;
            });

            let elementId = 'shareholderRelationshipAddMoreButton';
            this.setFocusByElem(elementId);
        }
    }
    addControlRelationship() {
        this.analytics.triggerView('AddControlRelationArrCompany' + this.controlRelationArr.length);
        if ((this.controlRelationArr && this.controlRelationArr.length) < this.maxCompanyNameAndSymbol) {
            if (this.removedControlElementArr.length > 0) {
                this.controlRelationArr.push(this.removedControlElementArr[0]);
                const temp = remove(this.removedControlElementArr, (ele) => {
                    return ele === this.removedControlElementArr[0];
                });
            }
            this.controlRelationCounter++;
            let elementId = 'companyNameCR' + (this.controlRelationArr[this.controlRelationArr.length - 1]);
            this.setFocusByElem(elementId);
            this.addAccessTextToDom('#addControlRelationship');
        }
    }
    removeControlRelationship(removeControl, symbol) {
        this.analytics.triggerView('RemoveControlRelationArrCompany' + removeControl);
        if (this.controlRelationArr && this.controlRelationArr.length > 1) {
            const removedEle = remove(this.controlRelationArr, (element) => {
                return element === removeControl;
            });
            if (this.regulatoryDisclosure['companyNameCR' + removedEle[0]] || this.regulatoryDisclosure['companySymbolCR' + removedEle[0]]) {
                this.companyName = this.regulatoryDisclosure['companyNameCR' + removedEle[0]];
                this.addAccessTextToDom('#deleteButton');
            }
            else {
                this.addAccessTextToDom('#emptyDeleteButton');
            }
            this.removedControlElementArr.push(removedEle[0]);
            this.regulatoryDisclosure['companyNameCR' + removedEle[0]] = null;
            this.regulatoryDisclosure['companySymbolCR' + removedEle[0]] = null;
            this.controlRelationCounter--;
            const temp = remove(this.regulatoryDisclosure.controlRelationships, (element) => {
                return element.mapId === removeControl;
            });
            let elementId = 'controlRelationshipAddMoreButton';
            this.setFocusByElem(elementId);
        }
    }

    // This Function reset the values of form for regulatory page screen on moving to the next page,
    //  when the response is selected as 'NO' and coming back to the page  the field becomes blank.
    resetComponentObjectAttr() {
        if (this.regulatoryDisclosure.relationShip === 'No') {
            this.regulatoryDisclosure.memberFirm = null;
        }
        if (this.regulatoryDisclosure.insiderRelationship === 'No') {
            for (let i = 0; i < this.maxCompanyNameAndSymbol; i++) {
                this.regulatoryDisclosure['companyNameIR' + i] = null;
                this.regulatoryDisclosure['companySymbolIR' + i] = null;
                this.regulatoryDisclosure['companyNameIRS' + i] = null;
                this.regulatoryDisclosure['companySymbolIRS' + i] = null;
            }
            this.regulatoryDisclosure.insiderRelationships = [];
            this.regulatoryDisclosure.spouseInsiderRelationships = [];
        }
        if (this.regulatoryDisclosure.spouseInsiderRelationship === 'No') {
            for (let i = 0; i < this.maxCompanyNameAndSymbol; i++) {
                this.regulatoryDisclosure['companyNameIRS' + i] = null;
                this.regulatoryDisclosure['companySymbolIRS' + i] = null;
            }
            this.regulatoryDisclosure.spouseInsiderRelationships = [];
        }
        if (this.regulatoryDisclosure.shareholderRelationship === 'No') {
            for (let i = 0; i < this.maxCompanyNameAndSymbol; i++) {
                this.regulatoryDisclosure['companyNameSSR' + i] = null;
                this.regulatoryDisclosure['companySymbolSSR' + i] = null;
            }
            this.regulatoryDisclosure.shareholderRelationships = [];
        }
        if (this.regulatoryDisclosure.controlRelationship === 'No') {
            for (let i = 0; i < this.maxCompanyNameAndSymbol; i++) {
                this.regulatoryDisclosure['companyNameCR' + i] = null;
                this.regulatoryDisclosure['companySymbolCR' + i] = null;
            }
            this.regulatoryDisclosure.controlRelationships = [];
        }
        if (this.regulatoryDisclosure.governmentalRelationship === 'No') {
            this.regulatoryDisclosure.governmentRelationPosition = null;
            this.regulatoryDisclosure.selectDomesticTitle = null;
            this.regulatoryDisclosure.selectForeignTitle = null;
        }
    }


    hideAccountSetup() {
        this.accountSetup = false;
    }

    onFinishLater(isExitModal = false) {
        // console.log('onFinishLater: ');
        if (!this.regulatoryDisclosuresForm.valid && isExitModal) {
            this.applicationService.setExitApplicationModal(true);
        }

        this.resetComponentObjectAttr();
        if (Utils.getIsOLBUser() && this.regulatoryDisclosuresForm.valid) {
            this.formService.forms.regulatoryDisclosures.isCompleted = true;
        }
        this.formService.setFormData('regulatoryDisclosures', this.regulatoryDisclosure);

        // To prepare payload with PI , CI, EI, FI , FD and RD
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');


        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        if (Utils.isSpousalRRSP()) {

            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        // save current page if form is valid
        if (!this.formService.forms.regulatoryDisclosures.isCompleted) {
            const emptyRegulatory = new RegulatoryDisclosure();
            this.formService.getPayload().setRegulatoryDisclosure(emptyRegulatory);
        } else {
            if (this.regulatoryDisclosuresForm.valid) {
                this.formService.getPayload().setRegulatoryDisclosure(this.regulatoryDisclosure);
            }
        }

        // console.log('payload ', this.formService.getPayload());

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true })
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });


    }

    saveOnSignOut() {
        this.resetComponentObjectAttr();
        this.formService.setFormData('regulatoryDisclosures', this.regulatoryDisclosure);
        this.formService.forms.regulatoryDisclosures.isCompleted = true;

        // To prepare payload with PI , CI, EI, FI , FD and RD
        const personalInformation = this.formService.getFormData('personalInformation');
        const contactInformation = this.formService.getFormData('contactInformation');
        const employmentInformation = this.formService.getFormData('employmentInformation');
        const familyInformation = this.formService.getFormData('familyInformation');
        const financialInformation = this.formService.getFormData('financialInformation');


        this.formService.getPayload().setPersonalInformation(personalInformation);
        this.formService.getPayload().setContactInformation(contactInformation);
        this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
        this.formService.getPayload().setFinancialInformation(financialInformation);
        // this.formService.getPayload().setRegulatoryDisclosure(this.regulatoryDisclosure);

        if (Utils.isSpousalRRSP()) {

            this.formService.getPayload().setSpousalRRSP(familyInformation);
        }

        // save current page if form is valid
        if (!this.formService.forms.regulatoryDisclosures.isCompleted) {
            const emptyRegulatory = new RegulatoryDisclosure();
            this.formService.getPayload().setRegulatoryDisclosure(emptyRegulatory);
        } else {
            if (this.regulatoryDisclosuresForm.valid) {
                this.formService.getPayload().setRegulatoryDisclosure(this.regulatoryDisclosure);
            }
        }

        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    const saveResponse = res.body;
                    this.applicationService.applicationNumber = saveResponse.applicationNumber;
                    this.appNumber = saveResponse.applicationNumber;
                    this.applicationService.saveOperationPerformed.emit(true);
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                // console.log('onFinishLater err: ', err);
                this.criticalError = true;
            });

    }


    onSubmit() {

        this.isFormSubmit = true;

        // For accessibility purposes, set focus on the invalid element.
        if (!this.regulatoryDisclosuresForm.valid) {
            this.setFocus();
        }

        if (this.regulatoryDisclosuresForm.valid) {

            this.resetComponentObjectAttr();
            this.formService.forms.regulatoryDisclosures.isCompleted = true;
            this.formService.setFormData('regulatoryDisclosures', this.regulatoryDisclosure);

            // To prepare payload with PI , CI, EI, FI , FD and RD
            const personalInformation = this.formService.getFormData('personalInformation');
            const contactInformation = this.formService.getFormData('contactInformation');
            const employmentInformation = this.formService.getFormData('employmentInformation');
            const familyInformation = this.formService.getFormData('familyInformation');
            const financialInformation = this.formService.getFormData('financialInformation');


            this.formService.getPayload().setPersonalInformation(personalInformation);
            this.formService.getPayload().setContactInformation(contactInformation);
            this.formService.getPayload().setEmploymentInformation(employmentInformation, contactInformation);
            this.formService.getPayload().setFamilyInformation(familyInformation);
            this.formService.getPayload().setFinancialInformation(financialInformation);
            this.formService.getPayload().setRegulatoryDisclosure(this.regulatoryDisclosure);

            if (Utils.isSpousalRRSP()) {

                this.formService.getPayload().setSpousalRRSP(familyInformation);
            }

            // console.log('payload with PI CI EI FI FD RD', this.formService.getPayload());

            if (Utils.getIsInSession() === false) {

                this.applicationService.saveAndcontinue(this.regulatoryDisclosuresForm, APP_ROUTES.consents);

            } else if (Utils.getIsInSession() === true) {

                this.applicationService.save(this.formService.getPayload())
                    .then((res: any) => {
                        // console.log(' save res: ', res.body);
                        // console.log(' save res status: ', res.status);
                        if (res.status === SUCCESS_RESPONSE) {
                             this.isFormsubmittedAndSaved = true;
                            this.applicationService.saveAndcontinue(this.regulatoryDisclosuresForm, APP_ROUTES.consents);

                        } else {
                            this.criticalError = true;
                        }
                    })
                    .catch(err => {
                        // console.log('save err: ', err.status);
                        this.criticalError = true; // show error when save fails on top of page
                    });

            }
        } else {
            // console.log(' form is invalid ');
        }
    }

    setFocusByElem(elementId: any) {

        setTimeout(() => {
            document.getElementById(elementId).focus();
        }, 250);

    }

    //For Accessibilty purpose, setFocus() method was created. 
    setFocus() {
        //setTimeout() method has been used since DOM needs to wait for its handlers.
        setTimeout(() => {
            Utils.autoFocusFormFirstField(this.regulatoryDisclosuresForm);
        }, 250)
    }

    ngOnDestroy() {
        // keep information of the form in service when form/ component is destroyed
        // for later data retrieval

        this.resetComponentObjectAttr();
        this.formService.setFormData('regulatoryDisclosures', this.regulatoryDisclosure);
        if (!this.isFormsubmittedAndSaved && this.regulatoryDisclosuresForm.valid) {
            this.formService.getPayload().setRegulatoryDisclosure(this.regulatoryDisclosure);
        }
        this.finishLaterSubscriber.unsubscribe();
        this.onExitApplicationPerformSaveSubscriber.unsubscribe();
    }
    mapRelationArry(relationArray, mapData, mapID) {
        const mappedArray = map(relationArray, function (relation) {
            return relation.mapId === mapID ? mapData : relation;
        });
        return mappedArray;
    }
    setCustomError(type, relationType, index) {
        if (type === 'name') {
            if (this.regulatoryDisclosure['companyName' + relationType + index] === '') {
                this.regulatoryDisclosure['companySymbol' + relationType + index] = null;
            }
            // this.regulatoryDisclosuresForm.controls['companyName' + relationType + index].setErrors({ inValidCompanyName: false });

        } else {
            if (this.regulatoryDisclosure['companySymbol' + relationType + index] === '') {
                this.regulatoryDisclosure['companyName' + relationType + index] = null;
            }
            // this.regulatoryDisclosuresForm.controls['companySymbol' + relationType + index].setErrors({ inValidCompanyName: false });
        }
    }
    companySymbolBlurEvent(companyDetails, type, index) {
        if (companyDetails.custData.isError) {
            this.regulatoryDisclosure['companyName' + type + index] = null;
            this.regulatoryDisclosure['companySymbol' + type + index] = null;
        }
    }
    updateCompanySymbol(companyDetails, type, index) {
        this.contextualSearchData = companyDetails.data;
        let companyItem: any;
        if (isNil(companyDetails.custData.valueObj.value)) {
            this.regulatoryDisclosure['companySymbol' + type + index] = companyDetails.symbol;
            this.regulatoryDisclosure['companyName' + type + index] = companyDetails.name;
            companyItem = new Company(
                this.contextualSearchData.name,
                this.contextualSearchData.symbol,
                this.contextualSearchData.country,
                null,
                null,
                index
            );
        } else {
            companyItem = new Company(
                this.regulatoryDisclosure['companyName' + type + index],
                this.regulatoryDisclosure['companySymbol' + type + index],
                companyDetails.custData.country,
                null,
                null,
                index
            );
        }
        if (companyDetails.custData.isError) {
            if (companyDetails.custData.valueObj.type === 'name') {
                this.regulatoryDisclosuresForm.controls['companyName' + type + index].setErrors({ inValidCompanyName: true });
                this.regulatoryDisclosure['companySymbol' + type + index] = null;
            } else {
                this.regulatoryDisclosuresForm.controls['companySymbol' + type + index].setErrors({ inValidCompanySymbol: true });
                this.regulatoryDisclosure['companyName' + type + index] = null;
            }
        } else {
            if (companyDetails.custData.valueObj.type === 'name') {
                this.regulatoryDisclosuresForm.controls['companyName' + type + index].setErrors(null);
            } else {
                this.regulatoryDisclosuresForm.controls['companySymbol' + type + index].setErrors(null);
            }
        }
        // For IR
        if (type === 'IR') {
            const isIrExist = find(this.regulatoryDisclosure.insiderRelationships, function (item: any) {
                return item.mapId === index;
            });
            if (isIrExist) {
                this.regulatoryDisclosure.insiderRelationships = this.mapRelationArry(
                    this.regulatoryDisclosure.insiderRelationships, companyItem, index);
            } else {
                if (this.regulatoryDisclosure.insiderRelationships.length < this.maxCompanyNameAndSymbol) {
                    this.regulatoryDisclosure.insiderRelationships.push(companyItem);
                }
            }
        }

        // For  spouse/common-law partner insiderRelationships
        if (type === 'IRS') {
            const isIrsExist = find(this.regulatoryDisclosure.spouseInsiderRelationships, function (item: any) {
                return item.mapId === index;
            });
            if (isIrsExist) {
                this.regulatoryDisclosure.spouseInsiderRelationships = this.mapRelationArry(
                    this.regulatoryDisclosure.spouseInsiderRelationships, companyItem, index);
            } else {
                if (this.regulatoryDisclosure.spouseInsiderRelationships.length < this.maxCompanyNameAndSymbol) {
                    this.regulatoryDisclosure.spouseInsiderRelationships.push(companyItem);
                }
            }
        }

        // For Significant shareholder relationships:
        if (type === 'SSR') {
            const isSsrExist = find(this.regulatoryDisclosure.shareholderRelationships, function (item: any) {
                return item.mapId === index;
            });

            if (isSsrExist) {
                this.regulatoryDisclosure.shareholderRelationships = this.mapRelationArry(
                    this.regulatoryDisclosure.shareholderRelationships, companyItem, index);
            } else {
                if (this.regulatoryDisclosure.shareholderRelationships.length < this.maxCompanyNameAndSymbol) {
                    this.regulatoryDisclosure.shareholderRelationships.push(companyItem);
                }
            }
        }
        // For Control relationships
        if (type === 'CR') {
            const isCrExist = find(this.regulatoryDisclosure.controlRelationships, function (item: any) {
                return item.mapId === index;
            });
            if (isCrExist) {
                this.regulatoryDisclosure.controlRelationships = this.mapRelationArry(
                    this.regulatoryDisclosure.controlRelationships, companyItem, index);
            } else {
                if (this.regulatoryDisclosure.controlRelationships.length < this.maxCompanyNameAndSymbol) {
                    this.regulatoryDisclosure.controlRelationships.push(companyItem);
                }

            }
        }
    }

    fetchMemberCode(value) {

        for (const item of this.memberFirm) {
            if (item.label === value) {

                /**Line #528 was added to fix defect 9711. It will display the correct value in case french company name is selected. */
                this.regulatoryDisclosure.memberFromLabel = item.label;
                this.regulatoryDisclosure.memberFirm = item.code;
            }
        }
    }


    setGTM() {

        const gtmDataLayerForRegulatory: GTMDataLayer = {
            'event': ANALYTICS.BMOEVENT,
            'vURL': Utils.getvURL(),
            'vPageTitles': Utils.getPageTitleRegulatoryDisclosures(),
            'category': ANALYTICS.category,
            'action': ANALYTICS.ACTIONS.REGULATORY_DISCLOUSRES,
            'label': ANALYTICS.label,
            'flow': ANALYTICS.flow,
            'prodName': Utils.accountName,
            'prodNumber': Utils.accountNumber,
            'prodTotalNumber': 1,
            'originalLocation': document.location.protocol + '//' + document.location.hostname +
                document.location.pathname + document.location.search,
            'language': Utils.getLanguage(),
            's_PPvid': ANALYTICS.regulatory_disclosures,
            'applicationId': '',
            'signerId': '',
            'packageId': '',
            'assisetdApp': false,
            'customerSource': Utils.getSubProduct(),
            'netWorth': Utils.netWorth
        };

        this.winRef.nativeWindow.dataLayer.push(gtmDataLayerForRegulatory);
        // console.log('regulatory disclosure', this.winRef.nativeWindow.dataLayer);

    }

}

export class RegulatoryDisclosure {
    relationShip = null;
    insiderRelationship = null;
    shareholderRelationship = null;
    spouseInsiderRelationship = null;
    controlRelationship = null;
    governmentalRelationship = null;
    governmentRelationPosition = null;
    selectDomesticTitle = null;
    selectForeignTitle = null;
    companyNameIR0 = null;
    companySymbolIR0 = null;
    companyNameIR1 = null;
    companySymbolIR1 = null;
    companyNameIR2 = null;
    companySymbolIR2 = null;
    companyNameIR3 = null;
    companySymbolIR3 = null;
    companyNameIR4 = null;
    companySymbolIR4 = null;
    companyNameIR5 = null;
    companySymbolIR5 = null;
    companyNameIR6 = null;
    companySymbolIR6 = null;
    companyNameIR7 = null;
    companySymbolIR7 = null;
    companyNameIR8 = null;
    companySymbolIR8 = null;
    companyNameIR9 = null;
    companySymbolIR9 = null;
    companyNameIRS0 = null;
    companySymbolIRS0 = null;
    companyNameIRS1 = null;
    companySymbolIRS1 = null;
    companyNameIRS2 = null;
    companySymbolIRS2 = null;
    companyNameIRS3 = null;
    companySymbolIRS3 = null;
    companyNameIRS4 = null;
    companySymbolIRS4 = null;
    companyNameIRS5 = null;
    companySymbolIRS5 = null;
    companyNameIRS6 = null;
    companySymbolIRS6 = null;
    companyNameIRS7 = null;
    companySymbolIRS7 = null;
    companyNameIRS8 = null;
    companySymbolIRS8 = null;
    companyNameIRS9 = null;
    companySymbolIRS9 = null;
    companyNameSSR0 = null;
    companySymbolSSR0 = null;
    companyNameSSR1 = null;
    companySymbolSSR1 = null;
    companyNameSSR2 = null;
    companySymbolSSR2 = null;
    companyNameSSR3 = null;
    companySymbolSSR3 = null;
    companyNameSSR4 = null;
    companySymbolSSR4 = null;
    companyNameSSR5 = null;
    companySymbolSSR5 = null;
    companyNameSSR6 = null;
    companySymbolSSR6 = null;
    companyNameSSR7 = null;
    companySymbolSSR7 = null;
    companyNameSSR8 = null;
    companySymbolSSR8 = null;
    companyNameSSR9 = null;
    companySymbolSSR9 = null;
    companyNameCR0 = null;
    companySymbolCR0 = null;
    companyNameCR1 = null;
    companySymbolCR1 = null;
    companyNameCR2 = null;
    companySymbolCR2 = null;
    companyNameCR3 = null;
    companySymbolCR3 = null;
    companyNameCR4 = null;
    companySymbolCR4 = null;
    companyNameCR5 = null;
    companySymbolCR5 = null;
    companyNameCR6 = null;
    companySymbolCR6 = null;
    companyNameCR7 = null;
    companySymbolCR7 = null;
    companyNameCR8 = null;
    companySymbolCR8 = null;
    companyNameCR9 = null;
    companySymbolCR9 = null;
    selectMemberFirm = null;
    memberFirm = null;
    memberFromLabel = null;

    insiderRelationships: any[] = [];
    spouseInsiderRelationships: any[] = [];
    shareholderRelationships: any[] = [];
    controlRelationships: any[] = [];
    intendedUseOfAccount = null;
}


export class Company {

    constructor(private companyName,
        private companyTradingSymbol,
        private country,
        private companyExchangeCode,
        private companyExchangeCountry,
        private mapId
    ) {

        this.companyExchangeCode = companyExchangeCode;
        this.companyExchangeCountry = companyExchangeCountry;
        this.companyName = companyName;
        this.companyTradingSymbol = companyTradingSymbol;
        this.country = country;
        this.mapId = mapId;
    }
}
