import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, EventEmitter, Renderer2 } from '@angular/core';
import { ApplicationService } from '../../shared/service/application.service';
import { FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS, LOCATION_INDEX, RIF_PAYMENT_METHOD, BMO_INSTITUTION_CODE, BMO_INSTITUTION_NAME , MARITAL_STATUS } from '../../config/app-config.constants';
import { FormService } from '../../shared/service/form.service';
import { Subscriber, Observable, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { WindowRefService } from '../../shared/service/window-ref.service';
import { AnalyticsService } from '../../shared/service/analytics.service';
import { Utils } from '../../shared/utils/utils.class';
import { GTMDataLayer } from '../../shared/interfaces/GTMDataLayer';
import { WITHDRAWAL_FREQUENCY_DROPDOWN_EN, WITHDRAWAL_FREQUENCY_DROPDOWN_FR, WITHDRAWAL_START_MONTH_DROPDOWN_EN,WITHDRAWAL_START_MONTH_DROPDOWN_FR} from '../../../app/config/app-config.constants';
import * as moment from 'moment';

export class WithdrawalInformationObj {
  isSpouseAgeUsed: any = null;
  frequency: any = null;
  whenInMonth: any = null;
  startMonth: any = null;
  startYear: any = null;
  greaterThanMinimumAmountType: any = null;
  amountType: any = null;
  amount: any = null;
  method: any = null;
  transferAccountNumber: any = null;
  institutionCode: any = null;
  institutionName: any = null;
  transitCode: any = null;
  isJointAccount: any = null;
  spouseDateOfBirth: any = null;
  validAccount: any = null;
  bmoInstitutionCode: any = null;
  bmoInstitutionName: any = null;
}

// export class FinancialInstitutions {
//   financialInstitutions = {
//   fiId:  null,
//   name:  null
//   }
// }

export class FinancialInstitutionObj {
  constructor(fiId,name){
    this.fiId = fiId;
    this.name = name;
  }
  fiId:  any;
  name:  any;
}

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html'
})
export class WithdrawalComponent implements OnInit {

  @ViewChild('withdrawalForm', { static: true }) withdrawalForm: NgForm;
  @ViewChild('annualAmountSelectCtrl', {static: true}) annualAmountSelectCtrl: FormControl;

  public isCurrentLangEnglish: boolean;
  public isCurrentLangFrench: boolean;
  public isFormSubmit: boolean;
  public showFinishLater = true;
  public criticalError: Boolean = false;
  public isReferenceCallFailed: Boolean = false;
  public APP_ROUTES = APP_ROUTES;
  public url: any;
  private getFLSubscriber: Subscription = new Subscription();
  public withdrawalInformationObj: WithdrawalInformationObj;
  private finishLaterSubscriber: Subscription = new Subscription();
  appNumber;
  public frequencyList: any;
  public startMonthList: any;
  public backRoute: any;
  public disableSpouseDob: boolean;
  public showPartnerAgeRqmt: boolean;
  public isSpouseAgeUsed: any;
  public isJointAccount: any;
  public showInstituteCode: boolean;
  public showTransitCode: boolean;
  public isNBMOEFT: boolean;
  public financialInstitutionList: any;
  public tempInstitutionList: any;
  public selectedInstitution: FinancialInstitutionObj;
  public reVisited: boolean;
  public isGreaterAmtSelected: boolean;
  public isIEBrowser: boolean;
  public isIosDevice: boolean;
  private onExitApplicationPerformSaveSubscriber;
  public isAgeUnder18:boolean = false;
  constructor(private applicationService: ApplicationService,
    public titleService: Title,
    private winRef: WindowRefService,
    public formService: FormService,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    public renderer: Renderer2) {

    this.isCurrentLangEnglish = this.applicationService.isCurrentLangEnglish();
    this.isCurrentLangFrench = this.applicationService.isCurrentLangFrench();
    this.formService.forms.withdrawal.isVisited = true;

    this.formService.setActiveComponent('withdrawal', this);
    this.url = '/' + APP_ROUTES.withdrawal;

  }

  ngOnInit(): void {
    this.isIEBrowser = this.winRef.isIEBrowser();
    this.isIosDevice = this.winRef.isIOS();
    if (!Utils.getIsBranchAssisted()) {
      if (Utils.getIsInSession()) {
        this.applicationService.setSignOutLink(true);
        this.showFinishLater = false;
      } else {
        this.applicationService.setSignOutLink(false);
        this.showFinishLater = true;
      }
    } else {
      this.applicationService.setSignOutLink(true);
      this.showFinishLater = false;
    }
    this.formService.setActiveForm(this.withdrawalForm);

    // SET TITLE
    this.titleService.setTitle(Utils.getPageTitleWithdrawalInformation());
    this.withdrawalInformationObj = new WithdrawalInformationObj();
    this.withdrawalInformationObj.startYear = '2';
     // References
     if (this.applicationService.isCurrentLangFrench()) {
      this.frequencyList = WITHDRAWAL_FREQUENCY_DROPDOWN_FR;
    } else { // default is English
      this.frequencyList = WITHDRAWAL_FREQUENCY_DROPDOWN_EN;
    }

    // Set Back route in case of beneficiary
    if (Utils.getBeneficiaryPageCondition() === true) {
      this.backRoute = APP_ROUTES.beneficiary;
    } else {
      this.backRoute = APP_ROUTES.family_information;
    }

    this.showInstituteCode = false;
    this.showTransitCode = false;
    this.isNBMOEFT = false;
    this.isGreaterAmtSelected = false;
    // coming to the page after first time (already visited)
    this.reVisited = false;
     if (this.formService.forms.withdrawal.data != null) {
       this.reVisited =true;
      this.withdrawalInformationObj = this.formService.getFormData('withdrawal');
      if (this.withdrawalInformationObj.frequency){
        this.setMonthList();
      }
      if (this.withdrawalInformationObj.method){
        this.displayTransitInstitute();
      }
      if (this.withdrawalInformationObj.amountType){
        this.setAmountAndTax();
        if (this.withdrawalInformationObj.amountType == '1') {
          this.withdrawalInformationObj.amount = null;
          this.withdrawalInformationObj.greaterThanMinimumAmountType = null;
        }
      }
     // this.withdrawalInformationObj.validAccount = true;
    }
    this.disableSpouseDob = false;
    this.showPartnerAgeRqmt = false;
    const familyInformation = this.formService.getFormData('familyInformation');

    if (familyInformation.maritalStatus === MARITAL_STATUS.MARRIED || familyInformation.maritalStatus === MARITAL_STATUS.COMMON_LAW ||
    (Utils.isSpousalRRSP() && (familyInformation.spousalMaritalStatus === MARITAL_STATUS.MARRIED || familyInformation.spousalMaritalStatus === MARITAL_STATUS.COMMON_LAW))) {
       this.showPartnerAgeRqmt = true;
       if (familyInformation.hasSuccessorAnnuitant === true && familyInformation.spouseDateOfBirth){
         this.disableSpouseDob = true;
         this.withdrawalInformationObj.spouseDateOfBirth = familyInformation.spouseDateOfBirth;
       }else{
         this.disableSpouseDob = false;
       }
    } else {
      this.withdrawalInformationObj.isSpouseAgeUsed = null;
      this.withdrawalInformationObj.spouseDateOfBirth = null;

    }

    this.finishLaterSubscriber = this.applicationService.getPartialSave()
      .subscribe(item => {
        if (item === 'withdrawal') {
          this.onFinishLater();
        }
      });
      this.onExitApplicationPerformSaveSubscriber = this.applicationService.onExitApplicationPerformSave.subscribe((value) => {
        if (value) {
            this.onFinishLater(true);
        }
    });
    /** To perform save operation on signout*/
    this.applicationService.onSignOutPerformSave.subscribe((data: any) => {
      if (data === 'perform save ' + this.url && !Utils.getsaveDoneOnSignOut()) {
        Utils.setsaveDoneOnSignOut(true);
        this.saveOnSignOut();
      }
    });

    this.getFLSubscriber = this.applicationService.getFinishLater().subscribe(item => {
      if (item === false) {
        this.finishLaterSubscriber.unsubscribe();
      }
    });

  }

  ngAfterViewInit(){
    this.analytics.setPageState(this.url);
    this.checkFormValidityOnSignOut();
  }

  checkFormValidityOnSignOut() {
    this.applicationService.onSignOutCheckValidity.subscribe((data: any) => {
        if (data === 'clicked on ' + this.url) {
            if (this.withdrawalForm.valid) {
                this.applicationService.validForm = true;
            } else {
                this.applicationService.validForm = false;
            }
        }
    });
}

  ngOnDestroy() {
    this.formService.setFormData('withdrawal', this.withdrawalInformationObj);
    this.finishLaterSubscriber.unsubscribe();
    this.onExitApplicationPerformSaveSubscriber.unsubscribe();
}

  onSubmit() {
    this.isFormSubmit = true;
    if (!this.withdrawalForm.valid) {
      this.setFocus();
     }else{
      this.resetComponentObjectAttr();
      this.formService.forms.withdrawal.isCompleted = true;
      this.updateFamilyInfoSpouseDob();
      this.formService.setFormData('withdrawal', this.withdrawalInformationObj);
      this.formService.getPayload().setWithdrawalInformation(this.withdrawalInformationObj);

      if (Utils.getIsInSession() === false) {

        if (Utils.getIsBranchReferral() === false && !this.formService.forms.celebrationInformation.isVisited){
            this.formService.forms.celebrationInformation.isVisited = true;
            this.applicationService.saveAndcontinue(this.withdrawalForm, APP_ROUTES.celebration);
        }else{
            this.applicationService.saveAndcontinue(this.withdrawalForm, APP_ROUTES.financial_details);
        }
    } else {
        this.applicationService.save(this.formService.getPayload())
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    if (!this.formService.forms.celebrationInformation.isVisited){
                        this.formService.forms.celebrationInformation.isVisited = true;
                        this.applicationService.saveAndcontinue(this.withdrawalForm, APP_ROUTES.celebration);
                    }else{
                        this.applicationService.saveAndcontinue(this.withdrawalForm, APP_ROUTES.financial_details);
                    }
                } else {
                    this.criticalError = true;
                }
            })
            .catch(err => {
                this.criticalError = true; // show error when save fails on top of page
            });
    }

     }
  }

  onFinishLater(isExitModal = false) {

    if (!this.withdrawalForm.valid && isExitModal) {
        this.applicationService.setExitApplicationModal(true);
    }
    if (Utils.getIsOLBUser()  && this.withdrawalForm.valid) {
      this.formService.forms.withdrawal.isCompleted = true;
  }
    this.formService.setFormData('withdrawal', this.withdrawalInformationObj);

    if (!this.formService.forms.withdrawal.isCompleted) {
      const emptyWithdrawal = new WithdrawalInformationObj();
      this.formService.getPayload().setWithdrawalInformation(emptyWithdrawal);
    } else {
      if (this.withdrawalForm.valid) {
        this.updateFamilyInfoSpouseDob();
        this.formService.getPayload().setWithdrawalInformation(this.withdrawalInformationObj);
      }
    }

    this.applicationService.save(this.formService.getPayload())
      .then((res: any) => {

        if (res.status === SUCCESS_RESPONSE) {
          const saveResponse = res.body;
          this.applicationService.applicationNumber = saveResponse.applicationNumber;
          this.applicationService.openFinishLater({ appNumber: saveResponse.applicationNumber, isAccountSetup: true })
        } else {
          this.criticalError = true;
        }
      })
      .catch(err => {
        this.criticalError = true;
      });
  }

  saveOnSignOut() {

    this.formService.forms.withdrawal.isCompleted = true;
    this.formService.forms.withdrawal.isVisited = true;

    this.formService.setFormData('withdrawal', this.withdrawalInformationObj);

    if (this.withdrawalForm.valid) {
      this.updateFamilyInfoSpouseDob();
      this.formService.getPayload().setWithdrawalInformation(this.withdrawalInformationObj);
    }

    this.applicationService.save(this.formService.getPayload())
      .then((res: any) => {

        if (res.status === SUCCESS_RESPONSE) {
          const saveResponse = res.body;
          this.applicationService.applicationNumber = saveResponse.applicationNumber;
          this.appNumber = saveResponse.applicationNumber;
          this.applicationService.saveOperationPerformed.emit(true);
        } else {
          this.criticalError = true;
        }
      })
      .catch(err => {
        this.criticalError = true;
      });
  }

  resetComponentObjectAttr() {

  }

  setFocus() {
    // setTimeout() method has been used since DOM needs to wait for its handlers.

    setTimeout(() => {
      Utils.autoFocusFormFirstField(this.withdrawalForm);
     }, 250);

}

  updateFamilyInfoSpouseDob() {

    if (this.withdrawalInformationObj.isSpouseAgeUsed === 'true' && this.withdrawalInformationObj.spouseDateOfBirth) {
      const familyInformation = this.formService.getFormData('familyInformation');
      if (!familyInformation.spouseDateOfBirth) {
        familyInformation.spouseDateOfBirth = this.withdrawalInformationObj.spouseDateOfBirth;
        this.formService.setFormData('familyInformation', familyInformation);
        this.formService.getPayload().setFamilyInformation(familyInformation);
      }
    }



}
  setMonthList() {
    let startMonthDropdown = [];
    if (this.applicationService.isCurrentLangFrench()) {
      startMonthDropdown = WITHDRAWAL_START_MONTH_DROPDOWN_FR;
    } else {
      startMonthDropdown = WITHDRAWAL_START_MONTH_DROPDOWN_EN;
    }

    if (this.withdrawalInformationObj.frequency === '1') {
      this.startMonthList = startMonthDropdown.filter(x => x.code === '1');
    }
    if (this.withdrawalInformationObj.frequency === '2') {
      this.startMonthList = startMonthDropdown.filter(x => +x.code <= +'3');
    }
    if (this.withdrawalInformationObj.frequency === '3') {
      this.startMonthList = startMonthDropdown.filter(x => +x.code <= +'6');
    }
    if (this.withdrawalInformationObj.frequency === '4') {
      this.startMonthList = startMonthDropdown;
    }
    if((this.startMonthList.filter(x => x.code == this.withdrawalInformationObj.startMonth)).length==0){
      this.withdrawalInformationObj.startMonth = null;
    }

  }

  async displayTransitInstitute(){
     await this.getFinancialInstitutionCodes();
    if (this.withdrawalInformationObj.method === RIF_PAYMENT_METHOD.BMOEFT){
      this.showInstituteCode = false;
      this.showTransitCode = true;
      this.isNBMOEFT = false;
      this.withdrawalInformationObj.bmoInstitutionCode = BMO_INSTITUTION_CODE;
      this.withdrawalInformationObj.bmoInstitutionName = this.financialInstitutionList?.filter(x => +x.fiId === +BMO_INSTITUTION_CODE).map(y => y.name)[0];

    }else if (this.withdrawalInformationObj.method === RIF_PAYMENT_METHOD.NBMOEFT){
      this.showInstituteCode = true;
      this.isNBMOEFT = true;
      this.showTransitCode = true;
      this.financialInstitutionList = this.financialInstitutionList.filter(x => +x.fiId !== +BMO_INSTITUTION_CODE);
      if (this.reVisited) {
        this.selectedInstitution = this.financialInstitutionList.filter(x => x.fiId === this.withdrawalInformationObj.institutionCode && x.name === this.withdrawalInformationObj.institutionName)[0];
      }
    }else if (this.withdrawalInformationObj.method === RIF_PAYMENT_METHOD.TFR){
      this.showInstituteCode = false;
      this.showTransitCode = false;
      this.isNBMOEFT = false;
      this.withdrawalInformationObj.bmoInstitutionCode = BMO_INSTITUTION_CODE;
      this.withdrawalInformationObj.bmoInstitutionName = this.financialInstitutionList?.filter(x => +x.fiId === +BMO_INSTITUTION_CODE).map(y => y.name)[0];
      //this.withdrawalInformationObj.transitCode = null;
    }
    if (!this.withdrawalInformationObj.bmoInstitutionName){
      this.withdrawalInformationObj.bmoInstitutionName = BMO_INSTITUTION_NAME;
    }

  }

  setInstitution(){

    this.withdrawalInformationObj.institutionName = this.selectedInstitution.name;
    this.withdrawalInformationObj.institutionCode = this.selectedInstitution.fiId;

  }

 async getFinancialInstitutionCodes() {
    // let financialInstitutions = new FinancialInstitutions();
    if (Utils.getFinancialInstitutions()) {
      //financialInstitutions = Utils.getFinancialInstitutions()
      //this.financialInstitutionList = financialInstitutions.financialInstitutions;
      this.tempInstitutionList = Utils.getFinancialInstitutions();
      this.tempInstitutionList.sort((a,b) => +a.fiId - +b.fiId);
      this.financialInstitutionList = this.tempInstitutionList.filter((v,idx,self) => self.findIndex(x => x.fiId === v.fiId && x.name === v.name) === idx);

    } else {
      await this.applicationService.getFinancialInstitutionCodes(Utils.getLanguage())
        .then((res: any) => {
          // financialInstitutions = res;
          // this.tempInstitutionList = financialInstitutions.financialInstitutions;
          this.tempInstitutionList = res;
          //Sort and remove duplicate
          this.tempInstitutionList.sort((a,b) => +a.fiId - +b.fiId);
          this.financialInstitutionList = this.tempInstitutionList.filter((v,idx,self) => self.findIndex(x => x.fiId === v.fiId && x.name === v.name) === idx);

        })
        .catch(err => {
          // this.tempInstitutionList = [{fiId: "0111",name: "NOVA SCOTIA123"},{fiId: "0002",name: "NOVA SCOTIA"},{fiId: "0002",name: "NOVA SCOTIA"},{fiId: "0003",name: "ICICI"},{fiId: "0003",name: "ICICI BANK"},{fiId: "0004",name: "HDFC BANK"}]
          // this.tempInstitutionList.sort((a,b) => +a.fiId - +b.fiId);
          // this.financialInstitutionList = this.tempInstitutionList.filter((v,idx,self) => self.findIndex(x => x.fiId === v.fiId && x.name === v.name) === idx);
          this.criticalError = true;
        });
    }
  }

  setAmountAndTax() {
    if (this.withdrawalInformationObj.amountType === '2') {
       this.isGreaterAmtSelected = true;
    }else{
      this.isGreaterAmtSelected = false;
      // this.withdrawalInformationObj.amount = null;
      // this.withdrawalInformationObj.greaterThanMinimumAmountType = '1';
    }
  }

  isUnder18Age(value:any) {
    this.isAgeUnder18 = value?.invalidAgeUnderEighteen || false;

  }


}
