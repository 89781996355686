import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ElementRef , ViewChild, SimpleChanges, HostListener} from '@angular/core';
import { ApplicationService } from '../../service/application.service';
import { ControlContainer, NgForm , FormControl} from '@angular/forms';
import { BACKSPACE , ENTER , CONTEXTUAL_FOCUSOUT_DROPDOWN_CLOSE} from '../../../config/app-config.constants';
import { get } from 'lodash';
@Component({
    selector: 'app-contextual-search',
    templateUrl: './contextual-search.component.html',
    host: {
        '(document:click)': 'handleClick($event)'
    },
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ContextualSearchComponent implements OnInit, OnChanges {

    @Input() listItems: any[];
    @Input() title: string;
    @Input() CurrentValue;
    @Input() isRequired;
    @Input() section: string;
    @Input() label: any;
    @Input() nameAttr: any;
    @Input() placeholder: string;
    @Input() exclusionList?: any[];
    @Input() listType?: string;
    @Input() isDisabled?: boolean;
    @Input() form: any;
    @Input() maxlength?: any;
    @Input() isIIROCMember: boolean;
    @Input() showErrorOnInvalid: boolean;
    @Input() dynamicInputValidation: any;
    @Input() isFormSubmit: boolean;
    @Input() selectedCode: string;
    @Input() errorID: string;
    @Input() errorMsg: string;
    @Output() changeEventEmitter: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('country', {static: true}) country: FormControl;
    public query = '';
    public filteredList = [];
    public trimmedList: any[];
    public isSearching: boolean;
   
    public selectedItem: any;
    public listExpandFlag: boolean = true;
    public queryNotMatch: boolean = false;
    constructor(public applicationService: ApplicationService, public elementRef: ElementRef) {

    }

    ngOnInit() {
        this.selectedItem = {};

        if (this.listItems && this.CurrentValue) {
            this.selectedItem = this.applicationService.getItemFromCode(this.CurrentValue, this.listItems);
        }

        if (this.listItems && this.CurrentValue && !this.isIIROCMember) {
            this.query = this.applicationService.getLabelFromCode(this.CurrentValue, this.listItems);
        }

        if (this.listItems && this.CurrentValue && this.isIIROCMember) {
            const label = this.applicationService.getLabelFromCode(this.CurrentValue, this.listItems);
            this.query = this.isValueNull(label) ? this.CurrentValue : label;
        }

        if (!this.dynamicInputValidation) {
            this.dynamicInputValidation = false;
        }
       
        if(this.selectedCode) {
           const selectedValue = this.trimmedList.filter((list) => list.code == this.selectedCode);
           if(selectedValue && selectedValue[0]) {
            this.select(selectedValue[0], null);
           }
        }
    }


    @HostListener("keydown", ["$event"])
    onKeyPress(event: KeyboardEvent) {
        if(event.code=="Escape"){
         this.listExpandFlag = false;
         if(document.getElementById(this.nameAttr)){
            document.getElementById(this.nameAttr).focus();
        }
        } else {
            this.listExpandFlag = true;
        }
    }
    isValueNull(value) {
        if (value === '' || value === undefined || value === null) {
            return true;
        }
        return false;
    }

    ngOnChanges(changes : SimpleChanges) {
        this.trimmedList = this.exclusionLogic();
        if (this.listItems && this.CurrentValue && changes.currentValue) {
            if (!this.isIIROCMember) {
                    this.query = this.applicationService.getLabelFromCode(this.CurrentValue, this.listItems);
            } else {
                let labelFromCode = this.applicationService.getLabelFromCode(this.CurrentValue, this.listItems);
                this.query = (labelFromCode) ? labelFromCode : this.CurrentValue;
            }
        }
    }

    changeEvent(selectedValue) {
        this.CurrentValue = selectedValue.code;
        if (this.isIIROCMember) {
            const selectedVal: any = {
                code: selectedValue.code,
                label: selectedValue.label,
                isIrrocMemberSelected: true
            };
            this.selectedItem = selectedVal;
            this.queryNotMatch = false;
            this.changeEventEmitter.emit(selectedVal);
        } else {
            this.queryNotMatch = false;
            this.changeEventEmitter.emit(selectedValue);
        }

    }

    openCountryList() {
        this.trimmedList = this.exclusionLogic();
        if (this.query === '' || this.query === undefined) {
            this.filteredList = this.exclusionLogic();
            this.isSearching = true;
        } else {
            this.filteredList = [];

        }
    }

    openCountryListOnClick() {
        if(this.filteredList?.length > 0) {
            this.filteredList = [];
        } else {
            this.trimmedList = this.exclusionLogic();
            this.filteredList = this.exclusionLogic();
            this.isSearching = true;
        }
       
       
    }
    filter(event: any = null) {
        if (this.query !== '' && this.query !== undefined && this.query !== null) {
            this.filteredList = this.trimmedList.filter(function (el) {
                return el.label.toLowerCase().includes(this.query.toLowerCase());
            }.bind(this));

            // if ((this.filteredList || []).length === 1 && (event || {}).keyCode !== BACKSPACE) {
            //     if ((this.filteredList[0].label || '').toLowerCase() === this.query.toLowerCase()) {
            //         this.select(this.filteredList[0], null);
            //     }

            // }
        } else {
            if(event?.keyCode == BACKSPACE) {
                this.openCountryList();
            } else if(!event || event.keyCode !== ENTER) { 
            this.filteredList = [];
            this.isSearching = true;
             }
        }
    }


    exclusionLogic() {
        if ((this.exclusionList && this.exclusionList.length > 0 && this.listType === 'country') || (this.exclusionList && this.exclusionList.length > 0 && this.listType === 'state')) {
            return this.listItems.filter((item) => {
                return !this.exclusionList.includes(item.code);
            });
        }
        return this.listItems;
    }

    handleClick(event) {
        let clickedComponent = event.target;
        let inside = false;

        do {
            if (clickedComponent === this.elementRef.nativeElement) {
                inside = true;
            }
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);
        if (!inside) {
            this.filteredList = [];
            
            if (!this.CurrentValue && !this.isIIROCMember || (this.queryNotMatch)) {
                this.query = undefined;
                this.isSearching = false;
                this.queryNotMatch = false;
                this.changeEventEmitter.emit(this.query);
            }
            if (this.isIIROCMember) {

                if (this.query !== this.selectedItem.label) {
                    this.queryNotMatch = false;
                    this.changeEventEmitter.emit(this.query);
                } else {                    
                    this.selectedItem.isIrrocMemberSelected = true;
                    this.queryNotMatch = false;
                    this.changeEventEmitter.emit(this.selectedItem);
                }
            }
        }
    }

    onChange() {
        this.queryNotMatch = false;
        if ((this.query != this.applicationService.getLabelFromCode(this.CurrentValue, this.listItems) || this.query === '' || this.query === undefined) && !this.isIIROCMember) {
            this.changeEventEmitter.emit(null);
            this.queryNotMatch = true;
        } else if (this.CurrentValue && this.query == this.applicationService.getLabelFromCode(this.CurrentValue, this.listItems)) {
               const currentVal: any = { code: this.CurrentValue,label: this.query, isIrrocMemberSelected: false};
               this.changeEventEmitter.emit(currentVal);
        }

    }

    onBlurEvent() {
        if (this.isIIROCMember) {
            if (!this.selectedItem || !(this.selectedItem || {}).code || !this.query || this.query === '') {
                this.selectedItem = {
                    code: this.query,
                    isblur: "true",
                };
            } else {
                this.selectedItem.isblur = "true";
            }
            this.queryNotMatch = false;
            this.changeEventEmitter.emit(this.selectedItem);
        }
    }

    select(item, $event) {
        this.selectedItem = item;
        this.query = item.label;
        setTimeout(() => {
         this.filteredList = [];
        }, 100);
        this.isSearching = false;
        this.changeEvent(item);
        if(document.getElementById(this.nameAttr)){
            document.getElementById(this.nameAttr).focus();
        }
        if(event) {
            event.preventDefault();
        }
    }
    onFocusOut($event) {
        if(CONTEXTUAL_FOCUSOUT_DROPDOWN_CLOSE.indexOf($event?.relatedTarget?.id) >= 0) {
            this.filteredList = [];
        }
    }
  
onComboboxKeyDown(event, currentItemIndex?) {
      var altKey = event.altKey;
      let parentElement = document.querySelector('#aria'+this.nameAttr+'Control');
      let nodes = parentElement.querySelectorAll('a');
     
    if (event.ctrlKey || event.shiftKey) {
      return;
    }
    
    switch (event.key) {
      case 'Down':
      case 'ArrowDown':
        if (this.filteredList.length > 0) {
          if (altKey) {
            this.openCountryList();
          } else {
            if (currentItemIndex>=0) {
                nodes[currentItemIndex+1].focus();
            } else {             
                nodes[0].focus();       
            }
          }
        }
        break;

      case 'Up':
      case 'ArrowUp':
        if (this.filteredList.length > 0) {
          if (currentItemIndex>=1) {
            nodes[currentItemIndex-1].focus();
          } else {
            if (!altKey) {
                nodes[0].focus();
            }
          }
        }
        break;
    }
  }
}
