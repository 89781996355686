import { CASH, MARGIN } from './../../config/app-config.constants';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormService } from '../../shared/service/form.service';
import { PersonalInformationComponent } from '../personal-information/personal-information.component';
import { ApplicationService } from '../../shared/service/application.service';
import { ActivatedRoute, Params, Route, Router, RouterStateSnapshot, NavigationEnd, NavigationStart, NavigationExtras } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../shared/utils/utils.class';
import { APP_ROUTES, SUCCESS_RESPONSE, ANALYTICS } from '../../config/app-config.constants';
import { Subscription } from 'rxjs';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, OnDestroy, AfterContentChecked {
    public lang: string;
    public nav;
    public isDisabled: boolean = false;
    public isBeneficiaryPageDisabled: boolean = false;
    public isWithdrawalPageDisabled: boolean = false;

    public subProduct: string;
    public queryParams = {};
    public adviceDirect = false;
    public selfDirected = false;
    public APP_ROUTES = APP_ROUTES;
    public BILAssisted = false;
    public isCashAccount: boolean = false;
    public currentPage: any = {};
    public routeSubs: Subscription;
    public isOlbCdbUser: Boolean = false;
    public beneficiaryCondition: Boolean = false;
    // hidePersonalInfo: boolean;

    public tabIndexValue: any = 0;
    public branchSDMultiAccount:any;

    hasBeneficiaryDetails = false;
    disableSelectAccount: boolean;
    disablePersonalInformation: boolean;
    disableContactInformation: boolean;
    disableEmploymentInformation: boolean;
    disableFamilyInformation: boolean;
    disableBeneficiaryInformation: boolean;
    disableWithdrawal: boolean;
    disableFinancialInformation: boolean;
    disbaleInvestmentKnowledge: boolean;
    disableRiskAndReturn: boolean;
    disableInvestorProfile: boolean;
    disableRegulatoryDisclosures: boolean;
    disableConsents: boolean;
    disableBmoRelationship: boolean;
    disableAccountPassword: boolean;
    disableReview: boolean;
    isMargin: boolean = false;
    isJourneyUser: boolean = false;
    isNonRegisteredAccount: boolean = false;

    constructor(public formService: FormService,
        private configService: ConfigResolverService<any>,
        private applicationService: ApplicationService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public translate: TranslateService) {

        // console.log(' forms', this.formService.forms);

        if (Utils.isSubProductAD()) {
            this.adviceDirect = true;
        } else {
            this.selfDirected = true;
        }

        this.routeSubs = this.router.events.subscribe((event: any) => {
           /*  if (event instanceof NavigationStart) {        //Fix for MWB-12655
                const url = event.url;
                if (url.includes(APP_ROUTES.financial_details)) {
                    this.currentPage['financial'] = true;
                } else {
                    this.currentPage['financial'] = false;
                }
            } */
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    const url = event.url;
                    Object.keys(APP_ROUTES).forEach(form => {
                        let doc = document.getElementById(form.toString());
                        if (doc && doc.hasAttribute("aria-current"))
                            document.getElementById(form.toString()).removeAttribute("aria-current");
                        if (url.indexOf(APP_ROUTES[form]) !== -1) {
                            let ele = document.getElementById(form.toString());
                            if (ele)
                                ele.setAttribute("aria-current", "page");
                        }
                    });
                    var element = document.getElementById("set-expectation");
                    if (element) {
                        if (url.includes(APP_ROUTES.set_expectation)) {
                            element.classList.add("active");
                        } else {
                            element.classList.remove("active");
                        }
                    }
                }, 500);
            }

        });

        // Beneficiary page condition
        this.beneficiaryCondition = Utils.getBeneficiaryPageCondition();
    }

    ngOnInit() {
        this.BILAssisted = this.configService.getConfig().BRANCH_ASSISTED === 'true' ? true : false;
        this.isCashAccount = Utils.isCash();
        this.isMargin = Utils.isMargin();
        this.isNonRegisteredAccount = this.isCashAccount || this.isMargin;
        this.branchSDMultiAccount = Utils.isBranchSDMultiAccountFlow();
        if (Utils.isSubProductAD()) {
            this.queryParams = {
                'lang': this.applicationService.getCurrentLanguage(),
                'subProduct': Utils.getSubProduct()

            };
        } else {
            this.queryParams = {
                'lang': this.applicationService.getCurrentLanguage(),
            };
        }
        if(Utils.getMboxDisable() && Utils.getMboxEdit()){
            this.queryParams['mboxEdit'] = Utils.getMboxEdit();
            this.queryParams['mboxDisable'] = Utils.getMboxDisable();
        }
        this.isOlbCdbUser = Utils.getIsOLBUser();
         // this.applicationService.gethidePersonalInfor().subscribe((val) => {
        //     this.hidePersonalInfo = val && val === true ? true : false;
        // })


        this.route.queryParams.subscribe(params => {
            if (params.lang) {
                this.lang = params.lang;
                if (Utils.isSubProductAD()) {
                        this.queryParams = {
                            'lang': Utils.getLanguage(),
                            'subProduct': Utils.getSubProduct()
                        }
                    this.subProduct = params.subProduct;
                }
                else {
                    this.queryParams = {
                        'lang': Utils.getLanguage()
                    }
                }
            } else {
                this.lang = 'en';
            }

            if(Utils.getMboxDisable() && Utils.getMboxEdit()){
                this.queryParams['mboxEdit'] = Utils.getMboxEdit();
                this.queryParams['mboxDisable'] = Utils.getMboxDisable();
            }
        });

        this.nav = this.applicationService.getBeneficiaryNavigationDisabled()
            .subscribe(item => {
                this.isBeneficiaryPageDisabled = item; // Disable or Enable  Navigation
                if(!this.isBeneficiaryPageDisabled) {
                    this.beneficiaryCondition = Utils.getBeneficiaryPageCondition();
                }

            });

        this.nav = this.applicationService.getWithdrawalNavigationDisabled()
        .subscribe(item => {
            this.isWithdrawalPageDisabled = item;
        });

        this.nav = this.applicationService.getNavigationDisabled()
            .subscribe(item => {
                this.isDisabled = item; // Disable or Enable  Navigation
                this.tabIndexValue = -1;
            });


        this.nav = this.applicationService.getAccountFlow()
            .subscribe(item => {
                console.log(item)
                if (item === 'AD') {
                    this.adviceDirect = true;

                } else {
                    this.adviceDirect = false;
                }
            });

        this.applicationService.selectAccount$.subscribe((accountData) => {
            // using this observable for resume application scenario and to show/hide beneficiary link in the nav menu
            this.hasBeneficiaryDetails = Utils.hasBeneficiaryDetails();
        });
        if (Utils.getIsBranchAssisted() !== true) {
            this.enableDisableLeftNav();
        }

        if(this.isJourneysUser){
            this.applicationService.setCashMarginAccount.subscribe(value => {
                this.isNonRegisteredAccount = (value.type === MARGIN.type || value.type === CASH.type) && value.isChecked;
            });
            this.applicationService.nonRegUnselected$.subscribe(value => {
                this.isNonRegisteredAccount = value;
            });
        }

    }

    get withdrawalCondition() {
        return Utils.getWithdrawalPageCondition();
    }

    get isJourneysUser(){
        return Utils.getIsJourneyUser();
    }

    triggerValidation() {
        // this.formService.currentFormComponent.onSubmit();
    }
    setNavigationSA() {
        Utils.setIsSelectAccountClicked(true);
        Utils.backToSelectAccountPage = true;
    }

    ngOnDestroy() {
        if (this.routeSubs) {
            this.routeSubs.unsubscribe();
        }
    }

    ngAfterContentChecked() {

        this.cdr.detectChanges();
        if (Utils.getIsBranchAssisted() !== true) {
            this.enableDisableLeftNav();
        }

    }

    enableDisableLeftNav(){
        this.resetLeftNav();
        if(!this.formService.forms.setExpectation.isCompleted){
            this.disableSelectAccount = true;
            this.disablePersonalInformation = true;
            this.disableContactInformation = true;
            this.disableEmploymentInformation = true;
            this.disableFamilyInformation = true;
            this.disableBeneficiaryInformation = true;
            this.disableWithdrawal = true;
            this.disableFinancialInformation = true;
            this.disbaleInvestmentKnowledge = true;
            this.disableRiskAndReturn = true;
            this.disableInvestorProfile = true;
            this.disableRegulatoryDisclosures = true;
            this.disableConsents = true;
            this.disableBmoRelationship = true;
            this.disableAccountPassword = true;
            this.disableReview = true;
        }
    }

    resetLeftNav(){
        this.disableSelectAccount = false;
        this.disablePersonalInformation = false;
        this.disableContactInformation = false;
        this.disableEmploymentInformation = false;
        this.disableFamilyInformation = false;
        this.disableBeneficiaryInformation = false;
        this.disableWithdrawal = false;
        this.disableFinancialInformation = false;
        this.disbaleInvestmentKnowledge = false;
        this.disableRiskAndReturn = false;
        this.disableInvestorProfile = false;
        this.disableRegulatoryDisclosures = false;
        this.disableConsents = false;
        this.disableBmoRelationship = false;
        this.disableAccountPassword = false;
        this.disableReview = false;
    }


}
