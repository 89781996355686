
import {
    FormControl,
    ValidatorFn,
    Validator, AbstractControl, NG_VALIDATORS,
} from '@angular/forms';

import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[appMinLengthValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MinLengthValidator,
            multi: true
        }
    ]

})
export class MinLengthValidator implements Validator {
    validator: ValidatorFn;
    // public minLength;
    // @Input() set appMinLengthValidator(minLength: any){
    //     this.minLength = +minLength;
    // }

 @Input('minLengthVal') minLengthVal: any;

    constructor() {
        this.validator = this.minLengthValidator();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    minLengthValidator(): ValidatorFn {
        return (control: AbstractControl) => {
          let controlValue = control.value;

            if (control.value !== undefined && control.value !== null) {
                controlValue = controlValue.trim();

                if (controlValue.length >= this.minLengthVal) {
                    return null;        // if the pattern is correct, retrun null means no errors
                }
                return {
                    minLengthError: {
                        min: true
                    }
                };
            }

        };
    }
}
