import { NG_VALIDATORS, FormControl, ValidatorFn, Validator, AbstractControl } from '@angular/forms';
import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appZipCodeValidator][ngModel]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ZipCodeValidator,
        multi: true
    }]
})
export class ZipCodeValidator implements Validator {
    validator: ValidatorFn;
    public numLockKeys: Array<string> = ['Del', 'Divide', 'Add', 'Subtract', 'Multiply'];
    constructor() {
        this.validator = this.zipCodeValidator();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }


    zipCodeValidator(): ValidatorFn {
        const zipCodePattern = new RegExp(/^\d{5}$|^\d{5}-\d{4,6}$/);
        return (control: AbstractControl) => {
            if (!control.value || zipCodePattern.test(control.value)) {
                return null;
            } else {
                return { zipCode: { invalidZipCode: true } };
            }
        };
    }

    @HostListener('keypress', ['$event'])
    keyEvent(event: KeyboardEvent) {
        const numbersOnly = new RegExp('^[0-9]+$');
        if (!numbersOnly.test(event.key)) {
            event.preventDefault();
            return;
        }

        if (this.numLockKeys.indexOf(event.key) !== -1) {
            event.preventDefault();
            return;
        }

    }
}

